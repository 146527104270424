// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  BooleanInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
import { useFormContext } from "react-hook-form";
import {
  add,
  format,
  getHours,
  getMinutes,
  getSeconds,
  lastDayOfMonth,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: "auto",
    },
  },
});

const availableCountry = [
  { id: "HK", name: "Hongkong" },
  { id: "ID", name: "Indonesia" },
  { id: "TW", name: "Taiwan" },
  { id: "MY", name: "Malaysia" },
];

export const CreateReferralBenefits = ({ onCancel, ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (data) => {
    await dataProvider
      .create("promotionsReferralBenefits", {
        data,
      })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/promotions/referral_benefits`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  const ToolbarCreate = () => {
    return (
      <Toolbar classes={useStyles()}>
        <SaveButton />
      </Toolbar>
    );
  };

  const today = new Date();
  const getHour = [getHours(today), getMinutes(today), getSeconds(today)];
  const newDay = add(today, { years: 30 });
  const defaultValidUntil = setSeconds(
    setMinutes(setHours(lastDayOfMonth(newDay), getHour[0]), getHour[1]),
    getHour[2]
  );

  return (
    <Create
      resource="promotionsReferralBenefits"
      title=" &rsaquo; Create"
      {...props}
    >
      <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{ width: "100%" }}
          onSubmit={onSubmitHandler}
          toolbar={<ToolbarCreate />}
        >
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <legend
                  className="MuiFormLabel-root MuiFormLabel-colorPrimary RaRadioButtonGroupInput-label MuiFormLabel-root"
                  style={{
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  <span>Country</span>
                </legend>
                <BooleanInput
                  source="country.worldwide"
                  label="Worldwide?"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-country-worldwide"
                />
              </Grid>
              <Grid item xs={8}>
                <CountryAutocomplete validate={required()} />
              </Grid>
              <Grid item xs={12} style={{ maxHeight: "5px" }}>
                <legend
                  className="MuiFormLabel-root MuiFormLabel-colorPrimary RaRadioButtonGroupInput-label MuiFormLabel-root"
                  style={{
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  <span>Rewards for REFERRER</span>
                </legend>
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="rewards.referrer.coin"
                  label="Coin"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-rewards-referrer-coin"
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="rewards.referrer.point"
                  label="Point"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-rewards-referrer-point"
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={8}>
                <ReferenceInput
                  source="rewards.referrer.package"
                  reference="servicesPackage"
                  label="Package"
                >
                  <AutocompleteInput
                    fullWidth
                    source="label"
                    optionText="label"
                    label="Package"
                    data-testid="input-rewards-referrer-package"
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} style={{ maxHeight: "5px" }}>
                <legend
                  className="MuiFormLabel-root MuiFormLabel-colorPrimary RaRadioButtonGroupInput-label MuiFormLabel-root"
                  style={{
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  <span>Rewards for REFEREE</span>
                </legend>
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="rewards.referee.coin"
                  label="Coin"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-rewards-referee-coin"
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="rewards.referee.point"
                  label="Point"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-rewards-referee-point"
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={8}>
                <ReferenceInput
                  source="rewards.referee.package"
                  reference="servicesPackage"
                  label="Package"
                >
                  <AutocompleteInput
                    fullWidth
                    source="label"
                    optionText="label"
                    label="Package"
                    data-testid="input-rewards-referee-package"
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={10}>
                <RulesRadioGroup
                  label="When applied?"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="limit"
                  label="Limit"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-limit"
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid From"
                  source="start"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-start"
                  defaultValue={today}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid Until"
                  source="end"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-end"
                  defaultValue={defaultValidUntil}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Box>
    </Create>
  );
};

const CountryAutocomplete = ({ validate }) => {
  const classes = useStyles();

  const [autocompleteValue, setAutocompleteValue] = useState([]);

  const { getValues, setValue } = useFormContext();
  const worldwide = getValues("country.worldwide");

  let countryField = "",
    contraCountryField = "",
    label = "";
  if (worldwide) {
    countryField = "country.exclude";
    contraCountryField = "country.include";
    label = "Excluding...";
  } else {
    countryField = "country.include";
    contraCountryField = "country.exclude";
    label = "Including...";
  }
  useEffect(() => {
    let haloTimeout = setTimeout(() => {
      let countryFieldVal = getValues(countryField);
      if (!countryFieldVal) countryFieldVal = [];
      setAutocompleteValue(
        countryFieldVal
          ?.map((v) => ({
            id: v,
            name: availableCountry.find((x) => x.id === v)?.name,
          }))
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
      );
    }, 2000);

    return () => clearTimeout(haloTimeout);
  }, [countryField, getValues]);

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={availableCountry}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={autocompleteValue}
      onChange={(_, v) => {
        setValue(
          countryField,
          v.map((inc) => inc.id),
          { shouldDirty: true }
        );
        setValue(contraCountryField, []);
        setAutocompleteValue(v);
      }}
      required={validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          placeholder={`select Country`}
          data-testid="input-country"
        />
      )}
    />
  );
};

const RulesRadioGroup = (props) => {
  const rules = {
    "on-codeMatch": false,
    "on-firstReload": false,
    "on-firstPurchase": false,
  };
  const rulesArray = Object.keys(rules);
  const defaultValue = rulesArray.find((key) => rules[key] === true);

  const { setValue } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRules = { ...rules };
    Object.keys(updatedRules).forEach((rule) => (updatedRules[rule] = false));
    setValue(
      "rules",
      { ...updatedRules, [event.target.value]: true },
      { shouldDirty: true }
    );
  };

  return (
    <FormControl>
      <FormLabel
        id="demo-row-radio-buttons-group-label"
        style={{
          color: "black",
          fontSize: "smaller",
        }}
      >
        {props.label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {rulesArray.map((rule) => {
          return (
            <FormControlLabel
              value={rule}
              key={rule}
              control={<Radio />}
              label={rule}
              data-testid={`input-rules-${rule}`}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
