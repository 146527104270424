import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { MuiChipsInput } from "mui-chips-input";
import Mbaas from "../../provider/Mbaas";
import Swal from "sweetalert2";

export default function AddMenu({
  open,
  setOpen,
  refetch,
  modeForm,
  data,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
  modeForm: string;
  data: any;
}) {
  const [purpose, setPurpose] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [labelEng, setLabelEng] = useState<string>("");
  const [platform, setPlatform] = useState<string>("android");
  const [screen, setScreen] = useState<string>("belanja");

  const [worldwide, setWorldwide] = useState<string>("false");
  const [include, setInclude] = useState<Array<string>>([]);
  const [exclude, setExclude] = useState<Array<string>>([]);

  const [token, setToken] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const resetState = () => {
    setPurpose("");
    setLabel("");
    setLabelEng("");
    setPlatform("android");
    setScreen("belanja");
    setWorldwide("false");
    setInclude([]);
    setExclude([]);
    setToken("");
    setError("");
  };

  useEffect(() => {
    if (modeForm === "edit") {
      setupData();
    }
  }, [data]);

  const setupData = () => {
    setPurpose(data.purpose || "");
    if (data.configuration) {
      setLabel(data.configuration.label_id || "");
      setLabelEng(data.configuration.label_eng || "");
      setPlatform(data.configuration.platform || "android");
      setScreen(data.configuration.screen || "belanja");
    }
    setWorldwide(data.country.worldwide.toString() || "false");
    setInclude(data.country.include || []);
    setExclude(data.country.exclude || []);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const dataForm = {
      token,
      data: {
        country: {
          worldwide: worldwide === "true",
          include,
          exclude,
        },
        purpose,
        configuration: {
          label_id: label,
          label_eng: labelEng,
          platform: platform,
          screen: screen,
        },
      },
    };

    let req;

    if (modeForm === "create") {
      req = await Mbaas.client.invokeApi("console-create-menu-layout", dataForm);
    } else {
      req = await Mbaas.client.invokeApi("console-update-menu-layout", {
        ...dataForm,
        id: data.id,
      });
    }

    console.log(req);

    if (req.data) {
      Swal.fire(
        "Success!",
        modeForm === "create" ? "Menu added" : "Menu Edited",
        "success"
      );
      refetch();
      setOpen(false);
      resetState();
    } else {
      setError(req.error.error);
      //   Swal.fire("Something wrong!", req.error.error, "error");
    }

    setLoading(false);
    // await uploadImage(childItem[0].children.data);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            <AddBoxIcon color="success" />{" "}
            {modeForm === "create" ? "Add New Menu Group" : "Edit Menu Group"}
          </Grid>
        </DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <DialogContentText align="center">
              {/* Scan this QRcode with Google Authenticator */}
            </DialogContentText>
            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <b>Config Purpose</b>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  label="Purpose"
                  value={purpose || ""}
                  onChange={(e) => setPurpose(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  size="medium"
                />
              </Box>
              <b>Configuration</b>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  label="Label Indonesia"
                  value={label || ""}
                  onChange={(e) => setLabel(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  size="medium"
                />
                <Box width={"32px"} />{" "}
                <TextField
                  label="Label English"
                  value={labelEng || ""}
                  onChange={(e) => setLabelEng(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Platform
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={platform}
                    label="Platform"
                    size="medium"
                    onChange={(e) => {
                      setPlatform(e.target.value);
                    }}
                  >
                    <MenuItem value={"android"}>ANDROID</MenuItem>
                    <MenuItem value={"ios"}>IOS</MenuItem>
                    <MenuItem value={"android,ios"}>ALL</MenuItem>
                  </Select>
                </FormControl>
                <Box width={"32px"} />{" "}
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Screen
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={screen}
                    label="Screen"
                    size="medium"
                    onChange={(e) => {
                      setScreen(e.target.value);
                    }}
                  >
                    <MenuItem value={"belanja"}>BELANJA</MenuItem>
                    <MenuItem value={"home"}>HOME</MenuItem>
                    <MenuItem value={"as2in1-home"}>AS2IN1 HOME</MenuItem>
                    <MenuItem value={"as2in1-postpaid-home"}>AS2IN1 POSTPAID HOME</MenuItem>
                    <MenuItem value={"as2in1-belanja"}>AS2IN1 BELANJA</MenuItem>
                    <MenuItem value={"as2in1-postpaid-belanja"}>AS2IN1 POSTPAID BELANJA</MenuItem>
                    <MenuItem value={"test-home"}>TEST HOME</MenuItem>
                    <MenuItem value={"test-belanja"}>TEST BELANJA</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <b>Country</b>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">Mode</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={worldwide}
                    label="Mode"
                    size="medium"
                    onChange={(e) => {
                      setWorldwide(e.target.value);
                      setInclude([]);
                      setExclude([]);
                    }}
                  >
                    <MenuItem value={"true"}>Worldwide</MenuItem>
                    <MenuItem value={"false"}>Selected Country</MenuItem>
                  </Select>
                </FormControl>

                {worldwide === "false" ? (
                  <MuiChipsInput
                    value={include}
                    onChange={(e: any) => setInclude(e)}
                    label="Include Country"
                    fullWidth
                    clearInputOnBlur
                    placeholder="Type and press enter"
                    variant="outlined"
                  />
                ) : (
                  <MuiChipsInput
                    value={exclude}
                    onChange={(e: any) => setExclude(e)}
                    label="Exclude Country"
                    clearInputOnBlur
                    fullWidth
                    placeholder="Type and press enter"
                    variant="outlined"
                  />
                )}
              </Box>
              <b>MFA</b>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  label="Token"
                  value={token || ""}
                  onChange={(e) => setToken(e.target.value)}
                  required
                  fullWidth
                  variant="outlined"
                  size="medium"
                />
                {error !== "" && <Alert severity="error">{error}</Alert>}
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              // onClick={() => setOpen(false)}
              // disabled={loading}
              type="submit"
              color="primary"
              size="small"
              variant="contained"
            >
              {loading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                resetState();
              }}
              // disabled={loading}
              color="primary"
              size="small"
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
