import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IdeviceLogs {
  deviceLogsFilter: {
    fingerprint: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: IdeviceLogs = {
  deviceLogsFilter: {
    fingerprint: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type deviceLogsFilter = {
  fingerprint: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const deviceLogsFilterSlice = createSlice({
  name: "thisDeviceLogsFilter",
  initialState,
  reducers: {
    setDeviceLogsFilter: (state, action: PayloadAction<deviceLogsFilter>) => {
      state.deviceLogsFilter = {
        ...state.deviceLogsFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setDeviceLogsFilter } = deviceLogsFilterSlice.actions;

export const getDeviceLogsFilter = (state: RootState) =>
  state.thisDeviceLogsFilter.deviceLogsFilter;

export default deviceLogsFilterSlice.reducer;
