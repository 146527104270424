// @ts-nocheck
import {
  SimpleForm,
  Edit,
  SelectInput,
  TextInput,
  useRecordContext,
  ImageInput,
  ImageField,
  required,
  useNotify,
  useRedirect,
  useDataProvider,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  FormDataConsumer,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

const CustomToolbar = ({ dataForm, initDataForm }: any) => {
  console.log("init", initDataForm);
  console.log("late", dataForm);
  console.log(_.isEqual(dataForm, initDataForm));
  const tempFormData = Object.keys(dataForm).forEach(
    (key) => !dataForm[key] && delete dataForm[key]
  );

  const notify = useNotify();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();
  const { mutate, isLoading, isSuccess } = useMutation(() =>
    dataProvider.update("diasporaHoliday", {
      data: { ...dataForm },
      id: dataForm?.id,
    })
  );
  const [eq, setEq] = useState(true);
  useEffect(() => {
    const equal = _.isEqual(dataForm, initDataForm);
    setEq(equal);
  }, [dataForm]);

  useEffect(() => {
    if (isSuccess) {
      // notify("ra.notification.updated", "info", { smart_count: 1 });
      redirect("/diasporaHoliday");
    }
  }, [isSuccess]);
  return (
    <Toolbar classes={useStyles()}>
      <SaveButton
        onClick={(event) => {
          if (Object.keys(dataForm).length >= 8) {
            event.preventDefault();
            mutate();
          }
        }}
        data-test-id="save_btn"
        disabled={eq}
      />
      <DeleteWithConfirmButton confirmContent="Are you sure want to delete this record?" />
    </Toolbar>
  );
};

const EditTitle = () => {
  return <span>Diaspora Holiday &gt; Edit</span>;
};

const EditPage = (props) => {
  const classes = useStyles();

  const countryList = [
    { id: "GLOBAL", name: "Worldwide" },
    { id: "HK", name: "Hong Kong" },
    { id: "ID", name: "Indonesia" },
    { id: "MY", name: "Malaysia" },
    { id: "TW", name: "Taiwan" },
  ];

  const PrevImage = () => {
    const record: any = useRecordContext();
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBlockEnd: 50,
        }}
      >
        <div>
          <span style={{ fontWeight: 300, fontSize: "0.75em" }}>
            Previous Image
          </span>
        </div>
        <div style={{ marginBlockStart: 20 }}>
          <img
            style={{ marginInlineStart: 40 }}
            src={record?.urlThumbnail}
            width={150}
          ></img>
        </div>
      </div>
    );
  };

  const [initDataForm, setInitDataForm] = useState({});
  const [dataForm, setDataForm] = useState({});
  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm
        toolbar={
          <CustomToolbar dataForm={dataForm} initDataForm={initDataForm} />
        }
      >
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  data-test-id="country_select"
                  fullWidth
                  className={classes.input}
                  source="country_code"
                  validate={[required()]}
                  choices={countryList}
                  label="Country"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  data-test-id="title_input"
                  fullWidth
                  validate={[required()]}
                  source="date"
                  label="Title (Date)"
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PrevImage />
                <ImageInput
                  source="newUrlThumbnail"
                  label="Thumbnail"
                  accept="image/*"
                  options={{ multiple: false }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Grid>
              <Box flex={1} mr="0.5em" display="none">
                <TextInput source="oldThumbnail" label="Line Count" fullWidth />
              </Box>
            </Grid>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (Object.keys(initDataForm).length === 0) {
                  setInitDataForm(formData);
                }
                setDataForm(formData);
              }}
            </FormDataConsumer>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));

export default EditPage;
