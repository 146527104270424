// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

import gql from "graphql-tag";
import mbaas from "../../../provider/Mbaas";
import { alpha } from "@mui/material/styles";
import { buildArgs } from "./../../../provider/utils2";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import ChoosenProductCatalogTable from "./ChoosenProductCatalogTable";
import TextField from "@mui/material/TextField";

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    numSelected,
    selected,
    setSelected,
    setFlag,
    setSearchString,
    flagRef,
    setChoosenProduct,
  } = props;

  const handleSetSearch = (value: string) => {
    setSearchString(value);
  };

  const handleSetChoosenProduct = (selected: any) => {
    setChoosenProduct((oldArray) => [...oldArray, ...selected]);
    setFlag("add-product");

    flagRef.current = "add-product";

    setSelected([]);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Product Catalog
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Add">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => handleSetChoosenProduct(selected)}
            style={{
              marginRight: "14px",
              height: "40px",
            }}
          >
            Add
          </Button>
        </Tooltip>
      ) : (
        <></>
      )}
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        onChange={(e) => handleSetSearch(e.target.value)}
      />
    </Toolbar>
  );
};

export default function ProductCatalogTable(props) {
  const { recordId, onClose, drawerOpen } = props;
  const [productCatalogList, setProductCatalogList] = useState([]);
  const [productCatalogCount, setProductCatalogCount] = useState(0);
  const [selected, setSelected] = React.useState<string[]>([]);
  //
  const [removed, setRemoved] = React.useState<string[]>([]);
  const [choosenProduct, setChoosenProduct] = React.useState<string[]>([]);
  //
  const [flag, setFlag] = React.useState("initial");
  //
  const [searchString, setSearchString] = React.useState<string>("");
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const flagRef = useRef(null);

  // -- reset choosenProduct, removed, flagRef when changing record
  useEffect(() => {
    setRemoved([]);
    setChoosenProduct([]);
    flagRef.current = "initial";
  }, [recordId]);

  useEffect(() => {
    const fetchAllProductCatalog = async (
      perPage: any,
      page: any,
      search: string
    ) => {
      const where: Record<string, any> = {};
      const filterArray: any[] = [];
      //
      const args = buildArgs({
        // @ts-ignore
        order: "DESC",
        size: perPage,
        pageNumber: page,
        sort: "created_at",
      });

      if (search) {
        filterArray.push({
          label: {
            _ilike: `%${search}%`,
          },
        });
      }
      if (filterArray.length > 0) where._and = filterArray;

      return await mbaas.client.gql.query({
        query: gql`
            query productCatalog($where: ProductCatalogsWhereInput){
                allProductCatalogsList(where: $where, ${args}) {
                data {
                    id
                    item_key
                    item_group
                    label
                    description
                    is_available
                    is_vas
                }
                count
                }
            }
            `,
        variables: {
          where,
        },
      });
    };
    fetchAllProductCatalog(
      controller.rowsPerPage,
      controller.page,
      searchString
    ).then((data) => {
      setProductCatalogList(data?.allProductCatalogsList?.data);
      setProductCatalogCount(data?.allProductCatalogsList?.count);
    });
  }, [controller, searchString]);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event: any, newPage: any) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    if (searchString.length > 0) {
      setController({
        ...controller,
        rowsPerPage: parseInt(5, 10),
        page: 0,
      });
    }
    if (searchString.length === 0) {
      setController({
        ...controller,
        rowsPerPage: 5,
        page: 0,
      });
    }
  }, [searchString]);

  return (
    <>
      <Card>
        <TableContainer>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            setSelected={setSelected}
            setSearchString={setSearchString}
            setChoosenProduct={setChoosenProduct}
            setFlag={setFlag}
            flagRef={flagRef}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Item Group</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productCatalogList.map((product) => {
                const isItemSelected = isSelected(product.id);
                return (
                  <TableRow
                    key={product.id}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    role="checkbox"
                    onClick={(event) => handleClick(event, product)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <TableCell>{product.label}</TableCell>
                    <TableCell>{product.item_group}</TableCell>
                    <TableCell>{product.description}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            onPageChange={handlePageChange}
            page={controller.page}
            count={productCatalogCount}
            rowsPerPage={controller.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
      <br />
      <ChoosenProductCatalogTable
        choosenProduct={choosenProduct}
        setRemoved={setRemoved}
        setChoosenProduct={setChoosenProduct}
        flagRef={flagRef}
        setFlag={setFlag}
        removed={removed}
        recordId={recordId}
        onClose={onClose}
      />
    </>
  );
}
