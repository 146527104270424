import React from "react";
import {
  Edit,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  SelectInput,
  NumberInput,
  Confirm,
  useUpdate,
  useGetOne,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField as MuiTextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: "auto",
    },
  },
});

const EditCurrencySaveButton = (props: any) => {
  const { openDialog } = props;

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const onSuccess = (response: any) => {
    notify(`Post "${response.data.title}" saved!`);
    redirect("/services/currencyRates");
    refresh();
  };
  return <SaveButton type="button" onClick={openDialog} />;
};

const EditCurrencyToolbar = (props: any, data: any) => {
  const { openDialog } = props;

  return (
    <Toolbar>
      <EditCurrencySaveButton openDialog={openDialog} />
    </Toolbar>
  );
};

interface IUpdatedData {
  origin: string;
  destination: string;
  rate: number;
}

export const EditPage = ({ onCancel, id, ...props }: any) => {
  const { recordId } = props;

  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [
    update,
    { isLoading: isLoadingUpdate, isSuccess: isUpdateSuccess, error },
  ] = useUpdate();
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [updatedData, setUpdatedData] = React.useState<IUpdatedData>({
    origin: "",
    destination: "",
    rate: 0,
  });

  const handleConfirmDialogOpen = (e: any) => {
    e.preventDefault();
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

  // -- If success creating data
  if (isUpdateSuccess) {
    notify("Update Data Success", { type: "success" });
    redirect("/services/currencyRates");
    refresh();
  }
  // -- If error when creating data
  if (error) {
    notify("Create Data Failed", { type: "warning" });
    redirect("/services/currencyRates");
    refresh();
  }
  // -- get current data to set the updated data state
  const { data } = useGetOne(
    "currencyRates",
    { id: recordId },
    { onError: () => redirect("/services/currencyRates") }
  );
  console.log(776123, "data", data);
  // -- set the updated data state
  React.useEffect(() => {
    setUpdatedData({
      origin: data?.origin,
      destination: data?.destination,
      rate: data?.rate,
    });
  }, [data]);
  // --
  const handleValidateOtp = async () => {
    const { data: resultData, error } = await mbaas.client.invokeApi(
      "console-validate-totp",
      {
        token: otpCode,
      }
    );
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: "#muiDrawer",
      });
    }
    // --
    if (resultData) {
      handleConfirmDialogClose();
      update("currencyRates", {
        id: recordId,
        data: { ...updatedData },
        previousData: { id: recordId, ...updatedData },
      });
    }
  };

  return (
    <Edit resource="currencyRates" title=" &rsaquo; Edit" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        height="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{ width: "100%" }}
          toolbar={<EditCurrencyToolbar openDialog={handleConfirmDialogOpen} />}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SelectInput
                source="origin"
                data-test-id="origin"
                validate={[required()]}
                fullWidth
                choices={[
                  { id: "HKD", name: "HKD" },
                  { id: "IDR", name: "IDR" },
                  { id: "TWD", name: "TWD" },
                  { id: "MYR", name: "MYR" },
                  { id: "USD", name: "USD" },
                ]}
                onChange={(e) =>
                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    origin: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="destination"
                data-test-id="destination"
                validate={[required()]}
                fullWidth
                choices={[
                  { id: "HKD", name: "HKD" },
                  { id: "IDR", name: "IDR" },
                  { id: "TWD", name: "TWD" },
                  { id: "MYR", name: "MYR" },
                  { id: "USD", name: "USD" },
                ]}
                onChange={(e) =>
                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    destination: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                source="rate"
                validate={[required()]}
                data-test-id="rate"
                fullWidth
                onChange={(e) =>
                  setUpdatedData((prevState: any) => ({
                    ...prevState,
                    rate: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </SimpleForm>
        {/* Show input totp dialog */}
        <Confirm
          id="muiDrawer"
          isOpen={openConfirmDialog}
          loading={otpCode.length < 6 ? true : false}
          title="OTP code"
          content={
            <MuiTextField
              type="number"
              fullWidth
              data-test-id="input-otp"
              value={otpCode}
              onChange={(e: any) => setOtpCode(e.target.value)}
            />
          }
          onConfirm={handleValidateOtp}
          onClose={handleConfirmDialogClose}
          sx={{
            "& .Mui-disabled": {
              color: "#c5c5c5",
            },
          }}
        />
      </Box>
    </Edit>
  );
};
