// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Pagination,
  DateField,
  FilterButton,
  FunctionField,
  Confirm,
  Button as RAButton,
  useListContext,
  useCreate,
} from "react-admin";
import { DateRange } from "../DateRange";
import { VoiceLogDetail } from "./Detail";
// import humanizeDuration from "humanize-duration";
import { Drawer, Paper, Typography } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setVoiceLogFilter } from "src/slices/voiceLogPage/filterVoiceLogSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import { endOfToday, startOfToday, subMonths, format } from "date-fns";
import GetAppIcon from "@mui/icons-material/GetApp";
import mbaas from "../../../src/provider/Mbaas";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    voiceLogFilter,
    maxResults,
    navigate,
    ...rest
  } = props;
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  usePersistFilter();

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    console.log(res.data, "ALOHA123");

    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  // -- export button
  const ExportVoiceButton = () => {
    const { filterValues } = useListContext();
    //
    const type_name = "usages-voice";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    console.log(737, "filterValues", filterValues, userData);
    //
    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        author_id: userData?.id,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            ...filterValues,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"usages-voice"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <RAButton label="Export VOICE Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </RAButton>
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={voiceLogFilter} />
      <ExportVoiceButton />
    </TopToolbar>
  );
};

const VoiceLogPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export const VoiceLogList = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelDrawer = useCallback(() => {
    navigate(`/usages/voice`);
  }, [navigate]);

  const match = matchPath("/usages/voice/:id/show/*", location.pathname);

  const VoiceLogFilter = [
    <DateRange alwaysOn />,
    <ReferenceInput
      source="number"
      reference="virtualMsisdnVoiceLogs"
      label="Number"
    >
      <AutocompleteInput
        fullWidth
        source="number"
        optionText="number"
        suggestionLimit={10}
        onChange={(e) => dispatch(setVoiceLogFilter({ number: e }))}
      />
    </ReferenceInput>,
  ];

  return (
    <>
      <List
        resource="voiceLogs"
        title="Consumptions &rsaquo; Voice"
        filters={VoiceLogFilter}
        actions={
          <ListActions navigate={navigate} voiceLogFilter={VoiceLogFilter} />
        }
        perPage={50}
        pagination={<VoiceLogPagination />}
        filterDefaultValues={{
          dateGte: subMonths(startOfToday(), 1),
          dateLte: endOfToday(),
        }}
        {...props}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id) => {
            navigate(`/usages/voice/${id}/show`);
          }}
        >
          <DateField
            source="created_at"
            label="Date"
            showTime
            locales="en-HK"
          />
          <TextField source="direction" />
          <FunctionField
            render={(record) => (
              <>
                {/* <strong>{record.a_name}</strong>
                <br /> */}
                <label>{record.a_number}</label>
              </>
            )}
            source="a_number"
            label="A Number"
          />
          <FunctionField
            render={(record) => (
              <>
                {/* <strong>{record.b_name}</strong>
                <br /> */}
                <label>{record.b_number}</label>
              </>
            )}
            source="b_number"
            label="B Number"
          />
          <TextField source="status" />
          <TextField source="hkd_cost" label="Cost" />
          {/* <DateField source="begin" showTime locales="en-HK" />
          <DateField source="end" showTime locales="en-HK" /> */}
          <FunctionField
            render={(record) => record.duration}
            source="duration"
            label="Duration (Second)"
          />
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <VoiceLogDetail
            id={(match as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};
