import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export interface IpurchaseLog {
  purchaseLogFilter: {
    purchase_id: string;
    payment_id: string;
    status: string;
    user_id: string;
    target_detail: string;
    dateGte: string;
    dateLte: string;
  };
}

const initialState: IpurchaseLog = {
  purchaseLogFilter: {
    purchase_id: "",
    payment_id: "",
    status: "",
    target_detail: "",
    user_id: "",
    dateGte: "",
    dateLte: "",
  },
};

type purchaseLogFilter = {
  purchase_id: string;
  payment_id: string;
  status: string;
  target_detail: string;
  user_id: string;
  dateGte: string;
  dateLte: string;
};

export const purchaseLogFilterSlice = createSlice({
  name: "thisPurchaseLogFilter",
  initialState,
  reducers: {
    setPurchaseLogFilter: (state, action: PayloadAction<purchaseLogFilter>) => {
      state.purchaseLogFilter = {
        ...state.purchaseLogFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setPurchaseLogFilter } = purchaseLogFilterSlice.actions;

export const getPurchaseLogFilter = (state: RootState) =>
  state.thisPurchaseLogFilter.purchaseLogFilter;

export default purchaseLogFilterSlice.reducer;
