import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
export class VirtualMsisdnVoiceLogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    if (params.filter.q) {
      where._or = [
        {
          counterparty_msisdn: {
            _ilike: `%${params.filter.q}%`,
          },
        },
        {
          subscriber_id_data: {
            phone: { _ilike: `%${params.filter.q}%` },
          },
        },
        {
          subscriber_id_data: {
            virtual_msisdn_user_id_list: {
              msisdn: { _ilike: `%${params.filter.q}%` },
            },
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query voiceCdrList($where: VoiceCdrWhereInput) {
          allVoiceCdrList(where: $where) {
            data {
              subscriber_id_data {
                phone
                virtual_msisdn_user_id_list {
                  data {
                    msisdn
                  }
                }
              }
              counterparty_msisdn
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    let { data } = queryResult?.allVoiceCdrList;
    const numberArray = [] as any[];
    data.forEach((voice: any) => {
      numberArray.push(
        {
          id: voice.counterparty_msisdn,
          number: voice.counterparty_msisdn,
        },
        {
          id:
            voice.subscriber_id_data.virtual_msisdn_user_id_list.data[0]
              ?.msisdn || "",
          number:
            voice.subscriber_id_data.virtual_msisdn_user_id_list.data[0]
              ?.msisdn || "",
        },
        {
          id: voice.subscriber_id_data.phone,
          number: voice.subscriber_id_data.phone,
        }
      );
    });
    const returnedData = numberArray.filter(
      (v, i, a) => a.findIndex((t) => t.number === v.number) === i
    );
    return {
      data: returnedData,
      total: returnedData.length,
    } as GetListResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          counterparty_msisdn: {
            _in: params.ids,
          },
        },
        {
          subscriber_id_data: {
            virtual_msisdn_user_id_list: {
              msisdn: { _in: params.ids },
            },
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query voiceCdrList($where: VoiceCdrWhereInput) {
          allVoiceCdrList(where: $where) {
            data {
              subscriber_id_data {
                virtual_msisdn_user_id_list {
                  data {
                    msisdn
                  }
                }
              }
              counterparty_msisdn
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    let { data } = queryResult?.allVoiceCdrList;
    const numberArray = [] as any[];
    data.forEach((voice: any) => {
      numberArray.push(
        {
          id: voice.counterparty_msisdn,
          number: voice.counterparty_msisdn,
        },
        {
          id:
            voice.subscriber_id_data.virtual_msisdn_user_id_list.data[0]
              ?.msisdn || "",
          number:
            voice.subscriber_id_data.virtual_msisdn_user_id_list.data[0]
              ?.msisdn || "",
        },
        {
          id: voice.subscriber_id_data.phone,
          number: voice.subscriber_id_data.phone,
        }
      );
    });
    const returnedData = numberArray.filter(
      (v, i, a) => a.findIndex((t) => t.number === v.number) === i
    );
    return {
      data: returnedData,
    } as GetManyResult;
  }
}
