// @ts-nocheck
import { List, Datagrid, FunctionField } from "react-admin";
import { makeStyles } from "@mui/styles";
import { Toolbar, SaveButton } from "react-admin";

export const CustomToolbarNoDelete = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const ListPage = (props) => {
  return (
    <List {...props} title="Privacy Policy">
      <Datagrid
        data-test-id="datagrid_privacypolicy"
        rowClick="edit"
        bulkActionButtons={false}
      >
        <FunctionField
          title="Language"
          render={(record) => {
            return record?.key.includes("id") ? "Indonesia" : "English";
          }}
          source="key"
        />
        <FunctionField
          render={(record) => {
            return record.value.split(" ").slice(0, 10).join(" ");
          }}
          source="value"
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
