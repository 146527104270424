import React from "react";
import Select from "react-select";
import voiceTariffsStore from "../store/tariffsStore";
import { view } from "@risingstack/react-easy-state";
import { TextareaAutosize } from "@mui/material";

interface GroupPatternOption {
  readonly value: string;
  readonly label: string;
}

function GroupPatternSelector(props: any) {
  const options: readonly GroupPatternOption[] = [
    { value: "CUSTOM", label: "CUSTOM" },
    { value: "AUTO", label: "AUTO-GENERATED" },
  ];

  const changeHandler = (value: any) => {
    voiceTariffsStore.groupPatternSelect = value.value;
  };

  const defaultValue = options.find(
    (option) => option.value === voiceTariffsStore.groupPatternSelect
  );

  return (
    <>
      <Select
        //@ts-ignore
        options={options}
        getOptionLabel={(halo: any) => {
          return `${halo?.label}`;
        }}
        onChange={changeHandler}
        placeholder={<div>Select Option</div>}
        //@ts-ignore
        defaultValue={defaultValue}
      />
      <br />
      <TextareaAutosize
        hidden={voiceTariffsStore.groupPatternSelect === "AUTO"}
        value={voiceTariffsStore.groupPattern}
        onChange={(data) =>
          (voiceTariffsStore.groupPattern = data.target.value)
        }
        id="group_pattern"
      />
    </>
  );
}

export default view(GroupPatternSelector);
