import MaterialTable from "@material-table/core";
import { Card, TextField } from "@mui/material";
import { useMemo } from "react";
import { useInput } from "react-admin";

interface RewardCodeTableProps {
  formData: any;
}

const RewardCodeTable: React.FC<RewardCodeTableProps> = () => {
  const { field } = useInput({ source: "item_detail.codes" });

  const recordData = useMemo(() => {
    return field.value ? field.value : [];
  }, [field.value]);

  const COLUMNS = useMemo(
    () => [
      {
        field: "code",
        title: "Code",
        editComponent: (props: any) => {
          return <TextField {...props} variant="standard" onChange={(e) => props.onChange(e.target.value)} />;
        },
      },
    ],
    []
  );

  const editTableObj = {
    onRowAdd: async (newData: any) => {
      return field.onChange([...recordData, newData]);
    },
    onRowUpdate: async (newData: any, oldData: any) => {
      const dataUpdate = [...recordData];
      const target = dataUpdate.find((el) => el.code === oldData.code);
      const index = dataUpdate.indexOf(target);
      dataUpdate[index] = newData;
      field.onChange(dataUpdate);
    },
    onRowDelete: async (oldData: any) => {
        const dataDelete = [...recordData];
        const target = dataDelete.find((el) => el.code === oldData.code);
        const index = dataDelete.indexOf(target);
        dataDelete.splice(index, 1);
        field.onChange(dataDelete);
    },

    // ketika data purchased true maka edit dan delete disable 
    isEditHidden: (data: any) => {
      return data.purchased
    },
    isDeleteHidden: (data: any) => {
      return data.purchased;
    },
  };

  return (
    <MaterialTable
      data={recordData}
      columns={COLUMNS}
      title={""}
      components={{
        Container: (props) => {
          return <Card variant="outlined">{props.children}</Card>;
        },
      }}
      editable={{ ...editTableObj }}
    />
  );
};

export default RewardCodeTable;
