import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import {
  SimpleForm,
  Toolbar,
  Confirm,
  // useNotify,
  useRefresh,
} from "react-admin";
import mbaas from "../../provider/Mbaas";
import TextField from "@mui/material/TextField";
import UpdateIcon from "@mui/icons-material/Update";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

export default function EditVnExpiryModal({
  open,
  handleClose,
  props,
  currentRecord,
}: any) {
  const classes = useStyles();
  // const notify = useNotify();
  const refresh = useRefresh();

  const [value, setValue] = useState(
    moment(currentRecord.virtual_msisdn_user_id_list?.data[0]?.expiry_date)
  );
  const [reason, setReason] = useState<string | null>();

  useEffect(() => {
    if (currentRecord.virtual_msisdn_user_id_list?.data[0]?.expiry_date)
      setValue(
        moment(currentRecord.virtual_msisdn_user_id_list?.data[0]?.expiry_date)
      );
  }, [currentRecord.virtual_msisdn_user_id_list?.data[0]?.expiry_date]);

  const CustomToolbar = (props: any) => {
    const useStyles = makeStyles(() => ({
      toolbar: {
        display: "flex",
        justifyContent: "space-between",
      },
    }));
    const [isLoadingOtp, setIsLoadingOtp] = useState(false);
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState<string>("");

    const handleClick = () => {
      setOpenOtpModal(true);
    };
    const handleDialogClose = () => {
      setOpenOtpModal(false);
    };

    const handleUpdateVnExpiry = async () => {
      setIsLoadingOtp(true);
      const { error: valTotpErr, data: valTotpData } =
        await mbaas.client.invokeApi("console-validate-totp", {
          token: otpCode,
        });
      if (valTotpErr) {
        Swal.fire({
          icon: "error",
          title: `Request failed: ${valTotpErr?.error}`,
          target: "#muiModal",
        });
      }
      if (valTotpData === "OK") {
        const res = await mbaas.client.invokeApi(
          "console-adjust-virtual-msisdn-expiry",
          {
            expiry_date: value,
            virtual_msisdn_id:
              currentRecord.virtual_msisdn_user_id_list?.data[0]?.id,
            reason: reason,
          }
        );

        if (res.data === "OK") {
          handleClose();
          refresh();
        }

        if (res.error) {
          Swal.fire({
            icon: "error",
            title: `Request failed: ${res?.error?.error}`,
            target: "#muiModal",
          })
          .then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              handleClose();
            }
          });
        }
      }
      setIsLoadingOtp(false);
    };

    return (
      <Toolbar {...props} classes={useStyles}>
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={<UpdateIcon />}
          disabled={!reason || !value}
        >
          Update
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          startIcon={<CloseIcon />}
          sx={{
            marginLeft: "10px",
          }}
        >
          Cancel
        </Button>
        <Confirm
          id="muiModal"
          isOpen={openOtpModal}
          loading={otpCode.length < 6 ? true : isLoadingOtp}
          title="OTP Code"
          content={
            <>
              <Typography variant="caption" gutterBottom>
                <b>Important!</b> By confirming this dialog it will update
                subscriber virtual number expiry date,<br></br> are you sure
                want to proceed?
              </Typography>
              <TextField
                type="number"
                fullWidth
                data-test-id="input-otp"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
              />
            </>
          }
          onConfirm={() => handleUpdateVnExpiry()}
          onClose={() => handleDialogClose()}
        />
      </Toolbar>
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" gutterBottom component="div">
              Edit {currentRecord?.name}'s Virtual Number Expiry
            </Typography>
            <SimpleForm toolbar={<CustomToolbar props={props} />}>
              <div className={classes.root}>
                <div className={classes.form}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                      >
                        Current Virtual Number Expiry
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Pick a Date"
                            value={value}
                            format="DD/MM/YYYY"
                            onChange={(newValue: any) => setValue(newValue)}
                            sx={{
                              width: "100%",
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                      >
                        Reason
                      </Typography>
                      <TextField
                        fullWidth
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </SimpleForm>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
