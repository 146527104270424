import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
} from "react-admin";

const DiasporaEmbassyList = (props: any) => {
  return (
    <List {...props} title="Diasporas Embassy Info">
      <Datagrid rowClick="edit" bulkActionButtons={false} data-test-id="datagrid_embassy">
        <TextField source="country_code" label="Country" />

        <FunctionField
          render={(record: { content: { title: any } }) => {
            return record?.content?.title;
          }}
          source="content"
          label="Title"
        />
        <DateField source="updated_at" showTime />
      </Datagrid>
    </List>
  );
};

export default DiasporaEmbassyList;
