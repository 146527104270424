import { useMemo } from "react";
import { AutocompleteInput, useGetList, useInput } from "react-admin";

interface ProductGroupInputProps {
  source: string;
  formData: any;
}

const ProductGroupInput: React.FC<ProductGroupInputProps> = (props) => {
  const { source, formData } = props;

  let filterss: any;
  if (formData.availability_by_service === 'M21' && formData.category === 'IDD') {
    filterss = {
      isAvailable: true,
      isMvno: false,
      itemGroup: 'VOICE'
    }
  } else if (formData.availability_by_service === 'M21' && formData.category === 'SMS') {
    filterss = {
      isAvailable: true,
      isMvno: false,
      itemGroup: 'SMS'
    }
  } else if (formData.availability_by_service === 'M21' && formData.category === 'VAS') {
    filterss = {
      isAvailable: true,
      isMvno: false,
      isVas: true
    }
  } else if (formData.availability_by_service === 'GHK' && formData.category !== 'VAS') {
    filterss = {
      isAvailable: true,
      isMvno: true,
      isMetadata: false,
    }
  } else if (formData.availability_by_service === 'GHK' && formData.category === 'VAS') {
    filterss = {
      isAvailable: true,
      isMvno: true,
      isVas: true,
      mvnoCountry: "HK"
    }
  } else if (formData.availability_by_service === 'G2' && formData.category === 'VAS') {
    filterss = {
      isAvailable: true,
      isVas: true,
      extraFilter: [
        {
          is_mvno: { _eq: true }, mvno_country: { _eq: "TW" },
        },
        {
          product_pricings_product_catalog_id_list: {
            product_catalog_id_data: {
              country: { _eq: "TW" }
            }
          }
        },
      ]
    }
  }

  const { field: fieldItem } = useInput({ source: "item_key" });
  const { data, isLoading } = useGetList("productCatalogs", {
    pagination: {
      page: 1,
      perPage: 1000,
    },
    filter: {
      ...filterss
    }
  });

  const choices = useMemo(() => {
    if (data) {
      return data.filter(
        (v: any, i: number, a: any) =>
          a.findIndex((t: any) => t.item_group === v.item_group) === i,
      );
    }
    return [];
  }, [data]);

  return (
    <AutocompleteInput
      choices={choices}
      optionValue="item_group"
      optionText="item_group"
      loading={isLoading}
      loadingText="Loading..."
      debounce={500}
      isOptionEqualToValue={(options, value) => {
        return options.item_group === value.item_group;
      }}
      fullWidth
      onChange={() => {
        fieldItem.onChange(null);
      }}
      source={source}
    />
  );
};

export default ProductGroupInput;
