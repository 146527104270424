import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  CreateParams,
  CreateResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class SystemConfigsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    filterArray.push({
      key: {
        _nilike: `otp_param%`,
      },
    });
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllSystemConfig($where: SystemConfigsWhereInput) {
          allSystemConfigsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              key
              value
              description
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    console.log(77623, "queryResult", queryResult);
    return {
      data: queryResult?.allSystemConfigsList?.data || [],
      total: queryResult?.allSystemConfigsList?.count || 0,
    } as GetListResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });

    const { key, description, value } = params?.data?.createdData;

    let thisValue;
    if (typeof value === "string") {
      thisValue = value;
    } else {
      thisValue = JSON.stringify(value);
    }

    const toBeCreatedData: any = {
      key: key,
      description: description,
      value: thisValue,
    };
    console.log(723, "toBeCreatedData", toBeCreatedData);
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [SystemConfigsCreateInput!]!) {
          createSystemConfigs(input: $input) {
            id
            created_at
            updated_at
            key
            value
            description
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createSystemConfigs[0] } as CreateResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    console.log({ method: "getOne", resource, params });
    const queryResult = await this.client.gql.query({
      query: gql`
        query aSystemConfig($id: UUID!) {
          getSystemConfigsById(id: $id) {
            id
            created_at
            updated_at
            key
            value
            description
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return {
      data: queryResult?.getSystemConfigsById,
    } as GetOneResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });

    const { key, description, value } = params?.data;

    const submittedData = {
      key: key,
      description: description,
      value: value,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: SystemConfigsUpdateInput!) {
          updateSystemConfigsById(ids: $ids, input: $input) {
            id
            created_at
            updated_at
            key
            value
            description
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: submittedData,
      },
    });

    return { data: queryResult?.updateSystemConfigsById[0] } as UpdateResult;
  }
}
