// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { useTimeout, useUpdateMany } from "react-admin";
import Swal from "sweetalert2";
import gql from "graphql-tag";
import mbaas from "../../../provider/Mbaas";
import { alpha } from "@mui/material/styles";
import { buildArgs } from "./../../../provider/utils2";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import Dialog from "./Dialog";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    numSelected,
    selected,
    setSelected,
    setSearchString,
    handleApply,
    setFlag,
    flagRef,
    handleClickOpenDialog,
    handleCloseDialog,
    setRemoved,
    openDialog,
  } = props;

  const handleSetSearch = (value: string) => {
    setSearchString(value);
  };

  const handleRemoveChoosenProduct = (selected: any) => {
    setRemoved((oldArray) => [...oldArray, ...selected]);
    //
    setFlag("remove-product");
    flagRef.current = "remove-product";
    //
    setSelected([]);
  };

  const confirmRemove = () => {
    handleRemoveChoosenProduct(selected);
    handleCloseDialog();
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Choosen Product
          </Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          {numSelected > 0 ? (
            <Tooltip title="Remove">
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleClickOpenDialog("remove-choosen-product")}
                style={{
                  marginRight: "14px",
                  height: "40px",
                  width: "146px",
                }}
              >
                Remove
              </Button>
            </Tooltip>
          ) : (
            <></>
          )}
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => handleSetSearch(e.target.value)}
          />
        </Stack>
      </Toolbar>
      <Dialog
        openDialog={openDialog.condition}
        dialogType={openDialog.dialogType}
        handleClickOpenDialog={handleClickOpenDialog}
        handleCloseDialog={handleCloseDialog}
        handleApply={handleApply}
        confirmRemove={confirmRemove}
      />
    </>
  );
};

export default function ChoosenProductCatalogTable(props: any) {
  const {
    choosenProduct,
    setChoosenProduct,
    removed,
    setFlag,
    flagRef,
    setRemoved,
    recordId,
    onClose,
  } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchString, setSearchString] = React.useState<string>("");
  const [choosenProductObj, setChoosenProductObj] = React.useState([]);
  const [choosenProductObjApplied, setChoosenProductObjApplied] =
    React.useState([]);
  const [choosenProductObjCount, setChoosenProductObjCount] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState({
    condition: false,
    dialogType: "",
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handleClickOpenDialog = (dialogType: string) => {
    setOpenDialog({ condition: true, dialogType: dialogType });
  };

  const handleCloseDialog = (dialogType: string) => {
    setOpenDialog({ condition: false, dialogType: dialogType });
  };

  const fetchAllProductCatalog = async (
    choosenProduct: any,
    perPage: any,
    page: any,
    recordId: any,
    removed: any,
    search: string
  ) => {
    //
    let where: Record<string, any> = {};
    const where2: Record<string, any> = {};
    let where3: Record<string, any> = {};
    //
    const filterArray: any[] = [];
    const filterArray2: any[] = [];
    const filterArray3: any[] = [];
    //
    const args = buildArgs({
      // @ts-ignore
      order: "DESC",
      size: perPage,
      pageNumber: page,
      sort: "created_at",
    });

    if (flagRef.current === "remove-product") {
      console.log(6675, "flag-remove-product", flagRef.current);
      if (removed.length > 0 && choosenProduct.length === 0) {
        console.log(6675, "removed.length > 0 && choosenProduct.length === 0");
        filterArray.push({
          id: {
            _nin: removed,
          },
        });

        filterArray.push({
          constraint_id: {
            _eq: recordId,
          },
        });

        if (filterArray.length > 0) where._and = filterArray;
      } else if (removed.length > 0 && choosenProduct.length > 0) {
        console.log(6675, "removed.length > 0 && choosenProduct.length > 0");

        let uniqueChoosenProduct = [...new Set(choosenProduct)];
        let uniqueRemoved = [...new Set(removed)];

        console.log(
          6625123123,
          "uniqueChoosenProduct-OG",
          uniqueChoosenProduct
        );
        console.log(6625123123, "uniqueRemoved-OG", uniqueRemoved);

        const fiterResult = uniqueChoosenProduct.filter(
          (x) => !uniqueRemoved.includes(x)
        );

        const fiterResultX = uniqueRemoved.filter(
          (x) => !uniqueChoosenProduct.includes(x)
        );

        const fiterResult2 = uniqueChoosenProduct.filter((x) =>
          uniqueRemoved.includes(x)
        );

        if (fiterResult2.length > 0) {
          console.log(6625123123, "remove-fiterResult2.length > 0");
          uniqueChoosenProduct = fiterResult;
          // uniqueRemoved = fiterResult2;
          uniqueRemoved = fiterResult2.concat(fiterResultX);
          // uniqueRemoved.concat(fiterResult2);
          setChoosenProduct([]);
          setRemoved([]);
          setChoosenProduct((oldArray) => [
            ...oldArray,
            ...uniqueChoosenProduct,
          ]);
          setRemoved((oldArray) => [...oldArray, ...uniqueRemoved]);
        }

        // console.log(6675, "removed", removed);
        // console.log(6675, "choosenProduct", choosenProduct);
        // console.log(6675, "fiterResult", fiterResult);
        // console.log(6675, "fiterResult2", fiterResult2);
        // console.log(66758898, "uniqueChoosenProduct", uniqueChoosenProduct);
        // console.log(66758898, "uniqueRemoved", uniqueRemoved);

        filterArray.push({
          id: {
            _nin: uniqueRemoved,
          },
        });

        filterArray.push({
          constraint_id: {
            _eq: recordId,
          },
        });

        if (filterArray.length > 0) where2._and = filterArray;

        // -- pada choosenProduct ini bisa jadi ada yang constrain_id = null
        filterArray2.push({
          id: {
            _in: uniqueChoosenProduct,
          },
        });

        if (filterArray2.length > 0) {
          filterArray2.push({
            ...where2,
          });

          where._or = filterArray2;
        }
      }

      if (removed.length === 0 && choosenProduct.length > 0) {
        console.log(6675, "removed.length === 0 && choosenProduct.length > 0");
        filterArray.push({
          id: {
            _in: choosenProduct,
          },
        });

        filterArray.push({
          constraint_id: {
            _eq: recordId,
          },
        });

        if (filterArray.length > 0) where._or = filterArray;
      }
    }

    if (flagRef.current === "add-product") {
      console.log(6675, "flag-add-product", flagRef.current);
      if (removed.length > 0 && choosenProduct.length === 0) {
        console.log(6675, "removed.length > 0 && choosenProduct.length === 0");
        filterArray.push({
          id: {
            _nin: removed,
          },
        });

        filterArray.push({
          constraint_id: {
            _eq: recordId,
          },
        });

        if (filterArray.length > 0) where._and = filterArray;
      } else if (removed.length > 0 && choosenProduct.length > 0) {
        let uniqueChoosenProduct = [...new Set(choosenProduct)];
        let uniqueRemoved = [...new Set(removed)];

        console.log(
          6625123123,
          "state-add-uniqueChoosenProduct-OG",
          uniqueChoosenProduct
        );
        console.log(6625123123, "state-add-uniqueRemoved-OG", uniqueRemoved);

        // -- sisanya removed yang nggak ada di choosenProduct
        const fiterResult = uniqueRemoved.filter(
          (x) => !uniqueChoosenProduct.includes(x)
        );

        // -- sisanya choosenProduct yang nggak ada di removed
        const fiterResultX = uniqueChoosenProduct.filter(
          (x) => !uniqueRemoved.includes(x)
        );

        // -- yang beririsan
        const fiterResult2 = uniqueRemoved.filter((x) =>
          uniqueChoosenProduct.includes(x)
        );

        console.log(6625123123, "SISANYA", fiterResultX);

        if (fiterResult2.length > 0) {
          console.log(6625123123, "add-fiterResult2.length > 0");
          uniqueChoosenProduct = fiterResult2.concat(fiterResultX);

          // uniqueRemoved.concat(fiterResult);
          uniqueRemoved = fiterResult;
          setChoosenProduct([]);
          setRemoved([]);
          setChoosenProduct((oldArray) => [
            ...oldArray,
            ...uniqueChoosenProduct,
          ]);
          setRemoved((oldArray) => [...oldArray, ...uniqueRemoved]);
        }

        filterArray.push({
          id: {
            _nin: uniqueRemoved,
          },
        });

        filterArray.push({
          constraint_id: {
            _eq: recordId,
          },
        });

        if (filterArray.length > 0) where2._and = filterArray;

        // -- pada choosenProduct ini bisa jadi ada yang constrain_id = null
        filterArray2.push({
          id: {
            _in: uniqueChoosenProduct,
          },
        });

        if (filterArray2.length > 0) {
          filterArray2.push({
            ...where2,
          });

          where._or = filterArray2;
        }
      }

      if (removed.length === 0 && choosenProduct.length > 0) {
        console.log(6675, "removed.length === 0 && choosenProduct.length > 0");
        filterArray.push({
          id: {
            _in: choosenProduct,
          },
        });

        filterArray.push({
          constraint_id: {
            _eq: recordId,
          },
        });

        if (filterArray.length > 0) where._or = filterArray;
      }
    }

    if (flagRef.current === "initial") {
      console.log(66751321233, "initial-exec");
      filterArray.push({
        id: {
          _in: choosenProduct,
        },
      });

      filterArray.push({
        constraint_id: {
          _eq: recordId,
        },
      });

      if (filterArray.length > 0) where._or = filterArray;
    }

    // -- search
    if (search) {
      filterArray3.push({
        label: {
          _ilike: `%${search}%`,
        },
      });
      if (filterArray3.length > 0) {
        where3 = where;
        where = {};
      }
    }

    if (filterArray3.length > 0) {
      filterArray3.push({
        ...where3,
      });

      where._and = filterArray3;
    }

    // console.log(6675, "where", where);
    // console.log(6675, "filterArray", filterArray);

    // console.log(6675, "where2", where2);
    // console.log(6675, "filterArray2", filterArray2);

    // console.log(6675, "where3", where3);
    // console.log(6675, "filterArray3", filterArray3);

    return await mbaas.client.gql.query({
      query: gql`
          query productCatalog($where: ProductCatalogsWhereInput) {
            allProductCatalogsList(
              where:$where, ${args}
            ) {
              data {
                id
                item_key
                item_group
                label
                description
                is_available
                is_vas
              }
              count
            }
          }
        `,
      variables: {
        where,
      },
    });
  };

  useEffect(() => {
    fetchAllProductCatalog(
      choosenProduct,
      controller.rowsPerPage,
      controller.page,
      recordId,
      removed,
      searchString
    ).then((data) => {
      setChoosenProductObj(data?.allProductCatalogsList?.data);
      setChoosenProductObjCount(data?.allProductCatalogsList?.count);
    });
  }, [choosenProduct, recordId, controller, removed, searchString]);
  //
  // console.log(
  //   412312,
  //   "exist",
  //   choosenProductObj?.map((x) =>
  //     choosenProduct.includes("f7cf4e28-e0fe-43a7-815e-6821214e7c4d")
  //   )
  // );
  //
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    controller.page > 0
      ? Math.max(
          0,
          (1 + controller.page) * controller.rowsPerPage -
            choosenProductObjCount
        )
      : 0;

  console.log(3434343434, "choosenProduct", choosenProduct);
  console.log(8877123213, "removed", removed);
  // -- set page to 0 when search is applied
  useEffect(() => {
    if (searchString.length > 0) {
      setController({
        rowsPerPage: parseInt(5, 10),
        page: 0,
      });
    }
    if (searchString.length === 0) {
      setController({
        rowsPerPage: 5,
        page: 0,
      });
    }
  }, [searchString]);
  // -- update constraint_id on apply button
  const [updateMany, { isLoading, isSuccess, error }] = useUpdateMany(
    "servicesProduct",
    {
      ids: choosenProductObjApplied,
      data: { constraint_id: recordId },
    }
  );
  // -- remove constraint_id when the record(s) are deselected from choosen product
  const [
    updateMany2,
    {
      isLoading: isLoadingRemove,
      isSuccess: isSuccessRemove,
      error: errorRemove,
    },
  ] = useUpdateMany("servicesProduct", {
    ids: removed,
    data: { constraint_id: null },
  });
  // -- show alert when success
  useEffect(() => {
    if (isSuccess) {
      handleCloseDialog("apply-constraint");
      Swal.fire({
        icon: "success",
        title: "config is saved",
        target: document.getElementById("muiDrawer"),
      });
    }
    if (error) {
      Swal.fire({
        icon: "error",
        title: error,
        target: document.getElementById("muiDrawer"),
      });
    }
  }, [isSuccess, error]);
  // -- handleApply button
  const handleApply = () => {
    fetchAllProductCatalog(
      choosenProduct,
      choosenProductObjCount,
      0,
      recordId,
      removed
    ).then((data) => {
      const mappedData = data?.allProductCatalogsList?.data?.map((x) => {
        return x.id;
      });

      setChoosenProductObjApplied(mappedData);
    });
    //
    if (removed.length > 0) {
      updateMany2();
    }
  };
  // -- run updateMany as soon as choosenProductObjApplied is changed
  useEffect(() => {
    if (choosenProductObjApplied.length !== 0) updateMany();
  }, [choosenProductObjApplied]);

  return (
    <>
      <Card>
        <TableContainer>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            setSelected={setSelected}
            setRemoved={setRemoved}
            handleClickOpenDialog={handleClickOpenDialog}
            handleCloseDialog={handleCloseDialog}
            flagRef={flagRef}
            setSearchString={setSearchString}
            handleApply={handleApply}
            setFlag={setFlag}
            openDialog={openDialog}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Item Group</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {choosenProductObj?.map((product) => {
                const isItemSelected = isSelected(product.id);
                return (
                  <TableRow
                    key={product.id}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    role="checkbox"
                    onClick={(event) => handleClick(event, product.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <TableCell>{product.label}</TableCell>
                    <TableCell>{product.item_group}</TableCell>
                    <TableCell>{product.description}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            onPageChange={handleChangePage}
            page={controller.page}
            count={choosenProductObjCount || 0}
            rowsPerPage={controller.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
      <br />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="contained"
          startIcon={<LibraryAddCheckIcon />}
          style={{ marginBottom: "17px" }}
          onClick={() => handleClickOpenDialog("apply-constraint")}
        >
          Apply
        </Button>

        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          style={{ marginBottom: "17px" }}
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}
