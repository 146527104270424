import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog(props: any) {
  const {
    openDialog,
    handleCloseDialog,
    dialogType,
    confirmRemove,
    handleApply,
  } = props;

  const handleExecution = (dialogType: string) => {
    switch (dialogType) {
      case "remove-choosen-product":
        confirmRemove();
        break;
      case "apply-constraint":
        handleApply();
        break;
      default:
        confirmRemove();
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color={"black"}>
            {dialogType === "remove-choosen-product"
              ? "Are you sure want to remove this item?"
              : "Are you sure want to apply this configuration?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No</Button>
          <Button onClick={() => handleExecution(dialogType)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
