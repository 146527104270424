import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import { CreateParams, CreateResult, GetListParams, GetListResult, GetOneParams, GetOneResult, UpdateManyParams, UpdateManyResult, UpdateParams, UpdateResult } from 'react-admin';
import gql from 'graphql-tag';
import { ProviderBase } from './ProviderBase';
import { buildArgs } from './utils';
import { dataProvider } from '.';
export class ServicesMvnoProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log({ method: 'getList', resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    filterArray.push({
      is_mvno: {
        _eq: true,
      },
    });
    filterArray.push({
      is_vas: {
        _eq: params.filter.isVas,
      },
    });
    if (params.filter.id) {
      const {
        data: { item_group: itemGroup },
      } = await dataProvider.getOne('productCatalogsItemGroup', {
        // } = await dataProvider.getOne('servicesMvno', {
        id: params.filter.id,
      });
      filterArray.push({
        item_group: {
          _eq: itemGroup,
        },
      });
    }
    if (params.filter.label) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.label}%`,
        },
      });
    }
    if (params.filter.item_key) {
      filterArray.push({
        item_key: {
          _ilike: `%${params.filter.item_key}%`,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || 'ASC',
      size: perPage,
      pageNumber: page,
      sort: field || 'item_group',
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query ServicesProductList($where: ProductCatalogsWhereInput) {
          allProductCatalogsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              item_key
              item_group
              label
              description
              retail_price
              inquiry_rules
              execution_rules
              is_available
              is_mvno
              is_vas
              mvno_country
              country
              auto_enabled
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allProductCatalogsList?.data,
      total: queryResult?.allProductCatalogsList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: 'getOne', resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aServiceProduct($id: UUID!) {
          getProductCatalogsById(id: $id) {
            id
            created_at
            updated_at
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            is_mvno
            is_vas
            mvno_country
            auto_enabled
            country
            product_pricings_product_catalog_id_list {
              data {
                id
                created_at
                updated_at
                product_catalog_id
                country
                schema
                value
              }
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: queryResult?.getProductCatalogsById };
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    console.log({ method: 'update', resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
      retail_price: Number(params.data.retail_price),
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    delete toBeUpdatedData.product_pricings_product_catalog_id_list;
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: ProductCatalogsUpdateInput!) {
          updateProductCatalogsById(ids: $ids, input: $input) {
            id
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            auto_enabled
            is_mvno
            is_vas
            country
            mvno_country
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return { data: queryResult?.updateProductCatalogsById[0] } as UpdateResult;
  }

  async updateMany(resource: string, params: UpdateManyParams<any>): Promise<UpdateManyResult<any>> {
    console.log({ method: 'updateMany', resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
      // @ts-ignore
      constraint_id: params?.data?.constraint_id,
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    delete toBeUpdatedData.product_pricings_product_catalog_id_list;
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: ProductCatalogsUpdateInput!) {
          updateProductCatalogsById(ids: $ids, input: $input) {
            id
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            constraint_id
            is_available
            auto_enabled
            country
            is_mvno
            is_vas
          }
        }
      `,
      variables: {
        // @ts-ignore
        ids: params?.ids,
        input: toBeUpdatedData,
      },
    });

    return {
      data: [queryResult?.updateProductCatalogsById[0]],
    } as UpdateManyResult;
  }

  async create(resource: string, params: CreateParams<any>): Promise<CreateResult<any>> {
    console.log({ method: 'create', resource, params });
    const toBeCreatedData: any = {
      ...params.data,
      retail_price: Number(params.data.retail_price),
      is_mvno: true,
      country: "ID",
      inquiry_rules: !params.data.inquiry_rules ? {} : params.data.inquiry_rules,
      execution_rules: !params.data.execution_rules ? {} : params.data.execution_rules,
    };
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [ProductCatalogsCreateInput!]!) {
          createProductCatalogs(input: $input) {
            id
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            is_mvno
            is_vas
            mvno_country
            country
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createProductCatalogs[0] } as CreateResult;
  }
}
