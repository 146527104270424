import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { GetListParams, GetListResult } from "react-admin";
import gqltag from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
export class DeviceLogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    if (params.filter.fingerprint) {
      filterArray.push({
        fingerprint: {
          _eq: params.filter.fingerprint,
        },
      });
    }

    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gqltag`
        query getAllDeviceLogs($where: DeviceLogsWhereInput) {
          allDeviceLogsList(where: $where, ${args}) {
            data {
              id
              info
              created_at
              fingerprint
              user_id_data{
                name
                email
              }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    console.log(77623, "queryResult", queryResult);
    return {
      data: queryResult?.allDeviceLogsList?.data || [],
      total: queryResult?.allDeviceLogsList?.count || 0,
    } as GetListResult;
  }
}
