import React from "react";
import {
  List,
  RaRecord,
  Datagrid,
  TextField,
  DateField,
  useGetList,
  useListContext,
  AutocompleteInput,
  CreateButton,
  TopToolbar,
  Button,
  useDeleteMany,
  useRefresh,
  Confirm,
  useNotify,
  FunctionField,
  TextInput,
  FilterButton,
} from "react-admin";
import { Drawer, TextField as MuiTextField } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { CreatePage } from "./CreatePage";
import UploadDialog from "./UploadDialog";
import UpdateDialog from "./UpdateDialog";
import TableDialog from "./TableDialog";
import PostAddIcon from "@mui/icons-material/PostAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Detail } from "./Details";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";
import { partition } from "lodash";

const choices = [
  { id: "HK", name: "Hongkong (HK)" },
  { id: "ID", name: "Indonesia (ID)" },
  { id: "TW", name: "Taiwan (TW)" },
];
const VirtualMsidnFilter = [
  <AutocompleteInput
    source="country"
    optionValue="id"
    optionText="name"
    choices={choices}
  />,
  <TextInput label="user" source="userName" alwaysOn />,
  <TextInput label="virtual number" source="virtualNumber" alwaysOn />,
];

const ListActions = (props: any) => {
  const { handleClickOpenDialog, openDialog, handleCloseDialog } = props;

  return (
    <TopToolbar>
      <Stack direction="row" spacing={1}>
        <FilterButton filters={VirtualMsidnFilter} />
        <Button
          label="Batch Add"
          onClick={handleClickOpenDialog}
          data-testid="add-virtualMsisdn-batch"
        >
          <PostAddIcon />
        </Button>
        <UploadDialog openDialog={openDialog} handleClose={handleCloseDialog} />
        <CreateButton label="Create New" />
      </Stack>
    </TopToolbar>
  );
};

const CustomBulkActionButton = () => {
  const [openTableDialog, setOpenTableDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [executedDelete, setExecuteDelete] = React.useState(false);
  const [allocatedMsisdn, setAllocatedMsisdn] = React.useState<Array<string>>(
    []
  );
  const [otpCode, setOtpCode] = React.useState<string>("");
  const { selectedIds, onSelect } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const reqLength = selectedIds.length;
  // --
  const handleExecuteDelete = () => {
    setExecuteDelete(true);
  };
  // --
  const handleDialogOpen = () => setOpenTableDialog(true);
  const handleDialogClose = () => setOpenTableDialog(false);
  const handleConfirmDialogOpen = () => setOpenConfirmDialog(true);
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);
  // --
  const { data, error } = useGetList("virtualMsisdn", {
    pagination: { page: 0, perPage: reqLength },
    filter: { selectedIds: selectedIds },
  });
  if (error) {
    throw new Error("error request");
  }
  // --
  const [
    deleteMany,
    { isLoading, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteMany("virtualMsisdn");
  // --
  // -- look for msisdn who dont have user_id (msisdn that can be deleted)
  const [allocatedMsisdn2, unAllocatedMsisdn2] = partition(
    data,
    function (x: any) {
      if (x.user_id) {
        return true;
      }
    }
  );
  //--
  const getArrAllocated = allocatedMsisdn2.map((x: any) => {
    return x.msisdn;
  });
  //
  const getArrUnAllocated = unAllocatedMsisdn2.map((x: any) => {
    return x.id;
  });
  // --
  React.useEffect(() => {
    if (executedDelete === true) {
      // -- delete msisdn
      deleteMany("virtualMsisdn", {
        ids: getArrUnAllocated,
      });
    }
  }, [executedDelete]);
  // --
  React.useEffect(() => {
    if (isDeleteSuccess) {
      handleConfirmDialogClose();
      handleDialogClose();
      setExecuteDelete(false);
      onSelect([]);
      refresh();
      notify("Msisdn Successfully Deleted", { type: "success" });
    }
  }, [isDeleteSuccess]);
  // --
  React.useEffect(() => {
    if (isDeleteError) {
      Swal.fire({
        icon: "error",
        title: `Deletion Failed`,
        timer: 1400,
        target: "#muiDrawer",
      });
      setTimeout(() => {
        handleConfirmDialogClose();
        onSelect([]);
      }, 1400);
    }
  }, [isDeleteError]);
  //--
  console.log(1232134, "isDeleteError", isDeleteError);
  //--
  const handleDeleteMsisdn = () => {
    if (allocatedMsisdn2.length > 0 && unAllocatedMsisdn2.length > 0) {
      setAllocatedMsisdn(getArrAllocated);
      handleDialogOpen();
    }
    if (allocatedMsisdn2.length > 0 && unAllocatedMsisdn2.length === 0) {
      setAllocatedMsisdn(getArrAllocated);
      handleDialogOpen();
    }
    if (allocatedMsisdn2.length === 0 && unAllocatedMsisdn2.length > 0) {
      // -- delete msisdn
      deleteMany("virtualMsisdn", {
        ids: getArrUnAllocated,
      });
    }
  };
  //--
  const handleValidateOtp = async () => {
    const { data, error } = await mbaas.client.invokeApi("console-validate-totp", {
      token: otpCode,
    });
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: "#muiDrawer",
      });
    }
    // --
    if (data) {
      Swal.fire({
        icon: "success",
        title: "Otp is valid",
        showConfirmButton: false,
        timer: 1400,
        target: "#muiDrawer",
      });
      setTimeout(() => {
        // -- perform delete msisdn
        handleDeleteMsisdn();
      }, 1400);
    }
  };
  //--
  return (
    <>
      <Button
        onClick={handleConfirmDialogOpen}
        label="Delete"
        data-testid="delete-btn"
      >
        <DeleteIcon color="error" />
      </Button>
      {/* Show Table Dialog */}
      <TableDialog
        openTableDialog={openTableDialog}
        handleExecuteDelete={handleExecuteDelete}
        handleDialogClose={handleDialogClose}
        allocatedMsisdn={allocatedMsisdn}
      />
      {/* Show input totp dialog */}
      <Confirm
        id="muiDrawer"
        isOpen={openConfirmDialog}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <MuiTextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleValidateOtp}
        onClose={handleConfirmDialogClose}
        sx={{
          "& .Mui-disabled": {
            color: "#c5c5c5",
          },
        }}
      />
    </>
  );
};

export const VirtualMsisdnList = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  // --
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
  const [recordId, setRecordId] = React.useState<string>("");

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenUpdateDialog = (e: any) => {
    e.stopPropagation();
    setOpenUpdateDialog(true);
  };
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const handleClose = React.useCallback(() => {
    navigate("/virtualMsisdn");
  }, [navigate]);

  const matchShow = matchPath("/virtualMsisdn/:id/show/*", location.pathname);
  const matchCreate = matchPath("/virtualMsisdn/create", location.pathname);

  console.log(12341, "location.pathname", location.pathname);

  return (
    <>
      <List
        {...props}
        actions={
          <ListActions
            handleClickOpenDialog={handleClickOpenDialog}
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
          />
        }
        filters={VirtualMsidnFilter}
        resource="virtualMsisdn"
        sort={{ field: "created_at", order: "DESC" }}
        title="Internal &rsaquo; Virtual MSISDNs"
      >
        <Datagrid
          // @ts-ignore
          rowClick={(id) => {
            navigate(`/virtualMsisdn/${id}/show`);
          }}
          // @ts-ignore
          key={(id) => id}
          bulkActionButtons={<CustomBulkActionButton />}
        >
          <DateField source="created_at" label="Created" showTime />
          <TextField source="country" />
          <TextField source="msisdn" />
          <TextField source="hkd_price" />

          <FunctionField
            render={(record: RaRecord) => {
              let user = "";
              if (record.user_id_data) {
                if (record.user_id_data.name) {
                  user = record.user_id_data.name;
                } else {
                  user = record.user_id_data.email;
                }
              }

              return <span>{user}</span>;
            }}
            source="user_id_data"
            label="User"
          />

          <DateField
            source="allocation_date"
            label="Allocation Date"
            showTime
          />
          <DateField source="expiry_date" label="Expiry Date" showTime />
          <DateField source="suspended_at" label="Suspended At" showTime />

          <FunctionField
            render={(record: RaRecord) => {
              return (
                <Button
                  label="Update"
                  disabled={record?.user_id ? true : false}
                  onClick={(e: any) => {
                    setRecordId(record?.id as string);
                    handleClickOpenUpdateDialog(e);
                  }}
                  data-testid={`update-btn-${record?.msisdn}`}
                >
                  <EditIcon />
                </Button>
              );
            }}
            source="content"
            label="Action"
            sortable={false}
          />
        </Datagrid>
      </List>
      {/* Update Dialog */}
      <UpdateDialog
        openUpdateDialog={openUpdateDialog}
        recordId={recordId}
        handleCloseUpdateDialog={handleCloseUpdateDialog}
      />
      {/* Side Drawer for History */}
      <Drawer
        open={
          !!matchCreate || (!!matchShow && matchShow?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchShow && matchShow?.params?.id !== "create" && (
          // @ts-ignore
          <Detail id={(matchShow as any).params.id} />
        )}
        {!!matchCreate && (
          // @ts-ignore
          <CreatePage />
        )}
      </Drawer>
    </>
  );
};
