import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export interface IpackageList {
  packageListFilter: {
    id: string;
    label: string;
  };
}

const initialState: IpackageList = {
  packageListFilter: {
    id: "",
    label: "",
  },
};

type packageListFilter = {
  id: string;
  label: string;
};

export const packagesListFilterSlice = createSlice({
  name: "thisPackagesListFilter",
  initialState,
  reducers: {
    setPackagesListFilter: (
      state,
      action: PayloadAction<packageListFilter>
    ) => {
      state.packageListFilter = {
        ...state.packageListFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setPackagesListFilter } = packagesListFilterSlice.actions;

export const getPackagesListFilter = (state: RootState) =>
  state.thisPackagesListFilter.packageListFilter;

export default packagesListFilterSlice.reducer;
