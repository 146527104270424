import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class ConstraintsLimitProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    console.log({ method: "getList", resource, params });

    if (params.filter.type) {
      filterArray.push({
        type: {
          _eq: params.filter.type,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query ConstraintList($where: ConstraintsWhereInput) {
          allConstraintsList(where: $where, ${args}) {
            data {
                id
                created_at
                updated_at
                label
                type
                values
                author
                scope
                author_data{
                  email
                  name
              }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allConstraintsList?.data || [],
      total: queryResult?.allConstraintsList?.count || 0,
    };
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });

    const { data } = await this.client.user.get();

    const toBeCreatedData: any = {
      label: params.data?.label,
      type: params.data?.type,
      values: !params.data.values ? {} : params.data.values,
      author: data?.id,
      scope: params.data?.isGlobal ? "GLOBAL" : null,
    };
    console.log(723, "toBeCreatedData", toBeCreatedData);
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [ConstraintsCreateInput!]!) {
          createConstraints(input: $input) {
            id
            created_at
            updated_at
            label
            type
            values
            author
            scope
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createConstraints[0] } as CreateResult;
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aConstraint($id: UUID!) {
          getConstraintsById(id: $id) {
            id
            created_at
            updated_at
            label
            type
            values
            author
            scope
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    const mappedObject = {
      ...queryResult?.getConstraintsById,
      isGlobal:
        queryResult?.getConstraintsById?.scope === "GLOBAL" ? true : false,
    };

    return { data: mappedObject };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });

    const { data } = await this.client.user.get();
    const toBeUpdatedData: any = {
      label: params.data?.label,
      type: params.data?.type,
      values: !params.data.values ? {} : params.data.values,
      author: data?.id,
      scope: params.data?.isGlobal ? "GLOBAL" : null,
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: ConstraintsUpdateInput!) {
          updateConstraintsById(ids: $ids, input: $input) {
            id
            created_at
            updated_at
            label
            type
            values
            author
            scope
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return { data: queryResult?.updateConstraintsById[0] } as UpdateResult;
  }
}
