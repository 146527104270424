import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class DocumentsBatchProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.type) {
      filterArray.push({
        type: {
          _ilike: `%${params.filter.type}%`,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "ASC",
      size: perPage,
      pageNumber: page,
      //   sort: field || "item_group",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllDocuments($where: DocumentsWhereInput) {
          allDocumentsList(where: $where, ${args}) {
            data{
                id
                created_at
                author_id
                type
                status
                url
                filename
                remark
                author_id_data{
                  email
                  name
                }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allDocumentsList?.data,
      total: queryResult?.allDocumentsList?.count,
    };
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "createReport", resource, params });
    const toBeCreatedData: any = {
      ...params.data,
    };
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [DocumentsCreateInput!]!) {
          createDocuments(input: $input) {
            id
            created_at
            updated_at
            author_id
            type
            status
            url
            filename
            remark
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createDocuments[0] } as CreateResult;
  }
}
