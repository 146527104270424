import { composeFilter } from "@mocobaas/client-js";
import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import gqltag from "graphql-tag";
export class DiasporasLatestNewsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const queryResult = await this.client.gql.query({
      query: gqltag`
            query {
                allDiasporasList(
                    limit: ${perPage}, 
                    offset: ${(page - 1) * perPage},
                    sort: ${field},
                    order: ${order || "ASC"},
                    where: {
                        category: {
                        _eq: "monthly_activity",
                        }
                    }
                    ){
                    data {
                        id
                        category
                        country_code
                        priority
                        content
                        created_at
                        updated_at
                    }
                    count
                }
            }
        `,
    });
    return {
      data: queryResult?.allDiasporasList?.data,
      total: queryResult?.allDiasporasList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const queryResult = await this.client.gql.query({
      query: gqltag`
            query {
                allDiasporasList(
                where: {
                    id: {
                    _eq: "${params.id}",
                    }
                }
                ){
                data {
                    id
                    category
                    country_code
                    priority
                    content
                }
                count
                }
            }
        `,
    });

    const data = queryResult?.allDiasporasList?.data[0];

    let preview = await this.client.storage.downloadUrl({
      fileId: data?.content?.thumbnailUri,
      params: {
        bucket: "diasporas",
      },
    });
    console.log(preview.data.url, "url");

    data.urlThumbnail = preview.data.url;
    const modifiedData = {
      ...data,
      oldThumbnail: data?.content?.thumbnailUri,
      url: data?.content?.url,
      showTime: data?.content?.showTime,
      title: data?.content?.title,
    };
    return { data: modifiedData } as GetOneResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const rawImage = params?.data?.thumbnail;

    const response: any = await this.client.storage.upload({
      file: rawImage.rawFile,
      params: {
        filename: `news-${new Date()}-${params?.data?.priority}`,
        bucket: "diasporas",
      },
    });

    const submittedData = {
      category: "monthly_activity",
      country_code: params?.data?.country_code,
      content: {
        title: params?.data?.title,
        url: params?.data?.url,
        thumbnailUri: response?.data.id,
        showTime: params?.data?.showTime,
      },
      priority: params?.data?.priority,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
                mutation($input: [DiasporasCreateInput!]!){
                    createDiasporas(input: $input){
                        id
                        category
                        country_code
                        content
                        priority
                    }
                }
            `,
      variables: {
        input: submittedData,
      },
    });

    return { data: queryResult?.createDiasporas[0] } as CreateResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const rawImage = params?.data?.newUrlThumbnail;

    let content = {};
    if (rawImage) {
      const response: any = await this.client.storage.upload({
        file: rawImage.rawFile,
        params: {
          filename: `news-${new Date()}-${params?.data?.priority}`,
          bucket: "diasporas",
        },
      });
      content = {
        title: params?.data?.title,
        url: params?.data?.url,
        thumbnailUri: response?.data.id,
        showTime: params?.data?.showTime,
      };
    } else {
      content = {
        title: params?.data?.title,
        url: params?.data?.url,
        thumbnailUri: params?.data?.oldThumbnail,
        showTime: params?.data?.showTime,
      };
    }

    const submittedData = {
      category: "monthly_activity",
      country_code: params?.data?.country_code,
      content: content,
      priority: params?.data?.priority,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
                mutation($ids: [UUID!]!, $input: DiasporasUpdateInput!){
                    updateDiasporasById(ids:$ids, input:$input){
                        id
                        category
                        country_code
                        content
                        priority
                    }
                }
            `,
      variables: {
        ids: [`${params.id}`],
        input: submittedData,
      },
    });

    return { data: queryResult?.updateDiasporasById[0] } as UpdateResult;
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
            mutation($ids: [UUID!]!){
              deleteDiasporasById(ids:$ids)
            }
          `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return { data: queryResult?.deleteDiasporasById[0] } as DeleteResult;
  }
}
