import { Button, Chip, Drawer } from '@mui/material';
import { get } from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { AutocompleteArrayInput, Datagrid, DateField, FilterButton, FunctionField, Identifier, List, NumberField, RaRecord, TopToolbar, useListContext } from 'react-admin';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { CreatePointRules } from './CreatePage';
import { EditPointRules } from './EditPage';
import EditIcon from '@mui/icons-material/Edit';
import { useRetrievePermission } from '../../../hooks/useRetrievePermission';

const DateRange = (props: any) => {
  const { filterValues, displayedFilters, setFilters } = useListContext();
  const [dateValue, setDateValue] = useState<any>({
    dateGte: filterValues.dateGte ? moment(filterValues.dateGte).startOf('day').format() : null,
    dateLte: filterValues.dateLte ? moment(filterValues.dateLte).endOf('day').format() : null,
  });

  const dateBegin = filterValues.dateGte ? new Date(moment(filterValues.dateGte).startOf('day').format()) : null;
  const dateEnd = filterValues.dateLte ? new Date(moment(filterValues.dateLte).endOf('day').format()) : null;

  return (
    <DateRangePicker
      defaultValue={dateBegin && dateEnd ? [dateBegin, dateEnd] : null}
      value={dateValue.dateGte && dateValue.dateLte ? [new Date(dateValue.dateGte), new Date(dateValue.dateLte)] : null}
      onClean={() => {
        setDateValue({
          dateGte: null,
          dateLte: null,
        });
        const updateFilter = {
          ...filterValues,
          dateGte: null,
          dateLte: null,
        };
        setFilters(updateFilter, displayedFilters);
      }}
      onChange={(value) => {
        const start = value ? moment(value[0].toString()).startOf('day').format() : null;
        const end = value ? moment(value[1].toString()).endOf('day').format() : null;

        setDateValue({
          dateGte: start,
          dateLte: end,
        });
        const updateFilter = {
          ...filterValues,
          dateGte: start,
          dateLte: end,
        };
        setFilters(updateFilter, displayedFilters);
      }}
    />
  );
};

const PointRulesFilter = [
  <DateRange alwaysOn />,
  <AutocompleteArrayInput
    label="Affected Country"
    source="affected_countries"
    choices={[
      { id: 'WW', name: 'WORLDWIDE' },
      { id: 'HK', name: 'Hongkong' },
      { id: 'ID', name: 'Indonesia' },
      { id: 'TW', name: 'Taiwan' },
      { id: 'MY', name: 'Malaysia' },
    ]}
    data-testid="search-country"
  />,
  <AutocompleteArrayInput
    label="Affected Service"
    source="affected_service"
    choices={[
      { id: 'G2', name: 'G2' },
      { id: 'M21', name: 'M21' },
    ]}
    data-testid="search-service"
  />,
];

const ListActions = ({ pointRulesFilter }: any) => {
  const [isSuperAdmin, isAdmin] = useRetrievePermission();

  return (
    <TopToolbar>
      <FilterButton filters={pointRulesFilter} />
      {(isSuperAdmin || isAdmin) && (
        <Button
          color="primary"
          size="small"
          data-testid="create_button"
          component={Link}
          to={{
            pathname: '/internal/point-rules/create',
          }}
        >
          + Add New
        </Button>
      )}
    </TopToolbar>
  );
};

export const PointRulesList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSuperAdmin, isAdmin] = useRetrievePermission();

  const matchCreate = matchPath('/internal/point-rules/create', location.pathname);

  let matchEdit = null;
  if (!location.pathname.endsWith('create')) {
    matchEdit = matchPath('/internal/point-rules/:id', location.pathname);
  }

  const onCloseDrawer = useCallback(() => {
    navigate('/internal/point-rules');
  }, [navigate]);

  return (
    <>
      <List resource="pointRules" sort={{ field: 'created_at', order: 'DESC' }} title="Internal &rsaquo; Point Rules" filters={PointRulesFilter} actions={<ListActions pointRulesFilter={PointRulesFilter} />} empty={false}>
        <Datagrid key={((id: Identifier) => id) as unknown as React.Key} bulkActionButtons={false}>
          <DateField
            source="created_at"
            label="Created"
            showTime
            locales="en-HK"
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }}
          />
          <FunctionField
            label="Affected Services"
            render={(record: any) => {
              const services = get(record, 'affected_service', []);
              return services.map((service: string) => <Chip style={{ margin: '0px 5px 5px 5px' }} size="small" label={service} key={service} />);
            }}
          />
          <FunctionField
            label="Affected Countries"
            render={(record: any) => {
              const countries = get(record, 'affected_countries', []);
              const max = 4;
              return (
                <>
                  {countries
                    .filter((_: any, i: number) => i < max)
                    .map((country: string) => (
                      <Chip key={country} variant="outlined" style={{ margin: '0px 5px 5px 5px', color: '#000' }} label={country} />
                    ))}
                  {countries.length > max && <Chip variant="outlined" style={{ margin: '0px 5px 5px 5px', background: '#111', color: '#fff' }} label={`+${countries.length - max}`} />}
                </>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Expiry Period"
            render={(record: { start: moment.MomentInput; end: moment.MomentInput }) => {
              const expiryObject = {
                from: moment(record.start).format('DD/M/YYYY, h:mm:ss a'),
                until: moment(record.end).format('DD/M/YYYY, h:mm:ss a'),
              };
              return (
                <div>
                  <label>
                    <b>From:</b>
                    <br />
                    {expiryObject.from}
                  </label>
                  <br />
                  <label>
                    <b>To:</b>
                    <br />
                    {expiryObject.until}
                  </label>
                </div>
              );
            }}
          />
          <FunctionField
            label="Limit Points"
            render={(record: RaRecord) => {
              return (
                <>
                  {record?.limits?.point && (
                    <div>
                      <label>
                        <b>Min:</b>&nbsp;
                        {record?.limits?.point?.min}
                      </label>
                    </div>
                  )}
                  {record?.limits?.point && (
                    <div>
                      <label>
                        <b>Max:</b>&nbsp;
                        {record?.limits?.point?.max}
                      </label>
                    </div>
                  )}
                </>
              );
            }}
          />
          <FunctionField
            label="Limit Coin"
            render={(record: RaRecord) => {
              return (
                <>
                  {record?.limits?.coin && (
                    <div>
                      <label>
                        <b>Min:</b>&nbsp;
                        {record?.limits?.coin?.min}
                      </label>
                    </div>
                  )}
                  {record?.limits?.coin && (
                    <div>
                      <label>
                        <b>Max:</b>&nbsp;
                        {record?.limits?.coin?.max}
                      </label>
                    </div>
                  )}
                </>
              );
            }}
          />
          <NumberField label="Usage Limits" source="limits.usage_limit" />
          {(isSuperAdmin || isAdmin) && (
            <FunctionField
              render={(record: RaRecord) => {
                return (
                  <Button
                    color="primary"
                    size="small"
                    data-testid="edit_button"
                    component={Link}
                    to={{
                      pathname: `/internal/point-rules/${record.id}`,
                    }}
                  >
                    <EditIcon /> Edit
                  </Button>
                );
              }}
              source="content"
              label="Action"
              sortable={false}
            />
          )}
        </Datagrid>
      </List>
      <Drawer variant="persistent" open={!!matchCreate || !!matchEdit} anchor="right" onClose={onCloseDrawer}>
        {!!matchCreate && <CreatePointRules onClose={onCloseDrawer} />}
        {!!matchEdit && <EditPointRules id={(matchEdit as any).params.id} onClose={onCloseDrawer} />}
      </Drawer>
    </>
  );
};
