import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  required,
  useNotify,
  Create,
  useRedirect,
  BooleanInput,
  SelectInput,
  TextInput,
  useGetList,
  useInput,
  Button,
  Error,
  Toolbar,
  SaveButton,
  useRefresh,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import AceEditor from "react-ace";
import Grid from "@mui/material/Grid";
import mbaas from "../../../provider/Mbaas";
import gql from "graphql-tag";
import Box from "@mui/material/Box";

const type = [
  { id: "PRODUCT_LIMIT", name: "PRODUCT_LIMIT" },
  { id: "RELOAD_LIMIT", name: "RELOAD_LIMIT" },
  { id: "STRIPE_LIMIT", name: "STRIPE_LIMIT" },
];

const ValuesInput = (props: any) => {
  const [inputValue, setInputValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const valuesInputHook = useInput(props);

  useEffect(() => {
    setInputValue(JSON.stringify(valuesInputHook.field.value, null, "\t"));
    setIsChanged(false);
  }, [valuesInputHook.field.value]);

  function onChange(newValue: any) {
    setInputValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(inputValue);
    valuesInputHook.field.onChange(parsed);
  }

  return (
    <div>
      <AceEditor
        name="valuesInputHook"
        style={{ height: "200px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={inputValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

const CreatePage = (props: any) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: any) => {
    // @ts-ignore
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/constraintsLimit");
    refresh();
  };

  return (
    <>
      <Create
        {...props}
        title="Constraints &gt; Add"
        mutationOptions={{
          onSuccess: onSuccess,
        }}
        redirect="list"
      >
        <Box
          display="block"
          ml={2}
          mr={2}
          justifyContent="center"
          alignItems="center"
          width="50vW"
          height="100vh"
          id="muiDrawerCreate"
        >
          <SimpleForm>
            <div className={classes.root}>
              <div className={classes.form}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      source="label"
                      validate={[required()]}
                      multiline
                      label="Label"
                      fullWidth
                      data-test-id="labelInput"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      fullWidth
                      className={classes.input}
                      source="type"
                      validate={[required()]}
                      choices={type}
                      label="Type"
                      data-test-id="type"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <BooleanInput
                      fullWidth
                      source="isGlobal"
                      label="is Global?"
                      data-test-id="isGlobal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p>Values</p>
                    <ValuesInput source="values" validate={required()} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </SimpleForm>
        </Box>
      </Create>
    </>
  );
};

export default CreatePage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
