/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Paper from "@mui/material/Paper";
import { Grid, Divider } from "@mui/material";
import ColorVal from "../utils/color";
import { useRedirect } from "react-admin";

export default function EmailVerified() {
  const redirect = useRedirect();

  return (
    <div
      style={{
        backgroundImage: `url(/bg5.jpg)`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Paper
          style={{
            width: "350px",
            padding: "40px",
            marginTop: "50px",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <img src={"logom21.png"} alt="logo" style={{ width: "80px" }} />
            </Grid>
            <Grid item style={{ width: "100%", margin: "20px 0px" }}>
              <Divider>Email Verification Complete</Divider>
            </Grid>
            <Grid
              item
              style={{
                color: ColorVal.primary,
                fontSize: "13px",
                cursor: "pointer",
              }}
              onClick={() => redirect("/login")}
            >
              Go to Login Page
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
