import React, { useEffect, useState } from "react";
import { Button, useInput, InputProps } from "react-admin";
import AceEditor from "react-ace";
import { Typography } from "@mui/material";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";

const HeightJSONInput = (props: InputProps) => {
  const [JSONValue, setJSONValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const jsondata = useInput(props);

  useEffect(() => {
    setJSONValue(JSON.stringify(jsondata.field.value, null, "\t"));
    setIsChanged(false);
  }, [jsondata.field.value]);

  function onChange(newValue: any) {
    setJSONValue(newValue);
    setIsChanged(true);
  }

  function saveToForm() {
    const parsed = JSON.parse(JSONValue);
    jsondata.field.onChange(parsed);
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Typography variant="h6" mb={1}>
        {`${jsondata.field.name
          .charAt(0)
          .toUpperCase()}${jsondata.field.name.slice(1)}`.replace("_", " ")}
      </Typography>
      <AceEditor
        name={jsondata.id}
        style={{ height: "450px", width: "100%" }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={JSONValue}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ marginTop: 8 }}
          onClick={saveToForm}
        >
          <>Commit Changes</>
        </Button>
      )}
    </div>
  );
};

export default HeightJSONInput;
