// @ts-nocheck
import * as React from "react";
import { List, FormTab, TabbedForm } from "react-admin";
import SmsTariffs from "./smsTariffs";
import VoiceTariffs from "./voiceTariffs";

export const ServicesTariffsList = () => (
  <List title="Services &rsaquo; Tariffs" resource="servicesTariffs" pagination={false} exporter={false}>
    <TabbedForm toolbar={false}>
      <FormTab label="sms tariffs" name="sms tariffs" path="">
        <SmsTariffs />
      </FormTab>
      <FormTab label="voice tariffs" name="voice tariffs" path="voice-tariffs">
        <VoiceTariffs />
      </FormTab>
    </TabbedForm>
  </List>
);
