// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Show,
  FunctionField,
  WithRecord,
  Pagination,
  useRecordContext,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  useNotify,
  useRedirect,
  required,
  Toolbar,
  SaveButton,
  Button,
  RaRecord,
  useRefresh,
  usePermissions,
} from "react-admin";
import StartIcon from '@mui/icons-material/Start';
import ReactJson from "react-json-view";
import moment from "moment";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button as MaterialButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import Mbaas from "../../provider/Mbaas";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";

const SyncReloadStatusButton = ({ record }) => {
  const onClickHandler = async () => {
    const { error: syncReloadStatusErr, data: syncReloadStatusData } =
      await Mbaas.client.invokeApi("console-sync-reload-status", {
        payment_id: record.reload_id,
      });
    if (syncReloadStatusErr)
      Swal.fire(
        `Request failed:` + "\n" + ` ${syncReloadStatusErr?.error}`,
        "",
        "error"
      );
    if (syncReloadStatusData === "OK")
      Swal.fire("Successfully Sync Reload Status!", "", "success");
  };
  const disabled =
    record.histories.filter((x) => x.data?.status === "PAID").length > 0;
  return (
    <MaterialButton
      startIcon={<SyncIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={onClickHandler}
      disabled={disabled}
    >
      Sync Reload Status
    </MaterialButton>
  );
};

function openPGS(record: any) {
  let prefix = '';
  if (window.location.host.startsWith('develop.') || window.location.host.startsWith('localhost:')) {
    prefix = 'develop.';
  }

  let pgsView: String; 
  if (record?.data?.payment_id) {
    pgsView = `https://${prefix}ims-pgs-console.pages.dev/pgs__payments?filter={"payment_id":"${record.data.payment_id}"}`;
  } else if (record?.data?.reference_id) { 
    pgsView = `https://${prefix}ims-pgs-console.pages.dev/pgs__payments?filter={"reference_id":"${record.data.reference_id}"}`;
  } else {
    return <></>;
  }

  const openTab = () => {
    window.open(encodeURI(pgsView));
  };

  return (
    <IconButton onClick={openTab}>
      <StartIcon fontSize="small" />
    </IconButton>
  );
}

const PurchaseLogHistories = ({ histories }) => {
  const [page, setPage] = useState(1);
  const perPage = 5;
  const total = histories?.length;
  const shownHistories = histories
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .filter(
      (x: any, index) =>
        x && index >= (page - 1) * perPage && index < page * perPage
    );
  console.log({ shownHistories, offset: (page - 1) * perPage });

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Remark</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>View on PGS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shownHistories.map((data) => {
            const dataData = {
              ...data.data,
              ...(data.override ? { override: data.override } : {}),
            };
            return (
              <TableRow key={data.id}>
                <TableCell>
                  {moment(data.date).format("DD/MM/YYYY, hh:mm:ss A")}
                </TableCell>
                <TableCell>{data.status.toUpperCase()}</TableCell>
                <TableCell>{data.remark}</TableCell>
                <TableCell>
                  <ReactJson
                    src={dataData || []}
                    shouldCollapse={() => { }}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    name={false}
                  />
                </TableCell>
                <TableCell>
                  <FunctionField label="View on PGS" render={() => openPGS(data)} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
        rowsPerPageOptions={[2]}
      />
    </>
  );
};

const useStyles = makeStyles({
  detailsBox: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
  },
  topGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
    paddingTop: "40px !important",
  },
  bottomGrid: {
    paddingBottom: "20px",
  },
  histExpTopGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
  },
  histExpDetailsBox: {
    paddingBottom: "5px",
    paddingTop: "5px !important",
  },
});

const CustomToolbar = ({ onCancel }) => (
  <Toolbar>
    <Grid item xs={12}>
      <SaveButton data-test-id="Override" label="Override" />
      <Button
        variant="contained"
        color="error"
        style={{
          minWidth: "64px",
          padding: "6px 16px",
          borderRadius: "4px",
          lineHeight: "1.75",
          marginLeft: "14px",
        }}
        label="ra.action.cancel"
        onClick={onCancel}
      >
        <CloseIcon />
      </Button>
    </Grid>
  </Toolbar>
);

const Override = ({ onCancel }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const refresh = useRefresh();

  const onSuccess = () => {
    redirect("/usages/reload");
    notify("Amount Has Been Overridden");
    refresh();
  };

  const disabledForm = record.status === "PAID";

  return (
    <Create
      resource="overrideReload"
      mutationOptions={{
        onSuccess: onSuccess,
      }}
      mutationMode="pessimistic"
      title=" &rsaquo; Override"
    >
      <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} />}>
        <div className={classes.root}>
          <Grid container maxWidth={600}>
            <Grid item xs={12}>
              <TextInput
                source="reload_id"
                fullWidth
                validate={required()}
                defaultValue={record.reload_id}
                disabled
                className={classes.input}
                label="Order ID"
              />
            </Grid>
            <Grid item xs={12}>
              <NumberInput
                data-test-id="amount"
                source="amount"
                fullWidth
                validate={required()}
                label="New Amount"
                disabled={disabledForm}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                data-test-id="remark"
                source="reason"
                validate={required()}
                fullWidth
                className={classes.input}
                disabled={disabledForm}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                data-test-id="totp"
                source="totp"
                validate={required()}
                fullWidth
                className={classes.input}
                disabled={disabledForm}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const ReloadLogDetail = ({ onCancel, ...props }) => {
  const { permissions } = usePermissions() as { permissions: Array<string> };
  console.log({ permissions });

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const [currentRecord, setCurrentRecord] = React.useState({} as RaRecord);

  return (
    <Show resource="reloadLogs" title=" &rsaquo; Detail" {...props}>
      <FunctionField render={(record: RaRecord) => setCurrentRecord(record)} />
      <Box
        pt={5}
        width={{ xs: "110vW", sm: 650 }}
        mt={{ xs: 2, sm: 1 }}
        ml={{ xs: 2, sm: 1 }}
        mr={{ xs: 2, sm: 1 }}
        minHeight="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid container spacing={2}>
              <Grid item xs={4} marginTop={2}>
                <strong>Subscriber Name</strong>
              </Grid>
              <Grid item xs={8} marginTop={2}>
                <TextField source="user_id_data.name" />
              </Grid>

              <Grid item xs={4}>
                <strong>Subscriber Email</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="user_id_data.email" />
              </Grid>

              <Grid item xs={4}>
                <strong>Subscriber Phone</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="user_id_data.phone" />
              </Grid>

              <Grid item xs={4}>
                <strong>Status</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record.status.toUpperCase();
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <strong>Channel</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="channel" />
              </Grid>

              <Grid item xs={4}>
                <strong>Amount</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="amount" />
              </Grid>

              <Grid item xs={4}>
                <strong>Rate Snapshot</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return (
                      <ReactJson
                        src={record.rate_snapshot || {}}
                        shouldCollapse={() => { }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <strong>Order Id</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="reload_id" />
              </Grid>

              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>
          </Tab>
          <Tab label="history">
            <WithRecord
              render={(record) => {
                return (
                  <>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      pt={2}
                      style={{ gap: "0.5rem" }}
                    >
                      <SyncReloadStatusButton record={record} />
                    </Box>
                    <PurchaseLogHistories histories={record.histories || []} />
                  </>
                );
              }}
            />
          </Tab>
          {permissions?.filter((x) => x.toLowerCase().includes("admin"))
            .length > 0 &&
            currentRecord?.channel === "TNG" && (
              <Tab label="override">
                <Override onCancel={onCancel} />
              </Tab>
            )}
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
