import { memo, useState } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  AppBar,
  Layout,
  Logout,
  UserMenu,
  LayoutProps,
  // MenuItemLink,
} from "react-admin";
import Menu from "./Menu";
import { Tooltip, IconButton, Divider, Typography } from "@mui/material";
import {
  QrCode,
  // Google
} from "@mui/icons-material";
import MFAdialog from "../component/MFAdialog";
const MyUserMenu = () => (
  <UserMenu>
    <div style={{ margin: "10px 15px", fontSize: "12px" }}>
      {localStorage.getItem("username")}
    </div>
    <Divider style={{ margin: "0px 15px" }} />
    {/* <MenuItemLink
      to={"/link-account"}
      primaryText={"Link Account"}
      leftIcon={<Google />}
    /> */}
    <Logout />
  </UserMenu>
);

const MyAppBar = memo((props) => {
  const [open, setOpen] = useState(false);

  return (
    <AppBar {...props} userMenu={<MyUserMenu />} elevation={1}>
      {" "}
      <Typography variant="h6" color="inherit" id="react-admin-title" />
      <span style={{ flex: "1" }} />
      <Tooltip title="Reset OTP" aria-label="barcode">
        <IconButton aria-label="Barcode" onClick={() => setOpen(true)}>
          <QrCode style={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <MFAdialog open={open} setOpen={setOpen} />
    </AppBar>
  );
});

const CustomLayout = (props: LayoutProps) => (
  <>
    <Layout {...props} appBar={MyAppBar} menu={Menu} />
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{ style: { width: 20, height: 30 } }}
    />
  </>
);

export default CustomLayout;
