import { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack } from "@mui/material";
import { Edit, useGetIdentity, useNotify, useRedirect, useRefresh } from "react-admin";
import Swal from "sweetalert2";
import { dataProvider } from "../../../provider";
import { isArray, isEmpty, omit } from "lodash";
import RewardForm from "./components/RewardForm";
import Mbaas from "../../../provider/Mbaas";
import moment from "moment";

interface EditServiceRewardProps {
  onClose: () => void;
  id: string;
}

export const EditServiceReward: React.FC<EditServiceRewardProps> = (props) => {
  const { onClose, id, ...restProps } = props;
  const { isLoading: isIdLoading, identity: user } = useGetIdentity();
  const [data, setData] = useState<any>({});
  const [itemGroup, setItemGroup] = useState<any>([]);
  const [loadingGetOne, setloadingGetOne] = useState<boolean>(true);
  const [loadingGetOneProduct, setloadingGetOneProduct] = useState<boolean>(true);

  useEffect(() => {
    dataProvider
      .getOne("rewardCatalogues", {
        id: id,
      })
      .then((v) => {
        const items = v.data || [];
        setData(items);
        setloadingGetOne(false);
      });
  }, [id]);

  useEffect(() => {
    dataProvider
      .getList("productCatalogs", {
        filter: data?.item_detail?.bonus_for?.item_keys
          ? {
              item_key: data?.item_detail?.bonus_for?.item_keys,
            }
          : {},
        pagination: { page: 1, perPage: 10 },
        sort: {
          field: "label",
          order: "ASC",
        },
      })
      .then((v) => {
        let itemGroup: any = [];
        if (data?.item_detail?.bonus_for?.item_keys) {
          itemGroup = Array.from(
            // create unique choice item group
            new Set(v.data.map((item: any) => item.item_group))
          );
        }

        setItemGroup(itemGroup);
        setloadingGetOneProduct(false);
      });
  }, [data]);

  const recordData = useMemo(() => {
    if (data) {
      const { item_detail, update_history, ...restData } = data;

      const isBonus = !isEmpty(item_detail?.bonus_for);

      const rewards = {
        ...restData,
        availability_by_service: isArray(restData.availability_by_service) ? restData.availability_by_service[0] : restData.availability_by_service,
        item_detail: {
          codes: item_detail?.codes,
          image: {
            src: item_detail?.image,
          },
        },
        is_bonus: isBonus,
        start: isBonus ? item_detail?.bonus_for.start : "",
        end: isBonus ? item_detail?.bonus_for.end : "",
        bonus_item_key: isBonus ? item_detail?.bonus_for.item_keys : [],
        bonus_item_group: isBonus ? itemGroup : [],
        category: item_detail?.item.category,
        item_key: item_detail?.item.item_key,
        item_group: item_detail?.item.item_group,
        limit: item_detail?.item.limit,
        amount: item_detail?.item.amount,
        update_history: update_history || [],
      };
      if (data.item_label && typeof data.item_label === "object" && !isArray(data.item_label)) {
        rewards["item_label"] = Object.keys(data.item_label).map((m) => ({ country: m, label: data.item_label[m] }));
      }
      if (data.item_detail?.codes && typeof data.item_detail?.codes === "object" && !isArray(data.item_detail?.codes)) {
        rewards["item_detail"]["codes"] = Object.keys(data.item_detail?.codes).map((m) => ({ code: data.item_detail?.codes[m] }));
      }

      if (data.redemption_amount && typeof data.redemption_amount === "object" && !isArray(data.redemption_amount)) {
        rewards["redemption_amount"] = Object.keys(data.redemption_amount).map((m) => ({ country: m, point: data.redemption_amount[m]?.point, coin: data.redemption_amount[m]?.coin }));
      }

      return rewards;
    }
    return {};
  }, [data, itemGroup]);

  console.log(data, 'cek-data')
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [loading, setLoading] = useState<boolean>(false);

  const isLoading = loading || isIdLoading || loadingGetOne || loadingGetOneProduct;

  const onSubmitHandler = async (values: any) => {
    setLoading(true);
    try {
      const { item_detail, category, availability_by_service, item_key, item_group, limit, amount, update_history, is_bonus, bonus_item_key, start, end, ...restValue } = values;

      let updateHistory = isArray(update_history) ? update_history : [];
      let tempImage = item_detail.image?.src;

      const payloads: any = {
        ...omit(restValue, ["item_label", "redemption_amount", "redeem_limits", "created_at", "updated_at", "id", "bonus_item_group"]),
        item_label: {},
        redemption_amount: {},
        redeem_limits: {},
        availability_by_service: [availability_by_service],
        item_detail: {
          codes: {},
          image: "",
          item: {
            category,
            item_key,
            item_group,
            limit,
            amount,
          },
        },
        update_history: [],
      };

      if (restValue.item_label) {
        restValue.item_label.forEach((m: any) => {
          payloads["item_label"][m.country] = m.label;
        });
      }

      if (item_detail.codes) {
        const codes: any = [];
        item_detail.codes.forEach((m: any) => {
          codes.push({
            code: m.code,
            redeemed: m.redeemed ? m.redeemed : false,
            purchased: m.purchased ? m.purchased : false,
            redeemed_at: m.redeemed_at ? m.redeemed_at : "",
            purchased_at: m.purchased_at ? m.purchased_at : "",
          });
        });
        payloads["item_detail"]["codes"] = codes;
      }

      if (restValue.redemption_amount) {
        restValue.redemption_amount.forEach((m: any) => {
          payloads["redemption_amount"][m.country] = {
            coin: Number(m.coin),
            point: Number(m.point),
          };
        });
      }

      if (values.redeem_limits) {
        payloads["redeem_limits"] = {
          daily: Number(values.redeem_limits.daily),
          monthly: Number(values.redeem_limits.monthly),
          minimum_balance: {
            coin: Number(values.redeem_limits.minimum_balance.coin),
            point: Number(values.redeem_limits.minimum_balance.point),
          },
        };
      }

      if (item_detail?.image?.rawFile) {
        const uploadImage: any = await Mbaas.client.storage.upload({
          file: item_detail.image?.rawFile,
          params: {
            filename: `reward-${Date.now()}`,
            bucket: "banners",
          },
        });

        const downloadResp = await Mbaas.client.storage.downloadUrl({
          params: {
            bucket: "banners",
          },
          fileId: uploadImage?.data?.id,
        });

        const urlImg = downloadResp.data.url.split("?");
        tempImage = urlImg[0];
      }

      payloads.item_detail.image = tempImage;
      if (is_bonus) {
        payloads.item_detail.bonus_for = { item_keys: bonus_item_key, start, end };
      } else {
        delete payloads.item_detail.bonus_for;
      }
      updateHistory.unshift({
        updated_by: {
          name: user?.name,
          user_id: user?.id,
        },
        changes: {
          from: {
            ...omit(data, ["created_at", "updated_at", "id", "update_history"]),
          },
          to: {
            ...omit(payloads, ["update_history"]),
          },
        },
        timestamp: moment().toISOString(),
      });

      payloads["update_history"] = updateHistory;

      const resp = await dataProvider.update("rewardCatalogues", {
        data: payloads,
        id: id,
        previousData: undefined,
      });

      if (resp.data) {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/services/rewards`);
        refresh();
        Swal.fire("Success!", "", "success");
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire(`Request failed: ${error}`, "", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Edit {...restProps} resource="rewardCatalogues" title=" &rsaquo; Edit">
      <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={0} sx={{ position: "absolute", right: 10, zIndex: 100 }}>
          <IconButton color="error" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <RewardForm onSubmit={onSubmitHandler} record={recordData} isLoading={isLoading} type="edit" />
      </Box>
    </Edit>
  );
};
