// @ts-nocheck
import React, { useEffect } from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Show,
  FunctionField,
  DateField,
} from "react-admin";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Button as MaterialButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  PhoneAndroid,
  Sms as SmsIcon,
  DialerSip as DialerSipIcon,
  TrendingUp as ServiceRateIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  detailsBox: {
    // borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "8px",
  },
  topGrid: {
    // borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "8px",
    paddingTop: "40px !important",
  },
  bottomGrid: {
    paddingBottom: "8px",
  },
});

export const Detail = ({ onCancel, ...props }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  return (
    <Show resource="smsLogs" title=" &rsaquo; Detail" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        minHeight="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid container spacing={2}>
              <Grid className={classes.topGrid} item xs={4}>
                <strong>Date</strong>
              </Grid>
              <Grid className={classes.topGrid} item xs={8}>
                <DateField
                  source="created_at"
                  label="Date"
                  showTime
                  locales="en-HK"
                  options={{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }}
                />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Direction</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="direction" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>A Number</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="a_number" label="A Number" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>B Number</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <TextField source="b_number" label="B Number" />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>Status</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record.status.toUpperCase();
                  }}
                />
              </Grid>
              <Grid className={classes.detailsBox} item xs={4}>
                <strong>HKD Cost</strong>
              </Grid>
              <Grid className={classes.detailsBox} item xs={8}>
                <FunctionField render={(record) => `HKD ${record.hkd_cost}`} />
              </Grid>
              <Grid className={classes.bottomGrid} item xs={12}>
                <FunctionField
                  render={(record) => (
                    <MaterialButton
                      startIcon={<PhoneAndroid />}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        navigate(
                          `/subscribers/${record.virtual_msisdn_id_data.user_id}/show`
                        )
                      }
                    >
                      Subscriber
                    </MaterialButton>
                  )}
                />
                &nbsp;
                <FunctionField
                  render={(record) => (
                    <MaterialButton
                      startIcon={<SmsIcon />}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        navigate(
                          `/subscribers/${record.virtual_msisdn_id_data.user_id}/show/sms`
                        )
                      }
                    >
                      SMS Quota
                    </MaterialButton>
                  )}
                />
                &nbsp;
                <FunctionField
                  render={(record) => (
                    <MaterialButton
                      startIcon={<DialerSipIcon />}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        navigate(
                          `/subscribers/${record.virtual_msisdn_id_data.user_id}/show/voice`
                        )
                      }
                    >
                      Voice Quota
                    </MaterialButton>
                  )}
                />
                &nbsp;
                <FunctionField
                  render={() => (
                    <MaterialButton
                      startIcon={<ServiceRateIcon />}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => navigate("/services/tarrifs")}
                    >
                      Service Rate
                    </MaterialButton>
                  )}
                />
              </Grid>
            </Grid>
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
