import { useEffect } from "react";
import { useListContext } from "react-admin";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
//
import {
  getPurchaseLogFilter,
  setPurchaseLogFilter,
} from "src/slices/purchaseLogPage/filterPurchaseLogSlice";
import {
  getMvnoFilter,
  setMvnoFilter,
} from "src/slices/mvnoPage/filterMvnoSlice";
import {
  getSmsLogFilter,
  setSmsLogFilter,
} from "src/slices/smsLogPage/filterSmsLogSlice";
import {
  getVoiceLogFilter,
  setVoiceLogFilter,
} from "src/slices/voiceLogPage/filterVoiceLogSlice";
import {
  getReloadLogFilter,
  setReloadLogFilter,
} from "src/slices/reloadLogPage/filterReloadLogSlice";
import { getPackagesListFilter } from "src/slices/packagesListPage/filterPackagesListlice";
import { getProductsListFilter } from "src/slices/productsListPage/filterProductsListlice";
import { getSubscribersFilter } from "src/slices/subscribersPage/filterSubscribersSlice";
import { getBannerPromotionsFilter } from "src/slices/bannerPromotionsPage/bannerPromotionsSlice";
import {
  getBroadcastFilter,
  setBroadcastFilter,
} from "src/slices/broadCastPage/broadcastSlice";
import {
  getSaleProgramFilter,
  setSaleProgramFilter,
} from "src/slices/saleProgramsPage/filterSaleProgramSlice";
import {
  getReferralBenefit,
  setReferralBenefitFilter,
} from "src/slices/referralBenefitsPage/filterReferralBenefitSlice";
import { getDeviceLogsFilter } from "src/slices/deviceLogsPage/filterDeviceLogsSlice";

export const usePersistFilter = () => {
  const { setFilters, filterValues } = useListContext();
  const location = useLocation();
  const dispatch = useDispatch();
  //
  const purchaseLogFilters = useSelector(getPurchaseLogFilter);
  const mvnoFilters = useSelector(getMvnoFilter);
  const smsLogFilter = useSelector(getSmsLogFilter);
  const voiceLogFilter = useSelector(getVoiceLogFilter);
  const reloadLogFilter = useSelector(getReloadLogFilter);
  const packagesListFilter = useSelector(getPackagesListFilter);
  const productsListFitler = useSelector(getProductsListFilter);
  const subscribersFilter = useSelector(getSubscribersFilter);
  const bannerPromotionsFilter = useSelector(getBannerPromotionsFilter);
  const broadcastFilter = useSelector(getBroadcastFilter);
  const saleProgramFilter = useSelector(getSaleProgramFilter);
  const referralBenefitFilter = useSelector(getReferralBenefit);
  const deviceLogsFilter = useSelector(getDeviceLogsFilter);

  function purchaseLogPageFilter() {
    setFilters(
      {
        purchase_id: purchaseLogFilters.purchase_id,
        payment_id: purchaseLogFilters.payment_id,
        status: purchaseLogFilters.status,
        target_detail: purchaseLogFilters.target_detail,
        user_id: purchaseLogFilters.user_id,
        dateGte: purchaseLogFilters.dateGte,
        dateLte: purchaseLogFilters.dateLte,
      },
      {}
    );
  }

  function mvnoPageFilter() {
    setFilters(
      {
        purchase_id: mvnoFilters.purchase_id,
        bundle_id: mvnoFilters.bundle_id,
        status: mvnoFilters.status,
        user_id: mvnoFilters.user_id,
        dateGte: mvnoFilters.dateGte,
        dateLte: mvnoFilters.dateLte,
      },
      {}
    );
  }

  function smsLogPageFilter() {
    setFilters(
      {
        virtual_msisdn_id: smsLogFilter.virtual_msisdn_id,
        dateGte: smsLogFilter.dateGte,
        dateLte: smsLogFilter.dateLte,
      },
      {}
    );
  }

  function voiceLogPageFilter() {
    setFilters(
      {
        number: voiceLogFilter.number,
        dateGte: voiceLogFilter.dateGte,
        dateLte: voiceLogFilter.dateLte,
      },
      {}
    );
  }

  function reloadLogPageFilter() {
    setFilters(
      {
        status: reloadLogFilter.status,
        name: reloadLogFilter.name,
        phone: reloadLogFilter.phone,
        channel: reloadLogFilter.channel,
        reload_id: reloadLogFilter.reload_id,
        dateGte: reloadLogFilter.dateGte,
        dateLte: reloadLogFilter.dateLte,
      },
      {}
    );
  }

  function packageListPageFilter() {
    setFilters(
      { id: packagesListFilter.id, label: packagesListFilter.label },
      {}
    );
  }

  function productListPageFilter() {
    setFilters(
      { id: productsListFitler.id, label: productsListFitler.label },
      {}
    );
  }

  function subscribersPageFilter() {
    setFilters(
      {
        keyword: subscribersFilter.keyword,
        status: subscribersFilter.status,
        msisdn: subscribersFilter.msisdn,
        passport_number: subscribersFilter.passport_number,
        dateGte: subscribersFilter.dateGte,
        dateLte: subscribersFilter.dateLte,
      },
      {}
    );
  }

  function bannerPromotionsPageFilter() {
    setFilters(
      {
        country: bannerPromotionsFilter.country,
        category: bannerPromotionsFilter.type,
        account_type: bannerPromotionsFilter.platform,
      },
      {}
    );
  }

  function broadcastPageFilter() {
    setFilters(
      {
        title: broadcastFilter.title,
        content: broadcastFilter.content,
        dateGte: broadcastFilter.dateGte,
        dateLte: broadcastFilter.dateLte,
      },
      {}
    );
  }

  function saleProgramPageFilter() {
    setFilters(
      {
        status: saleProgramFilter.status,
        country: saleProgramFilter.country,
        item_keys: saleProgramFilter.item_keys,
        item_groups: saleProgramFilter.item_groups,
        dateGte: saleProgramFilter.dateGte,
        dateLte: saleProgramFilter.dateLte,
      },
      {}
    );
  }

  function referralBenefitPageFilter() {
    setFilters(
      {
        status: referralBenefitFilter.status,
        country: referralBenefitFilter.country,
        dateGte: referralBenefitFilter.dateGte,
        dateLte: referralBenefitFilter.dateLte,
      },
      {}
    );
  }

  function deviceLogsPageFilter() {
    setFilters(
      {
        fingerprint: deviceLogsFilter.fingerprint,
        dateGte: deviceLogsFilter.dateGte,
        dateLte: deviceLogsFilter.dateLte,
      },
      {}
    );
  }

  // If filter is not set to alwaysOn, add that filter in useEffect below
  // to manually set the state to initial state if the filter is closed(x)
  useEffect(() => {
    switch (location.pathname) {
      case "/usages/purchase":
        if (filterValues.user_id === undefined) {
          // @ts-ignore
          dispatch(setPurchaseLogFilter({ user_id: "" }));
        }
        break;
      case "/usages/mvno":
        if (filterValues.user_id === undefined) {
          // @ts-ignore
          dispatch(setMvnoFilter({ user_id: "" }));
        }
        break;
      case "/usages/sms":
        if (filterValues.virtual_msisdn_id === undefined) {
          // @ts-ignore
          dispatch(setSmsLogFilter({ virtual_msisdn_id: "" }));
        }
        break;
      case "/usages/voice":
        if (filterValues.number === undefined) {
          // @ts-ignore
          dispatch(setVoiceLogFilter({ number: "" }));
        }
        break;
      case "/usages/reload":
        if (filterValues.phone === undefined) {
          // @ts-ignore
          dispatch(setReloadLogFilter({ phone: "" }));
        }
        if (filterValues.channel === undefined) {
          // @ts-ignore
          dispatch(setReloadLogFilter({ channel: "" }));
        }
        if (filterValues.reload_id === undefined) {
          // @ts-ignore
          dispatch(setReloadLogFilter({ reload_id: "" }));
        }
        break;
      case "/promotions/broadcast":
        if (filterValues.title === undefined) {
          // @ts-ignore
          dispatch(setBroadcastFilter({ title: "" }));
        }
        if (filterValues.content === undefined) {
          // @ts-ignore
          dispatch(setBroadcastFilter({ content: "" }));
        }
        break;
      case "/promotions/flashsale":
        if (filterValues.country === undefined) {
          // @ts-ignore
          dispatch(setSaleProgramFilter({ country: "" }));
        }
        if (filterValues.item_keys === undefined) {
          // @ts-ignore
          dispatch(setSaleProgramFilter({ item_keys: "" }));
        }
        if (filterValues.item_groups === undefined) {
          // @ts-ignore
          dispatch(setSaleProgramFilter({ item_groups: "" }));
        }
        break;
      case "/promotions/referral_benefits":
        if (filterValues.country === undefined) {
          // @ts-ignore
          dispatch(setReferralBenefitFilter({ country: "" }));
        }
        break;
      default:
        console.log("default");
        break;
    }
  }, [
    location.pathname,
    filterValues?.user_id,
    filterValues?.virtual_msisdn_id,
    filterValues?.number,
    filterValues?.phone,
    filterValues?.channel,
    filterValues?.reload_id,
    filterValues?.title,
    filterValues?.content,
    filterValues?.country,
    filterValues?.item_keys,
    filterValues?.item_groups,
  ]);

  // Execute setFilter()
  useEffect(() => {
    switch (location.pathname) {
      case "/usages/purchase":
        purchaseLogPageFilter();
        break;
      case "/usages/mvno":
        mvnoPageFilter();
        break;
      case "/usages/sms":
        smsLogPageFilter();
        break;
      case "/usages/voice":
        voiceLogPageFilter();
        break;
      case "/usages/reload":
        reloadLogPageFilter();
        break;
      case "/services/package":
        packageListPageFilter();
        break;
      case "/services/product":
        productListPageFilter();
        break;
      case "/subscribers":
        subscribersPageFilter();
        break;
      case "/promotionsBanner":
        bannerPromotionsPageFilter();
        break;
      case "/promotions/broadcast":
        broadcastPageFilter();
        break;
      case "/promotions/flashsale":
        saleProgramPageFilter();
        break;
      case "/promotions/referral_benefits":
        referralBenefitPageFilter();
        break;
      case "/internal/device-logs":
        deviceLogsPageFilter();
        break;
      default:
        console.log("default");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    purchaseLogFilters,
    mvnoFilters,
    smsLogFilter,
    voiceLogFilter,
    reloadLogFilter,
    packagesListFilter,
    productsListFitler,
    subscribersFilter,
    bannerPromotionsFilter,
    broadcastFilter,
    saleProgramFilter,
    referralBenefitFilter,
    deviceLogsFilter,
  ]);
};
