import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import LayoutDetail from "./LayoutDetail";
import LayoutList from "./LayoutList";
import { MenuListContext } from "./MenuContext";

export default function Index() {
  const [menuID, setMenuID] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");

  return (
    <MenuListContext.Provider
      value={{ menuID, setMenuID, purpose, setPurpose }}
    >
      <Box p={1} mt={1} mb={1}>
        <Paper elevation={1} sx={{ padding: "20px", minHeight: "500px" }}>
          {menuID === "" ? <LayoutList /> : <LayoutDetail />}
        </Paper>
      </Box>
    </MenuListContext.Provider>
  );
}
