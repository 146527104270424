import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  CreateParams,
  CreateResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class CurrencyRatesProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.origin) {
      filterArray.push({
        origin: {
          _eq: params.filter.origin,
        },
      });
    }

    if (params.filter.destination) {
      filterArray.push({
        destination: {
          _eq: params.filter.destination,
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllCurrencyRates($where: CurrencyRatesWhereInput) {
          allCurrencyRatesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              origin
              destination
              rate
              author
              author_data {
                id
                email
                name
              }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    console.log(77623, "queryResult", queryResult);
    return {
      data: queryResult?.allCurrencyRatesList?.data || [],
      total: queryResult?.allCurrencyRatesList?.count || 0,
    } as GetListResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });

    const { data } = await this.client.user.get();

    const { origin, destination, rate } = params?.data?.createdData;

    const toBeCreatedData: any = {
      origin: origin,
      destination: destination,
      rate: +rate,
      author: data?.id,
    };
    console.log(723, "toBeCreatedData", toBeCreatedData);
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [CurrencyRatesCreateInput!]!) {
          createCurrencyRates(input: $input) {
            id
            created_at
            updated_at
            origin
            destination
            rate
            author
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createCurrencyRates[0] } as CreateResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    console.log({ method: "getOne", resource, params });
    const queryResult = await this.client.gql.query({
      query: gql`
        query aCurrencyRate($id: UUID!) {
          getCurrencyRatesById(id: $id) {
            id
            created_at
            updated_at
            origin
            destination
            rate
            author
            author_data {
              id
              email
              name
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });
    return {
      data: queryResult?.getCurrencyRatesById,
    } as GetOneResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });

    const { origin, destination, rate } = params?.data;
    const { data } = await this.client.user.get();
    const submittedData = {
      origin: origin,
      destination: destination,
      rate: +rate,
      author: data?.id,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: CurrencyRatesUpdateInput!) {
          updateCurrencyRatesById(ids: $ids, input: $input) {
            id
            origin
            destination
            rate
            author
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: submittedData,
      },
    });

    return { data: queryResult?.updateCurrencyRatesById[0] } as UpdateResult;
  }
}
