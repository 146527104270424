import { usePermissions } from "react-admin";

export const useRetrievePermission = () => {
  const { isLoading, permissions } = usePermissions();
  const isSuperAdmin =
    !isLoading && permissions.find((el: string) => el === "Super Admin");

  const isAdmin =
    !isLoading && permissions.find((el: string) => el === "Admin");

  const isStaff =
    !isLoading && permissions.find((el: string) => el === "Staff");

  return [isSuperAdmin, isAdmin, isStaff];
};
