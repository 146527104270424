import MaterialTable from "@material-table/core";
import { Autocomplete, Card, TextField } from "@mui/material";
import { useMemo } from "react";
import { useInput } from "react-admin";
import countryList from "react-select-country-list";

interface ItemLabelProps {
    formData: any
}

const CountrySelector: React.FC = (props: any) => {
    const options = useMemo(() => {
        const country =  countryList().getData().map(m => ({ id: m.value, label: `${m.value} - ${m.label}`}))
        return country;
    }, []);

    const value = useMemo(() => {
        if(props.value){
            return options.find(m => m.id === props.value)
        }
        return null
    }, [props.value, options])

    return (
        <Autocomplete
            onChange={(_, value: any) => props.onChange(value.id)}
            value={value}
            options={options}
            renderInput={(params) => <TextField {...params} variant="standard"/>}
        />
    )
}

const ItemLabel: React.FC<ItemLabelProps> = () => {
    const { field } = useInput({ source: 'item_label'});

    const recordData = useMemo(() => {
        return field.value ? field.value : []
    }, [field.value]);

    const COLUMNS = useMemo(() => [
        { 
            field: "country", 
            title: "Country",
            render: (rowData: any) => {
                return rowData.country === "GLOBAL" ? "WORLDWIDE" : rowData.country ? `${countryList().getLabel(rowData.country)} (${rowData.country})` : "-"           
            },
            editComponent: (props: any) => {
                return <CountrySelector {...props} />
            }
        },
        { 
            field: "label", 
            title: "Translation Label",  
            editComponent: (props:any) => {                
                return (
                    <TextField 
                        {...props}
                        variant="standard"
                        onChange={(e) => props.onChange(e.target.value)}
                    />
                )
            }
        }
    ], []);

    const editTableObj = {
        onRowAdd: async (newData: any) => {
            return field.onChange([...recordData, newData]);
        },
        onRowUpdate: async (newData: any, oldData: any) => {
            const dataUpdate = [...recordData];
            const target = dataUpdate.find((el) => el.country === oldData.country);
            const index = dataUpdate.indexOf(target);
            dataUpdate[index] = newData;
            field.onChange(dataUpdate);
        },
    }

    return (
        <MaterialTable 
            data={recordData}
            columns={COLUMNS}
            title={""}
            components={{
                Container: (props) => {                
                    return <Card variant="outlined">
                        {props.children}
                    </Card>
                }
            }}
            editable={{...editTableObj}}
        />
    )
}

export default ItemLabel