import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import mbaas from "../provider/Mbaas";
import QRCodeView from "qrcode.react";
import { QrCode } from "@mui/icons-material";

export default function MFAdialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [uriqr, setUri] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (open) {
      fetchMFA();
    }
  }, [open]);

  const fetchMFA = async () => {
    setLoading(true);
    try {
      const req = await mbaas.client.invokeApi("console-get-my-mfa");
      console.log(req);
      setUri(req.data.uri);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            <QrCode /> QRCode
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Scan this QRcode with Google Authenticator
          </DialogContentText>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            style={{ marginTop: "20px" }}
          >
            {loading ? (
              <CircularProgress size={90} />
            ) : (
              <QRCodeView value={uriqr} />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            // disabled={loading}
            color="primary"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
