import React, { Fragment } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  Create,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  NumberInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const country = [
  { id: "ID", name: "ID" },
  { id: "HK", name: "HK" },
  { id: "TW", name: "TW" },
];

export const CreatePage = (props: any) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = (data: any) => {
    // @ts-ignore
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/virtualMsisdn");
    refresh();
  };

  return (
    <Fragment>
      <Create
        resource="virtualMsisdn"
        title=" &rsaquo; Create"
        {...props}
        mutationOptions={{
          onSuccess: onSuccess,
        }}
        redirect="list"
      >
        <Box
          pt={5}
          width={{ xs: "100vW", sm: 600 }}
          mt={{ xs: 2, sm: 1 }}
          height="100vh"
        >
          <SimpleForm>
            <Typography variant="h6" style={{ marginBottom: "17px" }}>
              Create New Virtual MSISDN
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="msisdn"
                  data-testid="msisdnInput"
                  label="msisdn"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  fullWidth
                  className={classes.input}
                  source="country"
                  validate={[required()]}
                  choices={country}
                  label="country"
                  data-test-id="country"
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  fullWidth
                  className={classes.input}
                  source="hkd_price"
                  data-testid="hkdPrice"
                  label="HKD Price"
                  validate={required()}
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
    </Fragment>
  );
};
