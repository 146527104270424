import mbaas from "../../provider/Mbaas";
import { Box, Fade, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Confirm, SimpleForm, Toolbar, useGetList, useNotify, useRefresh } from "react-admin";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import { get, isEmpty } from "lodash";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    borderRadius: "7px",
    boxShadow: 24,
    p: 4,
  };

export default function AdjustPointModal({
    open,
    handleClose,
    props,
    currentRecord,
}: any){   
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [pointBalance, setPointBalance] = useState<string>(
        currentRecord?.wallets_user_id_list?.data[0]?.balance
    );
      const [reason, setReason] = useState<string | null>();

    const { data: wallets, isLoading } = useGetList("wallets", {
        filter: {
            user_id: currentRecord?.id,
            unit: "POINT"
        },
    });

    useEffect(() => {
        if(wallets && !isEmpty(wallets)){
            setPointBalance(get(wallets, '0.balance', 0))
        }
    }, [wallets])

    const CustomToolbar = (props: any) => {
        const useStyles = makeStyles(() => ({
          toolbar: {
            display: "flex",
            justifyContent: "space-between",
          },
        }));
        const [isLoadingOtp, setIsLoadingOtp] = useState(false);
        const [openOtpModal, setOpenOtpModal] = useState(false);
        const [otpCode, setOtpCode] = useState("");
        const handleClick = () => {
          setOpenOtpModal(true);
        };
        const handleDialogClose = () => {
          setOpenOtpModal(false);
        };
    
        const handleUpdatePoint = async () => {
          setIsLoadingOtp(true);
          const { error: valTotpErr, data: valTotpData } =
            await mbaas.client.invokeApi("console-validate-totp", {
              token: otpCode,
            });
          if (valTotpErr) {
            Swal.fire({
              icon: "error",
              title: `Request failed: ${valTotpErr?.error}`,
              target: "#muiModal",
            });
          }
          if (valTotpData === "OK") {
            const res = await mbaas.client.invokeApi("console-adjust-point-balance", {
              balance: +pointBalance,
              user_id: currentRecord?.id,
              reason: reason,
            });
        
            if (res.data === "OK") {
              handleClose();
              refresh();
            }
    
            if (res.error) {
              notify(res?.error[0].message, { type: "error" });
            }
          }
          setIsLoadingOtp(false);
        };
    
        return (
          <>
            <Toolbar {...props} classes={useStyles}>
              <Button
                variant="contained"
                onClick={handleClick}
                startIcon={<UpdateIcon />}
                disabled={!reason || !pointBalance || isLoading}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                startIcon={<CloseIcon />}
                sx={{
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </Toolbar>
            <Confirm
              id="muiModal"
              isOpen={openOtpModal}
              loading={otpCode.length < 6 ? true : isLoadingOtp}
              title="OTP Code"
              content={
                <>
                  <Typography variant="caption" gutterBottom>
                    <b>Important!</b> By confirming this dialog it will update
                    subscriber balance,<br></br> are you sure want to proceed?
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    data-test-id="input-otp"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                </>
              }
              onConfirm={() => handleUpdatePoint()}
              onClose={() => handleDialogClose()}
            />
          </>
        );
    };
    return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" gutterBottom component="div">
              Edit {currentRecord?.name}'s Point
            </Typography>
            <SimpleForm toolbar={<CustomToolbar props={props} />}>
              <div className={classes.root}>
                  <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            display="block"
                            gutterBottom
                        >
                            Current Point Balance
                        </Typography>
                        <TextField
                            fullWidth
                            type="number"
                            value={pointBalance}
                            disabled={isLoading}
                            onChange={(e) => setPointBalance(e.target.value)}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            display="block"
                            gutterBottom
                        >
                            Reason
                        </Typography>
                        <TextField
                            fullWidth
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                        </Grid>
                    </Grid>
                  </div>
              </div>
            </SimpleForm>
          </Box>
        </Fade>
      </Modal>
    )
}

const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
    },
    form: {
      flexGrow: 1,
    },
    input: {
      marginBottom: "-20px ",
    },
    text: {
      color: "#0000008A",
    },
  }));