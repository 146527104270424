import React, { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createStyles, makeStyles } from "@mui/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@mui/lab/TreeItem";
import {
  CircularProgress,
  Paper,
  Grid,
  Typography,
  Theme,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddIcon from "@mui/icons-material/Add";
import Label from "@mui/icons-material/Label";
import { LexoRank } from "lexorank";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Mbaas from "../../provider/Mbaas";
import AddNewChild from "./AddNewChild";
import { MenuListContext } from "./MenuContext";
import gql from "graphql-tag";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";

// a little function to help us with reordering the result
const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  // console.log(startIndex);
  // console.log(endIndex);
  // console.log(list);

  let newKey;
  if (startIndex < endIndex) {
    console.log("AFTER NEXT");
    // console.log(list[endIndex]);
    const key = LexoRank.parse(list[endIndex].priority).genNext().toString();
    newKey = key;
    console.log(key);
  } else {
    console.log("BEFORE");
    // console.log(list[endIndex]);
    const key = LexoRank.parse(list[endIndex].priority).genPrev().toString();
    newKey = key;
    console.log(key);
  }
  // console.log(newKey);

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result[endIndex].priority = newKey;
  return { data: result[endIndex], newKey, result };
};

const grid = 8;

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  overflow: "hidden",
  minHeight: "500px",
});

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: any;
  labelInfo?: string;
  labelText: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, #F47820)`,
        color: "var(--tree-view-color, white)",
        fontWeight: "bold",
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
        {
          backgroundColor: "transparent",
        },
    },
    content: {
      width: "90%",
      height: "35px",
      marginLeft: "-15px",
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelIconImg: {
      marginRight: theme.spacing(1),
      width: "20px",
      height: "20px",
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  })
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {typeof LabelIcon === "string" && LabelIcon !== "" ? (
            <img src={LabelIcon} alt="icon" className={classes.labelIconImg} />
          ) : labelInfo === "Add" ? (
            <AddIcon color="inherit" className={classes.labelIcon} />
          ) : (
            <Label color="inherit" className={classes.labelIcon} />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          {/* <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography> */}
        </div>
      }
      // style={{
      //   '--tree-view-color': color,
      //   '--tree-view-bg-color': bgColor,
      // }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const LayoutDetail = () => {
  const [items, setItems] = React.useState<any>([]);
  const [loading, setloading] = React.useState(false);
  const [selectData, setSelectData] = React.useState<any>([]);

  const [expand, setExpand] = React.useState<Array<string>>([]);
  const [openForm, setOpenForm] = React.useState(false);
  const [mode, setMode] = React.useState("create");

  const globalstate = useContext(MenuListContext);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setloading(true);
    try {
      const req = await Mbaas.client.gql.query({
        query: gql`
        query layouts {
          allMenuLayoutsList(
            sort: priority
            order: ASC
            limit: 15
            where: { parent_id: { _eq : "${globalstate.menuID}" } }
          ) {
            data {
              id
              created_at
              updated_at
              country
              purpose
              configuration
              parent_id
              image
              priority
            }
            count
          }
        }
      `,
      });

      console.log(req);

      setItems(req.allMenuLayoutsList.data);
    } catch (e) {
      console.log(e);
    }
    setloading(false);
  };

  const updateRow = async (id: string, data: any) => {
    try {
      // console.log(id);
      // console.log(data);

      const req = await Mbaas.client.gql.mutation({
        mutation: gql`
          mutation updateMenu($ids: [UUID!]!, $input: MenuLayoutsUpdateInput!) {
            updateMenuLayoutsById(ids: $ids, input: $input) {
              id
              created_at
              updated_at
              country
              purpose
              configuration
              parent_id
              priority
            }
          }
        `,
        variables: {
          ids: id,
          input: { priority: data.priority },
        },
      });

      console.log(req);

      // fetchData();
    } catch (e: any) {
      Swal.fire("Something wrong!", e.error.error, "error");
    }
  };

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    console.log(result);

    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    // if (result.type === "parents") {
    const itemorder = reorder(items, sourceIndex, destIndex);

    setItems(itemorder.result);
    // console.log(result.draggableId);
    // console.log(itemorder);

    await updateRow(result.draggableId, itemorder.data);
  };

  const handleChange = (event: any, nodes: any) => {
    setExpand(nodes);
  };

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid
          item
          style={{
            width: "280px",
            maxHeight: "620px",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              margin: "20px 15px",
            }}
          >
            <IconButton
              aria-label="back"
              onClick={() => {
                globalstate.setMenuID("");
                globalstate.setPurpose("");
              }}
            >
              <ArrowBackIcon color="primary" />
            </IconButton>
            {globalstate.purpose}
          </div>
          <div></div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              <CircularProgress style={{ width: "75px", height: "75px" }} />
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="dropppable"
                direction="vertical"
                type="parents"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                      defaultEndIcon={<div style={{ width: 24 }} />}
                      onNodeToggle={handleChange}
                    >
                      {items.map(
                        (
                          item:
                            | {
                                id: string | number | null | undefined;
                                content: React.ReactNode;
                                value: any;
                                children: any;
                              }
                            | any,
                          index: any
                        ) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={expand.includes(item.id)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <StyledTreeItem
                                  nodeId={item.id}
                                  labelText={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                      }}
                                    >
                                      <span>{item.purpose} </span>
                                      {/* {!expand.includes(item.id) && !move && ( */}
                                      <span {...provided.dragHandleProps}>
                                        <DragIndicatorIcon
                                          style={{
                                            fontSize: "15px",
                                            cursor: "grabbing",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </span>
                                      {/* )} */}
                                    </div>
                                  }
                                  labelIcon={item.image}
                                  onClick={() => {
                                    setSelectData(item);
                                    setOpenForm(true);
                                    setMode("edit");
                                  }}
                                ></StyledTreeItem>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      <StyledTreeItem
                        nodeId="Add New Item"
                        labelText="Add New Item"
                        labelIcon={AddIcon}
                        labelInfo="Add"
                        onClick={() => {
                          setOpenForm(true);
                          setMode("create");
                          setSelectData([]);
                        }}
                        style={{
                          marginTop: snapshot.isDraggingOver ? "50px" : "0px",
                          minWidth: "280px",
                        }}
                      />
                    </TreeView>

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Grid>
        <Grid item xs>
          <Paper
            elevation={1}
            style={{ padding: "20px", minHeight: "550px", margin: "10px" }}
          >
            {openForm && (
              <AddNewChild
                items={items}
                refetch={fetchData}
                parent_id={globalstate.menuID}
                mode={mode}
                selectedData={selectData}
                setOpenForm={setOpenForm}
              />
            )}

            {!openForm && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{ minHeight: "550px" }}
                alignContent="center"
              >
                <Grid item>
                  <img
                    src="/logom21.png"
                    alt="logox"
                    style={{ width: "100px" }}
                  />
                </Grid>
                <Grid item sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Select Item
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LayoutDetail;
