import { AutocompleteInput, useGetList, useInput } from "react-admin";

interface ProductItemInputProps {
  formData: any;
  source: string;
}

const ProductItemInput: React.FC<ProductItemInputProps> = (props) => {
  const { source, formData } = props;
  const { field } = useInput({ source: "item_group" });
  let itemgroup: boolean;
  let filters: any;

  if (formData.item_group === '') {
    itemgroup = true;
  } else {
    itemgroup = false;
  }

  if (formData.availability_by_service === "GHK" && formData.category === "IDD") {
    filters = {
      itemKey: "TMBEBAS"
    }
  } else if (formData.availability_by_service === "GHK" && formData.category === "SMS") {
    filters = {
      itemKey: "ISMS"
    }
  } else {
    filters = {}
  }

  const { data, isLoading } = useGetList("productCatalogs", {
    filter: {
      ...(field.value ? { itemGroup: field.value } : {}),
      ...filters
    },
    pagination: {
      perPage: 1000,
      page: 1,
    },
  });

  return (
    <AutocompleteInput
      choices={data}
      optionValue="item_key"
      optionText="item_key"
      loadingText="Loading..."
      isOptionEqualToValue={(options, value) => {
        return options.id === value.id;
      }}
      loading={isLoading}
      disabled={itemgroup}
      debounce={500}
      fullWidth
      source={source}
    />
  );
};

export default ProductItemInput;
