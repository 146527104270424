import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  CreateParams,
  CreateResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class FirebaseRemoteConfigsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });

    const { error: err, data: result } = await this.client.invokeApi(
      "console-fr-config",
      {
        action: "get",
        data: {
          id: "country_list",
        },
      }
    );

    let finalData;

    if (result.data.data) {
      finalData = result.data.data.filter(
        (dt: any) => dt?.id === "country_list"
      );
      finalData = finalData.length > 0 ? finalData[0] : [];
    }

    return {
      data: [finalData],
      total: 1,
    } as GetListResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });

    const { key, description, value } = params?.data?.createdData;

    let thisValue;
    if (typeof value === "string") {
      thisValue = value;
    } else {
      thisValue = JSON.stringify(value);
    }

    const toBeCreatedData: any = {
      key: key,
      description: description,
      value: thisValue,
    };
    console.log(723, "toBeCreatedData", toBeCreatedData);
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [SystemConfigsCreateInput!]!) {
          createSystemConfigs(input: $input) {
            id
            created_at
            updated_at
            key
            value
            description
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createSystemConfigs[0] } as CreateResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    console.log({ method: "getOne-X", resource, params });
    const { error: err, data: result } = await this.client.invokeApi(
      "console-fr-config",
      {
        action: "get",
        data: {
          id: "country_list",
        },
      }
    );

    let finalData;

    if (result.data.data) {
      finalData = result.data.data.filter(
        (dt: any) => dt?.id === "country_list"
      );
      finalData = finalData.length > 0 ? finalData[0] : [];
    }

    return {
      data: finalData,
    } as GetOneResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const { key, value } = params?.data;

    const { error: err, data: result } = await this.client.invokeApi(
      "console-fr-config",
      {
        action: "update",
        data: {
          id: key,
          value: value,
        },
      }
    );

    let finalData;
    console.log(result, "JELLY");

    if (result.data.data) {
      finalData = result.data.data;
    }

    return { data: finalData } as UpdateResult;
  }
}
