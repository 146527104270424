import React, { useEffect } from "react";
import { Button, useListContext, Confirm, useCreate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { endOfToday, format } from "date-fns";
import SubscriberExportBtn from "./SubscriberExportBtn";
import { dataProvider } from "../../src/provider";

export default function CustomExportButton(props: any) {
  const { userData, exportBtnType, filter } = props;
  const [open, setOpen] = React.useState(false);
  // selected index refer to this => ["Export All", "Export Registered", "Export Not Registered"];
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [newFilterValueState, setNewFilterValueState] = React.useState({});
  const navigate = useNavigate();
  const { filterValues } = useListContext();

  async function elaborateFilterValues(params: any) {
    const {
      data: { item_group: itemGroup },
    } = await dataProvider.getOne("productCatalogsItemGroup", {
      id: params.id,
    });

    let newFilterValue = {};
    if (itemGroup) {
      newFilterValue = {
        item_group: itemGroup,
        ...filterValues,
      };
      // @ts-ignore
      delete newFilterValue.id;
    } else {
      newFilterValue = filterValues;
    }

    return newFilterValue;
  }

  useEffect(() => {
    if (exportBtnType === "packages" || exportBtnType === "products") {
      (async () => {
        try {
          const res = await elaborateFilterValues(filterValues);
          setNewFilterValueState(res);
        } catch (err) {
          console.log("Error occured");
        }
      })();
    }
  }, [filterValues, exportBtnType]);

  let type_name = "";
  let appliedFilter = {};
  //
  switch (exportBtnType) {
    case "subscribers":
      type_name = "subscribers";
      if (selectedIndex === 0) appliedFilter = {
        ...filterValues,
        ...filter
      };
      if (selectedIndex === 1)
        appliedFilter = {
          registered: true,
          ...filterValues,
          ...filter
        };
      if (selectedIndex === 2)
        appliedFilter = {
          non_registered: true,
          ...filterValues,
          ...filter
        };
      break;
    case "packages":
      type_name = "packages";
      appliedFilter = newFilterValueState;
      break;
    case "products":
      type_name = "products";
      appliedFilter = newFilterValueState;
      break;
    default:
      break;
  }
  //
  const gen_file_name = `${type_name}_${format(
    new Date(),
    "yyyy-MM-dd_kk_mm_ss"
  )}`;  
  //
  const [exportData, { data, error }] = useCreate();
  //
  if (data && !error) {
    console.log(767, "haiii");
  }

  console.log('appliedFilter', appliedFilter);
  
  //
  const handleOnClick = () => {
    setOpen(true);
    exportData("reports", {
      data: {
        author_id: userData?.id,
        type: type_name,
        status: "queued",
        data: {
          filter: appliedFilter,
          metadata: {
            generated_filename: gen_file_name,
          },
        },
      },
    });
  };

  const navigateToReportPage = () => {
    navigate(encodeURI(`/reports?filter={"type":"${type_name}"}`));
  };
  const ConfirmDialogContent = () => {
    return (
      <Paper elevation={0}>
        <Typography variant="body1" gutterBottom>
          Your file is exported as "{gen_file_name}.csv", to view the progress
          please visit report page below
        </Typography>
      </Paper>
    );
  };

  return (
    <>
      {exportBtnType === "subscribers" ? (
        <SubscriberExportBtn
          onClick={handleOnClick}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      ) : (
        <Button
          startIcon={<GetAppIcon />}
          label={`Export ${type_name} data`}
          onClick={handleOnClick}
        />
      )}

      <Confirm
        isOpen={open}
        title="Dear User,"
        content={<ConfirmDialogContent />}
        onClose={() => setOpen(false)}
        confirm="Go To Report"
        onConfirm={navigateToReportPage}
      />
    </>
  );
}
