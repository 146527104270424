import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
export class ReloadProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    console.log(params.filter);

    if (params.filter.q) {
      filterArray.push({
        user_id_data: {
          name: {
            _ilike: `%${params.filter.q}%`,
          },
        },
      });
    }
    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }
    if (params.filter.purchase_id) {
      filterArray.push({
        purchase_id: {
          _ilike: `%${params.filter.purchase_id}%`,
        },
      });
    }
    if (params.filter.status) {
      filterArray.push({
        status: {
          _ilike: params.filter.status,
        },
      });
    }
    if (params.filter.name && params.filter.phone) {
      filterArray.push({
        user_id_data: {
          _or: [
            { name: { _eq: params.filter.name } },
            {
              phone: {
                _ilike: `%${params.filter.phone}%`,
              },
            },
          ],
        },
      });
    }
    if (params.filter.name) {
      filterArray.push({
        user_id_data: {
          name: { _ilike: `%${params.filter.name}%` },
        },
      });
    }
    if (params.filter.phone) {
      filterArray.push({
        user_id_data: {
          phone: { _ilike: `%${params.filter.phone}%` },
        },
      });
    }
    if (params.filter.reload_id) {
      filterArray.push({
        reload_id: { _ilike: `%${params.filter.reload_id}%` },
      });
    }
    if (params.filter.channel) {
      filterArray.push({
        channel: {
          _ilike: `%${params.filter.channel}%`,
        },
      });
    }
    if (params.filter.app) {
      filterArray.push({
        app: {
          _eq: `${params.filter.app}`,
        },
      });
    }
    if (filterArray.length > 0) {
      if (params.filter.q) {
        where._or = filterArray;
      } else {
        where._and = filterArray;
      }
    }
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
      query WalletReloadList($where:WalletReloadLogsWhereInput) {
        allWalletReloadLogsList(
          where: $where, ${args}
          ){
            data {
              id
              created_at
              updated_at
              user_id
              channel
              amount
              status
              reload_id
              histories
              rate_snapshot
              app
              user_id_data{
                id
                email
                name
                phone
              }
              wallet_ledgers_wallet_reload_log_id_list{
                data{
                  id
                }
                count
              }
            }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    if (params.filter.q) {
      const userNameArray: any[] = [];
      queryResult?.allWalletReloadLogsList?.data.forEach((x: any) => {
        userNameArray.push({
          id: x.user_id_data.name,
          name: x.user_id_data.name,
        });
      });
      const userNameArrayFiltered = userNameArray.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      return {
        data: userNameArrayFiltered,
        total: userNameArrayFiltered.length,
      };
    }
    console.log(queryResult);
    return {
      data: queryResult?.allWalletReloadLogsList?.data.map((x: any) => {
        return { ...x, name: x.user_id_data.name };
      }),
      total: queryResult?.allWalletReloadLogsList?.count,
    };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._and = [
        {
          user_id_data: {
            id: {
              _in: params.ids,
            },
          },
        },
      ];
    }

    const queryResult = await this.client.gql.query({
      query: gql`
        query ManyWalletReloadList($where: WalletReloadLogsWhereInput) {
          allWalletReloadLogsList(where: $where) {
            data {
              id
              created_at
              updated_at
              user_id
              channel
              amount
              status
              histories
              rate_snapshot
              user_id_data {
                id
                email
                name
                phone
              }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    console.log(queryResult);

    return {
      data: queryResult?.allWalletReloadLogsList?.data.map((x: any) => {
        return { ...x, name: x.user_id_data.name };
      }),
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getwallet($id: UUID) {
          getWalletReloadLogsById(id: $id) {
            id
            created_at
            updated_at
            user_id
            channel
            amount
            status
            reload_id
            histories
            rate_snapshot
            user_id_data {
              id
              email
              name
              phone
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });
    console.log(queryResult.getWalletReloadLogsById);

    return { data: queryResult?.getWalletReloadLogsById };
  }
}
