import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class UsageRewardProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {

    const where: Record<string, any> = {};
    const { filter } = params
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    if(filter.dateGte && filter.dateLte){
      where['created_at'] = {
          _gte: filter.dateGte, _lte: filter.dateLte
      }
    }

    if(filter.status){
        where['status'] = {
            _eq: filter.status
        }
    }

    if(filter.code){
      where["code"] = {
        _ilike: `%${filter.code}%`
      }
    }

    const queryResult = await this.client.gql.query({
      query: gql`
          query AllRewardLogsList($where: RewardLogsWhereInput) {
            allRewardLogsList(where: $where, ${args}) {
                count
                data {
                    id
                    created_at
                    updated_at
                    user_id
                    reward_catalogue_id
                    reward_detail
                    subscriber_info
                    code
                    delivery_history
                    status
                    user_id_data {
                      id
                      name
                      country
                    }
                }
            }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allRewardLogsList?.data,
      total: queryResult?.allRewardLogsList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams<any>): Promise<GetOneResult<any>> {
    const queryResult = await this.client.gql.query({
        query: gql`
          query GetRewardLogsById($id: UUID) {
            getRewardLogsById(id: $id) {
                id
                created_at
                updated_at
                user_id
                reward_catalogue_id
                reward_detail
                subscriber_info
                code
                delivery_history
                status
            }
          }
        `,
        variables: {
            id: params.id,
        },
    });

    return {
        data: queryResult?.getRewardLogsById,
    };
}

}
