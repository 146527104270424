// @ts-nocheck
import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  Pagination,
  DateField,
  FilterButton,
  FunctionField,
  SelectInput,
  Button,
  TextInput,
  useListContext,
  Confirm,
  useCreate,
} from "react-admin";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Drawer, Paper, Typography } from "@mui/material";
import { DateRange } from "../DateRange";
import { ReloadLogDetail } from "./Detail";
import ReactJson from "react-json-view";
import GetAppIcon from "@mui/icons-material/GetApp";
import mbaas from "../../../src/provider/Mbaas";
import { useDispatch } from "react-redux";
import { setReloadLogFilter } from "src/slices/reloadLogPage/filterReloadLogSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import { endOfToday, startOfToday, subMonths, format } from "date-fns";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    purchaseLogFilter,
    navigate,
    ...rest
  } = props;
  const [userData, setUserData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  usePersistFilter();

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  // -- export button
  const ExportReloadButton = () => {
    const { filterValues } = useListContext();
    //
    const type_name = "usages-reload";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    console.log(737, "filterValues", filterValues);
    //
    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        author_id: userData?.id,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            ...filterValues,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"usages-reload"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button label="Export Reload Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </Button>
    );
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={purchaseLogFilter} />
      <ExportReloadButton />
    </TopToolbar>
  );
};

const ReloadLogPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export const ReloadLogList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelDrawer = useCallback(() => {
    navigate(`/usages/reload`);
  }, [navigate]);

  const match = matchPath("/usages/reload/:id/show/*", location.pathname);

  const PurchaseLogFilter = [
    <DateRange alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { id: "UNPAID", name: "UNPAID" },
        { id: "PAID", name: "PAID" },
      ]}
      alwaysOn
      data-testid="search-status"
      onChange={(e) => dispatch(setReloadLogFilter({ status: e.target.value }))}
    />,
    //   <AutocompleteInput
    //     fullWidth
    //     style={{ width: "230px" }}
    //     source="name"
    //     optionText={(record) => (record.name ? record.name : record.email)}
    //     data-testid="search-subscriber"
    //     onChange={(e) => dispatch(setReloadLogFilter({ name: e }))}
    //   />
    <TextInput
      source="name"
      label="Name"
      onChange={(e) => dispatch(setReloadLogFilter({ name: e.target.value }))}
      alwaysOn
    />,
    <SelectInput
      source="channel"
      label="Channel"
      choices={[
        { id: "PAYPAL", name: "PAYPAL" },
        { id: "TNG", name: "TNG" },
        { id: "MELON", name: "ATM INDO (MELON)" },
        { id: "STRIPE", name: "STRIPE" },
        { id: "CIRCLEK", name: "CIRCLE-K" },
        { id: "VOUCHER", name: "PAPER VOUCHER" },
        { id: "SEVENTW", name: "7-ELEVEN TW" },
        { id: "FAMILYTW", name: "FAMILY MART TW" },
        { id: "HILIFETW", name: "HILIFE TW" },
        { id: "OKMATW", name: "OK MART TW" },
        { id: "AS2in1 SIM", name: "AS2in1 SIM" },
        { id: "TNG_SIM", name: "TNG_SIM" },
        { id: "ASIAPAY", name: "ASIAPAY" },
        { id: "APPLEIAP", name: "APPLEIAP" },
      ]}
      data-testid="search-status"
      optionText={(choice) => choice.name}
      onChange={(e) =>
        dispatch(setReloadLogFilter({ channel: e.target.value }))
      }
      alwaysOn
    />,
    <TextInput
      source="phone"
      label="MSISDN"
      onChange={(e) => dispatch(setReloadLogFilter({ phone: e.target.value }))}
    />,
    <TextInput
      source="reload_id"
      label="Order Id"
      onChange={(e) =>
        dispatch(setReloadLogFilter({ reload_id: e.target.value }))
      }
    />,
    <SelectInput
      source="app"
      label="App"
      choices={[
        { id: "M21", name: "M21" },
        { id: "GHK", name: "GHK" },
        { id: "G2", name: "G2" },
      ]}
      data-testid="search-status"
      optionText={(choice) => choice.name}
      onChange={(e) =>
        dispatch(setReloadLogFilter({ app: e.target.value }))
      }
    />,
  ];

  return (
    <>
      <List
        resource="reloadLogs"
        title="Consumptions &rsaquo; Reload"
        filters={PurchaseLogFilter}
        filterDefaultValues={{
          dateGte: subMonths(startOfToday(), 1),
          dateLte: endOfToday(),
        }}
        sort={{ field: 'created_at', order: 'DESC' }}
        actions={
          <ListActions
            navigate={navigate}
            purchaseLogFilter={PurchaseLogFilter}
          />
        }
        perPage={50}
        pagination={<ReloadLogPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? "600px" : 0,
        }}
      >
        <Datagrid
          rowClick={(id) => {
            navigate(`/usages/reload/${id}/show`);
          }}
          bulkActionButtons={false}
          rowStyle={(record: any) => ({
            backgroundColor: record.status === "PAID" ? "#e6ffdb" : "",
          })}
        >
          <DateField source="created_at" showTime />
          <TextField source="app" label="App" />
          <TextField source="user_id_data.name" label="Subscriber" />
          <TextField source="user_id_data.phone" label="Phone" />

          <FunctionField
            label="Rate Snapshot"
            onClick={(e: any) => e.stopPropagation()}
            render={(record) => {
              if (record.rate_snapshot) {
                return (
                  <ReactJson
                    src={record.rate_snapshot || {}}
                    shouldCollapse={() => {
                      /* TODO document why this arrow function is empty */
                    }}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    name={false}
                  />
                );
              } else {
                return "-";
              }
            }}
          />
          <TextField source="channel" />
          <TextField source="amount" />
          <TextField source="reload_id" label="Order id" />
          <FunctionField
            label="Status"
            source="status"
            render={(record) => <label>{record.status.toUpperCase()}</label>}
          />
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <ReloadLogDetail
            id={(match as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};
