import { init } from "@mocobaas/client-js";
import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { EncryptionOptions } from "@mocobaas/client-js/build/PayloadEncryption";

class Mbaas {
  backendUrl: string;
  storageUrl: string;
  host: string;
  mbaasClient: MobileServiceClient;

  constructor() {
    this.host = window.location.host;
    // BE
    const beProd = "https://m21.live.telinhk.xyz";
    const beTest = "https://m21.develop.telinhk.xyz";
    // Storage
    const storageProd = "https://object.live.telinhk.xyz";
    const storageTest = "https://m21-minio.temphk.hash.id";
    //
    let enforcement: EncryptionOptions["enforcement"] = [];
    if (this.host === "m21-console.pages.dev") {
      this.backendUrl = beProd;
      this.storageUrl = storageProd;
      // enforcement = ["COMMON", "FUNCTION", "TABLE", "GRAPHQL"];
    } else {
      this.backendUrl = beTest;
      this.storageUrl = storageTest;
    }
    this.mbaasClient = new init(this.backendUrl, {
      payloadEncryption: {
        enforcement,
      },
    });
  }

  get appURL() {
    return this.host;
  }

  get client() {
    return this.mbaasClient;
  }
}

export default new Mbaas();
