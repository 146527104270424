import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class UsersProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    if (params.filter.keyword) {
      where._or = [
        {
          email: {
            _ilike: `%${params.filter.keyword}%`,
          },
        },
        {
          name: {
            _ilike: `%${params.filter.keyword}%`,
          },
        },
      ];
    }
    where.is_subscriber = {
      _eq: false,
    };

    where.roles = {
      _has_keys_any: ["Admin", "Super Admin", "Staff"],
    };

    /** for promotions/flashsale */
    if (params.filter.q) {
      where.name = {
        _ilike: `%${params.filter.q}%`,
      };
      where.is_subscriber = {
        _eq: true,
      };
      where.roles = {
        _has_keys_any: ["Subscriber"],
      };
    }

    /** for promotor's referee */
    if (params.filter.refereeIds) {
      where.id = {
        _in: params.filter.refereeIds,
      };
      where.is_subscriber = {
        _eq: true,
      };
      where.roles = {
        _has_keys_any: ["Subscriber"],
      };
    }

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });
    const { allUsersList, error } = await this.client.gql.query({
      query: `
      query usersList($where: UsersWhereInput) {
        allUsersList(where: $where, ${args}) {
          data {
            id
            name
            email
            roles
            created_at
            deactivated_at
            status
            is_blocked
            verified
            is_subscriber
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    console.log({ allUsersList, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }
    return {
      data: allUsersList.data,
      total: allUsersList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log("Getone", { resource, params });

    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
          deactivated_at
          verified
          phone
          status
          is_blocked
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    console.log(getUsersById);

    return { data: getUsersById };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log("get many user");

    console.log(params);

    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
          deactivated_at
          verified
          phone
          status
          is_blocked
        }
      }
      `,
      variables: {
        id: params.ids[0],
      },
    });

    console.log(getUsersById);

    return { data: [getUsersById] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    return { data: {} };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log("UPDATE USER");
    console.log(params);

    const res = await this.client.invokeApi("console-update-user", {
      token: params.data.token,
      data: {
        email: params.data.email,
        roles:
          typeof params.data.roles === "string"
            ? [params.data.roles]
            : params.data.roles,
        name: params.data.name,
        enabled: !params.data.edit_deactivated,
      },
    });

    console.log(res);

    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
          deactivated_at
          verified
          phone
          status
          is_blocked
        }
      }
      `,
      variables: {
        id: params.data.id,
      },
    });

    console.log(getUsersById);

    return { data: getUsersById };
  }
}
