import { Box, Grid, Tab } from "@mui/material";
import React, { useState } from "react";
import { DeleteWithConfirmButton, FormDataConsumer, ImageField, ImageInput, NumberInput, SaveButton, SimpleForm, Toolbar, TextInput, BooleanInput, DateTimeInput, required } from "react-admin";
import DeliveryModeInput from "./DeliveryModeInput";
import CategoryInput from "./CategoryInput";
import { get } from "lodash";
import ProductItemInput from "./ProductItemInput";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ItemLabel from "../ItemLabel";
import RedeemTable from "../RedeemTable";
import ServiceInput from "./ServiceInput";
import ProductGroupInput from "./ProductGroupInput";
import RewardCodeTable from "../RewardCodeTable";
import BonusProductGroupInput from "./BonusProductGroupInput";
import BonusProductItemInput from "./BonusProductItemInput";

interface RewardFormProps {
  onSubmit: (data: any) => any;
  isLoading: boolean;
  record?: any;
  type?: string;
}

const RewardForm: React.FC<RewardFormProps> = (props) => {
  const { onSubmit, isLoading, record, type } = props;
  const [tabValue, setTabValue] = useState("1");

  const handleChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const PostEditToolbar = () => (
    <Toolbar sx={{ justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteWithConfirmButton confirmTitle="Services &rsaquo; Rewards &rsaquo; Delete" redirect={`/services/rewards`} />
    </Toolbar>
  );

  const PostCreateToolbar = () => (
    <Toolbar sx={{ justifyContent: "space-between" }}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <SimpleForm record={record} pt={1} onSubmit={onSubmit} toolbar={type === "edit" ? <PostEditToolbar /> : <PostCreateToolbar />}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} sm={12}>
          <ImageInput source="item_detail.image" label="Image">
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ServiceInput isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDataConsumer>{({ formData }) => <DeliveryModeInput formData={formData} isLoading={!!isLoading} />}</FormDataConsumer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDataConsumer>{({ formData }) => <CategoryInput formData={formData} isLoading={isLoading} />}</FormDataConsumer>
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            const category = get(formData, "category");
            const service = get(formData, "availability_by_service");
            const delivery = get(formData, "delivery_mode");

            if (service && delivery && category && ["IDD", "SMS", "VAS"].includes(category)) {
              return (
                <>
                  <Grid item xs={12} sm={6}>
                    <ProductGroupInput source="item_group" formData={formData} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ProductItemInput source="item_key" formData={formData} />
                  </Grid>
                </>
              );
            }

            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            const category = get(formData, "category");
            const service = get(formData, "availability_by_service");
            const delivery = get(formData, "delivery_mode");

            if (service && delivery && ["IDD", "SMS", "RELOAD"].includes(category)) {
              return (
                <>
                  <Grid item xs={12} sm={6}>
                    <NumberInput fullWidth source="limit" />
                  </Grid>
                  {category === "RELOAD" && (
                    <Grid item xs={12} sm={6}>
                      <NumberInput fullWidth source="amount" />
                    </Grid>
                  )}
                </>
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <Grid item xs={6}>
          <BooleanInput fullWidth source="is_available" data-testid="is_available" label="Available?" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box paddingLeft={3} paddingBottom={2} paddingTop={2} fontSize={20} sx={{ border: "1px solid lightgrey", borderRadius: 1 }}>
            Redeem Limits
            <Grid container paddingTop={2}>
              <Grid item xs={6}>
                <TextInput label="Daily" source="redeem_limits.daily" />
              </Grid>
              <Grid item xs={6}>
                <TextInput label="Monthly" source="redeem_limits.monthly" />
              </Grid>
              <Grid item xs={6}>
                <TextInput label="Minimum Balance Coin" source="redeem_limits.minimum_balance.coin" />
              </Grid>
              <Grid item xs={6}>
                <TextInput label="Minimum Balance Point" source="redeem_limits.minimum_balance.point" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChangeTab}>
                      <Tab label="Item Label" value="1" />
                      <Tab label="Redeem Amount" value="2" />
                      {["COUPON-CODE", "VOUCHER-CODE"].includes(formData.delivery_mode) && <Tab label="Reward Codes" value="3" />}
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={{ padding: "5px" }}>
                    <ItemLabel formData={formData} {...rest} />
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: "5px" }}>
                    <RedeemTable formData={formData} {...rest} />
                  </TabPanel>
                  <TabPanel value="3" style={{ padding: "5px" }}>
                    <RewardCodeTable formData={formData} />
                  </TabPanel>
                </TabContext>
              );
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12} sm={12}>
          <BooleanInput fullWidth source="is_bonus" data-testid="is_bonus" label="Add Bonus?" />
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            const isBonus = get(formData, "is_bonus");
            if (isBonus) {
              return (
                <Grid item xs={12} sm={12}>
                  Bonus For?
                  <Grid container paddingTop={2}>
                    <Grid item xs={6}>
                      <BonusProductGroupInput formData={formData} source="bonus_item_group" />
                    </Grid>
                    <Grid item xs={6}>
                      <BonusProductItemInput formData={formData} source="bonus_item_key" />
                    </Grid>
                    <Grid xs={6}>
                      <DateTimeInput label="Valid From" source="start" validate={[required()]} data-testid="input-start" />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimeInput label="Valid Until" source="end" validate={[required()]} data-testid="input-end" />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
            return null;
          }}
        </FormDataConsumer>
      </Grid>
    </SimpleForm>
  );
};

export default RewardForm;
