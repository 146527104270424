// @ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  Create,
  Confirm,
  useNotify,
  useRedirect,
  useRefresh,
  NumberInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { Box, Grid, IconButton, TextField, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";
import { dataProvider } from "../../../provider";
/** for demo need only */
/** import { authenticator } from "otplib";*/

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const CreateCurrencyMsisdn = ({ onCancel, ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [packageData, setCurrencyMsisdnData] = useState(null);

  const onSubmitHandler = (data) => {
    setConfirmOpen(true);
    setCurrencyMsisdnData(data);
  };
  const onCloseHandlerConfirm = () => {
    setConfirmOpen(false);
  };

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onConfirmHandler = async () => {
    setIsLoadingOtp(true);
    const { error: valTotpErr, data: valTotpData } =
      await mbaas.client.invokeApi("console-validate-totp", {
        token: otpCode,
      });
    if (valTotpErr) {
      Swal.fire(`Request failed: ${valTotpErr?.error}`, "", "error");
    }
    if (valTotpData === "OK") {
      await dataProvider
        .create("currencyMsisdn", { data: packageData })
        .then(() => {
          notify("ra.notification.created", {
            type: "info",
            messageArgs: { smart_count: 1 },
            undoable: true,
          });
          redirect(`/internal/currency-prefix`);
          refresh();
          Swal.fire("Success!", "", "success");
        });
    }
    setIsLoadingOtp(false);
    setConfirmOpen(false);
  };

  return (
    <Fragment>
      <Confirm
        isOpen={confirmOpen}
        loading={otpCode.length < 6 ? true : isLoadingOtp}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            /** for demo need only */
            // onMouseOver={() => {
            //   const token = authenticator.generate("GVHTSPYMCAZGESAQ");
            //   setOtpCode(token);
            // }}
          />
        }
        onConfirm={onConfirmHandler}
        onClose={onCloseHandlerConfirm}
      />
      <Create resource="currencyMsisdn" title=" &rsaquo; Create" {...props}>
        <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
          <Stack
            direction="row"
            p={0}
            sx={{ position: "absolute", right: 0, zIndex: 100 }}
          >
            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          <SimpleForm onSubmit={onSubmitHandler}>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <NumberInput
                    fullWidth
                    className={classes.input}
                    source="msisdn_prefix"
                    data-testid="msisdn_prefix"
                    label="MSISDN Prefix"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="currency"
                    data-testid="currency"
                    validate={required()}
                  />
                </Grid>
              </Grid>
            </div>
          </SimpleForm>
        </Box>
      </Create>
    </Fragment>
  );
};
