import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IsaleProgram {
  saleProgramFilter: {
    status: string;
    country: string;
    item_keys: string;
    item_groups: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: IsaleProgram = {
  saleProgramFilter: {
    status: "",
    country: "",
    item_keys: "",
    item_groups: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type saleProgramFilter = {
  status: string;
  country: string;
  item_keys: string;
  item_groups: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const saleProgramFilterSlice = createSlice({
  name: "thisSaleProgramFilter",
  initialState,
  reducers: {
    setSaleProgramFilter: (state, action: PayloadAction<saleProgramFilter>) => {
      state.saleProgramFilter = {
        ...state.saleProgramFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSaleProgramFilter } = saleProgramFilterSlice.actions;

export const getSaleProgramFilter = (state: RootState) =>
  state.thisSaleProgramFilter.saleProgramFilter;

export default saleProgramFilterSlice.reducer;
