import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { GetOneParams, GetOneResult } from "react-admin";
import { ProviderBase } from "./ProviderBase";
import jwt from "jsonwebtoken";

export class DashboardProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ resource, params });
    const METABASE_SITE_URL = "https://metabase.master.telinhk.xyz";
    const METABASE_SECRET_KEY =
      "4e49e66927902752af635ff7464bf27034fe8f8ce81a4b0d76b7fb65c544be08";
    const payload = {
      resource: { dashboard: params.id },
      params: {},
      exp: Math.round(Date.now() / 1000) + 30 * 60, // 30 minute expiration
    };
    const token = jwt.sign(payload, METABASE_SECRET_KEY);
    const iframeUrl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#theme=transparent&bordered=false&titled=false";

    return { data: { id: params.id, metabaseUrl: iframeUrl } };
  }
}
