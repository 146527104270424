import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { CreateParams, CreateResult, DeleteParams, DeleteResult, GetListParams, GetListResult, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
import { dataProvider } from ".";
import { isArray } from "lodash";
export class PromotionsFlashsaleProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.dateGte || params.filter.dateLte) {
      filterArray.push({
        created_at: {
          _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
        },
      });
      filterArray.push({
        created_at: {
          _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
        },
      });
    }
    if (params.filter.status && params.filter.status === "ACTIVE") {
      filterArray.push({
        start: {
          _lte: moment().toISOString(),
        },
      });
      filterArray.push({
        end: {
          _gte: moment().toISOString(),
        },
      });
    }
    if (params.filter.status && params.filter.status === "EXPIRED") {
      filterArray.push({
        end: {
          _lte: moment().toISOString(),
        },
      });
    }
    if (params.filter.status && params.filter.status === "INACTIVE") {
      filterArray.push({
        start: {
          _gte: moment().toISOString(),
        },
      });
    }
    if (params.filter.country) {
      filterArray.push({
        country: {
          _eq: params.filter.country,
        },
      });
    }

    if (params.filter.platform) {
      filterArray.push({
        configs: {
          _contains: { "platform": [params.filter.platform]},
        },
      });
    }

    if (params.filter.item_groups) {
      filterArray.push({
        items: {
          _contains: { "item_groups": [params.filter.item_groups.toUpperCase()] },
        },
      });
    }

    if (params.filter.item_keys) {
      filterArray.push({
        items: {
          _contains: { "item_keys": [params.filter.item_keys.toUpperCase()] },
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order,
      size: perPage,
      pageNumber: page,
      sort: field,
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query PromotionsFlashsaleList($where: PromotionalFlashsalesWhereInput) {
          allPromotionalFlashsalesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              author_id_data {
                id
                name
                email
              }
              start
              end
              value
              country
              items
              configs
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    let returnedData = queryResult?.allPromotionalFlashsalesList?.data;
    returnedData = returnedData.map(async (x: any) => {
      const { data: ProductCatalogs } = await dataProvider.getList("servicesProduct", {
        filter: {
          item_keys: x.items.item_keys,
        },
        pagination: { page: 1, perPage: 1000 },
        sort: {
          field: "item_key",
          order: "ASC",
        },
      });
      const specific_subscriber = x.configs.specific_subscriber;
      if (x.configs.specific_subscriber.status) {
        specific_subscriber.name = specific_subscriber.subscribers.map((subs: { id: string; name: string; phone: string }) => subs.name).join(", ");
      }
      return {
        ...x,
        items: {
          ...x.items,
          item_keys: ProductCatalogs.map((product) => {
            return `${product.item_key} (${product.label})`;
          }),
        },
        configs: {
          ...x.configs,
          specific_subscriber,
        },
      };
    });
    returnedData = await Promise.all(returnedData) || [];
    return {
      data: returnedData,
      total: queryResult?.allPromotionalFlashsalesList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aFlashsaleMessage($id: UUID) {
          getPromotionalFlashsalesById(id: $id) {
            id
            created_at
            updated_at
            author_id
            start
            end
            value
            country
            items
            configs
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    let returnedData = queryResult?.getPromotionalFlashsalesById;
    const { data: PromotionalFlashsales } = await dataProvider.getList("servicesProduct", {
      filter: {
        item_keys: returnedData.items.item_keys,
      },
      pagination: { page: 1, perPage: 1000 },
      sort: {
        field: "label",
        order: "ASC",
      },
    });

    return {
      data: {
        ...returnedData,
        items: {
          ...returnedData.items,
          item_keys: PromotionalFlashsales.map((product) => {
            return `${product.item_key} (${product.label})`;
          }),
        },
      },
    };
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    if (toBeUpdatedData.configs) {
      delete toBeUpdatedData.configs.dirty;
    }
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: PromotionalFlashsalesUpdateInput!) {
          updatePromotionalFlashsalesById(ids: $ids, input: $input) {
            id
            author_id
            start
            end
            value
            country
            items
            configs
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return {
      data: queryResult?.updatePromotionalFlashsalesById[0],
    } as UpdateResult;
  }

  async create(resource: string, params: CreateParams<any>): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });
    const { data: userData } = await this.client.user.get();
    const toBeCreatedData = { ...params.data, author_id: userData?.id };
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [PromotionalFlashsalesCreateInput!]!) {
          createPromotionalFlashsales(input: $input) {
            id
            author_id
            start
            end
            value
            country
            items
            configs
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return {
      data: queryResult?.createPromotionalFlashsales[0],
    } as CreateResult;
  }

  async delete(resource: string, params: DeleteParams<any>): Promise<DeleteResult<any>> {
    console.log({ method: "delete", resource, params });
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deletePromotionalFlashsalesById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return {
      data: queryResult?.deletePromotionalFlashsalesById[0],
    } as DeleteResult;
  }
}
