import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  DeleteManyParams,
  DeleteManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class VirtualMsisdnProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.selectedIds) {
      filterArray.push({
        id: {
          _in: [...params.filter.selectedIds],
        },
      });
    }

    if (params.filter.q) {
      filterArray.push({
        msisdn: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }

    if (params.filter.country) {
      filterArray.push({
        country: {
          _eq: params.filter.country,
        },
      });
    }

    if (params.filter.userName) {
      filterArray.push({
        user_id_data: {
          name: {
            _ilike: `%${params.filter.userName}%`,
          },
        },
      });
    }

    if (params.filter.virtualNumber) {
      filterArray.push({
        msisdn: {
          _ilike: `%${params.filter.virtualNumber}%`,
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    if (params.sort.field === "user_id_data") {
      field = "user_id";
    }

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query virtualMsisdn($where: VirtualMsisdnWhereInput) {
          allVirtualMsisdnList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              country
              msisdn
              hkd_price
              user_id
              user_id_data {
                id
                email
                name
              }
              allocation_date
              expiry_date
              suspended_at
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allVirtualMsisdnList?.data || [],
      total: queryResult?.allVirtualMsisdnList?.count || 0,
    } as GetListResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });

    const toBeCreatedData: any = {
      ...params.data,
    };
    console.log(723, "toBeCreatedData", toBeCreatedData);
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [VirtualMsisdnCreateInput!]!) {
          createVirtualMsisdn(input: $input) {
            id
            created_at
            updated_at
            country
            msisdn
            hkd_price
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createVirtualMsisdn[0] } as CreateResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          id: {
            _in: params.ids,
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query virtualMsisdn($where: VirtualMsisdnWhereInput) {
          allVirtualMsisdnList(where: $where) {
            data {
              id
              msisdn
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allVirtualMsisdnList?.data,
    } as GetManyResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    console.log({ method: "getOne", resource, params });
    const queryResult = await this.client.gql.query({
      query: gql`
        query aVirtualMsisdn($id: UUID!) {
          getVirtualMsisdnById(id: $id) {
            id
            msisdn
            country
            hkd_price
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });
    return {
      data: queryResult?.getVirtualMsisdnById,
    } as GetOneResult;
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams<any>
  ): Promise<DeleteManyResult<any>> {
    console.log({ method: "deleteMany", resource, params });

    let final_result;

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation deleteVirtualMsisdn($ids: [UUID!]!) {
          deleteVirtualMsisdnById(ids: $ids)
        }
      `,
      variables: {
        ids: [...params.ids],
      },
    });

    if (queryResult?.deleteVirtualMsisdnById) {
      final_result = queryResult?.deleteVirtualMsisdnById;
    }

    if (queryResult?.error) {
      throw new Error("Deletion failed");
    }
    return {
      data: [final_result],
    } as DeleteManyResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const submittedData = {
      country: params?.data?.country,
      hkd_price: +params?.data?.hkd_price,
      msisdn: params?.data?.msisdn,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: VirtualMsisdnUpdateInput!) {
          updateVirtualMsisdnById(ids: $ids, input: $input) {
            id
            country
            msisdn
            hkd_price
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: submittedData,
      },
    });

    return { data: queryResult?.updateVirtualMsisdnById[0] } as UpdateResult;
  }
}
