import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";

export class CurrencyMsisdnProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;
  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query currencyMsisdnList($where: CurrencyMsisdnWhereInput) {
          allCurrencyMsisdnList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              msisdn_prefix
              currency
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allCurrencyMsisdnList?.data,
      total: queryResult?.allCurrencyMsisdnList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aCurrencyMsisdn($id: UUID) {
          getCurrencyMsisdnById(id: $id) {
            id
            created_at
            updated_at
            msisdn_prefix
            currency
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return {
      data: queryResult?.getCurrencyMsisdnById,
    };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const toBeUpdatedData: any = {
      ...params.data,
      msisdn_prefix: params.data.msisdn_prefix.toString(),
    };
    delete toBeUpdatedData.created_at;
    delete toBeUpdatedData.updated_at;
    delete toBeUpdatedData.id;
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: CurrencyMsisdnUpdateInput!) {
          updateCurrencyMsisdnById(ids: $ids, input: $input) {
            msisdn_prefix
            currency
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: toBeUpdatedData,
      },
    });

    return {
      data: queryResult?.updateCurrencyMsisdnById,
    } as UpdateResult;
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "create", resource, params });
    const toBeCreatedData = {
      ...params.data,
      msisdn_prefix: params.data.msisdn_prefix.toString(),
    };
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [CurrencyMsisdnCreateInput!]!) {
          createCurrencyMsisdn(input: $input) {
            msisdn_prefix
            currency
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return {
      data: queryResult?.createCurrencyMsisdn,
    } as CreateResult;
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    console.log({ method: "delete", resource, params });
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deleteCurrencyMsisdnById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return {
      data: queryResult?.deleteCurrencyMsisdnById,
    } as DeleteResult;
  }
}
