// @ts-nocheck
import React from "react";
import {
  SimpleForm,
  Edit,
  required,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  useRecordContext,
} from "react-admin";
import DOMPurify from "dompurify";
import { RichTextInput } from "ra-input-rich-text";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const transform = (data: any) => {
  console.log(data);

  let content = data.content;

  content = { ...content, content: DOMPurify.sanitize(content.content) };

  const res = {
    ...data,
    content,
    // question: DOMPurify.sanitize(data.question),
  };
  return res;
};

const countryList = [
  { id: "GLOBAL", name: "Worldwide" },
  { id: "HK", name: "Hong Kong" },
  { id: "ID", name: "Indonesia" },
  { id: "MY", name: "Malaysia" },
  { id: "TW", name: "Taiwan" },
];

const PrevImage = () => {
  const record: any = useRecordContext();
  return (
    <div
      style={{
        dislplay: "flex",
        justifyContent: "flex-start",
        alignItems: "left",
        flexDirection: "row",
        marginBlockEnd: 50,
      }}
    >
      <p>Previous Image</p>
      <img src={record?.content.mainImage} width={200} alt="img"></img>
    </div>
  );
};

const DiaspoaraEmbassyEdit = (props: any) => {
  const classes = useStyles();

  return (
    <Edit transform={transform} {...props}>
      <SimpleForm>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  source="content.title"
                  validate={[required()]}
                  multiline
                  label="Title"
                  fullWidth
                  data-test-id="title"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  fullWidth
                  className={classes.input}
                  source="country_code"
                  validate={[required()]}
                  choices={countryList}
                  label="Country"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="content.uri"
                  validate={[required()]}
                  multiline
                  label="Uri"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput
                  source="content.content"
                  label="Content"
                  className={classes.input}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PrevImage />
                <ImageInput
                  source="urlThumbnail"
                  label="Main Image"
                  accept="image/*"
                  options={{ multiple: false }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));

export default DiaspoaraEmbassyEdit;
