/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import mbaas from "../../provider/Mbaas";
import QRCodeView from "qrcode.react";
import { Key } from "@mui/icons-material";

export default function ResetPassDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            <Key /> Reset Password
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText align="center">
            Scan this QRcode with Google Authenticator
          </DialogContentText> */}
          <TextField
            label="Token"
            value={token}
            required
            fullWidth
            onChange={(e) => setToken(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            // disabled={loading}
            color="primary"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
