// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  SelectInput,
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  Button,
  FileInput,
  FileField,
  downloadCSV,
  AutocompleteArrayInput,
  AutocompleteInput,
  // NumberInput
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField as MuiTextField,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Papa from "papaparse";
import jsonExport from "jsonexport/dist";
import DescriptionIcon from "@mui/icons-material/Description";
import ReactJson from "react-json-view";
import Mbaas from "../../../provider/Mbaas";
import { isEmpty, omit, get } from "lodash";
import genRandomChar from "../../../utils/genRandomChar";
import { useFormContext } from "react-hook-form";
import InfoIcon from '@mui/icons-material/Info';
import withReactContent from "sweetalert2-react-content";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: "auto",
    },
  },
});

const ToolbarCreate = () => {
  return (
    <Toolbar classes={useStyles()}>
      <SaveButton />
    </Toolbar>
  );
};

const ToolbarCsvDialog = () => {
  return <></>;
};

export const CreateFlashsale = ({ onCancel, ...props }) => {
  const classes = useStyles();

  const [optionItemGroup, setOptionItemGroup] = useState([]);
  const [optionItemKey, setOptionItemKey] = useState([]);

  const [selectedItemGroup, setSelectedItemGroup] = useState([]);
  const [selectedItemKey, setSelectedItemKey] = useState([]);

  const [optionTargetItemGroup, setOptionTargetItemGroup] = useState([]);
  const [optionTargetItemKey, setOptionTargetItemKey] = useState([]);

  const [selectedTargetItemGroup, setSelectedTargetItemGroup] = useState([]);
  const [selectedTargetItemKey, setSelectedTargetItemKey] = useState([]);

  const [firstRender, setFirstRender] = useState(true);

  const [specSubSx, setSpecSubSx] = useState(12);

  const [openCsvDialog, setOpenCsvDialog] = useState(false);
  const [fileCsv, setFileCsv] = useState("");
  const [uploadedFileCsv, setUploadedFileCsv] = useState("");
  const [specificSubscriber, setSpecificSubscriber] = useState([]);

  const [isNewSubs, setIsNewSubs] = useState(false);
  const [isProductiveSubs, setIsProductiveSubs] = useState(false);
  const [isComebackSubs, setIsComebackSubs] = useState(false);

  const [bonusCode, setBonusCode] = useState("");
  const [urlOption, setUrlOption] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const notify = useNotify();

  const handleCloseCsvDialog = () => {
    setOpenCsvDialog(false);
  };

  const handleOpenCsvDialog = () => {
    setOpenCsvDialog(true);
  };

  const handleBonusCode = () => {
    const BonusCodeGenerate = genRandomChar(10).toUpperCase()
    setBonusCode(BonusCodeGenerate)
  };

  const handleUploadCsv = () => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns = parsedData
        .flat()
        .map((item) => item.phone)
        .filter((item) => item);
      if (!columns.length) {
        notify(`1st line in CSV file should be 'phone'!`, { type: "warning" });
        return;
      }
      setSpecificSubscriber(columns);
      setFileCsv(null);
      notify(`CSV file uploaded successfully!`);
    };
    reader.readAsText(fileCsv);
  };

  const uploadedCsv = async () => {
    let filename = `spec-subs-phone`;
    if (uploadedFileCsv !== "") filename = uploadedFileCsv;
    let jsonObject = [{ phone: "" }];
    if (specificSubscriber?.length) {
      jsonObject = specificSubscriber.map((phone) => {
        return { phone: phone };
      });
    }
    jsonExport(jsonObject, {}, (err: any, csv: any) => {
      downloadCSV(csv, filename);
    });
  };

  useEffect(() => {
    let haloTimeout = setTimeout(() => {
      dataProvider
        .getList("servicesProduct", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: {
            field: "label",
            order: "ASC",
          },
        })
        .then((v) => {
          const items = v.data;
          const itemGroups = [...new Set(items)]
            ?.filter(
              (v, i, a) =>
                a.findIndex((t) => t.item_group === v.item_group) === i
            )
            .filter(
              (x) => !["bpjs", "plnbill"].includes(x.item_group.toLowerCase())
            )
            .map((v) => ({
              id: v.item_group,
              name: v.item_group,
            }))
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          const itemKeys = [...new Set(items)]
            .filter((u) =>
              selectedItemGroup.map((t) => t.id).includes(u.item_group)
            )
            .map((v) => ({
              id: `${v.item_key} (${v.label})`,
              name: `${v.item_key} (${v.label})`,
            }));
          setOptionItemGroup(itemGroups);
          setOptionItemKey(itemKeys);
          !firstRender && setSelectedItemKey(itemKeys);
        });
    }, 2000);

    return () => clearTimeout(haloTimeout);
  }, [firstRender, selectedItemGroup]);

  useEffect(() => {
    let haloTimeout = setTimeout(() => {
      dataProvider
        .getList("servicesProduct", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: {
            field: "label",
            order: "ASC",
          },
        })
        .then((v) => {
          const items = v.data;
          const itemGroups = [...new Set(items)]
            ?.filter(
              (v, i, a) =>
                a.findIndex((t) => t.item_group === v.item_group) === i
            )
            .filter(
              (x) => !["bpjs", "plnbill"].includes(x.item_group.toLowerCase())
            )
            .map((v) => ({
              id: v.item_group,
              name: v.item_group,
            }))
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          const itemKeys = [...new Set(items)]
            .filter((u) =>
              selectedTargetItemGroup.map((t) => t.id).includes(u.item_group)
            )
            .map((v) => ({
              id: `${v.item_key} (${v.label})`,
              name: `${v.item_key} (${v.label})`,
            }));
          setOptionTargetItemGroup(itemGroups);
          setOptionTargetItemKey(itemKeys);
          !firstRender && setSelectedTargetItemKey(itemKeys);
        });
    }, 2000);

    return () => clearTimeout(haloTimeout);
  }, [firstRender, selectedTargetItemGroup]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const handleNotFound = () => {
    Swal.fire("Subscriber Not Found", "", "error")
  }
  
  const handlePercentage = () => {
    Swal.fire("Percentage Is More Than 1", "", "error")
  }

  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (data) => {
    let configsSpecSubs = [] as Array<{
      id: string;
      name: string;
      phone: string;
    }>;

    if (
      data.configs.specific_subscriber.status === true &&
      specificSubscriber?.length
    ) {
      const getSubs = await dataProvider.getList("subscribers", {
        filter: {
          phone: specificSubscriber,
        },
        sort: {
          field: "phone",
          order: "asc",
        },
        pagination: {
          page: 1,
          perPage: 999999,
        },
      });
      configsSpecSubs = getSubs.data.map((subs) => {
        return { id: subs.id, name: subs.name, phone: subs.phone };
      });
      if (!configsSpecSubs.length || (specificSubscriber.length !== configsSpecSubs.length)) {
        return handleNotFound();
      }
    }

    if (
      data.configs.specific_subscriber.status === true &&
      !specificSubscriber?.length
    ) {
      data.configs.specific_subscriber.status = false;
    }

    if(data.cashback.percentage && (data.cashback.percentage > 1)) {
      return handlePercentage();
    }

    await dataProvider
      .create("promotionsFlashsale", {
        data: {
          ...omit(data, ['bonus', 'is_bonus', 'cashback']),
          value: Number(data.value),
          configs: {
            ...{
              ...data.configs,
              ever_productive_subscriber: isProductiveSubs,
              new_subscriber_only: isNewSubs,
              comeback_subscriber: isComebackSubs,
              cashback: {
                status: (data.cashback.percentage && data.cashback.unit)? data.cashback.status : false,
                percentage: data.cashback.percentage ? `${data.cashback.percentage}` : "",
                unit: data.cashback.unit || "",
              }
            },
            specific_subscriber: {
              status: data.configs.specific_subscriber.status,
              ...(configsSpecSubs.length
                ? { subscribers: configsSpecSubs }
                : {}),
            },
          },
          items: {
            item_groups: selectedItemGroup.map((x) => {
              return x.id;
            }),
            item_keys: selectedItemKey.map((x) => {
              return x.id.split(" (")[0];
            }),
            bonus: {
              item_groups: selectedTargetItemGroup.map((x) => {
                return x.id;
              }),
              item_keys: selectedTargetItemKey.map((x) => {
                return x.id.split(" (")[0];
              }),
              product_name: data.bonus?.product_name || "",
              code: bonusCode,
              url: data.bonus?.url || ""
            },
          }
        },
      })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/promotions/flashsale`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  useEffect(() => {
    const getUrlList = async () => {
      if (isEmpty(selectedCountry) && isEmpty(selectedPlatform)) {
        return setUrlOption([]);
      }
      const resp = await Mbaas.client.invokeApi("console-webview-link-list", {
        country: selectedCountry,
        platform: selectedPlatform,
      });

      if (resp.data) {
        setUrlOption(resp.data.map((m) => ({ id: m.url, name: m.label })));
      } else {
        setUrlOption([]);
      }
    };
    getUrlList();
  }, [selectedCountry, selectedPlatform]);

  return (
    <Create resource="promotionsFlashsale" title=" &rsaquo; Create" {...props}>
      <Dialog open={openCsvDialog} onClose={handleCloseCsvDialog}>
        <DialogTitle>CSV File</DialogTitle>
        <DialogContent dividers>
          <SimpleForm toolbar={<ToolbarCsvDialog />}>
            <Button
              startIcon={<DescriptionIcon />}
              onClick={uploadedCsv}
              label={
                specificSubscriber.length
                  ? "See Uploaded CSV"
                  : "Download Master CSV"
              }
              variant="outlined"
            />
            <br />
            {specificSubscriber.length ? (
              <DialogContentText>
                <ReactJson
                  src={specificSubscriber || []}
                  collapsed={true}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={true}
                  groupArraysAfterLength={10}
                  name={false}
                />
                <br />
              </DialogContentText>
            ) : (
              ""
            )}
            <FileInput
              source="files"
              options={{ multiple: false }}
              onChange={(e) => {
                setFileCsv(e);
                const filenameArray = e?.name.split(".");
                filenameArray?.pop();
                setUploadedFileCsv(filenameArray?.join("."));
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCsvDialog}>Close</Button>
          <Button
            onClick={handleUploadCsv}
            startIcon={<UploadFileIcon />}
            label="Upload"
            autoFocus
            disabled={!fileCsv}
          />
        </DialogActions>
      </Dialog>
      <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          // sx={{ width: "100%" }}
          onSubmit={onSubmitHandler}
          toolbar={<ToolbarCreate />}
        >
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  label="Country"
                  source="country"
                  fullWidth
                  value={selectedCountry}
                  validate={required()}
                  choices={[
                    { id: "HK", name: "Hongkong" },
                    { id: "ID", name: "Indonesia" },
                    { id: "TW", name: "Taiwan" },
                    { id: "MY", name: "Malaysia" },
                  ]}
                  data-testid="input-country"
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <AutocompleteArrayInput
                  label="Platform"
                  source="configs.platform"
                  fullWidth
                  validate={required()}
                  value={selectedPlatform}
                  onChange={(value) => {
                    setSelectedPlatform(value);
                  }}
                  choices={[
                    { id: "M21", name: "M21" },
                    { id: "G2", name: "G2" },
                    { id: "GHK", name: "GHK" },
                  ]}
                  optionText="name"
                  optionsValue="id"
                  data-testid="input-platform"
                />
              </Grid>
              <Grid item xs={12}>
                <ItemGroupAutocomplete
                  label="Item Groups"
                  source="items.item_groups"
                  validate={required()}
                  option={optionItemGroup}
                  selectedItemGroup={selectedItemGroup}
                  setSelectedItemGroup={setSelectedItemGroup}
                  setSelectedItemKey={setSelectedItemKey}
                  firstRender={firstRender}
                  setFirstRender={setFirstRender}
                />
              </Grid>
              <Grid item xs={12}>
                <ItemKeyAutocomplete
                  label="Item Keys"
                  source="items.item_keys"
                  validate={required()}
                  option={optionItemKey}
                  selectedItemKey={selectedItemKey}
                  setSelectedItemKey={setSelectedItemKey}
                  firstRender={firstRender}
                  setFirstRender={setFirstRender}
                  data-testid="input-item-keys"
                />
              </Grid>
              <Grid item sm={12}>
                <Box sx={{ display: "flex" }}>
                  <h5>Promotion Features Descriptions</h5>
                  <MuiButton onClick={() => alertInformation("PROMOTION_FEATURES")} style={{ minWidth: 'auto', padding: 0, marginLeft: "10px" }}>
                  <InfoIcon />
                  </MuiButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="is_bonus" data-testid="is_bonus" label="Add Bonus?" />
              </Grid>
              <FormDataConsumer>
                {({ formData }) => {
                  const isBonus = get(formData, "is_bonus");
                  if (isBonus) {
                    return (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h5">Bonus Item</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ItemGroupAutocomplete
                            label="Target Item Groups"
                            source="bonus.item_groups"
                            validate={required()}
                            option={optionTargetItemGroup}
                            selectedItemGroup={selectedTargetItemGroup}
                            setSelectedItemGroup={setSelectedTargetItemGroup}
                            setSelectedItemKey={setSelectedTargetItemKey}
                            firstRender={firstRender}
                            className={classes.input}
                            setFirstRender={setFirstRender}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ItemKeyAutocomplete
                            label="Target Item Keys"
                            source="bonus.item_keys"
                            validate={required()}
                            option={optionTargetItemKey}
                            selectedItemKey={selectedTargetItemKey}
                            setSelectedItemKey={setSelectedTargetItemKey}
                            firstRender={firstRender}
                            setFirstRender={setFirstRender}
                            className={classes.input}
                            data-testid="input-bonus-item-keys"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            fullWidth
                            className={classes.input}
                            source="bonus.product_name"
                            data-testid="input-bonus-product-name"
                            label="Product Name"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <BonusCodeInput source="bonus.code" value={bonusCode} setBonusCode={setBonusCode} />
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex" }}>
                          <Button
                            onClick={handleBonusCode}
                            label="Generate"
                            sx={{ alignContent: "center", color: "white", backgroundColor: "#0488d1", margin: "8px 0px 3px 0px", padding: "0px 10px 0px 10px", ":hover": { color: "white", backgroundColor: "#0488d1" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AutocompleteInput
                            label="Url"
                            source="bonus.url"
                            fullWidth
                            choices={urlOption}
                          />
                        </Grid>
                      </>)
                  }
                  return null;
                }}
              </FormDataConsumer>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="cashback.status" label="Add Cashback?" />
              </Grid>
              <FormDataConsumer>
                {({ formData }) => {
                  const isCashback = get(formData, "cashback.status");
                  if (isCashback) {
                    return (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h5">Cashback Item</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            fullWidth
                            className={classes.input}
                            source="cashback.percentage"
                            label="Percentage (example: 0.5)"
                            inputProps={{ pattern: "[0-9.]*" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectInput
                            label="Unit"
                            source="cashback.unit"
                            fullWidth
                            choices={[
                              { id: "COIN", name: "Coin ( Balance )" },
                              { id: "POINT", name: "Point" },
                            ]}
                          />
                        </Grid>
                      </>)
                  }
                  return null;
                }}
              </FormDataConsumer>
              <Grid item sm={12}>
                <Box sx={{ display: "flex" }}>
                  <h5>Target Promotion Options</h5>
                  <MuiButton onClick={() => alertInformation("TARGET_PROMOTION")} style={{ minWidth: 'auto', padding: 0, marginLeft: "10px" }}>
                  <InfoIcon />
                  </MuiButton>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  source="configs.once_per_target"
                  label="1x / MSISDN?"
                  data-testid="input-once-per-target"
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  source="configs.new_subscriber_only"
                  label="For New Subs. Only?"
                  title="Subs. / MSISN without success transactions before this promo is created"
                  data-testid="input-new-subscriber-only"
                  checked={isNewSubs}
                  onChange={(e) => {
                    setIsNewSubs(e.target.checked);
                    setIsProductiveSubs(false);
                    setIsComebackSubs(false);
                  }}
                />
              </Grid>
              <Grid item xs={specSubSx}>
                <BooleanInput
                  source="configs.specific_subscriber.status"
                  label="For Specific Subs.?"
                  title="Is applied for Specific Subs.?"
                  data-testid="input-specific-subscriber-status"
                />
              </Grid>
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => {
                  if (formData?.configs?.specific_subscriber?.status) {
                    setSpecSubSx(6);
                    return (
                      <Grid item xs={6}>
                        <Button
                          label="CSV File"
                          data-testid="csv-file"
                          variant="contained"
                          onClick={handleOpenCsvDialog}
                        >
                          <PostAddIcon />
                        </Button>
                      </Grid>
                    );
                  } else {
                    setSpecSubSx(12);
                  }
                }}
              </FormDataConsumer>
              <Grid item xs={6}>
                <BooleanInput
                  source="configs.ever_productive_subscriber"
                  label="For Ever Productive Subs. ?"
                  title="Subs. who have done their first purchase"
                  data-testid="input-ever-productive-subscriber"
                  checked={isProductiveSubs}
                  onChange={(e) => {
                    setIsProductiveSubs(e.target.checked);
                    setIsNewSubs(false);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  source="configs.registered_subscriber"
                  label="For Registered Subs. ?"
                  title="Subs. who has registered"
                  data-testid="input-registered-subscriber"
                />
              </Grid>
              <Grid item xs={12}>
                <BooleanInput
                  source="configs.comeback_subscriber"
                  label="For Comeback Subs. ?"
                  title="Subs. who has not purchase more than 30 days"
                  data-testid="input-comeback-subscriber"
                  checked={isComebackSubs}
                  onChange={(e) => {
                    setIsComebackSubs(e.target.checked);
                    setIsNewSubs(false);
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <Box sx={{ display: "flex" }}>
                  <h5>Promotion Usage</h5>
                  <MuiButton onClick={() => alertInformation("PROMOTION_USAGE")} style={{ minWidth: 'auto', padding: 0, marginLeft: "10px" }}>
                  <InfoIcon />
                  </MuiButton>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="configs.max_usage_per_subscriber"
                  data-testid="input-max-usage-per-subscriber"
                  label="Max Usage per Subs."
                  validate={required()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="configs.total_usage"
                  data-testid="input-max-total-usage"
                  label="Max Total Usage"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="value"
                  data-testid="input-value"
                  label="Value"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid From"
                  source="start"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-start"
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  label="Valid Until"
                  source="end"
                  fullWidth
                  className={classes.input}
                  validate={[required()]}
                  data-testid="input-end"
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Box>
    </Create>
  );
};

const ItemGroupAutocomplete = ({ setSelectedItemGroup, ...props }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={props.selectedItemGroup}
      style={{ marginBottom: "32px" }}
      onChange={(_, v) => {
        // onChange(v)
        props.setFirstRender(false);
        setSelectedItemGroup(v);
        props.setSelectedItemKey([]);
      }}
      required={props.validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select Item Group`}
          data-testid="input-item-groups"
        />
      )}
    />
  );
};

const BonusCodeInput = ({ setBonusCode, ...props }) => {
  return (
    <MuiTextField
      fullWidth
      value={props.value}
      label="Bonus Code"
      defaultValue={props.value}
      onChange={(e) =>
        setBonusCode(e.target.value)
      }
    />
  );
};

const ItemKeyAutocomplete = ({ setSelectedItemKey, ...props }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={props.selectedItemKey}
      style={{ marginBottom: "32px" }}
      onChange={(_, v) => {
        // onChange(v)
        setSelectedItemKey(v);
      }}
      required={props.validate.isRequired}
      PaperComponent={({ children }) => (
        <Paper className={classes.test}>{children}</Paper>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select Item Key`}
        />
      )}
    />
  );
};

const alertInformation = (type:string) => {
  const MySwal = withReactContent(Swal);
  let promptMessage = ``;
  switch(type) {
    case "PROMOTION_FEATURES":
      promptMessage = `
      <p><strong>Add Bonus:</strong> This option is used to add a bonus to a promotion that has been set up.</p>
      <p><strong>Add Cashback:</strong> This option is used to configure cashback settings within a promotion.</p>
      `
      break;
    case "TARGET_PROMOTION":
      promptMessage = `
      <p><strong>1x/MSISDN:</strong> The promotion is applicable only once per MSISDN (mobile subscriber number) for a single product.</p>
      <p><strong>For New Subscribers Only:</strong> The promotion targets only new subscribers (customers who have never completed a successful transaction).</p>
      <p><strong>For Specific Subscribers:</strong> The promotion is targeted at specific subscriber numbers that are manually uploaded through a CSV file.</p>
      <p><strong>For Registered Subscribers:</strong> The promotion applies only to subscribers who are already registered.</p>
      <p><strong>For Ever Productive Subscribers:</strong> The promotion is for customers who have completed at least one SUCCESS transaction since the release of the M21 app. There is no minimum transaction amount required.</p>
      <p><strong>For Comeback Subscribers:</strong> The promotion targets customers who have been inactive for over 30 days and then become active again.</p>
      `
      break;
    case "PROMOTION_USAGE":
      promptMessage = `
      <p><strong>Max Usage per Subs.:</strong> The maximum number of times a promotion can be used by each subscriber.</p>
      <p><strong>Max Total Usage:</strong> The total maximum number of times the promotion can be used by all subscribers combined.</p>
      <p><strong>Value:</strong> The price of the product in HKD (Hong Kong Dollars).</p>
      `
      break;
  }
  ;

  MySwal.fire({
    target: document.getElementById("history-modal"),
    title: `Information Detail`,
    html: (
      <Box
        sx={{ textAlign: "left" }}
        dangerouslySetInnerHTML={{ __html: promptMessage }}
        id="boxmessage"></Box>
    ),
  });
};
