import ListPage from "./ListPage";
import CreatePage from "./CreatePage";
import EditPage from "./EditPage";

const resource = {
  list: ListPage,
  create: CreatePage,
  edit: EditPage,
};

export default resource;