import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IreferralBenefit {
  referralBenefitFilter: {
    status: string;
    country: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: IreferralBenefit = {
  referralBenefitFilter: {
    status: "",
    country: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type referralBenefitFilter = {
  status: string;
  country: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const referralBenefitFilterSlice = createSlice({
  name: "thisReferralBenefitFilter",
  initialState,
  reducers: {
    setReferralBenefitFilter: (
      state,
      action: PayloadAction<referralBenefitFilter>
    ) => {
      state.referralBenefitFilter = {
        ...state.referralBenefitFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setReferralBenefitFilter } = referralBenefitFilterSlice.actions;

export const getReferralBenefit = (state: RootState) =>
  state.thisReferralBenefitFilter.referralBenefitFilter;

export default referralBenefitFilterSlice.reducer;
