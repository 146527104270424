import React from "react";
import {
  List,
  RaRecord,
  Datagrid,
  TextField,
  DateField,
  Identifier,
  CreateButton,
  TopToolbar,
  FunctionField,
  RowClickFunction,
} from "react-admin";
import { Drawer } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { CreatePage } from "./CreatePage";
import { EditPage } from "./EditPage";

const ListActions = (props: any) => {
  return (
    <TopToolbar>
      <Stack direction="row" spacing={1}>
        <CreateButton
          to={{
            pathname: "/services/currencyRates/create",
          }}
          label="Create New"
        />
      </Stack>
    </TopToolbar>
  );
};

export const CurrencyRatesList = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  // --
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = React.useCallback(() => {
    navigate("/services/currencyRates");
  }, [navigate]);

  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath("/services/currencyRates/:id/*", location.pathname);
  const matchCreate = matchPath(
    "/services/currencyRates/create",
    location.pathname
  );

  return (
    <>
      <List
        {...props}
        actions={
          <ListActions
            handleClickOpenDialog={handleClickOpenDialog}
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
          />
        }
        resource="currencyRates"
        sort={{ field: "created_at", order: "DESC" }}
        title="Services &rsaquo; Conversion Rates"
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={
            ((id: Identifier) =>
              navigate(
                `/services/currencyRates/${id}`
              )) as unknown as RowClickFunction
          }
          key={((id: Identifier) => id) as unknown as React.Key}
        >
          <DateField source="created_at" label="Created at" showTime />
          <TextField source="origin" />
          <TextField source="destination" />
          <TextField source="rate" />

          <FunctionField
            render={(record: RaRecord) => {
              let user = "";
              if (record.author_data) {
                if (record.author_data.name) {
                  user = record.author_data.name;
                } else {
                  user = record.author_data.email;
                }
              }

              return <span>{user}</span>;
            }}
            source="author_data"
            label="Author"
          />
        </Datagrid>
      </List>
      {/* Side Drawer for Create and Edit */}
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          // @ts-ignore
          <EditPage recordId={(matchEdit as any).params.id} />
        )}
        {!!matchCreate && (
          // @ts-ignore
          <CreatePage />
        )}
      </Drawer>
    </>
  );
};
