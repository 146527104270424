// @ts-nocheck
import * as React from "react";
import {
  CreateButton,
  Datagrid,
  FilterButton,
  List,
  Pagination,
  RecordContextProvider,
  RichTextField,
  TextInput,
  TopToolbar,
  useListContext,
  Button as RAButton,
  Confirm,
} from "react-admin";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Drawer,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DateRange } from "../../DateRange";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { EditBroadcast } from "./Edit";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import { useDispatch } from "react-redux";
import { setBroadcastFilter } from "src/slices/broadCastPage/broadcastSlice";
import AddIcon from "@mui/icons-material/Add";
import { CreateBroadcast } from "./Create";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";
import SendIcon from "@mui/icons-material/Send";

const ListActions = ({ broadcastFilter }: any) => {
  const navigate = useNavigate();
  usePersistFilter();
  return (
    <TopToolbar>
      {/* <FilterButton filters={broadcastFilter} /> */}
      <Button
        startIcon={<AddIcon />}
        component={Link}
        to={{
          pathname: "/promotions/broadcast/create",
        }}
      >
        Create
      </Button>
    </TopToolbar>
  );
};

const BroadcastList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelDrawer = React.useCallback(() => {
    navigate(`/promotions/broadcast`);
  }, [navigate]);

  const [open, setOpen] = React.useState(false);
  const [bcId, setBcId] = React.useState("");

  const handleConfirm = async (id: any) => {
    const { error, data } = await mbaas.client.invokeApi(
      "console-broadcast-notifications",
      {
        broadcast_id: id,
      }
    );
    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
    }
    setOpen(false);
  };

  const BroadcastFilter = [
    <DateRange alwaysOn />,
    <TextInput
      source="title"
      label="Title"
      data-testid="search-title"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setBroadcastFilter({ title: e.target.value }))
      }
    />,
    <TextInput
      source="content"
      label="Content"
      data-testid="search-content"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setBroadcastFilter({ content: e.target.value }))
      }
    />,
  ];

  const matchCreate = matchPath(
    "/promotions/broadcast/create",
    location.pathname
  );
  let match = null;
  if (!location.pathname.endsWith("create"))
    match = matchPath("/promotions/broadcast/:id/*", location.pathname);

  const PromotionsPagination = () => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
  );
  const ConfirmDialogContent = () => {
    return (
      <Paper elevation={0}>
        <Typography variant="body1" gutterBottom>
          Continue to resend the broadcast message?
        </Typography>
      </Paper>
    );
  };
  return (
    <>
      <List
        perPage={25}
        component="div"
        // pagination={<PromotionsPagination />}
        resource="promotionsBroadcast"
        title="Promotions &rsaquo; Broadcast"
        filter={{ is_deleted: false }}
        filters={BroadcastFilter}
        actions={<ListActions broadcastFilter={BroadcastFilter} />}
      >
        <BroadcastGrid
          open={open}
          setOpen={setOpen}
          setBcId={setBcId}
          bcId={bcId}
        />
      </List>
      <Confirm
        isOpen={open}
        title="Dear User,"
        content={<ConfirmDialogContent />}
        onClose={() => props.setOpen(false)}
        confirm="Continue"
        onConfirm={() => handleConfirm(bcId)}
      />
      <Drawer
        open={!!match}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!match && (
          <EditBroadcast
            id={(match as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
      <Drawer
        open={!!matchCreate}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchCreate && (
          <CreateBroadcast match={matchCreate} onCancel={handleCancelDrawer} />
        )}
      </Drawer>
    </>
  );
};

const BroadcastGrid = (props: any) => {
  const navigate = useNavigate();

  const handleBtnClick = (id) => {
    props.setOpen(true);
    props.setBcId(id);
  };

  const { data, isLoading } = useListContext();

  if (isLoading) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{ marginTop: "1em" }}>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Grid key={record.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
            {/* @ts-ignore */}
            <Card>
              <CardMedia image={record.content.image} sx={{ height: 140 }} />
              <CardContent sx={{ paddingBottom: "0.5em" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h2" align="center">
                      {record.content.title}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      component="h2"
                      align="center"
                      noWrap
                    >
                      {record.content.url}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="h2"
                      align="center"
                      noWrap
                    >
                      <RichTextField
                        source="content.content"
                        resource="promotionsBroadcast"
                        stripTags
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions
                sx={{
                  ".MuiCardActions-spacing": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  },
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<EditIcon />}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                  onClick={() => navigate(`/promotions/broadcast/${record.id}`)}
                  data-testid="edit-button"
                >
                  Edit
                </Button>
                <RAButton
                  variant="outlined"
                  label="Resend"
                  size="small"
                  style={{ width: "50%" }}
                  onClick={() => handleBtnClick(record.id)}
                >
                  <SendIcon />
                </RAButton>
              </CardActions>
            </Card>
          </Grid>
        </RecordContextProvider>
      ))}
    </Grid>
  );
};

export default BroadcastList;
