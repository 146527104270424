import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import { dataProvider } from ".";
import moment from "moment";
export class SmsLogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    if (params.filter.dateGte || params.filter.dateLte) {
      where._and = [
        {
          created_at: {
            _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
          },
        },
        {
          created_at: {
            _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
          },
        },
      ];
    }
    if (params.filter.virtual_msisdn_id) {
      const {
        data: { msisdn: number },
      } = await dataProvider.getOne("virtualMsisdn", {
        id: params.filter.virtual_msisdn_id,
      });

      where._or = [
        {
          counterparty_msisdn: {
            _ilike: `%${number}%`,
          },
        },
        {
          virtual_msisdn_id_data: {
            msisdn: {
              _ilike: `%${number}%`,
            },
          },
        },
      ];
    }
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
      query smsCdrList($where:SmsCdrWhereInput) {
        allSmsCdrList(
          where: $where, ${args}
          ){
          data{
            id
            created_at
            direction
            virtual_msisdn_id_data {
              msisdn
              user_id
              user_id_data{
                phone
              }
            }
            counterparty_msisdn
            status
            hkd_cost
            current_user_id_data{
              name
              phone
            }
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });

    const mappedData = queryResult?.allSmsCdrList?.data.map((el: any) => {
      let a_number = "";
      let b_number = "";
      if (el.direction === "MO") {
        a_number = el.current_user_id_data.phone;
        b_number = el.counterparty_msisdn;
      } else {
        a_number = el.counterparty_msisdn;
        b_number = el.virtual_msisdn_id_data.msisdn;
      }
      return {
        ...el,
        a_number,
        b_number,
      };
    });

    return {
      data: mappedData,
      total: queryResult?.allSmsCdrList?.count,
    } as GetListResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          counterparty_msisdn: {
            _in: params.ids,
          },
        },
        {
          virtual_msisdn_id_data: {
            msisdn: {
              _in: params.ids,
            },
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query smsCdrList($where: SmsCdrWhereInput) {
          allSmsCdrList(where: $where) {
            data {
              id
              created_at
              direction
              virtual_msisdn_id_data {
                msisdn
              }
              counterparty_msisdn
              status
              hkd_cost
              current_user_id_data {
                name
                phone
              }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    const mappedData = queryResult?.allSmsCdrList?.data.map((el: any) => {
      let number = "";
      if (el.direction === "MO") {
        number = el.counterparty_msisdn;
      } else {
        number = el.virtual_msisdn_id_data.msisdn;
      }
      return {
        ...el,
        number,
      };
    });
    return {
      data: queryResult?.allSmsCdrList?.data.map((x: any) => {
        return mappedData;
      }),
    } as GetManyResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    console.log({ method: "getOne", resource, params });
    const queryResult = await this.client.gql.query({
      query: gql`
        query aSmsCdr($id: UUID) {
          getSmsCdrById(id: $id) {
            id
            created_at
            direction
            virtual_msisdn_id_data {
              msisdn
              user_id
              user_id_data {
                phone
              }
            }
            counterparty_msisdn
            status
            hkd_cost
            current_user_id_data {
              name
              phone
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    const rawData = queryResult?.getSmsCdrById;
    const finalData = {
      ...queryResult?.getSmsCdrById,
      a_number:
        rawData.direction === "MO"
          ? rawData.current_user_id_data.phone
          : rawData.counterparty_msisdn,
      b_number:
        rawData.direction === "MO"
          ? rawData.counterparty_msisdn
          : rawData.virtual_msisdn_id_data.msisdn,
    };

    return { data: finalData } as GetOneResult;
  }
}
