import { useMemo } from "react";
import { AutocompleteArrayInput, required, useGetList, useInput } from "react-admin";

interface BonusProductGroupInputProps {
  source: string;
  formData: any;
}

const BonusProductGroupInput: React.FC<BonusProductGroupInputProps> = (props) => {
  const { source } = props;

  const { field: fieldItem } = useInput({ source: "bonus_item_key" });
  const { data, isLoading } = useGetList("productCatalogs", {
    pagination: {
      page: 1,
      perPage: 1000,
    },
  });

  const choices = useMemo(() => {
    if (data) {
      return data.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.item_group === v.item_group) === i);
    }
    return [];
  }, [data]);

  return (
    <AutocompleteArrayInput
      choices={choices}
      label="Item group"
      optionValue="item_group"
      optionText="item_group"
      loading={isLoading}
      loadingText="Loading..."
      debounce={500}
      isOptionEqualToValue={(options, value) => {
        return options.item_group === value.item_group;
      }}
      onChange={() => {
        fieldItem.onChange(null);
      }}
      source={source}
      margin="normal"
      validate={[required()]}
      sx={{ marginRight: "50px" }}
    />
  );
};

export default BonusProductGroupInput;
