// @ts-nocheck
import React from "react";
import {
    TabbedShowLayout,
    Tab,
    FunctionField
} from "react-admin";
import {
    Box,
    Grid,
    IconButton,
    Stack,
    // TextField
} from "@mui/material";
import moment from "moment";
import ReactJson from "react-json-view";
import CloseIcon from "@mui/icons-material/Close";
import { useGetOne } from 'react-admin';

const DetailPage = ({walletId, onClose}) => {

    const { data } = useGetOne('walletLedgers', { id: walletId });
    
    return (
        <Box
                pt={5}
                width={{ xs: "100vW", sm: 600 }}
                mt={{ xs: 2, sm: 1 }}
                ml={{ xs: 2, sm: 1 }}
                mr={{ xs: 2, sm: 1 }}
                minHeight="90vh"
            >
            <Stack
            direction="row"
            p={0}
            sx={{ position: "absolute", right: 0, zIndex: 100 }}
            >
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Stack>
            { data &&
            <TabbedShowLayout>
                <Tab label="detail" path="">
                    <Grid container spacing={1}>
                        <Grid item xs={12} marginTop={2}>
                            <h4>Purchase</h4>
                        </Grid>
                        {
                            data.purchase_log_id_data === null ? (
                                <Grid item xs={12}>
                                    No results found
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={4}>
                                        <strong>Purchase ID</strong>
                                    </Grid>
                                    <Grid item xs={8}>                            
                                        <FunctionField 
                                            render={() => data.purchase_log_id_data.id}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Order ID</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.purchase_log_id_data.order_id}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Subscriber Name</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_id_data.user_id_data.name}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Status</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.purchase_log_id_data.status}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Product</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.purchase_log_id_data.product_catalog_id_data.label}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Selling Price</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.purchase_log_id_data.selling_price}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Target Detail</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                    <FunctionField
                                        render={() => {
                                                if (data.purchase_log_id_data.target_detail) {
                                                if (typeof data.purchase_log_id_data.target_detail === "string") {
                                                    return <span>{data.purchase_log_id_data.target_detail}</span>;
                                                } else {
                                                    return (
                                                    <ReactJson
                                                        src={data.purchase_log_id_data.target_detail || []}
                                                        shouldCollapse={() => {
                                                        /* TODO document why this arrow function is empty */
                                                        }}
                                                        enableClipboard={false}
                                                        displayDataTypes={false}
                                                        displayObjectSize={false}
                                                        name={false}
                                                    />
                                                    );
                                                }
                                                } else {
                                                return "-";
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Product Snapshot</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField
                                            render={() => {
                                                return (
                                                <ReactJson
                                                    src={data.purchase_log_id_data.product_snapshot || []}
                                                    shouldCollapse={() => {}}
                                                    enableClipboard={false}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    name={false}
                                                />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Currency Snapshot</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField
                                            render={() => {
                                                return (
                                                <ReactJson
                                                    src={data.purchase_log_id_data.currency_snapshot || []}
                                                    shouldCollapse={() => {}}
                                                    enableClipboard={false}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    name={false}
                                                />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Remark</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => {
                                                    if (data.purchase_log_id_data.remark) {
                                                        return <FunctionField render={() => data.purchase_log_id_data.remark} />;
                                                    } else {
                                                        return "-";
                                                    }
                                                }
                                            }
                                        />
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} marginTop={2}>
                            <h4>SMS</h4>
                        </Grid>
                        {
                            data.sms_cdr_id_data === null ? (
                                <Grid item xs={12}>
                                    No results found
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={4}>
                                        <strong>Date</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => {moment(data.sms_cdr_id_data.created_at).format("DD/MM/YYYY, hh:mm:ss A")}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Direction</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.sms_cdr_id_data.direction}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>A Number</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.a_number_sms}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>B Number</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.b_number_sms}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Status</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => {return data.sms_cdr_id_data.status.toUpperCase();}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>HKD Cost</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField render={() => `HKD ${data.sms_cdr_id_data.hkd_cost}`} />
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} marginTop={2}>
                            <h4>Voice</h4>
                        </Grid>
                        {
                            data.voice_cdr_id_data === null ? (
                                <Grid item xs={12}>
                                    No results found
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={4}>
                                        <strong>Date</strong>
                                    </Grid>
                                    <Grid item xs={8}>      
                                        <FunctionField 
                                            render={() => {moment(data.voice_cdr_id_data.created_at).format("DD/MM/YYYY, hh:mm:ss A")}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>A Number</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.voice_cdr_id_data.a_name}
                                        <br/>
                                        {data.voice_cdr_id_data.a_number_voice}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>B Number</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.voice_cdr_id_data.b_name}
                                        <br/>
                                        {data.voice_cdr_id_data.b_number_voice}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Direction</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.voice_cdr_id_data.direction}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Status</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.voice_cdr_id_data.status}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Begin</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => {moment(data.voice_cdr_id_data.begin).format("DD/MM/YYYY, hh:mm:ss A")}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>End</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => {moment(data.voice_cdr_id_data.end).format("DD/MM/YYYY, hh:mm:ss A")}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Duration (Second)</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.voice_cdr_id_data.duration}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Cost</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.voice_cdr_id_data.hkd_cost}
                                        />
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} marginTop={2}>
                            <h4>Reload</h4>
                        </Grid>
                        {
                            data.wallet_reload_log_id_data === null ? (
                                <Grid item xs={12}>
                                    No results found
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={4}>
                                        <strong>Subscriber Name</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_reload_log_id_data.user_id_data.name}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Subscriber Email</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_reload_log_id_data.user_id_data.email}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Subscriber Phone</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_reload_log_id_data.user_id_data.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Status</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => {return data.wallet_reload_log_id_data.status.toUpperCase()}}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Channel</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_reload_log_id_data.channel}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Amount</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_reload_log_id_data.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Rate Snapshot</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField
                                            render={() => {
                                                return (
                                                <ReactJson
                                                    src={data.wallet_reload_log_id_data.rate_snapshot || []}
                                                    shouldCollapse={() => {}}
                                                    enableClipboard={false}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    name={false}
                                                />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <strong>Order ID</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FunctionField 
                                            render={() => data.wallet_reload_log_id_data.reload_id}
                                        />
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </Tab>
            </TabbedShowLayout>
            }
        </Box>
    );
};

export default DetailPage;
