// @ts-nocheck
import React, { useCallback, useEffect } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Pagination,
  DateField,
  FilterButton,
  FunctionField,
  SelectInput,
  TextInput,
  Button,
  useListContext,
  Confirm,
  useCreate,
  useRecordContext,
  // BooleanField
} from "react-admin";
import moment from "moment";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Box, Drawer, Paper, Tab, Typography } from "@mui/material";
import { MvnoDetail } from "./Detail";
import GetAppIcon from "@mui/icons-material/GetApp";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Chip from "@mui/material/Chip";
import mbaas from "src/provider/Mbaas";
import { endOfToday, format } from "date-fns";
import { useDispatch } from "react-redux";
import { setMvnoFilter } from "src/slices/mvnoPage/filterMvnoSlice";
import { DateRangePicker } from "rsuite";
import { TabContext, TabList } from "@mui/lab";
import { useState } from "react";
import { dataProvider } from "../../provider";
import { isEmpty, isNull } from "lodash";

const ListActions = (props) => {
  const { className, exporter, filters, filter, MvnoFilter, maxResults, navigate, ...rest } = props;
  const [userData, setUserData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const { filterValues } = useListContext();

  // -- export button
  const ExportPurchaseLogButton = () => {
    //
    const type_name = "usages-purchase";
    const gen_file_name = `${type_name}_${format(new Date(), "yyyy-MM-dd_HH-mm-ss")}`;
    //
    const [exportData, { data, error }] = useCreate("reports", {
      data: {
        author_id: userData?.id,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            ...filter,
            ...filterValues,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"usages-purchase"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button label="Export Purchase Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm isOpen={open} title="Dear User," content={<ConfirmDialogContent />} onClose={() => setOpen(false)} confirm="Go To Report" onConfirm={navigateToReportPage} />
      </Button>
    );
  };

  const ExportTicketsButton = () => {
    //
    const type_name = "tickets";
    const gen_file_name = `${type_name}_${format(new Date(endOfToday()), "yyyy-MM-dd")}`;
    //
    console.log(737, "filterValues", filterValues);
    //
    const [exportData, { data, error }] = useCreate("reports", {
      data: {
        author_id: userData?.id,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            ...filter,
            ...filterValues,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen2(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"tickets"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button variant="contained" label="Export Ticket Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm isOpen={open2} title="Dear User," content={<ConfirmDialogContent />} onClose={() => setOpen2(false)} confirm="Go To Report" onConfirm={navigateToReportPage} />
      </Button>
    );
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={setMvnoFilter} />
      <ExportPurchaseLogButton />
      <ExportTicketsButton />
    </TopToolbar>
  );
};

const MvnoPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const rowStyleByStatus = (record) => {
  const bgColorMap = {
    success: "#dcf8c6",
    failed: "#ffe4e4",
  };
  let bgColor = bgColorMap[record.status.toLowerCase()];
  if (!bgColor) bgColor = "transaparent";
  return {
    backgroundColor: bgColor,
  };
};

const PayChannel = (props: any) => {
  const record = useRecordContext(props);
  const histories = record.histories;
  if (histories !== null) {
    if (histories.length) {
      const newArray = histories?.filter((element) => element.status === "UNPAID");
      const newArray2 = histories?.filter((element) => element.status === "PENDING");
      const dataPayment = !isEmpty(newArray) ? (newArray[0] ? newArray[0].data : []) : !isEmpty(newArray2) ? (newArray2[0] ? newArray2[0].data : []) : [];
      const pgwData = dataPayment ? (dataPayment.pgw_data ? dataPayment.pgw_data : dataPayment) : [];
      return (
        <>
          {!isEmpty(pgwData) && pgwData.channel && (
            <>
              <div style={{ display: "flex" }}>
                <div style={{ alignContent: "center" }}>
                  <img src={"/payments/" + pgwData.channel + ".png"} alt="logo" style={{ width: "40px", height: "40px", marginRight: "10px" }} />
                </div>
                <div>
                  <div style={{ fontStyle: "italic" }}>{pgwData.payment_id}</div>
                  {pgwData.payment_code && (
                    <div style={{ marginTop: "4px" }}>
                      <Chip icon={<QrCodeIcon fontSize="small" />} label={pgwData.payment_code} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export const MvnoList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataStorage] = useState(() => {
    const saved = localStorage.getItem("tab");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const handleCancelDrawer = useCallback(() => {
    navigate(`/usages/mvno`);
  }, [navigate]);

  const match = matchPath("/usages/mvno/:id/show/*", location.pathname);

  const DateRange = (props: any) => {
    const { filterValues, displayedFilters, setFilters } = useListContext();
    const [dateValue, setDateValue] = useState<any>({
      dateGte: filterValues.dateGte ? moment(filterValues.dateGte).startOf("day").format() : null,
      dateLte: filterValues.dateLte ? moment(filterValues.dateLte).endOf("day").format() : null,
    });

    const dateBegin = filterValues.dateGte ? new Date(moment(filterValues.dateGte).startOf("day").format()) : null;
    const dateEnd = filterValues.dateLte ? new Date(moment(filterValues.dateLte).endOf("day").format()) : null;

    return (
      <DateRangePicker
        defaultValue={dateBegin && dateEnd ? [dateBegin, dateEnd] : null}
        value={dateValue.dateGte && dateValue.dateLte ? [new Date(dateValue.dateGte), new Date(dateValue.dateLte)] : null}
        size="lg"
        onClean={() => {
          setDateValue({
            dateGte: null,
            dateLte: null,
          });
          const updateFilter = {
            ...filterValues,
            dateGte: null,
            dateLte: null,
          };
          setFilters(updateFilter, displayedFilters);
        }}
        onChange={(value) => {
          const start = value ? moment(value[0].toString()).startOf("day").format() : null;
          const end = value ? moment(value[1].toString()).endOf("day").format() : null;

          setDateValue({
            dateGte: start,
            dateLte: end,
          });
          const updateFilter = {
            ...filterValues,
            dateGte: start,
            dateLte: end,
          };
          setFilters(updateFilter, displayedFilters);
        }}
      />
    );
  };

  const MvnoFilter = [
    <DateRange key="date_range" alwaysOn />,
    <TextInput key="order_id" source="order_id" label="Order ID" alwaysOn sx={{ maxWidth: "150px" }} data-testid="search-purchase-id" onChange={(e) => dispatch(setMvnoFilter({ order_id: e.target.value }))} />,
    <TextInput key="purchase_id" source="purchase_id" label="Purchase ID" alwaysOn sx={{ maxWidth: "150px" }} data-testid="search-purchase-id" onChange={(e) => dispatch(setMvnoFilter({ purchase_id: e.target.value }))} />,
    <SelectInput
      key="status"
      source="status"
      label="Status"
      choices={[
        { id: "PAID", name: "PAID" },
        { id: "PENDING", name: "PENDING" },
        { id: "SUCCESS", name: "SUCCESS" },
        { id: "FAILED", name: "FAILED" },
      ]}
      alwaysOn
      data-testid="search-status"
      onChange={(e) => dispatch(setMvnoFilter({ status: e.target.value }))}
      sx={{ minWidth: "0px", width: "110px" }}
    />,

    <AutocompleteInput
      alwaysOn
      fullWidth
      size="small"
      source="user_country"
      key="user_country"
      label="Country"
      optionText={(choice) => choice.name}
      choices={[
        { id: "HK", name: "Hong Kong" },
        { id: "TW", name: "Taiwan" },
      ]}
      onChange={(e) => dispatch(setMvnoFilter({ user_country: e }))}
      sx={{ minWidth: "0px", width: "140px" }}
    />,
    <SelectInput
      key="isBonus"
      source="isBonus"
      label="Bonus"
      choices={[
        { id: true, name: "YES" },
        { id: false, name: "NO" }
      ]}
      onChange={(e) => dispatch(setMvnoFilter({ isBonus: e.target.value }))}
    />,
    <TextInput key="target_detail" source="target_detail" label="Target" data-testid="search-target-detail" onChange={(e) => dispatch(setMvnoFilter({ target_detail: e.target.value }))} sx={{ maxWidth: "150px" }} />,
    <TextInput key="bundle_id" source="bundle_id" label="Bundle ID" data-testid="search-bundle-id" onChange={(e) => dispatch(setMvnoFilter({ bundle_id: e.target.value }))} />,
    <ReferenceInput key="product_catalog_id" source="product_catalog_id" reference="productCatalogs" label="Product" filter={{ isMvno: true }}>
      <AutocompleteInput fullWidth source="label" optionText={(choice) => `${choice.label} - ${choice.item_key}`} label="Product" onChange={(e) => dispatch(setMvnoFilter({ product_catalog_id: e }))} sx={{ maxWidth: "150px" }} />
    </ReferenceInput>,
    <ReferenceInput key="user_id" source="user_id" reference="subscribers" label="Subscriber Name">
      <AutocompleteInput fullWidth source="name" data-testid="search-subscriber" optionText={(choice) => choice.name} label="Type in a name" onChange={(e) => dispatch(setMvnoFilter({ user_id: e }))} sx={{ width: "150px" }} />
    </ReferenceInput>,
  ];

  const [value, setValue] = React.useState(dataStorage.tab_mvno || "other");
  const [filter, setFilter] = React.useState({ isGrapari: false, isMvno: true });

  React.useEffect(() => {
    if (value === "taiwan") {
      setFilter({ isGrapari: true, grapariCountry: "TW", isMvno: true });
    } else if (value === "hongkong") {
      setFilter({ isGrapari: true, grapariCountry: "HK", isMvno: true });
    } else {
      setFilter({ isGrapari: false, isMvno: true });
    }
  }, [value]);

  function getFilterMVNO(array, targetValues) {
    const newArray = array.filter((element) => !targetValues.includes(element.key));
    return newArray;
  }

  const MVNOFilterM21 = getFilterMVNO(MvnoFilter, ["bundle_id"]);
  const MVNOFilterTW = getFilterMVNO(MvnoFilter, ["user_country"]);

  const handleChange = (e: SyntheticEvent, newValue: string) => {
    e.preventDefault();
    localStorage.setItem("tab", JSON.stringify({ tab_mvno: newValue }));
    setValue(newValue);
  };

  const [currencyRates, setCurrencyRates] = useState({});

  const formatNumber = (number, currency) => {
    switch (currency) {
      case "IDR":
        return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number);
      case "USD":
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }).format(number);
      case "TWD":
        return new Intl.NumberFormat("zh-TW", { style: "decimal", minimumFractionDigits: 0 }).format(number);
      case "MYR":
        return new Intl.NumberFormat("ms-MY", { style: "currency", currency: "MYR", minimumFractionDigits: 2 }).format(number);
      case "HKD":
        return new Intl.NumberFormat("zh-HK", { style: "currency", currency: "HKD", minimumFractionDigits: 2 }).format(number);
      default:
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }).format(number);
    }
  };

  useEffect(() => {
    const currencies = ["IDR", "USD", "MYR", "HKD"];
    const fetchRates = async () => {
      const rates = {};
      for (const currency of currencies) {
        const response = await dataProvider.getList("currencyRates", {
          filter: { origin: currency, destination: "TWD" },
          pagination: { page: 1, perPage: 1 },
          sort: { field: "created_at", order: "DESC" },
        });
        const items = response.data || [];
        rates[currency] = items[0]?.rate || 1;
      }
      setCurrencyRates(rates);
    };
    fetchRates();
  }, []);
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="M21" value="other" />
            <Tab label="MyGrapariTaiwan" value="taiwan" />
            <Tab label="MyGrapariHongkong" value="hongkong" />
          </TabList>
        </Box>
      </TabContext>
      <List
        resource="mvno"
        title="Consumptions &rsaquo; MVNO"
        filter={filter}
        filters={value === "other" ? MVNOFilterM21 : MVNOFilterTW}
        actions={<ListActions navigate={navigate} mvnoFilter={MvnoFilter} filter={filter} />}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        pagination={<MvnoPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: match ? "600px" : 0,
        }}
      >
        <Datagrid
          rowStyle={rowStyleByStatus}
          rowClick={(id) => {
            navigate(`/usages/mvno/${id}/show`);
          }}
          bulkActionButtons={false}
        >
          <DateField source="created_at" label="Date" showTime />
          <TextField source="order_id" label="Order ID" />
          <TextField source="purchase_id" label="Purchase ID" />
          <FunctionField
            label="Origin Cost"
            render={(record) => {
              const dataHistory = record?.histories;
              if (dataHistory !== null) {
                if (dataHistory.length) {
                  const history = record?.histories?.find((h) => h.status === "SUCCESS");
                  if (history) {
                    const originCost = history.data?.orderDetail?.cost?.hkd_cost;
                    const originCurrency = "HKD";
                    if (originCost && originCurrency) {
                      const rate = currencyRates[originCurrency] || 1;
                      return `NTD ${formatNumber(originCost * rate, "TWD")}`;
                    }
                  }
                } else {
                  return "";
                }
              }
              return "";
            }}
            sortable={false}
          />
          {value === "taiwan" && <TextField source="bundle_id" label="Bundle ID" />}
          <TextField source="user_id_data.phone" label="Register Number" sortable={false} />
          <TextField source="product_catalog_id_data.label" label="Product" sortable={false} />
          <TextField source="product_catalog_id_data.item_key" label="Item Key" sortable={false} />
          <TextField source="product_catalog_id_data.item_group" label="Item Group" sortable={false} />
          <FunctionField label="Bonus" render={(record) => {
            let dataSortHistories: any;
            let dataCekHistories: any;
            if (record.histories !== null) {
              if (record.histories.length) {
                dataSortHistories = record.histories
                dataSortHistories.sort(
                  (a: any, b: any) =>
                    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                )

                if ((!isNull(record.flashsale_id))) {
                  dataCekHistories = record.histories?.filter((element: any) => element.status === "PENDING" || element.status === "PAID");
                  dataCekHistories = dataCekHistories.filter((item: any) => item.data?.purchaseContext !== undefined);
                }
              }
            }
            if (!isEmpty(dataCekHistories) && dataCekHistories[0].data.purchaseContext.couponCode) {
              // return <BooleanField source="bonus" defaultValue={true}/>;
              return <>YES</>;
            } else {
              // return <BooleanField source="bonus" defaultValue={false}/>;
              return <>NO</>;
            }
          }
          } />
          {value !== "hongkong" && <PayChannel label="Pay Channel" />}
          <FunctionField label="Selling Price" render={(record) => {
            let dataSortHistories: any;
            let dataCekHistories: any;
            if (record.histories !== null) {
              if (record.histories.length) {
                dataSortHistories = record.histories
                dataSortHistories.sort(
                  (a: any, b: any) =>
                    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                )

                if ((!isNull(record.flashsale_id))) {
                  dataCekHistories = record.histories?.filter((element: any) => element.status === "PENDING" || element.status === "PAID");
                  dataCekHistories = dataCekHistories.filter((item: any) => item.data?.purchaseContext !== undefined);
                }
              }
            }
            if (!isEmpty(dataCekHistories) && dataCekHistories[0].data.purchaseContext.couponCode) {
              return 0;
            } else {
              return <TextField source="selling_price" />;
            }
          }
          } />
          <TextField source="target_detail" label="Target" />
          <FunctionField label="Status" source="status" render={(record) => <label>{record.status.toUpperCase()}</label>} />
          <FunctionField label="Remark" source="remark" render={(record) => <label>{record.remark}</label>} />
        </Datagrid>
      </List>
      <Drawer variant="persistent" open={!!match} anchor="right" onClose={handleCancelDrawer} sx={{ zIndex: 100 }}>
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && <MvnoDetail id={(match as any).params.id} onCancel={handleCancelDrawer} value={value} />}
      </Drawer>
    </>
  );
};
