import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  CreateParams,
  CreateResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class OtpConstraintsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    filterArray.push({
      key: {
        _ilike: `otp_param%`,
      },
    });
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllSystemConfig($where: SystemConfigsWhereInput) {
          allSystemConfigsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              key
              value
              description
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    console.log(77623, "queryResult", queryResult);
    const data = queryResult?.allSystemConfigsList?.data.map((x: any) => {
      return { id: x.id, value: JSON.parse(x.value), key: x.key.split(":")[1] };
    });
    const { error: err, data: result } = await this.client.invokeApi(
      "console-fr-config",
      {
        action: "get",
        data: {
          id: "country_list",
        },
      }
    );
    const countryList = result.data.data.find(
      (x: any) => x.id === "country_list"
    );
    data.push({
      id: countryList.id,
      key: "ALLOWED_COUNTRY",
      value: countryList.value.country_list,
    });
    console.log({ data });

    return {
      data: data || [],
      total: data?.length || 0,
    } as GetListResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    console.log({ method: "getOne", resource, params });
    let returnedData = {} as GetOneResult;
    if (params.id === "country_list") {
      const { error: err, data: result } = await this.client.invokeApi(
        "console-fr-config",
        {
          action: "get",
          data: {
            id: "country_list",
          },
        }
      );
      const countryList = result.data.data.find(
        (x: any) => x.id === "country_list"
      );
      returnedData = {
        data: {
          id: countryList.id,
          key: "ALLOWED_COUNTRY",
          value: countryList.value.country_list,
        },
      };
    } else {
      const queryResult = await this.client.gql.query({
        query: gql`
          query aSystemConfig($id: UUID!) {
            getSystemConfigsById(id: $id) {
              id
              created_at
              updated_at
              key
              value
              description
            }
          }
        `,
        variables: {
          id: params.id,
        },
      });
      const data = queryResult?.getSystemConfigsById;
      returnedData = {
        data: { id: data.id, key: data.key, value: JSON.parse(data.value) },
      };
    }
    return returnedData as GetOneResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });
    const { key, value } = params?.data;
    let returnedData = {} as UpdateResult;
    if (params.id === "country_list") {
      const { error: err, data: result } = await this.client.invokeApi(
        "console-fr-config",
        {
          action: "update",
          data: {
            id: key,
            value: { country_list: value },
          },
        }
      );
      returnedData = { data: result.data.data };
    } else {
      const submittedData = {
        value: JSON.stringify(value),
      };

      const queryResult = await this.client.gql.mutation({
        mutation: gql`
          mutation ($ids: [UUID!]!, $input: SystemConfigsUpdateInput!) {
            updateSystemConfigsById(ids: $ids, input: $input) {
              id
              created_at
              updated_at
              key
              value
              description
            }
          }
        `,
        variables: {
          ids: [`${params.id}`],
          input: submittedData,
        },
      });
      returnedData = { data: queryResult?.updateSystemConfigsById[0] };
    }

    return returnedData as UpdateResult;
  }
}
