import { Stack } from "@mui/material";
import React, { Fragment } from "react";
import {
  Datagrid,
  TextField,
  TextInput,
  Pagination,
  List,
  FilterForm,
  Identifier,
  DateField,
} from "react-admin";
import { DateRange } from "../../DateRange";
import { useDispatch } from "react-redux";
import { setDeviceLogsFilter } from "src/slices/deviceLogsPage/filterDeviceLogsSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";
//
const CustomPagination = (props: any) => (
  <Pagination rowsPerPageOptions={["5", "10", "25", "50", "100"]} {...props} />
);
//
const ListToolbar = ({ filters }: any) => {
  usePersistFilter();
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <FilterForm filters={filters} />
    </Stack>
  );
};

export const DeviceLogsList = () => {
  const dispatch = useDispatch();

  const Filters = [
    <DateRange alwaysOn />,
    <TextInput
      source="fingerprint"
      label="Fingerprint"
      alwaysOn
      data-test-id="search-by-fingerprint"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setDeviceLogsFilter({ fingerprint: e.target.value }))
      }
    />,
  ];

  //
  return (
    <Fragment>
      <List
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        actions={<ListToolbar filters={Filters} />}
        pagination={<CustomPagination />}
        resource="deviceLogs"
        title="Device Logs"
      >
        <Datagrid
          key={((id: Identifier) => id) as unknown as React.Key}
          bulkActionButtons={false}
        >
          <DateField
            source="created_at"
            label="Created"
            showTime
            locales="en-HK"
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }}
          />
          <TextField source="info" />
          <TextField source="fingerprint" />
          <TextField source="user_id_data.name" label="Subscriber" />
        </Datagrid>
      </List>
    </Fragment>
  );
};
