import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import gqltag from "graphql-tag";

export class DiasporasEmbassyClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    // const rsc = resource.replace("diaspora", "");
    // console.log(rsc, "dis");

    // const table = this.client.getTable("diasporas");
    // const results = await table.read();
    // console.log(results, 123);
    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    const { allDiasporasList, error } = await this.client.gql.query({
      query: `
      query allDiasporasList($where: DiasporasWhereInput) {
        allDiasporasList(where: $where, ${args}) {
          data {
            id
            created_at
            updated_at
            country_code
            category
            priority
            content
          }
          count
        }
      }
      `,
      variables: {
        where: { category: { _eq: "embassy_info" } },
      },
    });
    console.log({ allDiasporasList, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }
    // console.log(allUsersList);
    return {
      data: allDiasporasList.data,
      total: allDiasporasList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    let initTable = this.client.getTable("diasporas");
    const { data } = await initTable.lookup(params.id);
    console.log(data, "one");

    const modifiedData = {
      ...data,
      label: data?.content?.label,
      phone: data?.content?.phone,
    };
    return { data: modifiedData } as GetOneResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async delete(resource: string, params: DeleteParams): Promise<DeleteResult> {
    console.log(params);

    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
              mutation($ids: [UUID!]!){
                deleteDiasporasById(ids: $ids)
              }
          `,
      variables: {
        ids: params.id,
      },
    });

    console.log(queryResult);

    return { data: params.previousData };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    console.log(params, "embassy_info");
    const rawImage = params?.data?.urlThumbnail;

    const response: any = await this.client.storage.upload({
      file: rawImage.rawFile,
      params: {
        filename: `emergency-${Date.now()}`,
        bucket: "diasporas",
      },
    });

    const download = await this.client.storage.downloadUrl({
      params: {
        bucket: "diasporas",
      },
      fileId: response?.data.id,
    });

    console.log(download);

    const urlImg = download.data.url.split("?");

    const submittedData = {
      category: "embassy_info",
      country_code: params?.data?.country_code,
      content: {
        content: params?.data.content.content,
        mainImage: urlImg[0],
        title: params?.data?.content.title,
        uri: params?.data?.content.uri,
      },
      priority: 0,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
              mutation($input: [DiasporasCreateInput!]!){
                  createDiasporas(input: $input){
                      id
                      category
                      country_code
                      content
                      priority
                  }
              }
          `,
      variables: {
        input: submittedData,
      },
    });

    return { data: queryResult?.createDiasporas[0] } as CreateResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log(params.data);

    let inputData = {
      country_code: params.data.country_code,
      category: params.data.category,
      priority: params.data.priority,
      content: params.data.content,
    };

    if (params.data.urlThumbnail) {
      console.log("new photo");
      const rawImage = params?.data?.urlThumbnail;
      const response: any = await this.client.storage.upload({
        file: rawImage.rawFile,
        params: {
          filename: `embassy-${Date.now()}-${params?.data?.priority}`,
          bucket: "diasporas",
        },
      });

      const download = await this.client.storage.downloadUrl({
        params: {
          bucket: "diasporas",
        },
        fileId: response?.data.id,
      });

      const urlImg = download.data.url.split("?");

      console.log(response);
      const contentEdit = {
        ...inputData.content,
        mainImage: urlImg[0],
      };

      inputData = {
        ...inputData,
        content: contentEdit,
      };
    }

    const { updateDiasporasById, error } = await this.client.gql.mutation({
      mutation: `
      mutation allDiasporasList($ids: [UUID!]!, $input : DiasporasUpdateInput!) {
        updateDiasporasById(ids: $ids, input: $input) {
          id
          created_at
          updated_at
          country_code
          category
          priority
          content
        }
      }
      `,
      variables: {
        ids: params.data.id,
        input: inputData,
      },
    });
    console.log({ updateDiasporasById, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }

    return { data: updateDiasporasById[0] } as UpdateResult;
  }
}
