import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { ProviderBase } from "./ProviderBase";
import { CreateParams, CreateResult, GetListParams, GetListResult, GetOneParams, GetOneResult, UpdateParams, DeleteParams, UpdateResult, DeleteResult } from "react-admin";
import { buildArgs } from "./utils";
import gql from "graphql-tag";

export class ServicesRewardProviderClass extends ProviderBase<any> {
    client: MobileServiceClient;

    constructor(client: MobileServiceClient) {
        super();
        this.client = client;
    }

    async getList(resource: string, params: GetListParams): Promise<GetListResult<any>> {
        const where: Record<string, any> = {};
        const { filter } = params
        const { page, perPage } = params.pagination;
        let { field, order } = params.sort;

        const args = buildArgs({
            order: order || "DESC",
            size: perPage,
            pageNumber: page,
            sort: field || "created_at",
        });

        if (filter.dateGte && filter.dateLte) {
            where['created_at'] = {
                _gte: filter.dateGte, _lte: filter.dateLte
            }
        }

        if (filter.delivery_mode) {
            where["delivery_mode"] = {
                _in: filter.delivery_mode
            }
        }

        if (filter.availability_by_service) {
            where['availability_by_service'] = {
                _has_keys_any: filter.availability_by_service
            }
        }

        const { allRewardCataloguesList, error } = await this.client.gql.query({
            query: gql`
                query allRewardCataloguesList($where: RewardCataloguesWhereInput) {
                    allRewardCataloguesList(where: $where, ${args}) {
                        data {
                            id
                            created_at
                            updated_at
                            item_detail
                            item_label
                            redemption_amount
                            redeem_limits
                            delivery_mode
                            availability_by_service
                            update_history
                        }
                        count
                    }
                }
            
            `,
            variables: {
                where
            }
        })

        if (error) {
            console.warn(error);
            throw new Error("GraphQL query error");
        }

        return {
            data: allRewardCataloguesList.data,
            total: allRewardCataloguesList.count,
        };
    }
    async create(resource: string, params: CreateParams<any>): Promise<CreateResult<any>> {
        const toBeCreatedData = {
            ...params.data
        }

        const queryResult = await this.client.gql.mutation({
            mutation: gql`
                mutation CreateRewardCatalogues($input: [RewardCataloguesCreateInput!]!) {
                    createRewardCatalogues(input: $input) {
                        id
                        created_at
                        updated_at
                        item_detail
                        item_label
                        redemption_amount
                        redeem_limits
                        delivery_mode
                        availability_by_service
                    }
                }
            `,
            variables: {
                input: toBeCreatedData
            }
        });

        if (queryResult.error) {
            throw new Error("Failed Create Reward");
        }

        return {
            data: queryResult.createRewardCatalogues
        } as CreateResult
    }
    async getOne(resource: string, params: GetOneParams<any>): Promise<GetOneResult<any>> {
        const queryResult = await this.client.gql.query({
            query: gql`
                query GetRewardCataloguesById($id: UUID ) {
                    getRewardCataloguesById(id: $id) {
                        id
                        created_at
                        updated_at
                        item_detail
                        item_label
                        redemption_amount
                        redeem_limits
                        update_history
                        delivery_mode
                        availability_by_service
                        is_available
                    }
                }
            
            `,
            variables: {
                id: params.id,
            },
        });

        return {
            data: queryResult?.getRewardCataloguesById,
        };
    }
    async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
        console.log('params', params);

        const { error, updateRewardCataloguesById } = await this.client.gql.mutation({
            mutation: `
                mutation UpdateRewardCataloguesById(
                    $ids: [UUID!]!
                    $input: RewardCataloguesUpdateInput!
                ) {
                    updateRewardCataloguesById(ids: $ids, input: $input) {
                        id
                        created_at
                        updated_at
                        item_detail
                        item_label
                        redemption_amount
                        redeem_limits
                        delivery_mode
                        availability_by_service
                        redeem_limits
                        is_available
                        author_id
                        update_history
                    }
                }
            `,
            variables: {
                ids: params.id,
                input: params?.data
            }
        })

        if (error) {
            throw new Error("Failed Update Reward");
        }

        return {
            data: updateRewardCataloguesById[0]
        } as UpdateResult;
    }
    async delete(resource: string, params: DeleteParams<any>): Promise<DeleteResult<any>> {
        const queryResult = await this.client.gql.mutation({
            mutation: gql`
                mutation ($ids: [UUID!]!) {
                    deleteRewardCataloguesById(ids: $ids)
                }
            `,
            variables: {
                ids: [`${params.id}`],
            },
        });

        return {
            data: queryResult?.deleteRewardCataloguesById,
        } as DeleteResult;
    }
}