import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
} from "react-admin";
import gql from "graphql-tag";
import { buildArgs } from "./utils";
import { ProviderBase } from "./ProviderBase";
export class MsisdnHistoriesProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.virtual_msisdn_id) {
      filterArray.push({
        virtual_msisdn_id: {
          _eq: params.filter.virtual_msisdn_id,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllMsisdnHistories($where: MsisdnHistoriesWhereInput) {
          allMsisdnHistoriesList(where: $where, ${args}) {
            data {
                id
                created_at
                updated_at
                virtual_msisdn_id
                user_id
                allocation_date
                termination_date
                termination_reason
                remark
                user_id_data {
                    id
                    email
                    name
                }
                virtual_msisdn_id_data {
                    id
                    msisdn
                }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allMsisdnHistoriesList?.data,
      total: queryResult?.allMsisdnHistoriesList?.count,
    } as GetListResult;
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    console.log({ method: "getManyReference", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params?.target === "virtual_msisdn_id") {
      filterArray.push({
        virtual_msisdn_id: {
          _eq: params.id,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllMsisdnHistories($where: MsisdnHistoriesWhereInput) {
          allMsisdnHistoriesList(where: $where, ${args}) {
            data {
                id
                created_at
                updated_at
                virtual_msisdn_id
                user_id
                allocation_date
                termination_date
                termination_reason
                remark
                user_id_data {
                    id
                    email
                    name
                }
                virtual_msisdn_id_data {
                    id
                    msisdn
                }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    console.log(
      3124,
      "queryResult?.allMsisdnHistoriesList?.data",
      queryResult?.allMsisdnHistoriesList?.data
    );

    return {
      data: queryResult?.allMsisdnHistoriesList?.data || [],
      total: queryResult?.allMsisdnHistoriesList?.count || [],
    } as GetManyReferenceResult;
  }
}
