import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DescriptionIcon from "@mui/icons-material/Description";
import { makeStyles } from "@mui/styles";
import {
  SimpleForm,
  Toolbar,
  useCreate,
  Error,
  useUpdate,
  Confirm,
  useRedirect,
  FormDataConsumer,
  SelectInput,
  required,
  DateTimeInput,
  useFormGroupContext,
  Button as Buttons,
  downloadCSV,
  useNotify,
  FileInput,
  FileField,
} from "react-admin";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Papa from "papaparse";
import jsonExport from "jsonexport/dist";
import mbaas from "../../../provider/Mbaas";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SendIcon from "@mui/icons-material/Send";
import ReactJson from "react-json-view";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  p: 4,
};

export default function InviteUserModal({ open, handleClose, props }: any) {
  const classes = useStyles();
  const [targetList, setTargetList] = React.useState<any>([
    { id: "", label: "" },
  ]);
  const [targetSearch, setTargetSearch] = React.useState("");
  const [value, setValue] = React.useState([]);
  const [targetType, setTargetType] = React.useState("");
  const [scheduledAt, setScheduledAt] = React.useState(null);
  const notify = useNotify();

  async function fetchTarget(account_type: any) {
    let res;
    let where: Record<string, any> = {};
    const filterArray: any[] = [];
    if ((account_type === "M21" || account_type === "SIM") && targetSearch) {
      filterArray.push({
        phone: {
          _ilike: `%${targetSearch}%`,
        },
      });
    }

    // if (account_type === "SIM" && targetSearch) {
    //   filterArray.push({
    //     msisdn: {
    //       _ilike: `%${targetSearch}%`,
    //     },
    //   });
    // }
    if (filterArray.length > 0) where._and = filterArray;

    switch (account_type) {
      case "SIM":
      case "M21":
        let { allUsersList } = await mbaas.client.gql.query({
          query: `
            query usersList ($where: UsersWhereInput) {
              allUsersList (limit:10, where:$where) {
                data {
                  id
                  phone
                }
                count
              }
            }
            `,
          variables: {
            where,
          },
        });

        const newTargetList = allUsersList?.data?.map((dat: any) => {
          return {
            id: dat.phone,
            label: dat.phone,
          };
        });

        setTargetList(newTargetList);
        break;
      // case "SIM":
      //   let { allVirtualMsisdnList } = await mbaas.client.gql.query({
      //     query: `
      //       query virtualMsisdnList($where:VirtualMsisdnWhereInput) {
      //         allVirtualMsisdnList(limit:10, where:$where) {
      //           data{
      //             id
      //             msisdn
      //           }
      //           count
      //         }
      //       }
      //       `,
      //     variables: {
      //       where,
      //     },
      //   });

      //   const newTargetList2 = allVirtualMsisdnList?.data?.map((dat: any) => {
      //     return {
      //       id: dat.msisdn,
      //       label: dat.msisdn,
      //     };
      //   });

      //   setTargetList(newTargetList2);
      // break;
      default:
        res = "default";
    }
  }

  async function fetchAllTarget(target_type: any) {
    console.log("FETCH ALL TARGET: ", target_type);

    let where: Record<string, any> = {};
    const filterArray: any[] = [
      {
        phone: {
          _is_null: false,
        },
      },
      {
        roles: {
          _has_keys_any: ["Subscriber"],
        },
      },
      // M21
      ...(targetType === "M21_ALL"
        ? [
          {
            is_m21: {
              _eq: true,
            },
          },
        ]
        : []),
      ...(targetType === "M21_HK"
        ? [
          {
            is_m21: {
              _eq: true,
            },
          },
          {
            country: {
              _eq: "HK",
            },
          },
        ]
        : []),
      ...(targetType === "M21_TW"
        ? [
          {
            is_m21: {
              _eq: true,
            },
          },
          {
            country: {
              _eq: "TW",
            },
          },
        ]
        : []),
      ...(targetType === "M21_MY"
        ? [
          {
            is_m21: {
              _eq: true,
            },
          },
          {
            country: {
              _eq: "MY",
            },
          },
        ]
        : []),
      ...(targetType === "M21_ID"
        ? [
          {
            is_m21: {
              _eq: true,
            },
          },
          {
            country: {
              _eq: "ID",
            },
          },
        ]
        : []),
      // G2
      ...(targetType === "G2_ALL"
        ? [
          {
            is_grapari: {
              _eq: true,
            },
          },
          {
            country: {
              _eq: "TW",
            },
          },
        ]
        : []),
      // GHK
      ...(targetType === "GHK_ALL"
        ? [
          {
            is_grapari: {
              _eq: true,
            },
          },
          {
            country: {
              _eq: "HK",
            },
          },
        ]
        : []),
      // HASH_TS
      ...(targetType === "HASH_TS"
        ? [
          {
            phone: {
              _in: ["601137627101", "85254259878"],
            },
          },
        ]
        : []),
    ];

    if (filterArray.length > 0) where._and = filterArray;

    let { allUsersList } = await mbaas.client.gql.query({
      query: `
        query usersList ($where: UsersWhereInput) {
          allUsersList(where: $where, limit: 99999999, offset: 0){
            data {
              id
              phone
              email
              virtual_msisdn_user_id_list_aggregate{
                count
              }
              is_m21
              is_grapari
              country
            }
            count
          }
        }
        `,
      variables: {
        where,
      },
    });

    let filteredData = [];

    if (targetType === "M21_ALL_NO_VN") {
      filteredData = allUsersList?.data
        ?.filter(
          (el: any) => el?.virtual_msisdn_user_id_list_aggregate?.count === 0
        )
        .filter((dt: any) => dt?.is_m21 === true);
    } else if (targetType === "M21_ALL_VN_ONLY") {
      filteredData = allUsersList?.data
        ?.filter(
          (el: any) => el?.virtual_msisdn_user_id_list_aggregate?.count > 0
        )
        .filter((dt: any) => dt?.is_m21 === true);
    } else {
      filteredData = allUsersList?.data;
    }

    console.log(filteredData, "RAW");

    const newTargetList = filteredData
      .map((dat: any) => {
        return {
          id: dat.phone,
          label: dat.phone,
        };
      })
      .filter(
        (el: any) =>
          el.label !== "0" &&
          !el.label.toString().startsWith("0") &&
          el.label !== "null" &&
          !el.label.toString().startsWith("+")
      );

    console.log(newTargetList, "SMACKDOWN");

    if (targetType !== "CUSTOM") {
      setValue(newTargetList);
    } else {
      setValue([]);
    }
  }

  React.useEffect(() => {
    fetchTarget(props?.account_type);
  }, [targetSearch]);

  React.useEffect(() => {
    fetchAllTarget(targetType);
  }, [targetType]);

  const newValue = value?.map((dat: any) => {
    return dat?.label;
  });

  const typeList = [
    { id: "M21_ALL", name: "M21 - ALL SUBSCRIBERS" },
    { id: "M21_HK", name: "M21 - HONG KONG SUBSCRIBERS" },
    { id: "M21_TW", name: "M21 - TAIWAN SUBSCRIBERS" },
    { id: "M21_MY", name: "M21 - MALAYSIA SUBSCRIBERS" },
    { id: "M21_ID", name: "M21 - INDONESIA SUBSCRIBERS" },
    { id: "M21_ALL_VN_ONLY", name: "M21 - ALL SUBSCRIBERS (HAVE VN)" },
    { id: "M21_ALL_NO_VN", name: "M21 - ALL SUBSCRIBERS (DO NOT HAVE VN)" },
    { id: "G2_ALL", name: "G2 - ALL SUBSCRIBERS" },
    { id: "GHK_ALL", name: "Grapari HK - ALL SUBSCRIBERS" },
    { id: "HASH_TS", name: "HASH - TS TEAM" },
    { id: "CUSTOM", name: "CUSTOM TARGET" },
  ];

  const [openCsvDialog, setOpenCsvDialog] = React.useState(false);
  const [fileCsv, setFileCsv] = React.useState<any>("");
  const [uploadedFileCsv, setUploadedFileCsv] = React.useState("");
  const [specificSubscriber, setSpecificSubscriber] = React.useState([]);

  const CustomToolbar = (props: any) => {
    const useStyles = makeStyles(() => ({
      toolbar: {
        display: "flex",
        justifyContent: "space-between",
      },
    }));
    let topic = {
      M21_ALL: "M21_ALL_SUBSCRIBERS",
      M21_HK: "M21_ALL_SUBSCRIBERS_HK",
      M21_TW: "M21_ALL_SUBSCRIBERS_TW",
      M21_MY: "M21_ALL_SUBSCRIBERS_MY",
      M21_ID: "M21_ALL_SUBSCRIBERS_ID",
      M21_ALL_VN_ONLY: "M21_VN_SUBSCRIBERS",
      M21_ALL_NO_VN: "M21_NON_VN_SUBSCRIBERS",
      G2_ALL: "G2_ALL_SUBSCRIBERS",
      GHK_ALL: "GHK_ALL_SUBSCRIBERS",
      HASH_TS: "HASH_TS",
      CUSTOM: "CUSTOM",
    };

    const [open2, setOpen2] = React.useState(false);
    const handleClick = () => setOpen2(true);
    const handleDialogClose = () => setOpen2(false);

    const redirect = useRedirect();

    const newObj = {
      title: props?.props?.title,
      content: props?.props?.content,
      scheduled_at: scheduledAt ? scheduledAt : null,
      url: props?.props?.custom_url
        ? props?.props?.custom_url
        : props?.props?.url,
      image: props?.props?.image,
      target: newValue,
    };

    const [create, { isLoading, error, isSuccess, data: newCreatedData }] =
      useCreate("promotionsBroadcast", {
        //@ts-ignore
        data: { ...newObj, topic: topic[targetType] },
      });

    React.useEffect(() => {
      if (isSuccess) {
        update();
        handleClose();
      }
    }, [isSuccess === true]);

    const [update, { isLoading: isLoading2, error: error2 }] = useUpdate(
      "promotionsBanner",
      {
        id: props?.props?.id,
        data: {
          id: props?.props?.id,
          broadcast_message_id: newCreatedData?.id,
        },
        previousData: props?.props,
      }
    );

    const handleSend = () => {
      create();
    };

    if (error) {
      // @ts-ignore
      return <Error />;
    }

    return (
      <Toolbar {...props} classes={useStyles}>
        <Button
          disabled={isLoading || targetType === null || targetType === ""}
          variant="contained"
          onClick={handleClick}
          startIcon={<SendIcon />}
        >
          Send
        </Button>
        <Confirm
          isOpen={open2}
          loading={isLoading}
          title="Important"
          content="By confirming this dialog it will automatically send SMS message to the target list, are you sure want to proceed?"
          onConfirm={handleSend}
          onClose={handleDialogClose}
        />
      </Toolbar>
    );
  };

  const handleCloseCsvDialog = () => {
    setOpenCsvDialog(false);
  };

  const handleOpenCsvDialog = () => {
    setOpenCsvDialog(true);
  };

  const handleUploadCsv = () => {
    const reader = new FileReader();
    reader.onload = async ({ target }: any) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns: any = parsedData
        .flat()
        .map((item: any) => item.phone)
        .filter((item: any) => item);
      if (!columns.length) {
        notify(`1st line in CSV file should be 'phone'!`, { type: "warning" });
        return;
      }
      const dataTarget = columns?.map((item: any) => ({
        id: item,
        label: item
      }))
      setSpecificSubscriber(columns);
      setValue(dataTarget);
      setFileCsv(null);
      notify(`CSV file uploaded successfully!`);
    };
    reader.readAsText(fileCsv);
  };

  const uploadedCsv = async () => {
    let filename = `spec-subs-phone`;
    if (uploadedFileCsv !== "") filename = uploadedFileCsv;
    let jsonObject = [{ phone: "" }];
    if (specificSubscriber?.length) {
      jsonObject = specificSubscriber.map((phone) => {
        return { phone: phone };
      });
    }
    jsonExport(jsonObject, {}, (err: any, csv: any) => {
      downloadCSV(csv, filename);
    });
  };

  const ToolbarCsvDialog = () => {
    return <></>;
  };

  console.log(123, "targetList", targetList);

  return (
    <div>
      <Dialog open={openCsvDialog} onClose={handleCloseCsvDialog}>
        <DialogTitle>CSV File</DialogTitle>
        <DialogContent dividers>
          <SimpleForm toolbar={<ToolbarCsvDialog />}>
            <Buttons
              startIcon={<DescriptionIcon />}
              onClick={uploadedCsv}
              label={
                specificSubscriber.length
                  ? "See Uploaded CSV"
                  : "Download Master CSV"
              }
              variant="outlined"
            />
            <br />
            {specificSubscriber.length ? (
              <DialogContentText>
                <ReactJson
                  src={specificSubscriber || []}
                  collapsed={true}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={true}
                  groupArraysAfterLength={10}
                  name={false}
                />
                <br />
              </DialogContentText>
            ) : (
              ""
            )}
            <FileInput
              source="files"
              options={{ multiple: false }}
              onChange={(e) => {
                setFileCsv(e);
                const filenameArray = e?.name.split(".");
                filenameArray?.pop();
                setUploadedFileCsv(filenameArray?.join("."));
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Buttons onClick={handleCloseCsvDialog} label="Close"></Buttons>
          <Buttons
            onClick={handleUploadCsv}
            startIcon={<UploadFileIcon />}
            label="Upload"
            autoFocus
            disabled={!fileCsv}
          />
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          setTargetType("")
          setSpecificSubscriber([]);
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" gutterBottom component="div">
              Please Select the Target
            </Typography>
            <SimpleForm toolbar={<CustomToolbar props={props} />}>
              <div className={classes.root}>
                <div className={classes.form}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <DateTimeInput
                        fullWidth
                        source="scheduled_at"
                        label="Schedule At"
                        onChange={(e) => {
                          setScheduledAt(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        fullWidth
                        className={classes.input}
                        source="type"
                        validate={[required()]}
                        choices={typeList}
                        // value={targetType}
                        // defaultValue={"ALL"}
                        label="Target"
                        onChange={(event) => {
                          setTargetType(event.target.value);
                        }}
                        data-test-id="country"
                      />
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <FormDataConsumer>
                        {({ formData }: any) =>
                          formData.type === "CUSTOM" && (
                            <Grid item style={{ marginBottom: '20px' }}>
                              <Buttons
                                label="CSV File"
                                data-testid="csv-file"
                                variant="contained"
                                onClick={handleOpenCsvDialog}
                              >
                                <PostAddIcon />
                              </Buttons>
                            </Grid>
                          )
                        }
                      </FormDataConsumer>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </SimpleForm>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
