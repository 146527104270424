import { createContext } from "react";

type MenuListType = {
  menuID: string;
  setMenuID: any;
  purpose: string;
  setPurpose: any;
};
export const MenuListContext = createContext<MenuListType>({
  menuID: "",
  setMenuID: null,
  purpose: "",
  setPurpose: null,
});
