// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import {
  SimpleForm,
  required,
  useListContext,
  Create,
  useNotify,
  useRedirect,
  SelectInput,
  TextInput,
  useGetList,
  ImageInput,
  ImageField,
  Loading,
  Error,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  useDataProvider,
  CreateParams,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useMutation } from "react-query";
import _ from "lodash";

const CustomToolbarNoDelete = ({ dataForm, extData }: any) => {
  console.log(dataForm, "HEHE");

  const tempFormData = Object.keys(dataForm).forEach(
    (key) => !dataForm[key] && delete dataForm[key]
  );

  const notify = useNotify();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();
  const { mutate, isLoading, isSuccess } = useMutation(() =>
    dataProvider.create("diasporaNews", {
      data: { ...dataForm, priority: extData?.length + 1 },
    })
  );
  const [eq, setEq] = useState(true);
  useEffect(() => {
    const equal = _.isEqual(dataForm, {});
    setEq(equal);
  }, [dataForm]);

  useEffect(() => {
    if (isSuccess) {
      notify("ra.notification.created", "info", { smart_count: 1 });
      redirect("/diasporaNews");
    }
  }, [isSuccess]);
  return (
    <Toolbar classes={useStyles()}>
      <SaveButton
        onClick={(event) => {
          if (Object.keys(dataForm).length === 5) {
            event.preventDefault();
            mutate();
          }
        }}
        data-test-id="save_btn"
        disabled={eq}
      />
    </Toolbar>
  );
};

const CreatePage = (props: any) => {
  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/diasporaNews");
  };

  const countryList = [
    { id: "GLOBAL", name: "Worldwide" },
    { id: "HK", name: "Hong Kong" },
    { id: "ID", name: "Indonesia" },
    { id: "MY", name: "Malaysia" },
    { id: "TW", name: "Taiwan" },
  ];
  const { data, isLoading, error } = useGetList("diasporaNews");
  // const postDefaultValue = () => ({ total: data?.total });

  const [dataForm, setDataForm] = useState({});
  return (
    <Create
      {...props}
      title="Diaspora Latest News &gt; Add"
      mutationOptions={{
        onSuccess: onSuccess,
      }}
    >
      <SimpleForm
        toolbar={
          <CustomToolbarNoDelete
            dataForm={dataForm}
            extData={!isLoading && !error && data}
          />
        }
      >
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  data-test-id="country_select"
                  fullWidth
                  className={classes.input}
                  source="country_code"
                  validate={[required("Required")]}
                  choices={countryList}
                  label="Country"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  data-test-id="title_input"
                  fullWidth
                  validate={[required("Required")]}
                  source="title"
                  label="Title"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  data-test-id="showtime_input"
                  fullWidth
                  validate={[required("Required")]}
                  source="showTime"
                  label="Show Time"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  data-test-id="url_input"
                  fullWidth
                  validate={[required("Required")]}
                  source="url"
                  label="URL"
                />
              </Grid>
              <Grid item xs={6}>
                <ImageInput
                  source="thumbnail"
                  validate={[required("Required")]}
                  label="Thumbnail"
                  accept="image/*"
                  options={{ multiple: false }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Grid>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setDataForm(formData);
                }}
              </FormDataConsumer>
              {/* <Box flex={1} mr='0.5em' display="none"> */}
              {/* <NumberInput source={'total'} label='Line Count' fullWidth /> */}
              {/* </Box> */}
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));

export default CreatePage;
