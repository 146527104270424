// @ts-nocheck
import {
  Loading,
  Authenticated,
  useGetOne,
  GetOneParams
} from "react-admin";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React from "react";

const Dashboard = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const {
    data: dataDailySummary,
    isLoading: loadingDailySummary,
    error: errorDailySummary,
  } = useGetOne("dashboard", { id: 41 } as GetOneParams);
  const {
    data: dataMonthlySummary,
    isLoading: loadingMonthlySummary,
    error: errorMonthlySummary,
  } = useGetOne("dashboard", { id: 42 } as GetOneParams);
  const {
    data: dataFinanceSummary,
    isLoading: loadingFinanceSummary,
    error: errorFinanceSummary,
  } = useGetOne("dashboard", { id: 43 } as GetOneParams);
  const {
    data: dataOperationalSummary,
    isLoading: loadingOperationalSummary,
    error: errorOperationalSummary,
  } = useGetOne("dashboard", { id: 44 } as GetOneParams);
  if (
    loadingDailySummary ||
    loadingMonthlySummary ||
    loadingFinanceSummary ||
    loadingOperationalSummary
  ) {
    return <Loading />;
  }
  if (
    errorDailySummary ||
    errorMonthlySummary ||
    errorFinanceSummary ||
    errorOperationalSummary
  ) {
    console.log({
      error:
        errorDailySummary ||
        errorMonthlySummary ||
        errorFinanceSummary ||
        errorOperationalSummary,
    });
  }


  return (
    <Authenticated>
      {/* <Box
        sx={{
          width: "100%",
          height: "100vh",
          typography: "body1",
          padding: "15px",
        }}
      >
        {dataDashboard && dataDashboard.metabaseUrl && (
          <iframe
            title="Overall"
            frameBorder={0}
            src={dataDashboard.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </Box> */}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Daily Summary" value="1" />
            <Tab label="Monthly Summary" value="2" />
            <Tab label="Finance Summary" value="3" />
            <Tab label="Operational Summary" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ height: "100%", padding: "0px" }}>
          {dataDailySummary && dataDailySummary.metabaseUrl && (
            <iframe
              frameBorder={0}
              src={dataDailySummary.metabaseUrl}
              width="100%"
              height="100%"
              allowTransparency
            />
          )}
        </TabPanel>
        <TabPanel value="2" style={{ height: "100%", padding: "0px" }}>
          {dataMonthlySummary && dataMonthlySummary.metabaseUrl && (
            <iframe
              frameBorder={0}
              src={dataMonthlySummary.metabaseUrl}
              width="100%"
              height="100%"
              allowTransparency
            />
          )}
        </TabPanel>
        <TabPanel value="3" style={{ height: "100%", padding: "0px" }}>
          {dataFinanceSummary && dataFinanceSummary.metabaseUrl && (
            <iframe
              frameBorder={0}
              src={dataFinanceSummary.metabaseUrl}
              width="100%"
              height="100%"
              allowTransparency
            />
          )}
        </TabPanel>
        <TabPanel value="4" style={{ height: "100%", padding: "0px" }}>
          {dataOperationalSummary && dataOperationalSummary.metabaseUrl && (
            <iframe
              frameBorder={0}
              src={dataOperationalSummary.metabaseUrl}
              width="100%"
              height="100%"
              allowTransparency
            />
          )}
        </TabPanel>
      </TabContext>
    </Authenticated>
  );
};

export default Dashboard;
