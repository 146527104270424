// @ts-nocheck
import React, { useEffect } from "react";
import { TextField, Show, FunctionField, DateField } from "react-admin";
import { byIso } from "country-code-lookup";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import humanizeDuration from "humanize-duration";

export const VoiceLogDetail = ({ onCancel, ...props }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const renderCountry = (req_country) => {
    try {
      const contryData = byIso(req_country);
      return (
        <>
          <strong>{req_country}</strong>
          &nbsp;-&nbsp;<label>{contryData?.country}</label>
        </>
      );
    } catch (err) {
      return (
        <>
          <strong>{req_country}</strong>
          &nbsp;-&nbsp;
          <label>{req_country}</label>
        </>
      );
    }
  };

  return (
    <Show resource="voiceLogs" title=" &rsaquo; Detail" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        mt={{ xs: 2, sm: 1 }}
        ml={{ xs: 2, sm: 1 }}
        mr={{ xs: 2, sm: 1 }}
        minHeight="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Card elevation={0}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Detail
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4} marginTop={2}>
                <strong>Date</strong>
              </Grid>
              <Grid item xs={8} marginTop={2}>
                <DateField
                  source="created_at"
                  label="Date"
                  showTime
                  locales="en-HK"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>A Number</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => (
                    <>
                      <strong>{record.a_name}</strong>
                      <br />
                      <label>{record.a_number}</label>
                    </>
                  )}
                  source="a_number"
                  label="A Number"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>B Number</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => (
                    <>
                      <strong>{record.b_name}</strong>
                      <br />
                      <label>{record.b_number}</label>
                    </>
                  )}
                  source="=b_number"
                  label="B Number"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Direction</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="direction" />
              </Grid>
              <Grid item xs={4}>
                <strong>Status</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="status" />
              </Grid>
              <Grid item xs={4}>
                <strong>Begin</strong>
              </Grid>
              <Grid item xs={8}>
                <DateField source="begin" showTime locales="en-HK" />
              </Grid>
              <Grid item xs={4}>
                <strong>End</strong>
              </Grid>
              <Grid item xs={8}>
                <DateField source="end" showTime locales="en-HK" />
              </Grid>
              <Grid item xs={4}>
                <strong>Duration (Second)</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => record.duration}
                  source="duration"
                  label="Duration"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Cost</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="hkd_cost" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card elevation={0}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Service Cost
            </Typography>
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={4}>
                <strong>Version</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="service_rate_id_data.version" />
              </Grid>
              <Grid item xs={4}>
                <strong>Rate Label</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => record.service_rate_id_data.label}
                  source="service_rate_id_data.label"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Origin Country</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) =>
                    renderCountry(record.service_rate_id_data.origin_country)
                  }
                  source="service_rate_id_data.origin_country"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Destination Country</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) =>
                    renderCountry(
                      record.service_rate_id_data.destination_country
                    )
                  }
                  source="service_rate_id_data.destination_country"
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Rate</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="service_rate_id_data.rate" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Show>
  );
};
