import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import { GetListParams, GetListResult, GetManyParams, GetManyResult, GetOneParams, GetOneResult } from 'react-admin';
import gql from 'graphql-tag';
import { ProviderBase } from './ProviderBase';
import { buildArgs } from './utils';
export class ProductCatalogsItemGroupProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log({ method: 'getList', resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    // filterArray.push({
    //   is_mvno: {
    //     _eq: false,
    //   },
    // });

    if (params.filter.isMvno) {
      filterArray.push({
        is_mvno: {
          _eq: true,
        },
      });
    } else {
      filterArray.push({
        is_mvno: {
          _eq: false,
        },
      });
    }
    if (params.filter.isVas === true) {
      filterArray.push({
        is_vas: {
          _eq: true,
        },
      });
    } else if (params.filter.isVas === false) {
      filterArray.push({
        is_vas: {
          _eq: false,
        },
      });
    }
    if (params.filter.itemGroup) {
      filterArray.push({
        item_group: {
          _eq: params.filter.itemGroup,
        },
      });
    }
    if (params.filter.itemKey) {
      filterArray.push({
        item_key: {
          _ilike: `%${params.filter.itemKey}%`,
        },
      });
    }
    if (params.filter.q) {
      filterArray.push({
        item_group: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    const args = buildArgs({
      // @ts-ignore
      order: 'ASC',
      size: perPage,
      pageNumber: page,
      sort: 'item_group',
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query ProductCatalogsItemGroupList($where: ProductCatalogsWhereInput) {
          allProductCatalogsList(where: $where, ${args}) {
            data {
              id
              item_group
              label
              item_key
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    console.log({
      method: 'getList',
      resource,
      params,
      returnedData: queryResult?.allProductCatalogsList?.data.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.item_group === v.item_group) === i),
    });
    return {
      data: queryResult?.allProductCatalogsList?.data.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.item_group === v.item_group) === i),
      total: queryResult?.allProductCatalogsList?.count,
    };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    console.log({ method: 'getMany', resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          id: {
            _in: params.ids,
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query ProductCatalogsItemGroupList($where: ProductCatalogsWhereInput) {
          allProductCatalogsList(where: $where) {
            data {
              id
              item_group
              label
              item_key
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allProductCatalogsList?.data || [],
    };
  }

  async getOne(resource: string, params: GetOneParams<any>): Promise<GetOneResult<any>> {
    console.log({ method: 'getOne', resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query aServiceProduct($id: UUID!) {
          getProductCatalogsById(id: $id) {
            id
            item_group
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: queryResult?.getProductCatalogsById };
  }
}
