import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IsmsLog {
  smsLogFilter: {
    virtual_msisdn_id: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: IsmsLog = {
  smsLogFilter: {
    virtual_msisdn_id: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type smsLogFilter = {
  virtual_msisdn_id: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const smsLogFilterSlice = createSlice({
  name: "thisSmsLogFilter",
  initialState,
  reducers: {
    setSmsLogFilter: (state, action: PayloadAction<smsLogFilter>) => {
      state.smsLogFilter = {
        ...state.smsLogFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSmsLogFilter } = smsLogFilterSlice.actions;

export const getSmsLogFilter = (state: RootState) =>
  state.thisSmsLogFilter.smsLogFilter;

export default smsLogFilterSlice.reducer;
