/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import mbaas from "../../provider/Mbaas";
import QRCodeView from "qrcode.react";
import { Key } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// @ts-ignore
import Zoom from "react-img-zoom";
// @ts-ignore
import createHash from "crypto-browserify";
import axios from "axios";
import { randomBytes, secretbox } from "tweetnacl";
import {
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  useNotify,
  useRefresh,
} from "react-admin";
import FilerobotImageEditor from "react-filerobot-image-editor";
import { ReactPanZoom } from "../RaPhotoPan";

const dummyimg = [
  "https://www.imigrasi.go.id/wp-content/uploads/2019/07/13-32-19-timthumb.jpeg",
  "https://cdn.dribbble.com/users/289900/screenshots/4610643/selfie1.jpg",
];
export default function FormKycDialogPassport({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
}) {
  const [token, setToken] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imgzoom, setImgzoom] = useState<boolean>(false);
  const [imgtarget, setImgtarget] = useState<string>("");
  const [imgblob, setImgblob] = useState("");
  const [imgblobSelfie, setImgblobSelfie] = useState("");

  const [imgblobdefault, setImgblobdefault] = useState("");
  const [imgblobSelfiedefault, setImgblobSelfiedefault] = useState("");
  // const [status, setStatus] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const [uPassportB64, setUPassportB64] = useState("");
  const [uSelfieB64, setUSelfieB64] = useState("");
  // const registrasiData = data?.registrations_user_id_list?.data[0];
  const [passportData, setpassData] = useState(
    data?.passports_registration_id_list?.data[0]
  );

  useEffect(() => {
    console.log(data, "ALOHA1234");

    directDecryptFile();
    directDecryptFileSelfie();
  }, [data]);

  useEffect(() => {
    setpassData(data?.passports_registration_id_list?.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const changeValue = (value: any, names: any) => {
    setpassData({
      ...passportData,
      [names]: value,
    });
  };

  const onB64Load = (fileString: any, type: any) => {
    if (type === "selfie") {
      setUSelfieB64(fileString);
    } else {
      setUPassportB64(fileString);
    }
  };

  // @ts-ignore
  const toBase64 = async (file, callback) => {
    const reader = new FileReader();

    let result = "";

    reader.readAsDataURL(file);
    reader.onload = async () => {
      // Use a regex to remove data url part

      // reader.result.replace("data:", "").replace(/^.+,/, "");
      // onB64Load(reader.result.replace("data:", "").replace(/^.+,/, ""), type);

      // if (type === "selfie") {
      // 	// @ts-ignore
      // 	setUSelfieB64(reader.result);
      // } else {
      // 	setUPassportB64(reader.result);
      // }

      // @ts-ignore
      result = reader.result;
      await callback(result);
      //   console.log(result, "KMZWA");
    };
  };

  const submitForm = async (e: any, status: boolean) => {
    console.log(status, "PASSDATA", passportData);
    try {
      const dataX = {
        token: token,
        data: {
          ...passportData,
          remark: remark,
          status:
            e === "CANCELED" ? "CANCELED" : status ? "APPROVED" : "REJECTED",
        },
      };
      const req = await mbaas.client.invokeApi(
        "console-update-registration-account",
        dataX
      );
      console.log(req);

      if (req.error) {
        if (req.error.error) {
          notify(req.error.error, { type: "warning" });
        } else {
          notify("Please fill all input form", { type: "warning" });
        }
        return;
      }

      if (updateImagePassport != null) {
        await toBase64(updateImagePassport, async (passportb64: any) => {
          console.log(passportb64, "PB64");

          console.log(
            "PASSPHOTO: ",
            data?.passports_registration_id_list?.data[0]
          );

          const secret = data.user_id_data.secret + data.user_id_data.id;
          const key = createHash.createHash("sha256").update(secret).digest();
          const nonce = randomBytes(secretbox.nonceLength);
          const binaryString = window.atob(passportb64?.split(",")[1]);
          const len = binaryString.length;
          const bytesImage = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytesImage[i] = binaryString.charCodeAt(i);
          }
          const ciphertext = secretbox(bytesImage, nonce, key);
          const mergedtext = new Uint8Array(nonce.length + ciphertext.length);
          mergedtext.set(nonce);
          mergedtext.set(ciphertext, nonce.length);
          const uploadData = await mbaas.client.storage.upload({
            params: {
              filename:
                data?.passports_registration_id_list?.data[0]?.document_id,
              bucket: "registrations",
              expire: "20",
            },
            file: mergedtext,
          });
        });

        // console.log(uploadData, 5758);
      }

      if (updateImagePassportSelfie != null) {
        await toBase64(
          updateImagePassportSelfie,
          async (passportSelfieb64: any) => {
            console.log(passportSelfieb64, "PB64");

            console.log(
              "PASSPHOTO: ",
              data?.passports_registration_id_list?.data[0]
            );

            const secret = data.user_id_data.secret + data.user_id_data.id;
            const key = createHash.createHash("sha256").update(secret).digest();
            const nonce = randomBytes(secretbox.nonceLength);
            const binaryString = window.atob(passportSelfieb64?.split(",")[1]);
            const len = binaryString.length;
            const bytesImage = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytesImage[i] = binaryString.charCodeAt(i);
            }
            const ciphertext = secretbox(bytesImage, nonce, key);
            const mergedtext = new Uint8Array(nonce.length + ciphertext.length);
            mergedtext.set(nonce);
            mergedtext.set(ciphertext, nonce.length);
            const uploadData = await mbaas.client.storage.upload({
              params: {
                filename:
                  data?.passports_registration_id_list?.data[0]?.selfie_id,
                bucket: "registrations",
                expire: "20",
              },
              file: mergedtext,
            });
          }
        );

        // console.log(uploadData, 5758);
      }

      notify("Success update registration", { type: "success" });
      setRemark("");
      setToken("");

      setUpdateImagePassport(null);
      setImgblob(imgblobdefault);
      // @ts-ignore
      hiddenFileInput.current.value = "";
      setUpdateImagePassportSelfie(null);
      setImgblobSelfie(imgblobSelfiedefault);
      // @ts-ignore
      hiddenFileInputSelfie.current.value = "";
      setOpen(false);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const submitFormUpdate = async (e: any, status: string) => {
    console.log(status, "PASSDATA", passportData);
    try {
      const dataX = {
        token: token,
        data: {
          ...passportData,
          remark: remark,
          status: e === "CANCELED" ? "CANCELED" : status,
        },
      };
      const req = await mbaas.client.invokeApi(
        "console-update-registration-account",
        dataX
      );
      console.log(req);

      if (req.error) {
        if (req.error.error) {
          notify(req.error.error, { type: "warning" });
        } else {
          notify("Please fill all input form", { type: "warning" });
        }
        return;
      }

      if (updateImagePassport != null) {
        await toBase64(updateImagePassport, async (passportb64: any) => {
          console.log(passportb64, "PB64");

          console.log(
            "PASSPHOTO: ",
            data?.passports_registration_id_list?.data[0]
          );

          const secret = data.user_id_data.secret + data.user_id_data.id;
          const key = createHash.createHash("sha256").update(secret).digest();
          const nonce = randomBytes(secretbox.nonceLength);
          const binaryString = window.atob(passportb64?.split(",")[1]);
          const len = binaryString.length;
          const bytesImage = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytesImage[i] = binaryString.charCodeAt(i);
          }
          const ciphertext = secretbox(bytesImage, nonce, key);
          const mergedtext = new Uint8Array(nonce.length + ciphertext.length);
          mergedtext.set(nonce);
          mergedtext.set(ciphertext, nonce.length);
          const uploadData = await mbaas.client.storage.upload({
            params: {
              filename:
                data?.passports_registration_id_list?.data[0]?.document_id,
              bucket: "registrations",
              expire: "20",
            },
            file: mergedtext,
          });
        });

        // console.log(uploadData, 5758);
      }

      if (updateImagePassportSelfie != null) {
        await toBase64(
          updateImagePassportSelfie,
          async (passportSelfieb64: any) => {
            console.log(passportSelfieb64, "PB64");

            console.log(
              "PASSPHOTO: ",
              data?.passports_registration_id_list?.data[0]
            );

            const secret = data.user_id_data.secret + data.user_id_data.id;
            const key = createHash.createHash("sha256").update(secret).digest();
            const nonce = randomBytes(secretbox.nonceLength);
            const binaryString = window.atob(passportSelfieb64?.split(",")[1]);
            const len = binaryString.length;
            const bytesImage = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytesImage[i] = binaryString.charCodeAt(i);
            }
            const ciphertext = secretbox(bytesImage, nonce, key);
            const mergedtext = new Uint8Array(nonce.length + ciphertext.length);
            mergedtext.set(nonce);
            mergedtext.set(ciphertext, nonce.length);
            const uploadData = await mbaas.client.storage.upload({
              params: {
                filename:
                  data?.passports_registration_id_list?.data[0]?.selfie_id,
                bucket: "registrations",
                expire: "20",
              },
              file: mergedtext,
            });
          }
        );

        // console.log(uploadData, 5758);
      }

      notify("Success update registration", { type: "success" });
      setRemark("");
      setToken("");

      setUpdateImagePassport(null);
      setImgblob(imgblobdefault);
      // @ts-ignore
      hiddenFileInput.current.value = "";
      setUpdateImagePassportSelfie(null);
      setImgblobSelfie(imgblobSelfiedefault);
      // @ts-ignore
      hiddenFileInputSelfie.current.value = "";
      setOpen(false);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const decryptFile = async (fileid: string) => {
    setLoading(true);
    setImgzoom(true);
    try {
      // console.log("====================================");
      // console.log(data);
      // console.log("====================================");
      const secretUser = data.user_id_data.secret;
      const idUser = data.user_id_data.id;

      const secret = secretUser + idUser;

      const key = createHash.createHash("sha256").update(secret).digest();
      const decUrl = await mbaas.client.storage.downloadUrl({
        params: {
          bucket: "registrations",
          expire: "20",
        },
        fileId: fileid,
      });

      // console.log(decUrl);
      const { data: mergedtext } = await axios.request({
        method: "get",
        url: decUrl.data.url,
        responseType: "arraybuffer",
      });
      // console.log(mergedtext);
      const nonce = mergedtext.slice(0, secretbox.nonceLength);
      const ciphertext = mergedtext.slice(
        secretbox.nonceLength,
        mergedtext.length
      );
      // console.log("====================================");
      // console.log("chiperText");
      // console.log(ciphertext);
      // const encodedString = Buffer.from(ciphertext).toString("base64");
      // console.log(encodedString);

      // console.log("nonce");
      // console.log(nonce);

      // console.log("key");
      // console.log(key);
      // const encodedStrings = Buffer.from(key).toString("base64");
      // console.log(encodedStrings);

      // console.log("====================================");
      var chpr = new Uint8Array(ciphertext);
      var iv = new Uint8Array(nonce);

      const plaintext = secretbox.open(chpr, iv, key);
      // const plaintext = secretbox.open(ciphertext, nonce, key);

      if (plaintext) {
        // console.log("plaintext");
        // console.log(plaintext);
        const base64Plain = Buffer.from(plaintext).toString("base64");
        // console.log(base64Plain);

        // const content = new Uint8Array([
        //   137, 80, 78, 71, 13, 10, 26, 10, 0, 0, 0, 13, 73, 72, 68, 82, 0, 0, 0,
        //   5, 0, 0, 0, 5, 8, 6, 0, 0, 0, 141, 111, 38, 229, 0, 0, 0, 28, 73, 68,
        //   65, 84, 8, 215, 99, 248, 255, 255, 63, 195, 127, 6, 32, 5, 195, 32,
        //   18, 132, 208, 49, 241, 130, 88, 205, 4, 0, 14, 245, 53, 203, 209, 142,
        //   14, 31, 0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130,
        // ]);
        // const example = await URL.createObjectURL(
        //   new Blob([content.buffer], { type: "image/jpeg" })
        // );

        const blobUrl = await (window.URL ? URL : webkitURL).createObjectURL(
          new Blob([plaintext], { type: "image/jpeg" })
        );

        // console.log(blobUrl);

        // as blob
        setImgblobSelfie(blobUrl);

        // as data
        // setImgblob("data:image/jpeg;base64," + base64Plain);
      } else {
        throw new Error("decryption failed");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const directDecryptFile = async () => {
    setLoading(true);
    // setImgzoom(true);
    try {
      // console.log("====================================");
      // console.log(data);
      // console.log("====================================");
      const secretUser = data.user_id_data.secret;
      const idUser = data.user_id_data.id;

      const secret = secretUser + idUser;

      const key = createHash.createHash("sha256").update(secret).digest();
      const decUrl = await mbaas.client.storage.downloadUrl({
        params: {
          bucket: "registrations",
          expire: "20",
        },
        fileId: data?.passports_registration_id_list?.data[0]?.document_id,
      });

      // console.log(decUrl);
      const { data: mergedtext } = await axios.request({
        method: "get",
        url: decUrl.data.url,
        responseType: "arraybuffer",
      });
      // console.log(mergedtext);
      const nonce = mergedtext.slice(0, secretbox.nonceLength);
      const ciphertext = mergedtext.slice(
        secretbox.nonceLength,
        mergedtext.length
      );
      // console.log("====================================");
      // console.log("chiperText");
      // console.log(ciphertext);
      // const encodedString = Buffer.from(ciphertext).toString("base64");
      // console.log(encodedString);

      // console.log("nonce");
      // console.log(nonce);

      // console.log("key");
      // console.log(key);
      // const encodedStrings = Buffer.from(key).toString("base64");
      // console.log(encodedStrings);

      // console.log("====================================");
      var chpr = new Uint8Array(ciphertext);
      var iv = new Uint8Array(nonce);

      const plaintext = secretbox.open(chpr, iv, key);
      // const plaintext = secretbox.open(ciphertext, nonce, key);

      if (plaintext) {
        // console.log("plaintext");
        // console.log(plaintext);
        const base64Plain = Buffer.from(plaintext).toString("base64");
        // console.log(base64Plain);

        // const content = new Uint8Array([
        //   137, 80, 78, 71, 13, 10, 26, 10, 0, 0, 0, 13, 73, 72, 68, 82, 0, 0, 0,
        //   5, 0, 0, 0, 5, 8, 6, 0, 0, 0, 141, 111, 38, 229, 0, 0, 0, 28, 73, 68,
        //   65, 84, 8, 215, 99, 248, 255, 255, 63, 195, 127, 6, 32, 5, 195, 32,
        //   18, 132, 208, 49, 241, 130, 88, 205, 4, 0, 14, 245, 53, 203, 209, 142,
        //   14, 31, 0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130,
        // ]);
        // const example = await URL.createObjectURL(
        //   new Blob([content.buffer], { type: "image/jpeg" })
        // );

        const blobUrl = await (window.URL ? URL : webkitURL).createObjectURL(
          new Blob([plaintext], { type: "image/*" })
        );

        // console.log(blobUrl);

        // as blob
        setImgblob(blobUrl);
        setImgblobdefault(blobUrl);
        // as data
        // setImgblob("data:image/jpeg;base64," + base64Plain);
      } else {
        throw new Error("decryption failed");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const directDecryptFileSelfie = async () => {
    setLoading(true);
    // setImgzoom(true);
    try {
      // console.log("====================================");
      // console.log(data);
      // console.log("====================================");
      const secretUser = data.user_id_data.secret;
      const idUser = data.user_id_data.id;

      const secret = secretUser + idUser;

      const key = createHash.createHash("sha256").update(secret).digest();
      const decUrl = await mbaas.client.storage.downloadUrl({
        params: {
          bucket: "registrations",
          expire: "20",
        },
        fileId: data?.passports_registration_id_list?.data[0]?.selfie_id,
      });

      // console.log(decUrl);
      const { data: mergedtext } = await axios.request({
        method: "get",
        url: decUrl.data.url,
        responseType: "arraybuffer",
      });
      // console.log(mergedtext);
      const nonce = mergedtext.slice(0, secretbox.nonceLength);
      const ciphertext = mergedtext.slice(
        secretbox.nonceLength,
        mergedtext.length
      );
      // console.log("====================================");
      // console.log("chiperText");
      // console.log(ciphertext);
      // const encodedString = Buffer.from(ciphertext).toString("base64");
      // console.log(encodedString);

      // console.log("nonce");
      // console.log(nonce);

      // console.log("key");
      // console.log(key);
      // const encodedStrings = Buffer.from(key).toString("base64");
      // console.log(encodedStrings);

      // console.log("====================================");
      var chpr = new Uint8Array(ciphertext);
      var iv = new Uint8Array(nonce);

      const plaintext = secretbox.open(chpr, iv, key);
      // const plaintext = secretbox.open(ciphertext, nonce, key);

      if (plaintext) {
        // console.log("plaintext");
        // console.log(plaintext);
        const base64Plain = Buffer.from(plaintext).toString("base64");
        // console.log(base64Plain);

        // const content = new Uint8Array([
        //   137, 80, 78, 71, 13, 10, 26, 10, 0, 0, 0, 13, 73, 72, 68, 82, 0, 0, 0,
        //   5, 0, 0, 0, 5, 8, 6, 0, 0, 0, 141, 111, 38, 229, 0, 0, 0, 28, 73, 68,
        //   65, 84, 8, 215, 99, 248, 255, 255, 63, 195, 127, 6, 32, 5, 195, 32,
        //   18, 132, 208, 49, 241, 130, 88, 205, 4, 0, 14, 245, 53, 203, 209, 142,
        //   14, 31, 0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130,
        // ]);
        // const example = await URL.createObjectURL(
        //   new Blob([content.buffer], { type: "image/jpeg" })
        // );

        const blobUrl = await (window.URL ? URL : webkitURL).createObjectURL(
          new Blob([plaintext], { type: "image/*" })
        );

        // console.log(blobUrl);

        // as blob
        setImgblobSelfie(blobUrl);
        setImgblobSelfiedefault(blobUrl);

        // as data
        // setImgblob("data:image/jpeg;base64," + base64Plain);
      } else {
        throw new Error("decryption failed");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const [updateImagePassport, setUpdateImagePassport] = useState(null);
  const [updateImagePassportSelfie, setUpdateImagePassportSelfie] =
    useState(null);

  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputSelfie = React.useRef(null);

  const handleClick = (event: any) => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleClickSelfie = (event: any) => {
    // @ts-ignore
    hiddenFileInputSelfie.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    // console.log(fileUploaded, 666);
    setUpdateImagePassport(fileUploaded);
    setImgblob(URL.createObjectURL(fileUploaded));
  };

  const handleChangeSelfie = (event: any) => {
    const fileUploaded = event.target.files[0];
    setUpdateImagePassportSelfie(fileUploaded);
    setImgblobSelfie(URL.createObjectURL(fileUploaded));
  };

  return (
    <div>
      <Dialog
        open={imgzoom}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">File Detail</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loading ? (
              "LOADING . . ."
            ) : (
              <img
                src={imgblobSelfie}
                alt="imgpreview"
                style={{ width: 900 }}
              />
              // <FilerobotImageEditor
              // 	// @ts-ignore
              // 	show={false}
              // 	source={imgblob}
              // 	onClose={() => {}}
              // 	onBeforeSave={(_savedImageDataBefore) => false}
              // 	onSave={async (editedImageObject, _designState) => {
              // 		const secret = data?.user_id_data?.secret + data?.user_id_data?.id;
              // 		const key = createHash.createHash("sha256").update(secret).digest();
              // 		const nonce = randomBytes(secretbox.nonceLength);
              // 		const binaryString = window.atob(
              // 			editedImageObject.imageBase64?.split(",")[1] as string
              // 		);
              // 		const len = binaryString.length;
              // 		const bytesImage = new Uint8Array(len);
              // 		for (let i = 0; i < len; i++) {
              // 			bytesImage[i] = binaryString.charCodeAt(i);
              // 		}
              // 		const ciphertext = secretbox(bytesImage, nonce, key);
              // 		const mergedtext = new Uint8Array(nonce.length + ciphertext.length);
              // 		mergedtext.set(nonce);
              // 		mergedtext.set(ciphertext, nonce.length);
              // 		const uploadData = await mbaas.client.storage.upload({
              // 			params: {
              // 				filename: data?.passports_registration_id_list?.data[0]?.document_id,
              // 				bucket: "registrations",
              // 				expire: "20",
              // 			},
              // 			file: mergedtext,
              // 		});
              // 	}}
              // />
              // <img src={imgblob} alt="imgpreview" style={{ width: 900 }} />

              // <Zoom img={imgblob} zoomScale={2} width={900} height={500} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setImgzoom(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            <Key /> Form KYC Account
          </Grid>
        </DialogTitle>
        {/* <form onSubmit={(e) => submitForm(e)}> */}
        <form onSubmit={(e) => e.preventDefault()}>
          <DialogContent>
            <DialogContentText>
              {passportData?.country_code !== "IDN" && (
                <Grid container xs={12} style={{ marginBlockEnd: "10px" }}>
                  <Grid item xs={12}>
                    <Alert severity="error">
                      <AlertTitle>
                        The current user is <strong>NOT eligible</strong> to
                        have a Virtual Number (VN). Please check the{" "}
                        <strong>KYC Data!</strong>
                      </AlertTitle>
                    </Alert>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  xs={6}
                >
                  <div style={{ marginInlineEnd: 10 }}>Passport Image</div>
                  <>
                    <Button
                      style={{ width: "auto", textTransform: "none" }}
                      variant="contained"
                      onClick={handleClick}
                    >
                      Update Passport Image
                    </Button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </>
                  {updateImagePassport && (
                    <Button
                      style={{
                        width: "auto",
                        textTransform: "none",
                        marginInlineStart: 10,
                      }}
                      variant="outlined"
                      onClick={() => {
                        setUpdateImagePassport(null);
                        setImgblob(imgblobdefault);
                        // @ts-ignore
                        hiddenFileInput.current.value = "";
                      }}
                    >
                      Reset Image
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  Data checking for pending subscriber registration
                </Grid>
              </Grid>
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBlockStart: 10,
                  }}
                >
                  {loading ? (
                    "LOADING . . ."
                  ) : (
                    // <img src={imgblob} alt="imgpreview" style={{ width: 500 }} />
                    <div
                      style={{
                        width: 600,
                        maxWidth: 600,
                        maxHeight: 300,
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <ReactPanZoom image={imgblob} alt="imgpreview" />
                    </div>
                    // <Zoom img={imgblob} zoomScale={2} width={900} height={500} />
                  )}
                </div>
                <DialogContentText style={{ marginBlockStart: 20 }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      xs={12}
                    >
                      <div style={{ marginInlineEnd: 10 }}>
                        Passport Selfie Image
                      </div>
                      <>
                        <Button
                          style={{ width: "auto", textTransform: "none" }}
                          variant="contained"
                          onClick={handleClickSelfie}
                        >
                          Update Passport Selfie Image
                        </Button>
                        <input
                          type="file"
                          ref={hiddenFileInputSelfie}
                          onChange={handleChangeSelfie}
                          style={{ display: "none" }}
                        />
                      </>
                      {updateImagePassportSelfie && (
                        <Button
                          style={{
                            width: "auto",
                            textTransform: "none",
                            marginInlineStart: 10,
                          }}
                          variant="outlined"
                          onClick={() => {
                            setUpdateImagePassportSelfie(null);
                            setImgblobSelfie(imgblobSelfiedefault);
                            // @ts-ignore
                            hiddenFileInputSelfie.current.value = "";
                          }}
                        >
                          Reset Image
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </DialogContentText>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBlockStart: 10,
                  }}
                >
                  {loading ? (
                    "LOADING . . ."
                  ) : (
                    // <img src={imgblob} alt="imgpreview" style={{ width: 500 }} />
                    <div
                      style={{
                        width: 600,
                        maxWidth: 600,
                        maxHeight: 300,
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <ReactPanZoom image={imgblobSelfie} alt="imgpreview" />
                    </div>
                    // <Zoom img={imgblob} zoomScale={2} width={900} height={500} />
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <TextField
                    label="Country code"
                    value={passportData?.country_code || ""}
                    onChange={(e) =>
                      changeValue(e.target.value, "country_code")
                    }
                    required
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <TextField
                    label="Passport number"
                    value={passportData?.passport_number || ""}
                    onChange={(e) =>
                      changeValue(e.target.value, "passport_number")
                    }
                    required
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <TextField
                    label="Fullname"
                    value={passportData?.full_name || ""}
                    onChange={(e) => changeValue(e.target.value, "full_name")}
                    required
                    fullWidth
                  />
                  {/* <Box width={"32px"} /> */}
                  {/* <FormControl variant="filled" sx={{ minWidth: 120 }} fullWidth>
										<InputLabel id="demo-simple-select-filled-label">
										Sex
										</InputLabel>
										<Select
										labelId="demo-simple-select-filled-label"
										id="demo-simple-select-filled"
										value={passportData?.sex || "L"}
										onChange={(e) => changeValue(e.target.value, "sex")}
										fullWidth
										// onChange={handleChange}
										>
										<MenuItem value={"P"}>Perempuan</MenuItem>
										<MenuItem value={"L"}>Laki Laki</MenuItem>
										</Select>
									</FormControl> */}
                  {/* <TextField
										label="Citizenship"
										value={passportData?.citizenship || ""}
										onChange={(e) => changeValue(e.target.value, "citizenship")}
										required
										fullWidth
									/> */}
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <TextField
                    label="Citizenship"
                    value={passportData?.citizenship || ""}
                    onChange={(e) => changeValue(e.target.value, "citizenship")}
                    required
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <Box width={"32px"} />
                  {/* <TextField
										id="date"
										label="Date of birth"
										type="date"
										value={passportData?.date_of_birth || ""}
										onChange={(e) => changeValue(e.target.value, "date_of_birth")}
										fullWidth
										InputLabelProps={{
										shrink: true,
										}}
									/> */}
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  {/* <TextField
										label="Place of birth"
										value={passportData?.place_of_birth || ""}
										onChange={(e) => changeValue(e.target.value, "place_of_birth")}
										required
										fullWidth
									/> */}

                  <TextField
                    id="date"
                    label="Date of expiry"
                    type="date"
                    fullWidth
                    value={passportData?.date_of_expiry || ""}
                    onChange={(e) =>
                      changeValue(e.target.value, "date_of_expiry")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                {/* <Box
									style={{ marginBlockStart: 10, marginBlockEnd: 20 }}
									sx={{
										display: "flex",
										justifyContent: "space-between",
										flexDirection: "row",
										width: "100%",
									}}
								>
									<>
										<Button
											style={{ width: "100%", textTransform: "none" }}
											variant="contained"
											onClick={handleClick}
										>
											Update Passport Image
										</Button>
										<input
											type="file"
											ref={hiddenFileInput}
											onChange={handleChange}
											style={{ display: "none" }}
										/>
									</>

									<Box width={"32px"} />
									<>
										<Button
											style={{ width: "100%", textTransform: "none" }}
											variant="contained"
											onClick={handleClickSelfie}
										>
											Update Passport Selfie Image
										</Button>
										<input
											type="file"
											ref={hiddenFileInputSelfie}
											onChange={handleChangeSelfie}
											style={{ display: "none" }}
										/>
									</>
								</Box> */}
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  {/* <TextField
										label="Registration Number"
										value={passportData?.registration_number || ""}
										onChange={(e) =>
										changeValue(e.target.value, "registration_number")
										}
										required
										fullWidth
									/> */}
                  <Box width={"32px"} />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  {/* <TextField
										label="Issuing Office"
										value={passportData?.issuing_office || ""}
										onChange={(e) => changeValue(e.target.value, "issuing_office")}
										required
										fullWidth
									/> */}
                  <TextField
                    label="Remark (Note)"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    required
                    fullWidth
                  />
                  <Box width={"32px"} />

                  <TextField
                    label="Token"
                    value={token}
                    required
                    fullWidth
                    onChange={(e) => setToken(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* <div style={{ margin: "20px 0px 10px 0px" }}>Registration File</div> */}
            <Grid container direction="row" alignItems="center" gap={1}>
              {/* <Button
								size="small"
								variant="contained"
								onClick={() => decryptFile(passportData?.document_id)}
								disabled={
									passportData?.document_id === null ||
									passportData?.document_id === undefined
								}
							>
								Document
							</Button> */}
              {/* <Button
								size="small"
								variant="contained"
								onClick={() => decryptFile(passportData?.selfie_id)}
								disabled={
									passportData?.selfie_id === null ||
									passportData?.selfie_id === undefined
								}
							>
								Selfie
							</Button> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container direction="row" justifyContent="space-between">
              <Button
                onClick={() => {
                  setUpdateImagePassport(null);
                  setImgblob(imgblobdefault);
                  // @ts-ignore
                  hiddenFileInput.current.value = "";
                  setUpdateImagePassportSelfie(null);
                  setImgblobSelfie(imgblobSelfiedefault);
                  // @ts-ignore
                  hiddenFileInputSelfie.current.value = "";
                  setOpen(false);
                }}
                // disabled={loading}
                color="primary"
                size="small"
              >
                Close
              </Button>
              {data?.status === "PENDING" ? (
                <Grid item gap={2}>
                  <Button
                    style={{ marginRight: "20px", backgroundColor: "#e02828" }}
                    color="secondary"
                    variant="contained"
                    size="small"
                    type="submit"
                    startIcon={<CancelIcon />}
                    onClick={(e) => submitForm(e, false)}
                  >
                    REJECT
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    type="submit"
                    startIcon={<CheckCircleIcon />}
                    onClick={(e) => submitForm(e, true)}
                  >
                    ACCEPT
                  </Button>
                </Grid>
              ) : data?.status === "APPROVED" ? (
                <Grid item gap={2}>
                  <Button
                    style={{ marginRight: "20px", backgroundColor: "#e02828" }}
                    color="secondary"
                    variant="contained"
                    size="small"
                    type="submit"
                    startIcon={<CancelIcon />}
                    onClick={(e) => submitForm("CANCELED", false)}
                  >
                    CANCEL REGISTRATION
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    type="submit"
                    startIcon={<CheckCircleIcon />}
                    onClick={(e) => {
                      submitFormUpdate(e, data?.status);
                    }}
                  >
                    UPDATE DATA
                  </Button>
                </Grid>
              ) : (
                <Grid item gap={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    type="submit"
                    startIcon={<CheckCircleIcon />}
                    onClick={(e) => {
                      submitFormUpdate(e, data?.status);
                    }}
                  >
                    UPDATE DATA
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
