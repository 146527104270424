import * as React from "react";
import {
  Show,
  TextInput,
  SimpleForm,
  FunctionField,
  RaRecord,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ReactJson from "react-json-view";

export const ExecutionLogsShow = ({ ...props }) => {
  return (
    <Show resource="executionLogs" title=" &rsaquo; Detail" {...props}>
      <Box width="50vW" ml={2} mr={2} data-testid="show-detail">
        <SimpleForm toolbar={false}>
          <Grid container spacing={2}>
            <Box>
              <OverrideTextInputWhenDisabled
                source="type"
                label="Type"
                fullWidth
                disabled
              />
              <OverrideTextInputWhenDisabled
                source="stage"
                label="Stage"
                fullWidth
                disabled
              />
              <OverrideTextInputWhenDisabled
                source="reference_id"
                label="Reference ID"
                fullWidth
                disabled
              />
              <OverrideTextInputWhenDisabled
                source="status"
                label="status"
                fullWidth
                disabled
              />

              <>
                <Typography mb={1}>Reference Detail</Typography>
                <FunctionField
                  label="Reference Detail"
                  render={(record: RaRecord) => (
                    <div>
                      <label>
                        <ReactJson
                          src={record.reference_detail || {}}
                          name={false}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                        />
                      </label>
                    </div>
                  )}
                />
              </>

              <div style={{ marginBottom: 25 }}>
                <Typography mb={1} mt={3}>
                  Normal Detail
                </Typography>
                <FunctionField
                  label="Normal Detail"
                  render={(record: RaRecord) => (
                    <div>
                      <label>
                        <ReactJson
                          src={record.normal_detail || {}}
                          name={false}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                        />
                      </label>
                    </div>
                  )}
                />
              </div>

              <div style={{ marginBottom: 25 }}>
                <Typography mb={1} mt={3}>
                  Exception Detail
                </Typography>
                <FunctionField
                  label="Exception Detail"
                  render={(record: RaRecord) => (
                    <div>
                      <label>
                        <ReactJson
                          src={record.exception_detail || {}}
                          name={false}
                          enableClipboard={false}
                          displayDataTypes={false}
                          displayObjectSize={false}
                        />
                      </label>
                    </div>
                  )}
                />
              </div>
            </Box>
          </Grid>
        </SimpleForm>
      </Box>
    </Show>
  );
};

const OverrideTextInputWhenDisabled = withStyles({
  root: {
    "& .MuiFilledInput-input.Mui-disabled": {
      "-webkit-text-fill-color": "black", // (default alpha is 0.38)
      "background-color": "white", // (default alpha is 0.38)
    },
  },
})(TextInput);
