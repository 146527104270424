import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
export class VoiceLogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    if (params.filter.q) {
      where._or = [
        {
          counterparty_msisdn: {
            _ilike: `%${params.filter.q}%`,
          },
        },
        {
          subscriber_id_data: {
            phone: { _ilike: `%${params.filter.q}%` },
          },
        },
        {
          subscriber_id_data: {
            virtual_msisdn_user_id_list: {
              msisdn: { _ilike: `%${params.filter.q}%` },
            },
          },
        },
      ];
    }
    if (params.filter.dateGte || params.filter.dateLte) {
      where._and = [
        {
          created_at: {
            _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
          },
        },
        {
          created_at: {
            _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
          },
        },
      ];
    }
    if (params.filter.number) {
      where._or = [
        {
          counterparty_msisdn: {
            _ilike: `%${params.filter.number}%`,
          },
        },
        {
          subscriber_id_data: {
            phone: { _ilike: `%${params.filter.number}%` },
          },
        },
        {
          subscriber_id_data: {
            virtual_msisdn_user_id_list: {
              msisdn: { _ilike: `%${params.filter.number}%` },
            },
          },
        },
      ];
    }
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
      query voiceCdrList($where:VoiceCdrWhereInput) {
        allVoiceCdrList(
          where: $where, ${args}
          ){
          data{
            id
            created_at
            direction
            service_rate_id_data {
              destination_country
              rate
              version
              is_active
              label
              origin_country
            }
            subscriber_id_data{
              phone
              name
              virtual_msisdn_user_id_list{
                data {
                  msisdn
                }
              }
            }
            counterparty_msisdn
            status
            hkd_cost
            begin
            end
            duration
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    const counterpartyName = await this.fetchCounterPartyData(
      queryResult?.allVoiceCdrList?.data
    );
    const data = queryResult?.allVoiceCdrList?.data.map(async (x: any) => {
      let vn =
        x.subscriber_id_data.virtual_msisdn_user_id_list.data[0]?.msisdn || "";
      if (vn === "") {
        vn = await this.fetchMissingVn(x.subscriber_id_data.phone, x.end);
      }
      let a_number = "";
      let a_name = "";
      let b_number = "";
      let b_name = "";
      if (x.direction === "MO") {
        a_number = vn;
        a_name = x.subscriber_id_data.name;
        b_number = x.counterparty_msisdn;
        b_name = counterpartyName[x.counterparty_msisdn];
      } else {
        a_number = x.counterparty_msisdn;
        a_name = counterpartyName[x.counterparty_msisdn];
        b_number =
          x.subscriber_id_data.virtual_msisdn_user_id_list.data[0]?.msisdn ||
          "-";
        b_name = x.subscriber_id_data.name;
      }
      return { ...x, a_number, b_number, a_name, b_name, number: vn };
    });
    const returnedData = await Promise.all(data);
    return {
      data: returnedData,
      total: queryResult?.allVoiceCdrList?.count,
    };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });
    const where: Record<string, any> = {};
    if (params.ids) {
      where._or = [
        {
          counterparty_msisdn: {
            _in: params.ids,
          },
        },
        {
          subscriber_id_data: {
            virtual_msisdn_user_id_list: {
              msisdn: { _in: params.ids },
            },
          },
        },
      ];
    }
    const queryResult = await this.client.gql.query({
      query: gql`
        query voiceCdrList($where: VoiceCdrWhereInput) {
          allVoiceCdrList(where: $where) {
            data {
              id
              created_at
              direction
              service_rate_id_data {
                destination_country
                rate
                version
                is_active
                label
                origin_country
              }
              subscriber_id_data {
                phone
                name
                virtual_msisdn_user_id_list {
                  data {
                    msisdn
                  }
                }
              }
              counterparty_msisdn
              status
              hkd_cost
              begin
              end
              duration
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    const counterpartyName = await this.fetchCounterPartyData(
      queryResult?.allVoiceCdrList?.data
    );
    const data = queryResult?.allVoiceCdrList?.data.map(async (x: any) => {
      let vn =
        x.subscriber_id_data.virtual_msisdn_user_id_list.data[0]?.msisdn || "";
      if (vn === "") {
        vn = await this.fetchMissingVn(x.subscriber_id_data.phone, x.end);
      }
      let a_number = "";
      let a_name = "";
      let b_number = "";
      let b_name = "";
      if (x.direction === "MO") {
        a_number = vn;
        a_name = x.subscriber_id_data.name;
        b_number = x.counterparty_msisdn;
        b_name = counterpartyName[x.counterparty_msisdn];
      } else {
        a_number = x.counterparty_msisdn;
        a_name = counterpartyName[x.counterparty_msisdn];
        b_number =
          x.subscriber_id_data.virtual_msisdn_user_id_list.data[0].msisdn ||
          "-";
        b_name = x.subscriber_id_data.name;
      }
      return { ...x, a_number, b_number, a_name, b_name, number: vn };
    });
    const returnedData = await Promise.all(data);
    return {
      data: returnedData,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const queryResult = await this.client.gql.query({
      query: gql`
        query voiceCdrById($id: UUID) {
          getVoiceCdrById(id: $id) {
            id
            created_at
            direction
            service_rate_id_data {
              destination_country
              rate
              version
              is_active
              label
              origin_country
            }
            subscriber_id_data {
              phone
              name
              virtual_msisdn_user_id_list {
                data {
                  msisdn
                }
              }
            }
            counterparty_msisdn
            status
            hkd_cost
            begin
            end
            duration
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });
    const cdrData = queryResult?.getVoiceCdrById;
    const counterpartyName = await this.fetchCounterPartyData([cdrData]);
    let vn =
      cdrData.subscriber_id_data.virtual_msisdn_user_id_list.data[0]?.msisdn ||
      "";
    if (vn === "") {
      vn = await this.fetchMissingVn(
        cdrData.subscriber_id_data.phone,
        cdrData.end
      );
    }
    let a_number = "";
    let a_name = "";
    let b_number = "";
    let b_name = "";
    if (cdrData.direction === "MO") {
      a_number = vn;
      a_name = cdrData.subscriber_id_data.name;
      b_number = cdrData.counterparty_msisdn;
      b_name = counterpartyName[cdrData.counterparty_msisdn];
    } else {
      a_number = cdrData.counterparty_msisdn;
      a_name = counterpartyName[cdrData.counterparty_msisdn];
      b_number =
        cdrData.subscriber_id_data.virtual_msisdn_user_id_list.data[0].msisdn ||
        "-";
      b_name = cdrData.subscriber_id_data.name;
    }
    return {
      data: {
        ...cdrData,
        a_number,
        b_number,
        a_name,
        b_name,
      },
    };
  }

  async fetchCounterPartyData(cdrData: object[]) {
    const listOfCounterpartyMSISDN = new Set();
    cdrData.forEach((x: any) =>
      listOfCounterpartyMSISDN.add(x.counterparty_msisdn)
    );
    let counterpartyName: { [key: string]: string } = {};
    if (listOfCounterpartyMSISDN) {
      const whereParty: Record<string, any> = {};
      whereParty._or = [
        {
          phone: {
            _in: Array.from(listOfCounterpartyMSISDN),
          },
        },
        {
          virtual_msisdn_user_id_list: {
            msisdn: {
              _in: Array.from(listOfCounterpartyMSISDN),
            },
          },
        },
      ];
      const partyResults = await this.client.gql.query({
        query: gql`
          query partyList($where: UsersWhereInput) {
            allUsersList(where: $where) {
              data {
                name
                phone
                virtual_msisdn_user_id_list {
                  data {
                    msisdn
                  }
                }
              }
            }
          }
        `,
        variables: {
          where: whereParty,
        },
      });
      partyResults?.allUsersList?.data.forEach(
        (element: {
          phone: string;
          name: string;
          virtual_msisdn_user_id_list: { data: { msisdn: string }[] };
        }) => {
          counterpartyName[element?.phone] = element.name;
          counterpartyName[
            element?.virtual_msisdn_user_id_list?.data[0]?.msisdn
          ] = element.name;
        }
      );
    }
    //
    return counterpartyName || [];
  }

  async fetchMissingVn(userPhone: string, endCallDate: string) {
    let missingVn = "";
    const where: Record<string, any> = {};
    where._and = [
      {
        created_at: {
          _gt: moment(endCallDate).toISOString(),
        },
      },
      {
        register_msisdn: {
          _eq: userPhone,
        },
      },
      {
        mode: {
          _eq: "DEPROVISION",
        },
      },
    ];
    const args = buildArgs({
      // @ts-ignore
      order: "ASC",
      sort: "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query missingVns($where: ProvisionResultsWhereInput) {
          allProvisionResultsList(where: $where, ${args}) {
            data {
              tsel_msisdn
            }
          }
        }
      `,
      variables: {
        where,
      },
    });
    if (queryResult?.allProvisionResultsList?.data.length) {
      missingVn = queryResult?.allProvisionResultsList?.data[0].tsel_msisdn;
    }
    return missingVn;
  }
}
