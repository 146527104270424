import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
import { LexoRank } from "lexorank";
import mbaas from "../provider/Mbaas";
export class PromotionsBannerProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    const dateNow = Date.now();
    console.log({ method: "getListNYA", resource, params });
    // if (params.filter.dateGte || params.filter.dateLte) {
    //   filterArray.push({
    //     created_at: {
    //       _gte: moment(params.filter.dateGte).startOf("day").toISOString(),
    //     },
    //   });
    //   filterArray.push({
    //     created_at: {
    //       _lte: moment(params.filter.dateLte).endOf("day").toISOString(),
    //     },
    //   });
    // }
    if (params.filter.type === "ACTIVE") {
      filterArray.push({
        date_start: {
          _lte: moment(dateNow).toISOString(),
        },
      });
      filterArray.push({
        date_end: {
          _gte: moment(dateNow).toISOString(),
        },
      });
    } else if (params.filter.type === "INACTIVE") {
      filterArray.push({
        date_start: {
          _gte: moment(dateNow).toISOString(),
        },
      });
    } else {
      filterArray.push({
        date_end: {
          _lte: moment(dateNow).toISOString(),
        },
      });
    }

    if (params.filter.country) {
      filterArray.push({
        country: {
          _eq: params.filter.country,
        },
      });
    }
    if (params.filter.account_type) {
      filterArray.push({
        account_type: {
          _eq: params.filter.account_type,
        },
      });
    }
    if (params.filter.category) {
      filterArray.push({
        category: {
          _eq: params.filter.category,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    console.log("MANAA", where);

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      size: perPage,
      pageNumber: page,
      // order: order || "DESC",
      // sort: field || "created_at",
      sort: "priority",
      order: "ASC",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query PromotionsBannerList($where: PromotionalBannersWhereInput) {
          allPromotionalBannersList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              title
              content
              image
              url
              country
              date_start
              date_end
              category
              account_type
              author_id
              priority
              broadcast_message_id
              broadcast_message_id_data{
                id
                content
                scheduled_at
              }
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allPromotionalBannersList?.data,
      total: queryResult?.allPromotionalBannersList?.count,
    };
  }

  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    const rawImage = params?.data?.urlThumbnail;

    const response: any = await this.client.storage.upload({
      file: rawImage.rawFile,
      params: {
        filename: `bannersPromo-${Date.now()}`,
        bucket: "banners",
      },
    });

    const download = await this.client.storage.downloadUrl({
      params: {
        bucket: "banners",
      },
      fileId: response?.data.id,
    });

    const urlImg = download.data.url.split("?");

    const { data } = await this.client.user.get();

    const newKey = LexoRank.middle().toString();

    const submittedData = {
      category: params?.data?.category,
      country: params?.data?.country_code,
      image: urlImg[0],
      content: params?.data.content,
      title: params?.data?.title,
      url:
        params?.data?.url === "custom_url"
          ? params?.data?.custom_url
          : params?.data?.url,
      account_type: params?.data?.account_type,
      date_start: params?.data?.date_start,
      date_end: params?.data?.date_end,
      author_id: data?.id,
      priority: newKey,
    };

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [PromotionalBannersCreateInput!]!) {
          createPromotionalBanners(input: $input) {
            id
            title
            content
            image
            url
            country
            date_start
            date_end
            category
            account_type
            author_id
            broadcast_message_id
          }
        }
      `,
      variables: {
        input: submittedData,
      },
    });

    return { data: queryResult?.createPromotionalBanners[0] } as CreateResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const { data } = await this.client.user.get();

    let inputData = {
      category: params?.data?.category,
      country: params?.data?.country,
      content: params?.data.content,
      title: params?.data?.title,
      url:
        params?.data?.url === "custom_url"
          ? params?.data?.custom_url
          : params?.data?.url,
      date_start: params?.data?.date_start,
      account_type: params?.data?.account_type,
      date_end: params?.data?.date_end,
      broadcast_message_id: params?.data?.broadcast_message_id,
      author_id: data?.id,
    };

    if (params.data.urlThumbnail) {
      console.log("new photo");
      const rawImage = params?.data?.urlThumbnail;
      const response: any = await this.client.storage.upload({
        file: rawImage.rawFile,
        params: {
          filename: `bannersPromo-${Date.now()}-${params?.data?.priority}`,
          bucket: "banners",
        },
      });

      const download = await this.client.storage.downloadUrl({
        params: {
          bucket: "banners",
        },
        fileId: response?.data.id,
      });

      const urlImg = download.data.url.split("?");

      console.log(response);
      const contentEdit = {
        ...inputData,
        image: urlImg[0],
      };

      inputData = {
        ...contentEdit,
        // content: contentEdit,
      };
    }

    const { updatePromotionalBannersById, error } =
      await this.client.gql.mutation({
        mutation: `
      mutation updatePromoBanner($ids: [UUID!]!, $input: PromotionalBannersUpdateInput!){
        updatePromotionalBannersById(ids: $ids, input: $input){
            id
            title
            content
            image
            url
            country
            date_start
            date_end
            category
            account_type
            author_id
            broadcast_message_id
          }
        }
      `,
        variables: {
          ids: params.data.id,
          input: inputData,
        },
      });

    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }

    return { data: updatePromotionalBannersById[0] } as UpdateResult;
  }

  async getOne(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<any>> {
    const queryResult = await this.client.gql.query({
      query: gql`
        query getOnePromoBanner($id: UUID!) {
          getPromotionalBannersById(id: $id) {
            id
            created_at
            updated_at
            title
            content
            image
            url
            country
            date_start
            date_end
            category
            account_type
            author_id
            author_id_data {
              id
              email
              name
            }
            broadcast_message_id
            broadcast_message_id_data {
              id
              content
              scheduled_at
            }
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

      const resp = await mbaas.client.invokeApi("console-webview-link-list", {
        country: queryResult?.getPromotionalBannersById?.country,
        platform: [queryResult?.getPromotionalBannersById?.account_type],
      });
    
      resp.data.push({ url: "custom_url", label: "CUSTOM URL" });

    const ifValueExist = (value: any) => {
      return resp.data.some((el: any) => {
        return el.url === value;
      });
    };

    let custom_url;

    if (!ifValueExist(queryResult?.getPromotionalBannersById?.url)) {
      custom_url = queryResult?.getPromotionalBannersById?.url;

      if (queryResult?.getPromotionalBannersById?.url !== undefined) {
        // @ts-ignore
        queryResult.getPromotionalBannersById["url"] = "custom_url";
      }
    }

    return {
      data: { ...queryResult?.getPromotionalBannersById, custom_url },
    } as GetOneResult;
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    console.log({ method: "delete", resource, params });
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!) {
          deletePromotionalBannersById(ids: $ids)
        }
      `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return {
      data: queryResult?.deletePromotionalBannersById[0],
    } as DeleteResult;
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }
}
