import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { CreateParams, CreateResult } from "react-admin";
import Mbaas from "./Mbaas";
import { ProviderBase } from "./ProviderBase";
export class OverrideProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    console.log(params, 9090909090);
    const { data } = await Mbaas.client.invokeApi("console-validate-totp", {
      token: params.data.totp,
    });
    console.log({ data });

    if (data === "OK") {
      let payload: Record<string, any> = {
        transaction_id: params.data.id,
        status: params.data.status,
        reason: params.data.reason,
        data: params.data.additional_data,
      };
      if (params.data.status === "ALTER") {
        payload = {
          ...payload,
          newItemKey: params.data.item_key,
          newTarget: params.data.newTarget,
          newOpts: { userId: params.data.userId, zoneId: params.data.zoneId },
        };
      }
      await Mbaas.client
        .invokeApi("console-override-transaction", payload)
        .then((response) => {
          console.log({ response });

          if (response?.error) {
            throw new Error(
              `Override Error: ${
                response.error?.error ? response.error?.error : response.error
              }`
            );
          }
          return response;
        });
    } else {
      throw new Error("TOTP Invalid");
    }
    return { data: params.data } as CreateResult;
  }
}
