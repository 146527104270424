import { Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { Key } from "@mui/icons-material";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  SearchInput,
  ChipField,
  BooleanField,
  usePermissions,
  EditButton,
  ExportButton,
  FilterButton,
  TopToolbar,
  FunctionField,
  useRecordContext,
  useNotify,
} from "react-admin";
import InviteUserDialog from "./InviteUserDialog";
import ResetPassDialog from "./ResetPasswordDialog";
import mbaas from "../../provider/Mbaas";
const listFilters = [<SearchInput source="keyword" alwaysOn />];

const ListPage = () => {
  const { isLoading, permissions } = usePermissions();
  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [reset, setReset] = React.useState<string>("");
  const notify = useNotify();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      {isAdmin && (
        <Button
          startIcon={<PersonAddAltRoundedIcon />}
          style={{ padding: "1px 10px" }}
          onClick={() => setOpen(true)}
        >
          Invite
        </Button>
      )}
      <ExportButton />
    </TopToolbar>
  );

  const resetPassRequest = async (email: string) => {
    console.log(email);
    setReset(email);
    try {
      const forget = await mbaas.client.auth.forgotPassword(email);
      console.log(forget);
      notify(`Success send email to ${email} for reset password`, {
        type: "success",
      });
    } catch (e) {
      notify("Something went wrong", { type: "warning" });
    }
    setReset("");
  };

  const ResetPass = (props: any) => {
    const record = useRecordContext(props);
    return (
      <Button
        variant="contained"
        size="small"
        startIcon={record.email === reset ? "" : <Key />}
        color="primary"
        disabled={record.email === reset}
        onClick={() => resetPassRequest(record.email)}
      >
        {record.email === reset ? (
          <CircularProgress style={{ width: "25px", height: "25px" }} />
        ) : (
          "Reset"
        )}
      </Button>
    );
  };
  ResetPass.defaultProps = { label: "Password", addLabel: true };

  return (
    <React.Fragment>
      <List
        filters={listFilters}
        perPage={25}
        sort={{ field: "created_at", order: "ASC" }}
        debounce={1200}
        actions={<ListActions />}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="email" />
          <TextField source="name" />
          <ChipField source="roles" sortable={false} />
          <BooleanField source="verified" label="Verified" sortable={false} />
          <FunctionField
            source="deactivated_at"
            label="Blocked"
            render={(
              record: { deactivated_at: any },
              source: string | undefined
            ) => (
              <BooleanField
                record={{ ...record, deactivated_at: !!record.deactivated_at }}
                source={source}
              />
            )}
          />
          <DateField source="created_at" showTime />
          {isAdmin && <ResetPass />}
          {isAdmin && <EditButton />}
        </Datagrid>
      </List>
      <InviteUserDialog open={open} setOpen={setOpen} />
      <ResetPassDialog open={open1} setOpen={setOpen1} />
    </React.Fragment>
  );
};

export default ListPage;
