import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class ServicesTariffsClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log(555, { method: "getList", resource, params });

    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.service_type === "SMS") {
      filterArray.push({
        service_type: {
          _eq: params.filter.service_type,
        },
      });
    }

    if (params.filter.service_type === "VOICE") {
      filterArray.push({
        service_type: {
          _eq: params.filter.service_type,
        },
      });
    }

    filterArray.push({
      is_active: {
        _eq: true,
      },
    });

    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: 999999,
      pageNumber: page,
      sort: field || "updated_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query ServicesTariffsList($where: ServiceRatesWhereInput) {
          allServiceRatesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              service_type
              destination_country
              origin_country
              label
              direction
              group_prefix
              group_pattern
              rate
              author
              version
              is_active
              author_data {
                email
                name
              }
              destination_country_calling_code
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allServiceRatesList?.data || [],
      total: queryResult?.allServiceRatesList?.count || 0,
    };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log({ method: "getMany", resource, params });

    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    console.log({ method: "getManyReference", resource, params });

    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "updated_at",
    });

    if (params.target === "SMS") {
      filterArray.push({
        service_type: {
          _eq: params.target,
        },
      });
      const queryResult = await this.client.gql.query({
        query: gql`
        query ServicesTariffsList($where: ServiceRatesWhereInput) {
          allServiceRatesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              service_type
              destination_country
              origin_country
              label
              direction
              group_prefix
              group_pattern
              rate
              author
              version
              is_active
            }
            count
          }
        }
      `,
        variables: {
          where,
        },
      });

      return {
        data: queryResult?.allServiceRatesList?.data || [],
        total: queryResult?.allServiceRatesList?.count || 0,
      };
    }

    if (params.target === "VOICE") {
      filterArray.push({
        service_type: {
          _eq: params.target,
        },
      });
      const queryResult = await this.client.gql.query({
        query: gql`
        query ServicesTariffsList($where: ServiceRatesWhereInput) {
          allServiceRatesList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              service_type
              destination_country
              origin_country
              label
              direction
              group_prefix
              group_pattern
              rate
              author
              version
              is_active
            }
            count
          }
        }
      `,
        variables: {
          where,
        },
      });

      return {
        data: queryResult?.allServiceRatesList?.data || [],
        total: queryResult?.allServiceRatesList?.count || 0,
      };
    }

    return {
      data: [],
      total: 0,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    return { data: "" };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log({ method: "update", resource, params });

    return { data: "" } as UpdateResult;
  }
}
