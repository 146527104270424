// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  useListContext,
  FunctionField,
  TextField,
} from "react-admin";
import { format } from "date-fns";

const ListPage = (props) => {
  const listData = useListContext(props);

  return (
    <List {...props} title="Diasporas Emergency">
      <Datagrid
        data-test-id="datagrid_emergency"
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField source="country_code" label="Country" />
        <FunctionField
          render={(record) => {
            return record?.content?.label;
          }}
          source="content"
          label="Title"
        />
        <FunctionField
          render={(record) => {
            return format(new Date(record?.updated_at), "dd-MM-yyyy");
          }}
          source="updated_at"
          label="Last Update"
        />
        {/* <RichTextField source="question" stripTags={true}/> */}
        {/* <RichTextField source="answer" stripTags={true} /> */}
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  );
};

export default ListPage;
