import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { ProviderBase } from "./ProviderBase";
import { CreateParams, CreateResult, GetListParams, GetListResult, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from "react-admin";
import { buildArgs } from "./utils";
import gql from "graphql-tag";

export class PointRulesProviderClass extends ProviderBase<any>{
    client: MobileServiceClient;

    constructor(client: MobileServiceClient){
        super();
        this.client = client;
    }
    async getList(resource: string, params: GetListParams): Promise<GetListResult<any>> {
        console.log({ method: "getList", resource, params });
        const where: Record<string, any> = {};
        const { filter } = params
        const { page, perPage } = params.pagination;
        let { field, order } = params.sort;

        const args = buildArgs({
            order: order || "DESC",
            size: perPage,
            pageNumber: page,
            sort: field || "created_at",
        });

        if(filter.dateGte && filter.dateLte){
            where['created_at'] = {
                _gte: filter.dateGte, _lte: filter.dateLte
            }
        }

        if(filter.affected_countries){
            where['affected_countries'] = {
                _has_keys_any: filter.affected_countries
            }
        }

        if(filter.affected_service){
            where['affected_service'] = {
                _has_keys_any: filter.affected_service
            }
        }

        const {allPointRulesList, error} = await this.client.gql.query({
            query: gql`
                query allPointRulesList($where: PointRulesWhereInput) {
                    allPointRulesList(where: $where, ${args}) {
                        data {
                            id
                            created_at
                            updated_at
                            affected_service
                            affected_countries
                            start
                            end
                            limits
                        }
                        count
                    }
                }
            
            `,
            variables: {
                where
            }
        })

        if (error) {
            console.warn(error);
            throw new Error("GraphQL query error");
        }

        return {
            data: allPointRulesList.data,
            total: allPointRulesList.count,
        };
    }
    async create(resource: string, params: CreateParams<any>): Promise<CreateResult<any>> {
        console.log({ method: "create", resource, params });
        const toBeCreatedData = {
            ...params.data
        }

        const queryResult = await this.client.gql.mutation({
            mutation: gql`
                mutation CreatePointRules($input: [PointRulesCreateInput!]!) {
                    createPointRules(input: $input) {
                        id
                        created_at
                        affected_service
                        affected_countries
                        start
                        end
                        limits
                    }
                }
            
            `,
            variables:{
                input: toBeCreatedData
            }
        });

        if(queryResult.error){
            throw new Error("Failed Create Point Rules");
        }

        return {
            data: queryResult.createPointRules
        } as CreateResult
    }
    async getOne(resource: string, params: GetOneParams<any>): Promise<GetOneResult<any>> {
        console.log({ method: "getOne", resource, params });

        const queryResult = await this.client.gql.query({
            query: gql`
                query GetPointRulesById($id: UUID) {
                    getPointRulesById(id: $id) {
                        id
                        created_at
                        updated_at
                        affected_service
                        affected_countries
                        start
                        end
                        limits
                    }
                }
            `,
            variables: {
                id: params.id,
            },
        });

        return {
            data: queryResult?.getPointRulesById,
        };
    }

    async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
        console.log({ method: "update", resource, params });
        const submittedData = {
            ...params?.data,
        };

        const queryResult = await this.client.gql.mutation({
            mutation: gql`
                mutation UpdatePointRulesById($ids: [UUID!]!, $input: PointRulesUpdateInput!) {
                    updatePointRulesById(ids: $ids, input: $input) {
                        id
                        created_at
                        updated_at
                        affected_service
                        affected_countries
                        start
                        end
                        limits
                    }
                }
            `,
            variables: {
                ids: [`${params.id}`],
                input: submittedData
            }
        })

        if(queryResult.error){
            throw new Error("Failed Update Point Rules");
        }

        return {
            data: queryResult.updatePointRulesById
        } as UpdateResult;
    }
}   