const Color = {
  primary: "#145DFF",
  secondary: "#FFFFFF",
  default: "",
  error: "",
  warning: "",
  success: "",
};

export default Color;
