import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Identifier,
  CreateButton,
  TopToolbar,
  RowClickFunction,
} from "react-admin";
import { Drawer } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { CreatePage } from "./CreatePage";
import { EditPage } from "./EditPage";

const ListActions = (props: any) => {
  return (
    <TopToolbar>
      <Stack direction="row" spacing={1}>
        <CreateButton
          to={{
            pathname: "/internal/systemConfigs/create",
          }}
          label="Create New"
        />
      </Stack>
    </TopToolbar>
  );
};

export const SystemConfigsList = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  // --
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = React.useCallback(() => {
    navigate("/internal/systemConfigs");
  }, [navigate]);

  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath("/internal/systemConfigs/:id/*", location.pathname);
  const matchCreate = matchPath(
    "/internal/systemConfigs/create",
    location.pathname
  );

  return (
    <>
      <List
        {...props}
        actions={
          <ListActions
            handleClickOpenDialog={handleClickOpenDialog}
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
          />
        }
        resource="systemConfigs"
        sort={{ field: "created_at", order: "DESC" }}
        title="Internal &rsaquo; Configuration"
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={
            ((id: Identifier) =>
              navigate(
                `/internal/systemConfigs/${id}`
              )) as unknown as RowClickFunction
          }
          key={((id: Identifier) => id) as unknown as React.Key}
        >
          <DateField source="created_at" label="Created at" showTime />
          <TextField source="key" />
          <TextField source="description" />
          <TextField source="value" />
        </Datagrid>
      </List>
      {/* Side Drawer for Create and Edit */}
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          // @ts-ignore
          <EditPage
            recordId={(matchEdit as any).params.id}
            onCancel={handleClose}
          />
        )}
        {!!matchCreate && (
          // @ts-ignore
          <CreatePage />
        )}
      </Drawer>
    </>
  );
};
