
import CreatePage from "./CreatePage";
import EditPage from "./EditPage";
import ListPage from "./ListPage";

const resource = {
  list: ListPage,
  create: CreatePage,
  edit: EditPage,
};

export default resource;