// @ts-nocheck
import React, { useCallback } from 'react';
import { List, Datagrid, TopToolbar, sanitizeListRestProps, TextField, Pagination, DateField, BooleanField, usePermissions, TextInput, AutocompleteInput, ReferenceInput } from 'react-admin';
import { matchPath, useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Detail } from './Detail';
import { Box, Drawer, Button as MaterialButton, Tab } from '@mui/material';
import { EditMvno } from './Edit';
// import ReactJson from "react-json-view";

import mbaas from '../../../../src/provider/Mbaas';
import { useDispatch } from 'react-redux';
import { setProductsListFilter } from 'src/slices/productsListPage/filterProductsListlice';
import { usePersistFilter } from 'src/hooks/usePersistFilter';
import { CreateMvno } from './Create';
import { TabContext, TabList } from '@mui/lab';
import { dataProvider } from '@/src/provider';

export interface UpdateProductCsvInterface {
  openDialog: {
    isOpen: boolean;
    dialogType: string;
  };
  setOpenDialog: (props: OpenStateDialogInterface) => any;
}

const ServicesProductPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, openDialog, setOpenDialog, userData, ...rest } = props;
  const { isLoading, permissions } = usePermissions();
  usePersistFilter();
  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Admin' || el === 'Super Admin');

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {isAdmin && (
        <>
          <MaterialButton
            color="primary"
            size="small"
            data-testid="create_button"
            component={Link}
            to={{
              pathname: '/services/mvno/create',
            }}
          >
            + CREATE NEW
          </MaterialButton>
        </>
      )}
    </TopToolbar>
  );
};

export interface OpenStateDialogInterface {
  isOpen: boolean;
  dialogType: string;
}

export const ServicesMvnoList = () => {
  const [openDialog, setOpenDialog] = React.useState<OpenStateDialogInterface>({
    isOpen: false,
    dialogType: '',
  });
  let { id: idDataRecord } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const dispatch = useDispatch();
  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const handleCancelDrawer = useCallback(() => {
    navigate(`/services/mvno`);
  }, [navigate]);

  const matchCreate = matchPath('/services/mvno/create', location.pathname);
  let matchEdit = null;
  if (!location.pathname.endsWith('create')) matchEdit = matchPath('/services/mvno/:id', location.pathname);
  const matchShow = matchPath('/services/mvno/:id/show/*', location.pathname);

  const rowStyleBySelectedRecord = (record: RaRecord) => {
    let bgColor = '';
    if (record.id === idDataRecord) {
      bgColor = '#f2f2f2';
    }

    return {
      backgroundColor: bgColor,
    };
  };
  const [value, setValue] = React.useState('product');
  const [filter, setFilter] = React.useState({ isVas: true });
  React.useEffect(() => {
    if (value === 'product') {
      setFilter({ isVas: true });
    } else {
      setFilter({ isVas: false });
    }
  }, [value]);

  const handleChange = (e: SyntheticEvent, newValue: string) => {
    e.preventDefault();
    setValue(newValue);
  };
  const ServicesMvnoFilter = [
    <TextInput alwaysOn data-test-id="search-by-label" source="label" onChange={(e) => dispatch(setProductsListFilter({ label: e.target.value }))} />,
    <TextInput alwaysOn data-test-id="search-by-item_key" source="item_key" onChange={(e) => dispatch(setProductsListFilter({ item_key: e.target.value }))} />,
    <ReferenceInput filter={{ isVas: value === 'product' && 'package', isMvno: true }} source="id" reference="productCatalogsItemGroup" alwaysOn>
      <AutocompleteInput
        sx={{ width: '300px' }}
        fullWidth
        source="item_group"
        optionText={(choice?: any) => (choice?.id ? choice.item_group : '')}
        label="Item Group"
        data-testid="search-item-group"
        onChange={(e) => dispatch(setProductsListFilter({ id: e }))}
      />
    </ReferenceInput>,
  ];

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="MVNO Product" value="product" />
            <Tab label="MVNO Package" value="package" />
          </TabList>
        </Box>
      </TabContext>
      <List
        empty={false}
        exporter={false}
        resource="servicesMvno"
        title="Services &rsaquo; MVNO"
        filter={filter}
        filters={ServicesMvnoFilter}
        perPage={25}
        pagination={<ServicesProductPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!matchCreate || !!matchShow || !!matchEdit ? '600px' : 0,
        }}
        actions={<ListActions openDialog={openDialog} setOpenDialog={setOpenDialog} userData={userData} />}
      >
        <Datagrid
          rowClick={(id) => {
            navigate(`/services/mvno/${id}/show`);
          }}
          key={(id) => id}
          bulkActionButtons={false}
          rowStyle={rowStyleBySelectedRecord}
        >
          <DateField
            source="created_at"
            label="Date Added"
            showTime
            locales="en-HK"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }}
          />
          <TextField source="item_key" label="Item Key" />
          <TextField source="item_group" label="Item Group" />
          <TextField source="label" label="Label" />
          <TextField source="description" label="Description" />
          <TextField source="retail_price" label="Price" />
          <BooleanField source="is_available" label="Available?" />

          <BooleanField source="auto_enabled" label="Auto Enabled?" />
        </Datagrid>
      </List>
      <Drawer variant="persistent" open={!!matchCreate} anchor="right" onClose={handleCancelDrawer} sx={{ zIndex: 100 }}>
        {!!matchCreate && <CreateMvno onCancel={handleCancelDrawer} />}
      </Drawer>
      <Drawer variant="persistent" open={!!matchShow} anchor="right" onClose={handleCancelDrawer} sx={{ zIndex: 100 }}>
        {!!matchShow && <Detail id={(matchShow as any).params.id} onCancel={handleCancelDrawer} match={matchShow} />}
      </Drawer>
      <Drawer variant="persistent" open={!!matchEdit} anchor="right" onClose={handleCancelDrawer} sx={{ zIndex: 100 }}>
        {!!matchEdit && <EditMvno id={(matchEdit as any).params.id} onCancel={handleCancelDrawer} />}
      </Drawer>
    </>
  );
};
