import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  FunctionField,
  FileField,
  AutocompleteInput,
  RaRecord,
} from "react-admin";

const ReportFilter = (props: any) => {
  const choices = [
    { id: "usages-sms", name: "sms" },
    { id: "usages-voice", name: "voice" },
    { id: "referred-by-promotor", name: "referred by promotor" },
    { id: "complete-referral-data", name: "complete referral data" },
    { id: "usages-purchase", name: "purchase" },
    { id: "usages-reload", name: "reload" },
    { id: "subscribers", name: "subscribers" },
    { id: "packages", name: "packages" },
    { id: "products", name: "products" },
    { id: "tickets", name: "tickets" },
  ];
  return (
    <Filter {...props}>
      <AutocompleteInput
        source="type"
        optionValue="id"
        optionText="name"
        choices={choices}
        alwaysOn
      />
    </Filter>
  );
};

export const ReportsList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ReportFilter />}
      resource="reports"
      sort={{ field: "created_at", order: "DESC" }}
      title="Documents &rsaquo; Reports"
      bulkActionButtons={false}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="created_at" label="Created" showTime />
        <TextField source="author_id_data.email" label="Author" />
        <TextField source="status" />
        <FunctionField
          label="File"
          render={(record: RaRecord) =>
            record.uri ? (
              <FileField
                source="uri"
                title={record.uri.split("/").pop()}
                label="file"
              />
            ) : (
              <label>{record.status.toUpperCase()}</label>
            )
          }
        />
      </Datagrid>
    </List>
  );
};
