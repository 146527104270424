// @ts-nocheck
import React, { Fragment, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  required,
  AutocompleteInput,
  useNotify,
  useRedirect,
  useRefresh,
  SaveButton,
  Toolbar,
  useGetList,
  Loading,
  useInput,
  FormDataConsumer,
  AutocompleteArrayInput
} from "react-admin";
import "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import { makeStyles } from "@mui/styles";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataProvider } from "../../../provider";
import Swal from "sweetalert2";
/** for demo need only */
/** import { authenticator } from "otplib"; */

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const EditPaymentChannel = ({ onCancel, id, ...props }) => {
  const classes = useStyles();

  const { data: dataProductGroup, isLoading } = useGetList(
    "productCatalogsItemGroup",
    {
      filter: {
        is_vas: true
      },
      pagination: {
        page: 1,
        perPage: 1000
      }
    }
  );

  const choices = dataProductGroup?.map((item: any) => ({
    id: item.item_group,
    item_group: item.item_group
  }));

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSubmitHandler = async (data) => {
    const dataSubmit = {
      ...data,
      app: "M21"
    }
    await dataProvider
      .update("paymentChannel", { data: dataSubmit, id })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/services/payment-channel/`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  const ToolbarEdit = () => {
    return (
      <Toolbar classes={useStyles()}>
        <SaveButton />
      </Toolbar>
    );
  };

  return (
    <Fragment>
      <Edit resource="paymentChannel" title=" &rsaquo; Edit" {...props}>
        <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
          <Stack
            direction="row"
            p={0}
            sx={{ position: "absolute", right: 0, zIndex: 100 }}
          >
            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          {isLoading ? (
            <Loading />
          ) : (
            <SimpleForm onSubmit={onSubmitHandler} toolbar={<ToolbarEdit />}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <AutocompleteInput
                    className={classes.input}
                    fullWidth
                    source="item_group"
                    choices={choices}
                    optionText="item_group"
                    label="Item Group"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormDataConsumer>{({ formData }) => <CountryInput formData={formData} />}</FormDataConsumer>
                </Grid>
                {/* <Grid item xs={6}>
                  <FormDataConsumer>{({ formData }) => <AppInput formData={formData} />}</FormDataConsumer>
                </Grid> */}
                <Grid item xs={12}>
                  <FormDataConsumer>{({ formData }) => <PaymentChannelInput formData={formData} />}</FormDataConsumer>
                </Grid>
              </Grid>
            </SimpleForm>
          )}
        </Box>
      </Edit>
    </Fragment>
  );
};

const CountryInput: React.FC = (props) => {
  const { field: fieldApp } = useInput({
    source: "app",
  });

  const { field: fieldPayment } = useInput({
    source: "payment_channel",
  });

  return (
    <AutocompleteInput
      fullWidth
      size="small"
      source="country"
      label="Country"
      optionValue="id"
      optionText="name"
      choices={[{ id: "HK", name: "Hong Kong" }, { id: "TW", name: "Taiwan" }]}
      alwaysOn
      onChange={() => {
        fieldApp.onChange(null);
        fieldPayment.onChange(null);
      }}
      validate={[required()]}
    />
  );
};

// const AppInput: React.FC = (props) => {
//   const { formData } = props;

//   const CHOICES: any = {
//     TW: [
//       { id: "M21", name: "M21" },
//       { id: "G2", name: "G2" }
//     ],
//     HK: [
//       { id: "M21", name: "M21" },
//       { id: "GHK", name: "GHK" },
//     ]
//   };

//   return (
//     <AutocompleteInput
//       source="app"
//       optionValue="id"
//       optionText="name"
//       label="App"
//       fullWidth
//       validate={[required()]}
//       choices={CHOICES[formData.country] || []}
//     />
//   );
// };

const PaymentChannelInput: React.FC = (props) => {
  const { formData } = props;

  const CHOICES: any = {
    TW: [
      { id: "SEVENTW", name: "7-11" },
      { id: "FAMILYTW", name: "Family Mart" },
      { id: "HILIFETW", name: "Hi-life" },
      { id: "OKMATW", name: "OK Mart" },
      { id: "COIN", name: "Coin" },
    ],
    HK: [
      { id: "COIN", name: "Coin" },
    ]
  };

  return (
    <AutocompleteArrayInput
      source="payment_channel"
      optionValue="id"
      optionText="name"
      label="Payment Channel"
      fullWidth
      validate={[required()]}
      choices={CHOICES[formData.country] || []}
    />
  );
};
