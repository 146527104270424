// @ts-nocheck
import React from "react";
import {
  SimpleForm,
  required,
  useNotify,
  Create,
  useRedirect,
  SelectInput,
  TextInput,
} from "react-admin";
import DOMPurify from "dompurify";
import { RichTextInput } from "ra-input-rich-text";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const langList = [
  { id: "en", name: "English" },
  { id: "id", name: "Indonesia" },
  { id: "ch", name: "Chinese" },
];

const transform = (data) => ({
  ...data,
  answer: DOMPurify.sanitize(data.answer),
  // question: DOMPurify.sanitize(data.question),
});

const CreatePage = (props) => {
  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/faqs");
  };

  return (
    <Create
      {...props}
      title="FAQs &gt; Add"
      transform={transform}
      mutationOptions={{
        onSuccess: onSuccess,
      }}
    >
      <SimpleForm>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectInput
                  data-test-id="select_lang"
                  fullWidth
                  className={classes.input}
                  source="language"
                  validate={[required()]}
                  choices={langList}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <RichTextInput
                  source="question"
                  className={classes.input}
                  fullWidth
                /> */}
                <TextInput
                  data-test-id="question_input"
                  source="question"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput
                  data-test-id="answer_input"
                  source="answer"
                  className={classes.input}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreatePage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
