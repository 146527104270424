// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  SimpleForm,
  TopToolbar,
  Edit,
  EditButton,
  required,
  useListContext,
  useNotify,
  Create,
  CreateButton,
  useRedirect,
  FunctionField,
  sanitizeListRestProps,
  RichTextField,
  SelectInput,
  TextInput,
  TextField,
  useInput,
  useCreateContext,
  useGetList,
  NumberInput,
  useRecordContext,
  useGetOne,
  Loading,
  Error,
  ImageInput,
  ImageField,
  useDataProvider,
  FormDataConsumer,
  Toolbar,
  SaveButton,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { useMutation } from "react-query";
import _ from "lodash";

const countryList = [
  { id: "GLOBAL", name: "Worldwide" },
  { id: "HK", name: "Hong Kong" },
  { id: "ID", name: "Indonesia" },
  { id: "MY", name: "Malaysia" },
  { id: "TW", name: "Taiwan" },
];

const CustomToolbarNoDelete = ({ dataForm, extData }: any) => {
  console.log(extData?.length, "length");

  const tempFormData = Object.keys(dataForm).forEach(
    (key) => !dataForm[key] && delete dataForm[key]
  );

  const notify = useNotify();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();
  const { mutate, isLoading, isSuccess } = useMutation(() =>
    dataProvider.create("diasporaEmergency", {
      data: { ...dataForm, priority: extData?.length + 1 },
    })
  );
  const [eq, setEq] = useState(true);
  useEffect(() => {
    const equal = _.isEqual(dataForm, {});
    setEq(equal);
  }, [dataForm]);

  useEffect(() => {
    if (isSuccess) {
      notify("ra.notification.created", "info", { smart_count: 1 });
      redirect("/diasporaEmergency");
    }
  }, [isSuccess]);
  return (
    <Toolbar classes={useStyles()}>
      <SaveButton
        data-test-id="save_btn"
        onClick={(event) => {
          if (Object.keys(dataForm).length === 4) {
            event.preventDefault();
            mutate();
          }
        }}
        data-test-id="save_btn"
        disabled={eq}
      />
    </Toolbar>
  );
};

const CreatePage = (props) => {
  const listData = useListContext();

  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/diasporaEmergency");
  };
  const { data, isLoading, error } = useGetList("diasporaEmergency");

  // const postDefaultValue = () => ({ total: data?.total });
  const [dataForm, setDataForm] = useState({});
  return (
    <Create
      {...props}
      title="Diaspora Emergency &gt; Add"
      mutationOptions={{
        onSuccess: onSuccess,
      }}
    >
      <SimpleForm
        toolbar={
          <CustomToolbarNoDelete
            dataForm={dataForm}
            extData={!isLoading && !error && data}
          />
        }
      >
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  data-test-id="country_select"
                  fullWidth
                  className={classes.input}
                  source="country_code"
                  validate={[required()]}
                  choices={countryList}
                  label="Country"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  data-test-id="phone_input"
                  fullWidth
                  validate={[required()]}
                  type={"tel"}
                  source="phone"
                  label="Phone Number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  data-test-id="label_input"
                  fullWidth
                  validate={[required()]}
                  source="label"
                  label="Label"
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <ImageInput
                  source="thumbnail"
                  label="Thumbnail"
                  accept="image/*"
                  options={{ multiple: false }}
                >
                  <ImageField
                    data-test-id="image_input"
                    source="src"
                    title="title"
                  />
                </ImageInput>
              </Grid>
            </Grid>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                setDataForm(formData);
              }}
            </FormDataConsumer>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreatePage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
