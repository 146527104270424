import React, { Fragment } from "react";
import {
  SimpleForm,
  required,
  Create,
  useNotify,
  useRedirect,
  useRefresh,
  TextInput,
  Confirm,
  SaveButton,
  Toolbar,
  useCreate,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  TextField as MuiTextField,
} from "@mui/material";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CreateCurrencySaveButton = (props: any) => {
  const { openDialog } = props;

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  return <SaveButton type="button" onClick={openDialog} />;
};

const CreateCurrencyToolbar = (props: any, data: any) => {
  const { openDialog } = props;

  return (
    <Toolbar>
      <CreateCurrencySaveButton openDialog={openDialog} />
    </Toolbar>
  );
};

interface ICreatedData {
  key: string;
  description: string;
  value: string;
}

export const CreatePage = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [activeInput, setActiveInput] = React.useState("richText");
  const [createCurrencyRate, { data, isLoading, error, isSuccess }] =
    useCreate();
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [createdData, setCreatedData] = React.useState<ICreatedData>({
    key: "",
    description: "",
    value: "",
  });

  const handleConfirmDialogOpen = (e: any) => {
    e.preventDefault();
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);

  // -- If success creating data
  if (isSuccess) {
    notify("ra.notification.created");
    redirect("/internal/systemConfigs");
    refresh();
  }
  // -- If error when creating data
  if (error) {
    notify("Create Data Failed", { type: "warning" });
    redirect("/internal/systemConfigs");
    refresh();
  }

  const handleValidateOtp = async () => {
    const { data: resultData, error } = await mbaas.client.invokeApi(
      "console-validate-totp",
      {
        token: otpCode,
      }
    );
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: "#muiDrawer",
      });
    }
    // --
    if (resultData) {
      handleConfirmDialogClose();
      createCurrencyRate("systemConfigs", {
        data: {
          createdData,
        },
      });
    }
  };

  console.log(77612, "createdData", createdData);

  return (
    <Fragment>
      <Create
        resource="systemConfigs"
        title=" &rsaquo; Create"
        {...props}
        redirect="list"
      >
        <Box
          pt={5}
          width={{ xs: "100vW", sm: 600 }}
          mt={{ xs: 2, sm: 1 }}
          height="100vh"
        >
          <SimpleForm
            toolbar={
              <CreateCurrencyToolbar openDialog={handleConfirmDialogOpen} />
            }
          >
            <Typography variant="h6" style={{ marginBottom: "17px" }}>
              Create New System Config
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  source="key"
                  validate={[required()]}
                  multiline
                  label="key"
                  fullWidth
                  data-test-id="keyInput"
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      key: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="description"
                  validate={[required()]}
                  multiline
                  label="description"
                  fullWidth
                  data-test-id="descriptionInput"
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      description: e.target.value,
                    }))
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <MuiTextField
                  fullWidth
                  rows={7}
                  data-test-id="value"
                  multiline
                  label="value"
                  value={createdData?.value}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      value: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
      {/* Show input totp dialog */}
      <Confirm
        id="muiDrawer"
        isOpen={openConfirmDialog}
        loading={otpCode.length < 6 ? true : false}
        title="OTP code"
        content={
          <MuiTextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleValidateOtp}
        onClose={handleConfirmDialogClose}
        sx={{
          "& .Mui-disabled": {
            color: "#c5c5c5",
          },
        }}
      />
    </Fragment>
  );
};
