// @ts-nocheck
import React from "react";
import {
  SimpleForm,
  Edit,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import DOMPurify from "dompurify";
import { RichTextInput } from "ra-input-rich-text";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { CustomToolbar } from "../CustomToolbar";

const langList = [
  { id: "en", name: "English" },
  { id: "id", name: "Indonesia" },
  { id: "ch", name: "Chinese" },
];

const transform = (data) => ({
  ...data,
  answer: DOMPurify.sanitize(data.answer),
  // question: DOMPurify.sanitize(data.question),
});

const EditTitle = () => {
  return <span>FAQs &gt; Edit</span>;
};

const EditPage = (props) => {
  const classes = useStyles();

  return (
    <Edit transform={transform} title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectInput
                  data-test-id="select_lang"
                  fullWidth
                  className={classes.input}
                  source="language"
                  validate={[required()]}
                  choices={langList}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  data-test-id="question_input"
                  source="question"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput
                  data-test-id="question_input"
                  source="answer"
                  className={classes.input}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditPage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
