import React from "react";
import { MuiChipsInput } from "mui-chips-input";
import voiceTariffsStore from "../store/tariffsStore";
import { view } from "@risingstack/react-easy-state";

function GroupPrefixChips(props: any) {
  const handleChange = (chip: any) => {
    voiceTariffsStore.groupPrefix = [...chip].sort();
  };
  const handleEdit = (chipValue: string, chipIndex: number) => {
    voiceTariffsStore.groupPrefixEditing = true;
    setTimeout(() => {
      voiceTariffsStore.groupPrefixEditing = false;
    }, 3000);
  };

  return (
    <MuiChipsInput
      value={voiceTariffsStore.groupPrefix}
      onChange={handleChange}
      onEditChip={handleEdit}
      onAddChip={handleEdit}
      label="Group Prefix"
      fullWidth
      clearInputOnBlur
      placeholder="Type and press enter"
      variant="outlined"
      hideClearAll
    />
  );
}

export default view(GroupPrefixChips);
