import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface Ibroadcast {
  broadcastFilter: {
    title: string;
    content: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: Ibroadcast = {
  broadcastFilter: {
    title: "",
    content: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type broadcastFilter = {
  title: string;
  content: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const broadcastFilterSlice = createSlice({
  name: "thisBroadcastFilter",
  initialState,
  reducers: {
    setBroadcastFilter: (state, action: PayloadAction<broadcastFilter>) => {
      state.broadcastFilter = {
        ...state.broadcastFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setBroadcastFilter } = broadcastFilterSlice.actions;

export const getBroadcastFilter = (state: RootState) =>
  state.thisBroadcastFilter.broadcastFilter;

export default broadcastFilterSlice.reducer;
