import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { ProviderBase } from "./ProviderBase";
import { GetListParams, GetListResult } from "react-admin";
import { buildArgs } from "./utils";

export class WalletsProviderClass extends ProviderBase<any> {
    client: MobileServiceClient;

    constructor(client: MobileServiceClient){
        super();
        this.client = client;
    }
    
    async getList(resource: string, params: GetListParams): Promise<GetListResult<any>> {
        console.log({ method: "getList", resource, params });
        const where: Record<string, any> = {
            _and: []
        };

        if(params.filter.user_id){
            where._and.push({ user_id: { _eq: params.filter.user_id}});
        }

        if(params.filter.unit){
            where._and.push({ unit: { _eq: params.filter.unit}});
        }

        const args = buildArgs({
            order: params.sort.order.toUpperCase(),
            size: params.pagination.perPage,
            pageNumber: params.pagination.page,
            sort: params.sort.field,
        });
        
        const { allWalletsList, error} = await this.client.gql.query({
            query: `
                query allWalletsList($where: WalletsWhereInput){
                    allWalletsList(where: $where, ${args}){
                        data{
                            created_at
                            updated_at
                            user_id
                            id
                            balance
                            unit
                            user_id_data{
                            id
                            name
                            }
                        }
                    }
                }
            `,
            variables: {
                where
            }
        })
        console.log({ allWalletsList, error });
        if (error) {
            console.warn(error);
            throw new Error("GraphQL query error");
        }
        return {
            data: allWalletsList.data,
            total: allWalletsList.count,
        };
    }
}