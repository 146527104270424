import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Identifier,
  CreateButton,
  TopToolbar,
  RowClickFunction,
  FunctionField,
  Button,
} from "react-admin";
import { Drawer } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { EditPage } from "./EditPage";
import EditIcon from "@mui/icons-material/Edit";

import ReactJson from "react-json-view";

const ListActions = (props: any) => {
  return (
    <TopToolbar>
      <Stack direction="row" spacing={1}></Stack>
    </TopToolbar>
  );
};

export const FirebaseRemoteConfigsList = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  // --
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = React.useCallback(() => {
    navigate("/internal/firebaseRemoteConfigs");
  }, [navigate]);

  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath(
      "/internal/firebaseRemoteConfigs/:id/*",
      location.pathname
    );
  const matchCreate = matchPath(
    "/internal/firebaseRemoteConfigs/create",
    location.pathname
  );

  return (
    <>
      <List
        {...props}
        actions={
          <ListActions
            handleClickOpenDialog={handleClickOpenDialog}
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
          />
        }
        resource="firebaseRemoteConfigs"
        sort={{ field: "created_at", order: "DESC" }}
        title="Internal &rsaquo; Configuration"
      >
        <Datagrid
          bulkActionButtons={false}
          // rowClick={
          //   ((id: Identifier) =>
          //     navigate(
          //       `/internal/firebaseRemoteConfigs/${id}`
          //     )) as unknown as RowClickFunction
          // }
          key={((id: Identifier) => id) as unknown as React.Key}
        >
          {/* <DateField source="created_at" label="Created at" showTime /> */}
          <TextField source="id" label="Parameter" />
          <FunctionField
            render={(record: any) => {
              console.log(record, "ALOHA");
              return (
                <ReactJson
                  src={record.value || {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  name={false}
                  collapsed={1}
                />
              );
            }}
            source="value"
            label="Value"
          />
          <FunctionField
            label="Action"
            render={(record: any) => {
              return (
                <Button
                  variant="contained"
                  label="update"
                  endIcon={<EditIcon />}
                  onClick={() => {
                    navigate(`/internal/firebaseRemoteConfigs/${record.id}`);
                  }}
                />
              );
            }}
          />
        </Datagrid>
      </List>
      {/* Side Drawer for Create and Edit */}
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          // @ts-ignore
          <EditPage
            recordId={(matchEdit as any).params.id}
            onCancel={handleClose}
          />
        )}
      </Drawer>
    </>
  );
};
