// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Show,
  FunctionField,
  WithRecord,
  Pagination,
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  Button,
  useRecordContext,
  useNotify,
  useRedirect,
  required,
  useGetOne
} from "react-admin";
import { isEmpty } from "lodash";
import ReactJson from "react-json-view";
import moment from "moment";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonGroup,
  TextField as TF,
  Button as MaterialButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import StartIcon from '@mui/icons-material/Start';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SyncIcon from "@mui/icons-material/Sync";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { useNavigate } from "react-router-dom";
import Mbaas from "../../provider/Mbaas";
import Swal from "sweetalert2";
import JSONInput from "../JSONInput";
import EditIcon from "@mui/icons-material/Edit";
import { Save } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { isNull } from 'lodash';

const useStyles = makeStyles({
  detailsBox: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
  },
  topGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
    paddingTop: "40px !important",
  },
  bottomGrid: {
    paddingBottom: "20px",
  },
  histExpTopGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
  },
  histExpDetailsBox: {
    paddingBottom: "5px",
    paddingTop: "5px !important",
  },
});

function openIms(record: any) {
  let prefix = '';
  if (window.location.host.startsWith('develop.') || window.location.host.startsWith('localhost:')) {
    prefix = 'develop.';
  }

  const historiesStatus = record.status;
  let IdOrder: String;
  if (historiesStatus !== 'FAILED' && historiesStatus !== 'PENDING' && historiesStatus !== 'SUCCESS') {
    return <></>;
  }

  if (historiesStatus === 'SUCCESS' || historiesStatus === 'PENDING') {
    if (!record.data.orderId) {
      return <></>;
    }
    IdOrder = record.data.orderId;
  } else if (historiesStatus === 'FAILED') {
    if (!record.data.data) {
      return <></>;
    } else if (!record.data.data.orderId) {
      return <></>;
    }
    IdOrder = record.data.data.orderId;
  }

  const imsView = `https://${prefix}ims-pgs-console.pages.dev/ims__transactions?filter={"order_id":"${IdOrder}"}`;

  const openTab = () => {
    window.open(encodeURI(imsView));
  };

  return (
    <IconButton onClick={openTab}>
      <StartIcon fontSize="small" />
    </IconButton>
  );
}

const MVNOHistories = ({ histories, value }) => {
  const [page, setPage] = useState(histories ? 1 : 0);
  const perPage = 5;
  if (histories !== null) {
    if (histories.length) {
      const total = histories?.length;
      const shownHistories = histories
        .sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
        .filter(
          (x: any, index) =>
            x && index >= (page - 1) * perPage && index < page * perPage
        );

      return (
        <>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell>Data</TableCell>
                {(value !== 'hongkong') &&
                  <TableCell>View On IMS</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {shownHistories.map((data) => {
                const dataData = {
                  ...data.data,
                  ...(data.override ? { override: data.override } : {}),
                };
                return (
                  <TableRow key={data.id}>
                    <TableCell>
                      {moment(data.timestamp).format("DD/MM/YYYY, hh:mm:ss A")}
                    </TableCell>
                    <TableCell>{data.status}</TableCell>
                    <TableCell>{data.remark}</TableCell>
                    <TableCell>
                      <ReactJson
                        src={dataData || []}
                        shouldCollapse={() => {
                          /* TODO document why this arrow function is empty */
                        }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    </TableCell>
                    {(value !== 'hongkong') &&
                      <TableCell>
                        <FunctionField label="View on IMS" render={() => openIms(data)} />
                      </TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Pagination
            page={page}
            perPage={perPage}
            setPage={setPage}
            total={total}
            rowsPerPageOptions={[2]}
          />
        </>
      );
    }
    return <>Histories Is Not Array Object</>
  }
  return <>Histories is Null</>
};

function openPgs(mode: 'PGS' | 'BARCODE', record: any) {
  let prefix = '';
  if (window.location.host.startsWith('develop.') || window.location.host.startsWith('localhost:')) {
    prefix = 'develop.';
  }

  if (mode === 'BARCODE') {
    window.open(record.deeplink);
  } else if (mode === 'PGS') {
    const payId = record.payment_id;
    const pgsView = `https://${prefix}ims-pgs-console.pages.dev/pgs__payments?filter={"payment_id":"${payId}"}`;

    window.open(encodeURI(pgsView));
  }
}

const SyncVASButton = ({ transactionId, orderId }) => {
  const onClickHandler = async () => {
    const { error: syncVasErr, data: syncVasData } =
      await Mbaas.client.invokeApi("console-sync-vas", {
        transaction_id: transactionId,
      });
    if (syncVasErr)
      Swal.fire(`Request failed: ${syncVasErr?.error}`, "", "error");
    if (syncVasData === "OK")
      Swal.fire("Successfully Synced with VAS!", "", "success");
  };
  return (
    <MaterialButton
      startIcon={<SyncIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={onClickHandler}
      disabled={!orderId?.startsWith("MT")}
    >
      Sync VAS
      {orderId?.startsWith("MT") ? `: ${orderId}` : ``}
    </MaterialButton>
  );
};

const SyncStatusButton = ({ transactionId }) => {
  const onClickHandler = async () => {
    const { error: syncStatusErr, data: syncStatusData } =
      await Mbaas.client.invokeApi("console-sync-purchase-and-history-status", {
        transaction_id: transactionId,
      });
    if (syncStatusErr)
      Swal.fire(`Request failed: ${syncStatusErr?.error}`, "", "error");
    if (syncStatusData === "OK")
      Swal.fire("Successfully Synced Status", "", "success");
  };
  return (
    <MaterialButton
      startIcon={<SyncIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={onClickHandler}
    >
      Sync Status
    </MaterialButton>
  );
};

const RefundBalanceButton = ({ purchaseLogId }) => {
  const [loading, setLoading] = useState(false);
  const onClickHandler = async () => {
    setLoading(true)
    const { error: syncStatusErr, data: syncStatusData } =
      await Mbaas.client.invokeApi("console-refund-failed-purchase-ghk", {
        purchase_log_id: purchaseLogId,
      });
    if (syncStatusErr) {
      Swal.fire(`Request failed: ${syncStatusErr?.error}`, "", "error")
      setLoading(false)
    };
    if (syncStatusData === "OK") {
      Swal.fire("Successfully Refund Balance", "", "success")
      setLoading(false)
    };
  };
  return (
    <MaterialButton
      startIcon={<CurrencyExchangeIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={onClickHandler}
      disabled={loading}
    >
      Refund Balance
    </MaterialButton>
  );
};

const ExecLogButton = ({ recordId }) => {
  const navigate = useNavigate();
  return (
    <MaterialButton
      startIcon={<RunningWithErrorsIcon />}
      color="primary"
      variant="contained"
      size="small"
      onClick={() =>
        navigate(
          `/internal/execution-logs?filter={"reference_id":"${recordId}"}`
        )
      }
    >
      View Execution Logs
    </MaterialButton>
  );
};

const CustomToolbar = ({ onCancel }) => (
  <Toolbar>
    <Grid item xs={12}>
      <SaveButton data-test-id="Override" label="Override" />
      <Button
        variant="contained"
        color="error"
        style={{
          minWidth: "64px",
          padding: "6px 16px",
          borderRadius: "4px",
          lineHeight: "1.75",
          marginLeft: "14px",
        }}
        label="ra.action.cancel"
        onClick={onCancel}
      >
        <CloseIcon />
      </Button>
    </Grid>
  </Toolbar>
);

const Override = ({ onCancel }) => {
  const record = useRecordContext();

  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();

  const onSuccess = () => {
    redirect("/usages/mvno");
    notify("Transaction Has Been Overridden");
  };

  const statusChoices = {
    PAID: [
      { id: "PAID_EXECUTE_ONLY", name: "PAID_EXECUTE_ONLY" },
      { id: "FAILED", name: "FAILED" },
    ],
    PENDING: [
      { id: "SUCCESS", name: "SUCCESS" },
      { id: "FAILED", name: "FAILED" },
    ],
    SUCCESS: [{ id: "FAILED", name: "FAILED" }],
    FAILED: [
      { id: "SUCCESS", name: "SUCCESS" },
      { id: "ALTER", name: "ALTER MGP" },
    ],
  };

  return (
    <Create
      resource="override"
      mutationOptions={{
        onSuccess: onSuccess,
      }}
      mutationMode="pessimistic"
      title=" &rsaquo; Override"
    >
      <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} />}>
        <div className={classes.root}>
          <Grid container maxWidth={600}>
            <Grid item xs={12}>
              <TextInput
                source="id"
                fullWidth
                validate={required()}
                defaultValue={record.id}
                disabled
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                data-test-id="status"
                source="status"
                fullWidth
                validate={required()}
                choices={statusChoices[record?.status]}
              />
            </Grid>
            <Grid item xs={12}>
              <JSONInput
                data-test-id="additional_data"
                source="additional_data"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                data-test-id="remark"
                source="reason"
                validate={required()}
                fullWidth
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                data-test-id="totp"
                source="totp"
                validate={required()}
                fullWidth
                className={classes.input}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const MvnoDetail = ({ onCancel, value, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const [newOrderId, setNewOrderId] = useState("");

  const { data, isLoading } = useGetOne('purchaseLogs', { id: props.id });

  let dataSortHistories;
  let dataCekHistories;

  if (!isLoading) {
    if (data.histories !== null) {
      if (data.histories.length) {
        dataSortHistories = data.histories
        dataSortHistories.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )

        if ((!isNull(data.flashsale_id) || !isNull(data.reward_log_id))) {
          dataCekHistories = data.histories?.filter((element) => element.status === "PENDING" || element.status === "PAID");
          dataCekHistories = dataCekHistories.filter(item => item.data.purchaseContext !== undefined);
        }
      }
    }
  }

  const dataHistories = () => {
    const histories = data.histories ? data.histories : data;
    if (histories !== null) {
      if (histories.length) {
        const newArray = histories?.filter((element) => element.status === "UNPAID" || element.status === "PAID");
        const dataHis = newArray.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
        const dataPayment = !isEmpty(newArray) ? (dataHis[dataHis.length - 1] ? dataHis[dataHis.length - 1].data : []) : [];
        let pgwData = dataPayment ? (dataPayment.pgw_data ? dataPayment.pgw_data : dataPayment) : [];
        if (!isEmpty(pgwData)) {
          pgwData['status'] = dataHis[0].status;
        }
        return pgwData;
      } else {
        return ""
      }
    }
    return "";
  };

  let PGWData: any;

  if (!isLoading) {
    PGWData = dataHistories();
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const handleOpenEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setNewOrderId("");
  };

  const handleSave = async (purchaseLogId) => {
    const { error: resErr, data: resData } = await Mbaas.client.invokeApi(
      "console-update-order-id",
      {
        purchase_log_id: purchaseLogId,
        order_id: newOrderId || "",
      }
    );
    if (resErr) Swal.fire(`Request failed: ${resErr?.error}`, "", "error");
    if (resData === "OK") {
      Swal.fire("Successfully update Order Id!", "", "success");
      handleCancelEdit();
    }
  };

  return (
    <Show resource="mvno" title=" &rsaquo; Detail" {...props}>
      <Box
        pt={5}
        width={{ xs: "130vW", sm: 750 }}
        mt={{ xs: 2, sm: 1 }}
        ml={{ xs: 2, sm: 1 }}
        mr={{ xs: 2, sm: 1 }}
        minHeight="100vh"
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid container spacing={2}>
              <Grid item xs={4} marginTop={2}>
                <strong>Purchase ID</strong>
              </Grid>
              <Grid item xs={8} marginTop={2}>
                <TextField source="purchase_id" />
              </Grid>
              <Grid item xs={4}>
                <strong>Order ID</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return !record?.order_id?.startsWith("MT") ? (
                      <Grid
                        container
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        {editMode ? (
                          <Grid item xs={5}>
                            <TF
                              label="New Order Id"
                              size="small"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={newOrderId}
                              onChange={(e) => {
                                setNewOrderId(e.target.value);
                              }}
                            />
                          </Grid>
                        ) : (
                          <TextField source="order_id" />
                        )}
                        {editMode ? (
                          <Grid item xs={7}>
                            <Button
                              size={"small"}
                              variant="contained"
                              style={{ marginLeft: "10px" }}
                              onClick={async () => await handleSave(record?.id)}
                            >
                              <Save style={{ marginRight: "5px" }} />
                              <div style={{ fontSize: 14 }}>Save</div>
                            </Button>
                            <Button
                              size={"small"}
                              variant="contained"
                              color="error"
                              style={{ marginLeft: "5px" }}
                              onClick={() => handleCancelEdit()}
                            >
                              <CancelIcon style={{ marginRight: "5px" }} />
                              <div style={{ fontSize: 14 }}>Cancel</div>
                            </Button>
                          </Grid>
                        ) : (
                          <Button
                            size={"small"}
                            variant="contained"
                            style={{
                              marginLeft: "10px",
                            }}
                            onClick={() => handleOpenEdit()}
                          >
                            <EditIcon style={{ marginRight: "5px" }} />
                            <div style={{ fontSize: 14 }}>Edit Order Id</div>
                          </Button>
                        )}
                      </Grid>
                    ) : (
                      <TextField source="order_id" />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Subscriber Name</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="user_id_data.name" />
              </Grid>
              <Grid item xs={4}>
                <strong>Status</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record.status;
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Product</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="product_catalog_id_data.label" />
              </Grid>
              <Grid item xs={4}>
                <strong>Selling Price</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField label="Selling Price" render={(record) => {
                  let dataSortHistories: any;
                  let dataCekHistories: any;
                  if (record.histories !== null) {
                    if (record.histories.length) {
                      dataSortHistories = record.histories
                      dataSortHistories.sort(
                        (a: any, b: any) =>
                          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                      )

                      if ((!isNull(record.flashsale_id))) {
                        dataCekHistories = record.histories?.filter((element: any) => element.status === "PENDING" || element.status === "PAID");
                        dataCekHistories = dataCekHistories.filter((item: any) => item.data?.purchaseContext !== undefined);
                      }
                    }
                  }
                  if (!isEmpty(dataCekHistories) && dataCekHistories[0].data.purchaseContext.couponCode) {
                    return 0;
                  } else {
                    return <TextField source="selling_price" />;
                  }
                }
                } />
              </Grid>
              <Grid item xs={4}>
                <strong>Target Detail</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    if (record.target_detail) {
                      if (typeof record.target_detail === "string") {
                        return <span>{record.target_detail}</span>;
                      } else {
                        return (
                          <ReactJson
                            src={record.target_detail || []}
                            shouldCollapse={() => {
                              /* TODO document why this arrow function is empty */
                            }}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            name={false}
                          />
                        );
                      }
                    } else {
                      return "-";
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Product Snapshot</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return (
                      <ReactJson
                        src={record.product_snapshot || []}
                        shouldCollapse={() => { }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Currency Snapshot</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return (
                      <ReactJson
                        src={record.currency_snapshot || []}
                        shouldCollapse={() => { }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <strong>Remark</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    if (record.remark) {
                      return <TextField source="remark" />;
                    } else {
                      return "-";
                    }
                  }}
                />
              </Grid>
              {!isLoading && !isEmpty(dataCekHistories) && dataCekHistories[0].data.purchaseContext.couponCode &&
                <Grid item xs={12}>
                  <FunctionField
                    render={(record) => {
                      if (record.flashsale_id) {
                        return (
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <strong>Coupon Code</strong>
                            </Grid>
                            <Grid item xs={8}>
                              <Grid container spacing={2}>
                                <Grid item>
                                  {dataCekHistories[0].data.purchaseContext.couponCode}
                                </Grid>
                                <Grid item>
                                  <strong>( Bonus )</strong>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      } else if (record.reward_log_id) {
                        return (
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <strong>Coupon Code</strong>
                            </Grid>
                            <Grid item xs={8}>
                              <Grid container spacing={2}>
                                <Grid item>
                                  {dataCekHistories[0].data.purchaseContext.couponCode}
                                </Grid>
                                <Grid item>
                                  <strong>( Reward )</strong>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      }
                      return ""
                    }}
                  />
                </Grid>
              }
              {!isLoading && !isEmpty(dataSortHistories) && (dataSortHistories[0]?.status === "SUCCESS") &&
                <Grid item xs={12}>
                  <FunctionField
                    render={(record) => {
                      if (dataSortHistories[0].data.orderDetail) {
                        if (dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete) {
                          return (
                            <Grid container spacing={2}>
                              {dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete.pin &&
                                <>
                                  <Grid item xs={4}>
                                    <strong>PIN</strong>
                                  </Grid>
                                  <Grid item xs={8}>
                                    {dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete.pin}
                                  </Grid>
                                </>
                              }
                              {dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete.serial &&
                                <>
                                  <Grid item xs={4}>
                                    <strong>Serial</strong>
                                  </Grid>
                                  <Grid item xs={8}>
                                    {dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete.serial}
                                  </Grid>
                                </>
                              }
                              {dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete.token &&
                                <>
                                  <Grid item xs={4}>
                                    <strong>Token</strong>
                                  </Grid>
                                  <Grid item xs={8}>
                                    {dataSortHistories[0].data.orderDetail.product.pinSerialTokenComplete.token}
                                  </Grid>
                                </>
                              }
                            </Grid>
                          )
                        } else if (dataSortHistories[0].data.orderDetail.product.pinSerialToken) {
                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <strong>PIN / Serial / Token</strong>
                              </Grid>
                              <Grid item xs={8}>
                                {dataSortHistories[0].data.orderDetail.product.pinSerialToken}
                              </Grid>
                            </Grid>
                          )
                        }
                      }
                      return ""
                    }}
                  />
                </Grid>
              }
            </Grid>
          </Tab>
          <Tab label="history">
            <WithRecord
              render={(record) => {
                console.log(record, 979797);
                if (record.product_snapshot?.is_vas === true) {
                  return (
                    <>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        pt={2}
                        style={{ gap: "0.5rem" }}
                      >
                        <SyncVASButton
                          transactionId={record.id}
                          orderId={record.order_id}
                        />
                        {value === 'hongkong' && (data.status === "FAILED") && (!isNull(data.mvno_deduct_id) ? (data.mvno_is_refunded === false) : !isNull(data.mvno_deduct_id)) &&
                          <RefundBalanceButton
                            purchaseLogId={record.id}
                          />
                        }
                        <SyncStatusButton
                          transactionId={record.id}
                        />
                        <ExecLogButton recordId={record.id} />
                      </Box>
                      <MVNOHistories histories={record.histories} value={value} />
                    </>
                  );
                } else {
                  return (
                    <>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        pt={2}
                        style={{ gap: "0.5rem" }}
                      >
                        {value === 'hongkong' && (data.status === "FAILED") && (!isNull(data.mvno_deduct_id) ? (data.mvno_is_refunded === false) : !isNull(data.mvno_deduct_id)) &&
                          <RefundBalanceButton
                            purchaseLogId={record.id}
                          />
                        }
                        <SyncStatusButton
                          transactionId={record.id}
                        />
                        <ExecLogButton recordId={record.id} />
                      </Box>
                      <MVNOHistories histories={record.histories} value={value} />
                    </>
                  );
                }
              }}
            />
          </Tab>
          <Tab label="Override">
            <Override />
          </Tab>
          {value !== 'hongkong' && !isEmpty(PGWData) && (PGWData.channel) && (
            <Tab label="Payment Detail">
              <Grid container spacing={2}>
                <Grid item xs={4} marginTop={2}>
                  <strong>Status</strong>
                </Grid>
                <Grid item xs={8} marginTop={2}>
                  {PGWData.status}
                </Grid>
                <Grid item xs={4}>
                  <strong>Amount</strong>
                </Grid>
                <Grid item xs={8}>
                  {PGWData.amount}
                </Grid>
                <Grid item xs={4}>
                  <strong>Channel</strong>
                </Grid>
                <Grid item xs={8}>
                  {PGWData.channel}
                </Grid>
                <Grid item xs={4}>
                  <strong>Currency</strong>
                </Grid>
                <Grid item xs={8}>
                  {PGWData.currency}
                </Grid>
                <Grid item xs={4}>
                  <strong>Expiry</strong>
                </Grid>
                <Grid item xs={8}>
                  {PGWData.expiry}
                </Grid>
                <Grid item xs={4}>
                  <strong>Payment Code</strong>
                </Grid>
                <Grid item xs={8}>
                  {PGWData.payment_code}
                </Grid>
                <Grid item xs={4}>
                  <strong>Payment ID</strong>
                </Grid>
                <Grid item xs={8}>
                  {PGWData.payment_id}
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup variant="outlined" size="small">
                    <MaterialButton startIcon={<StartIcon />} onClick={() => openPgs('PGS', PGWData)}>
                      Open PGS
                    </MaterialButton>
                    <MaterialButton startIcon={<QrCodeIcon />} onClick={() => openPgs('BARCODE', PGWData)}>
                      View Barcode
                    </MaterialButton>
                    <MaterialButton startIcon={<ContentCopyIcon />} onClick={() => navigator.clipboard.writeText(PGWData.deeplink)}>
                      Copy URL
                    </MaterialButton>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Tab>
          )}
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
