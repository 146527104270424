import { store } from "@risingstack/react-easy-state";

const params = {
  submitConf: { method: "", data: {} },
  destinationCountry: "",
  originCountry: "",
  groupPrefix: [],
  groupPrefixEditing: false,
  groupPatternSelect: "CUSTOM",
  groupPattern: "",
  tableData: [],
} as {
  submitConf: { method: string; data: any };
  destinationCountry: string;
  originCountry: string;
  groupPrefix: Array<any>;
  groupPrefixEditing: boolean;
  groupPatternSelect: "CUSTOM" | "AUTO";
  groupPattern: string;
  tableData: Array<any>;
};
const tariffsStore = store(params);

export default tariffsStore;
