import { AutocompleteArrayInput, required, useGetList, useInput } from "react-admin";

interface ProductItemInputProps {
  formData: any;
  source: string;
}

const ProductItemInput: React.FC<ProductItemInputProps> = (props) => {
  const { source, formData } = props;
  const { field } = useInput({ source: "bonus_item_group" });
  let item_group: boolean;
  let filters: any;

  if (formData.bonus_item_group?.length === 0) {
    item_group = true;
  } else {
    field.value = formData.bonus_item_group
    item_group = false;
  }

  const { data, isLoading } = useGetList("productCatalogs", {
    filter: {
      ...(field.value ? { item_group: field.value } : {}),
      ...filters,
    },
    pagination: {
      perPage: 1000,
      page: 1,
    },
  });

  return (
    <AutocompleteArrayInput
      choices={data}
      label="Item key"
      optionValue="item_key"
      optionText="item_key"
      loadingText="Loading..."
      isOptionEqualToValue={(options, value) => {
        return options.id === value.id;
      }}
      loading={isLoading}
      disabled={item_group}
      debounce={500}
      source={source}
      margin="normal"
      sx={{ marginRight: "50px" }}
      validate={[required()]}
    />
  );
};

export default ProductItemInput;
