import * as React from "react";
import {
  FileInput,
  FileField,
  SimpleForm,
  ExportButton,
  downloadCSV,
  required,
  useCreate,
  Create,
  Confirm,
  useNotify,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { endOfToday, format } from "date-fns";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jsonExport from "jsonexport/dist";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DescriptionIcon from "@mui/icons-material/Description";
import mbaas from "../../../provider/Mbaas";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export interface CustomizedDialogsProps {
  openDialog: boolean;
  handleClose: () => void;
}

type userData = {
  id: string;
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DownloadMaster = async () => {
  jsonExport(
    [{}],
    {
      headers: ["country", "msisdn", "hkd_price"],
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "batch-add-virtual-msisdn-template");
    }
  );
};

export default function CustomizedDialogs(props: CustomizedDialogsProps) {
  const { handleClose, openDialog } = props;
  const [fileUpload, setFileUpload] = React.useState("");
  const [userData, setUserData] = React.useState<userData>({
    id: "",
  });
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirm2, setOpenConfirm2] = React.useState(false);
  const navigate = useNavigate();
  const notify = useNotify();
  //
  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData({ id: res?.data?.id });
    }
    if (res?.error) {
      notify(`${res?.error?.title}`, { type: "error" });
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);
  //
  const [uploadData, { data, isLoading, error, isSuccess }] = useCreate();
  if (error) {
    throw new Error("error request");
  }
  //
  React.useEffect(() => {
    if (isSuccess === true) setOpenConfirm(true);
  }, [isSuccess]);
  //
  const ToolbarEdit = () => {
    return <></>;
  };
  //
  const handleUpload = async () => {
    const response: any = await mbaas.client.storage.upload({
      file: fileUpload,
      params: {
        filename: `upload-csv-vm-${format(
          new Date(endOfToday()),
          "yyyy-MM-dd"
        )}`,
        bucket: "documents",
        signed: false,
        expire: "60",
      },
    });

    console.log(response);

    uploadData("documentsBatch", {
      data: {
        type: "VIRTUAL_MSISDN_BATCH",
        author_id: userData.id,
        // -- the right file name is this one
        filename: `upload-csv-vm-${format(
          new Date(endOfToday()),
          "yyyy-MM-dd"
        )}`,
        status: "QUEUED",
      },
    });

    setOpenConfirm2(false);
  };

  const ConfirmDialogContent = () => {
    return (
      <Paper elevation={0}>
        <Typography variant="body1" gutterBottom>
          Your file is being processed with name "{data.filename}", you can see
          the progress by visiting batch page.
        </Typography>
      </Paper>
    );
  };

  const navigateToBatchPage = () => {
    navigate(encodeURI(`/documents`));
  };

  return (
    <div>
      <Create component="div" title=" ">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          fullWidth
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Batch Add
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <SimpleForm toolbar={<ToolbarEdit />} title=" ">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ExportButton
                    variant="outlined"
                    startIcon={<DescriptionIcon />}
                    label="Download Template"
                    exporter={DownloadMaster}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileInput
                    source="files"
                    onChange={(e: any) => setFileUpload(e)}
                    validate={[required()]}
                    options={{ multiple: false }}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </Grid>
              </Grid>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              startIcon={isLoading ? "" : <UploadFileIcon />}
              disabled={!fileUpload}
              onClick={() => setOpenConfirm2(true)}
              data-testid="upload-btn"
            >
              {isLoading ? <CircularProgress size="27px" /> : "Upload"}
            </Button>
            {/* Yes/No confirm dialog */}
            <Confirm
              isOpen={openConfirm2}
              title="Dear User,"
              content="Are you sure want to upload this file?"
              onClose={() => setOpenConfirm2(false)}
              confirm="Yes"
              onConfirm={handleUpload}
            />
            {/* This dialog prompt will appear as soon as the upload is success */}
            <Confirm
              isOpen={openConfirm}
              title="Dear User,"
              content={<ConfirmDialogContent />}
              onClose={() => setOpenConfirm(false)}
              confirm="Go To Batch"
              onConfirm={navigateToBatchPage}
            />
          </DialogActions>
        </BootstrapDialog>
      </Create>
    </div>
  );
}
