import { configureStore } from "@reduxjs/toolkit";
import purchaseLogFilterSlice from "./slices/purchaseLogPage/filterPurchaseLogSlice";
import smsLogFilterSlice from "./slices/smsLogPage/filterSmsLogSlice";
import voiceLogFilterSlice from "./slices/voiceLogPage/filterVoiceLogSlice";
import reloadLogFilterSlice from "./slices/reloadLogPage/filterReloadLogSlice";
import packagesListFilterSlice from "./slices/packagesListPage/filterPackagesListlice";
import productsListFilterSlice from "./slices/productsListPage/filterProductsListlice";
import subscribersFilterSlice from "./slices/subscribersPage/filterSubscribersSlice";
import bannerPromotionsFilterSlice from "./slices/bannerPromotionsPage/bannerPromotionsSlice";
import broadcastFilterSlice from "./slices/broadCastPage/broadcastSlice";
import saleProgramFilterSlice from "./slices/saleProgramsPage/filterSaleProgramSlice";
import referralBenefitFilterSlice from "./slices/referralBenefitsPage/filterReferralBenefitSlice";
import deviceLogsFilterSlice from "./slices/deviceLogsPage/filterDeviceLogsSlice";
import mvnoFilterSlice from "./slices/mvnoPage/filterMvnoSlice";

export const store = configureStore({
  reducer: {
    thisPurchaseLogFilter: purchaseLogFilterSlice,
    thisSmsLogFilter: smsLogFilterSlice,
    thisVoiceLogFilter: voiceLogFilterSlice,
    thisReloadLogFilter: reloadLogFilterSlice,
    thisPackagesListFilter: packagesListFilterSlice,
    thisProductsListFilter: productsListFilterSlice,
    thisMvnoFilter: mvnoFilterSlice,
    thisSubscribersFilter: subscribersFilterSlice,
    thisBannerPromotionsFilter: bannerPromotionsFilterSlice,
    thisBroadcastFilter: broadcastFilterSlice,
    thisSaleProgramFilter: saleProgramFilterSlice,
    thisReferralBenefitFilter: referralBenefitFilterSlice,
    thisDeviceLogsFilter: deviceLogsFilterSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
