import { AutocompleteInput, RaRecord, required, useInput } from "react-admin";

interface CategoryInputProps {
  isLoading?: boolean;
  formData: any;
}

const CategoryInput: React.FC<CategoryInputProps> = (props) => {
  const { formData, isLoading } = props;
  const { field } = useInput({ source: "item_group" });

  let choices: any;
  if (formData.availability_by_service === "M21") {
    choices = {
      "AUTO-ORDER": [
        { id: "IDD", name: "IDD" },
        { id: "SMS", name: "SMS" },
        { id: "RELOAD", name: "RELOAD" },
      ],
      "COUPON-CODE": [{ id: "VAS", name: "VAS" }],
      "VOUCHER-CODE": [{ id: "SOUVENIR", name: "SOUVENIR" }],
    };
  } else if (formData.availability_by_service === "GHK") {
    choices = {
      "AUTO-ORDER": [
        { id: "IDD", name: "IDD" },
        { id: "SMS", name: "SMS" }
      ],
      "COUPON-CODE": [{ id: "VAS", name: "VAS" }],
      "VOUCHER-CODE": [{ id: "SOUVENIR", name: "SOUVENIR" }],
    };
  } else if (formData.availability_by_service === "G2") {
    choices = {
      "COUPON-CODE": [{ id: "VAS", name: "VAS" }],
      "VOUCHER-CODE": [{ id: "SOUVENIR", name: "SOUVENIR" }],
    };
  }

  return (
    <AutocompleteInput
      source="category"
      optionValue="id"
      optionText="name"
      label="Category"
      isOptionEqualToValue={(options: RaRecord, value: RaRecord) => {
        return options.id === value.id;
      }}
      fullWidth
      readOnly={isLoading}
      validate={required()}
      onChange={() => field.onChange(null)}
      choices={formData.delivery_mode ? choices[formData.delivery_mode] : []}
    />
  );
};

export default CategoryInput;
