// @ts-nocheck
import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Pagination,
  DateField,
  FilterButton,
  Button,
  useListContext,
  Confirm,
  useCreate,
} from "react-admin";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "../DateRange";
import { Detail } from "./Detail";
import { Drawer, Paper, Typography } from "@mui/material";
import { endOfToday, startOfToday, subMonths, format } from "date-fns";
import GetAppIcon from "@mui/icons-material/GetApp";
import mbaas from "../../../src/provider/Mbaas";
import { useDispatch } from "react-redux";
import { setSmsLogFilter } from "src/slices/smsLogPage/filterSmsLogSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    smsLogFilter,
    navigate,
    ...rest
  } = props;

  const [userData, setUserData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  usePersistFilter();

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  // -- export button
  const ExportSmsButton = () => {
    const { filterValues } = useListContext();
    //
    const type_name = "usages-sms";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    console.log(737, "filterValues", filterValues);
    //
    const [exportData, { data, isLoading, error }] = useCreate("reports", {
      data: {
        author_id: userData?.id,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            ...filterValues,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"usages-sms"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button label="Export SMS Data" onClick={() => handleOnClick()}>
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </Button>
    );
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={smsLogFilter} />
      <ExportSmsButton />
    </TopToolbar>
  );
};

const SmsLogPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export const SmsLogList = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelDrawer = useCallback(() => {
    navigate(`/usages/sms`);
  }, [navigate]);

  const match = matchPath("/usages/sms/:id/show/*", location.pathname);

  const SmsLogFilter = [
    <DateRange alwaysOn data-testid="search-date-range" />,
    <ReferenceInput
      source="virtual_msisdn_id"
      reference="virtualMsisdn"
      label="Number"
    >
      <AutocompleteInput
        fullWidth
        source="msisdn"
        optionText="msisdn"
        label="Number"
        data-testid="search-number"
        optionText={(choice) => choice.msisdn}
        onChange={(e) => dispatch(setSmsLogFilter({ virtual_msisdn_id: e }))}
      />
    </ReferenceInput>,
  ];

  return (
    <>
      <List
        resource="smsLogs"
        title="Consumptions &rsaquo; SMS"
        filters={SmsLogFilter}
        sort={{ field: "created_at", order: "DESC" }}
        actions={
          <ListActions navigate={navigate} smsLogFilter={SmsLogFilter} />
        }
        perPage={50}
        pagination={<SmsLogPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? "600px" : 0,
        }}
        filterDefaultValues={{
          dateGte: subMonths(startOfToday(), 1),
          dateLte: endOfToday(),
        }}
        {...props}
      >
        <Datagrid
          rowClick={(id) => {
            navigate(`/usages/sms/${id}/show`);
          }}
          key={(id) => id}
          bulkActionButtons={false}
        >
          <DateField
            source="created_at"
            label="Date"
            showTime
            locales="en-HK"
            data-testid="list-date"
          />
          <TextField source="direction" />
          <TextField source="a_number" label="A Number" sortable={false} />
          <TextField source="b_number" label="B Number" sortable={false} />
          <TextField source="status" />
          <TextField source="hkd_cost" label="Cost" />
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <Detail id={(match as any).params.id} onCancel={handleCancelDrawer} />
        )}
      </Drawer>
    </>
  );
};
