import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import mbaas from "../../provider/Mbaas";
import { useNotify, useRefresh } from "react-admin";

const rolesList = ["Admin", "Super Admin", "Staff"];

export default function InviteUserDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [roles, setRoles] = useState<string[]>([]);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // const [block, setBlock] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const notify = useNotify();
  const refresh = useRefresh();
  const handleChange = (event: SelectChangeEvent<typeof roles>) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const resetState = () => {
    setRoles([]);
    setName("");
    setEmail("");
    setToken("");
  };

  const submit = async () => {
    setLoading(true);
    try {
      const res = await mbaas.client.invokeApi("console-invite-user", {
        token,
        data: {
          email,
          roles,
          name,
        },
      });

      console.log(res);
      if (res.error) {
        notify(res.error.error, { type: "warning" });
        setLoading(false);
        return;
      }

      resetState();
      refresh();
      notify("Success Invite User", { type: "success" });
      setOpen(false);
    } catch (e) {
      console.log(e);
      notify("Something went wrong", { type: "warning" });
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            <PersonAddAltRoundedIcon /> Invite User
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invite user, token get from google authenticator
          </DialogContentText>
          <Grid
            container
            direction="column"
            gap={1}
            style={{ marginTop: "10px" }}
          >
            <TextField
              label="Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <FormControl variant="filled" required>
              <InputLabel id="demo-multiple-name-label">Roles</InputLabel>
              <Select
                id="demo-multiple-name"
                variant="filled"
                value={roles}
                fullWidth
                // multiple
                onChange={handleChange}
                // input={<OutlinedInput label="Name" />}
                // renderValue={(selected) => (
                //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                //     {selected.map((value) => (
                //       <Chip key={value} label={value} />
                //     ))}
                //   </Box>
                // )}
              >
                {rolesList.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Token"
              value={token}
              required
              fullWidth
              onChange={(e) => setToken(e.target.value)}
            />
            {/* <FormControlLabel
              control={<Switch color="primary" />}
              label="Blocked User"
              labelPlacement="end"
              checked={block}
              onChange={() => setBlock(!block)}
            /> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            // disabled={loading}
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            size="small"
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Send"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
