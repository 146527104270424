import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  Pagination,
  DateField,
  BooleanField,
  AutocompleteInput,
  ReferenceInput,
  usePermissions,
  TextInput,
  Button,
  RaRecord,
} from "react-admin";
import {
  matchPath,
  useLocation,
  useNavigate,
  Link,
  useParams,
} from "react-router-dom";
import { Detail } from "./Detail";
import { Drawer, Button as MaterialButton } from "@mui/material";
import { EditPackage } from "./Edit";
import { CreatePackage } from "./Create";
import UpdateIcon from "@mui/icons-material/Update";
import UploadDialog from "../UploadDialog";
import mbaas from "../../../../src/provider/Mbaas";
import CustomExportButton from "../../CustomExportButton";
import { useDispatch } from "react-redux";
import { setPackagesListFilter } from "src/slices/packagesListPage/filterPackagesListlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";

const ServicesPackagePagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export interface UpdateProductCsvInterface {
  openDialog: {
    isOpen: boolean;
    dialogType: string;
  };
  setOpenDialog: (props: OpenStateDialogInterface) => any;
}

const UpdateProductCsv = (props: UpdateProductCsvInterface) => {
  const { openDialog, setOpenDialog } = props;

  const handleClickOpenDialog = (dialogType: string) => {
    setOpenDialog({ isOpen: true, dialogType: dialogType });
  };
  const handleCloseDialog = (dialogType: string) => {
    setOpenDialog({ isOpen: false, dialogType: dialogType });
  };

  return (
    <>
      <Button
        label="Update Product"
        onClick={() => handleClickOpenDialog("internal-product")}
        data-testid="update-product"
      >
        <UpdateIcon />
      </Button>
      <Button
        label="Update Pricing"
        onClick={() => handleClickOpenDialog("internal-pricing")}
        data-testid="update-pricing"
      >
        <UpdateIcon />
      </Button>

      <UploadDialog
        openDialog={openDialog.isOpen}
        dialogType={openDialog.dialogType}
        handleClickOpen={() => handleClickOpenDialog(openDialog.dialogType)}
        handleClose={() => handleCloseDialog(openDialog.dialogType)}
      />
    </>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    openDialog,
    setOpenDialog,
    userData,
    ...rest
  } = props;
  const { isLoading, permissions } = usePermissions();
  usePersistFilter();
  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {isAdmin && (
        <>
          <CustomExportButton exportBtnType="packages" userData={userData} />
          <UpdateProductCsv
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
          <MaterialButton
            color="primary"
            size="small"
            data-testid="create_button"
            component={Link}
            to={{
              pathname: "/services/package/create",
            }}
          >
            + Add new Package
          </MaterialButton>
        </>
      )}
    </TopToolbar>
  );
};

export interface OpenStateDialogInterface {
  isOpen: boolean;
  dialogType: string;
}

export const ServicesPackageList = () => {
  const [openDialog, setOpenDialog] = React.useState<OpenStateDialogInterface>({
    isOpen: false,
    dialogType: "",
  });
  const [userData, setUserData] = React.useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { id: idDataRecord } = useParams();

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const handleCancelDrawer = useCallback(() => {
    navigate(`/services/package`);
  }, [navigate]);
  const matchCreate = matchPath("/services/package/create", location.pathname);
  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath("/services/package/:id", location.pathname);
  const matchShow = matchPath(
    "/services/package/:id/show/*",
    location.pathname
  );

  const rowStyleBySelectedRecord = (record: RaRecord) => {
    let bgColor = "";
    if (record.id === idDataRecord) {
      bgColor = "#f2f2f2";
    }

    return {
      backgroundColor: bgColor,
    };
  };

  const ServicesPackageFilter = [
    <ReferenceInput
      filter={{ isVas: false }}
      source="id"
      reference="productCatalogsItemGroup"
      alwaysOn
    >
      <AutocompleteInput
        sx={{ width: "300px" }}
        fullWidth
        source="item_group"
        optionText="item_group"
        label="Item Group"
        data-testid="search-item-group"
        //@ts-ignore
        onChange={(e) => dispatch(setPackagesListFilter({ id: e }))}
      />
    </ReferenceInput>,
    <TextInput
      alwaysOn
      data-test-id="search-by-label"
      source="label"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setPackagesListFilter({ label: e.target.value }))
      }
    />,
  ];

  return (
    <>
      <List
        empty={false}
        resource="servicesPackage"
        title="Services &rsaquo; Package"
        filter={{ isVas: false, isMvno: false }}
        filters={ServicesPackageFilter}
        sort={{ field: "item_key", order: "ASC" }}
        actions={
          <ListActions
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            userData={userData}
          />
        }
        perPage={25}
        pagination={<ServicesPackagePagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight:
            !!matchCreate || !!matchShow || !!matchEdit ? "600px" : 0,
        }}
      >
        <Datagrid
          // @ts-ignore
          rowClick={(id) => {
            navigate(`/services/package/${id}/show`);
          }}
          // @ts-ignore
          key={(id) => id}
          bulkActionButtons={false}
          rowStyle={rowStyleBySelectedRecord}
        >
          <DateField
            source="created_at"
            label="Date Added"
            showTime
            locales="en-HK"
            options={{
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }}
          />
          <TextField source="item_key" label="Item Key" />
          <TextField source="item_group" label="Item Group" />
          <TextField source="label" label="Label" />
          <TextField source="description" label="Description" />
          <TextField source="retail_price" label="Price" />
          <BooleanField source="is_available" label="Available?" />
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!matchCreate}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchCreate && <CreatePackage onCancel={handleCancelDrawer} />}
      </Drawer>
      <Drawer
        variant="persistent"
        open={!!matchShow}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchShow && (
          <Detail
            id={(matchShow as any).params.id}
            onCancel={handleCancelDrawer}
            match={matchShow}
          />
        )}
      </Drawer>
      <Drawer
        variant="persistent"
        open={!!matchEdit}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchEdit && (
          <EditPackage
            id={(matchEdit as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};
