import * as React from "react";
import MaterialTable, { Column } from "@material-table/core";
import mbaas from "../../../provider/Mbaas";
import gql from "graphql-tag";
import Swal from "sweetalert2";

import { Confirm, useRecordContext, usePermissions } from "react-admin";
import { Paper, TextField, Typography } from "@mui/material";
import CountrySelector from "../../CountrySelector";
// @ts-ignore
import countryList from "react-select-country-list";

import { differenceWith, toPairs, isEqual, fromPairs } from "lodash";

interface IMvnoPricingItem {
  id: string;
  value: number;
  country: string;
  product_pricing_id: string;
  product_pricing_country: string;
  is_available: boolean;
  schema: string;
}

const MvnoPricing = () => {
  const record = useRecordContext();

  const { isLoading: isLoading2, permissions } = usePermissions();

  console.log("permissions", permissions);

  const isAdmin =
    !isLoading2 &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  const mapData = (rawData: any) => {
    return rawData.map((x: any) =>
      Object.assign({
        product_item_id: record.id,
        ...x,
        // product_pricing_id: x.id,
        // product_pricing_country: x.country,
        // product_pricing_schema: x.schema,
        // product_pricing_value: x.value,
      })
    );
  };

  const originData = mapData(
    record.product_pricings_product_catalog_id_list.data
  );
  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(originData);
  //
  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitConf, setSubmitConf] = React.useState({ method: "", data: {} });
  const [destinationCountry, setDestinationCountry] = React.useState("");
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    const { error, data } = await mbaas.client.invokeApi(submitConf.method, {
      ...submitConf.data,
      token: otpCode,
    });
    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      initData();
    }
    setIsLoading(false);
    setOpen(false);
  };
  //

  // initData here
  const initData = async () => {
    setIsTableLoading(true);
    const { getProductCatalogsById } = await mbaas.client.gql.query({
      query: gql`
        query aServiceProduct($id: UUID!) {
          getProductCatalogsById(id: $id) {
            id
            created_at
            updated_at
            item_key
            item_group
            label
            description
            retail_price
            inquiry_rules
            execution_rules
            is_available
            is_vas
            vas_sync_time
            vas_sync_detail
            product_pricings_product_catalog_id_list {
              data {
                id
                created_at
                updated_at
                product_catalog_id
                country
                schema
                value
              }
              count
            }
          }
        }
      `,
      variables: {
        id: record.id,
      },
    });

    //
    const mappedData = mapData(
      getProductCatalogsById.product_pricings_product_catalog_id_list.data
    );

    setTableData(mappedData);
    setIsTableLoading(false);
  };
  //
  const columns: Array<Column<IMvnoPricingItem>> = [
    {
      title: "Country",
      field: "country",
      render: (rowData) =>
        rowData.country === "GLOBAL"
          ? "WORLDWIDE"
          : `${countryList().getLabel(rowData.country)} (${rowData.country})`,
      editComponent(props) {
        return (
          <CountrySelector
            destinationCountry={destinationCountry}
            setDestinationCountry={setDestinationCountry}
          />
        );
      },
    },
    {
      title: "Value",
      field: "value",
      editComponent(props) {
        return (
          <TextField
            value={props?.rowData?.value ? props?.rowData?.value : null}
            onChange={(data) => props.onChange(+data.target.value)}
            id="value"
            label="Value"
            variant="standard"
            type="number"
          />
        );
      },
    },
  ];

  const editableObj = {
    onRowAdd: async (newData: any) => {
      if (Object.keys(newData).length === 0) {
        Swal.fire("There is no data to be added!", "", "error");
      } else {
        const { value } = newData;
        //
        setOtpCode("");
        setSubmitConf({
          method: "console-add-pricings",
          data: {
            data: {
              product_catalog_id: record.id,
              // @ts-ignore
              country: destinationCountry?.value,
              value: value,
            },
          },
        });
        setOpen(true);
      }
    },
    onRowUpdate: async (newData: any, oldData: any) => {
      console.log(234, {
        oldData,
        newData,
      });
      // @ts-ignore
      if (oldData.country !== destinationCountry.value) {
        // @ts-ignore
        newData.country = destinationCountry.value;
      }
      // compare old data with new data and send the new one
      const changes = differenceWith(
        toPairs(newData),
        toPairs(oldData),
        isEqual
      );
      const changes2 = fromPairs(changes);
      //
      if (changes2.is_available) {
        changes2.is_available = changes2.is_available === "true" ? true : false;
      }
      //
      if (Object.keys(changes2).length === 0) {
        Swal.fire("There is no data to be updated!", "", "error");
      } else {
        setOtpCode("");
        setSubmitConf({
          method: "console-update-pricings",
          data: {
            data: {
              ...changes2,
            },
            id: newData.id,
          },
        });
        setOpen(true);
      }
    },
  };

  // show editabled when super admin or admin
  const showEditTable = isAdmin ? { ...editableObj } : {};

  if (!record) return null;
  return (
    <>
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Typography variant="h5" gutterBottom marginTop={1} marginBottom={3}>
        {record.label}
      </Typography>
      <Paper>
        <MaterialTable
          columns={columns}
          data={tableData}
          // @ts-ignore
          title={false}
          editable={{
            ...showEditTable,
          }}
        />
      </Paper>
    </>
  );
};

export default MvnoPricing;
