import React, { useCallback } from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  TextField,
  Pagination,
  DateField,
  usePermissions,
  NumberField,
  Identifier,
  RowClickFunction,
} from "react-admin";
import { matchPath, useLocation, useNavigate, Link } from "react-router-dom";
import { Drawer, Button as MaterialButton } from "@mui/material";
import { EditCurrencyMsisdn } from "./Edit";
import { CreateCurrencyMsisdn } from "./Create";

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    openDialog,
    setOpenDialog,
    ...rest
  } = props;
  const { isLoading, permissions } = usePermissions();
  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {isAdmin && (
        <>
          <MaterialButton
            color="primary"
            size="small"
            data-testid="create_button"
            component={Link}
            to={{
              pathname: "/internal/currency-prefix/create",
            }}
          >
            + Add new Prefix
          </MaterialButton>
        </>
      )}
    </TopToolbar>
  );
};

const CurrencyMsisdnPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

export const CurrencyMsisdnList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCancelDrawer = useCallback(() => {
    navigate(`/internal/currency-prefix`);
  }, [navigate]);
  const matchCreate = matchPath(
    "/internal/currency-prefix/create",
    location.pathname
  );
  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath("/internal/currency-prefix/:id", location.pathname);
  const matchShow = matchPath(
    "/internal/currency-prefix/:id/show/*",
    location.pathname
  );

  const { isLoading, permissions } = usePermissions();
  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  const rowClickHandler = (id: Identifier) => {
    if (isAdmin) {
      navigate(`/internal/currency-prefix/${id}`);
    }
  };

  return (
    <>
      <List
        empty={false}
        resource="currencyMsisdn"
        title="Internal &rsaquo; Currency Prefix"
        sort={{ field: "created_at", order: "DESC" }}
        actions={<ListActions />}
        perPage={25}
        pagination={<CurrencyMsisdnPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight:
            !!matchCreate || !!matchShow || !!matchEdit ? "600px" : 0,
        }}
      >
        <Datagrid
          rowClick={
            ((id: Identifier) =>
              rowClickHandler(id)) as unknown as RowClickFunction
          }
          key={((id: Identifier) => id) as unknown as React.Key}
          bulkActionButtons={false}
        >
          <DateField
            source="created_at"
            label="Date Added"
            showTime
            locales="en-HK"
            options={{
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }}
          />
          <NumberField source="msisdn_prefix" label="MSISDN Prefix" />
          <TextField source="currency" />
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={!!matchCreate || !!matchEdit}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchCreate && (
          <CreateCurrencyMsisdn onCancel={handleCancelDrawer} />
        )}
        {!!matchEdit && (
          <EditCurrencyMsisdn
            id={(matchEdit as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};
