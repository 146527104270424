import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IvoiceLog {
  voiceLogFilter: {
    number: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: IvoiceLog = {
  voiceLogFilter: {
    number: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type voiceLogFilter = {
  number: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const voiceLogFilterSlice = createSlice({
  name: "thisVoiceLogFilter",
  initialState,
  reducers: {
    setVoiceLogFilter: (state, action: PayloadAction<voiceLogFilter>) => {
      state.voiceLogFilter = {
        ...state.voiceLogFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setVoiceLogFilter } = voiceLogFilterSlice.actions;

export const getVoiceLogFilter = (state: RootState) =>
  state.thisVoiceLogFilter.voiceLogFilter;

export default voiceLogFilterSlice.reducer;
