import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IbannerPromotions {
  bannerPromotionsFilter: {
    country: string;
    type: string;
    platform: string;
  };
}

const initialState: IbannerPromotions = {
  bannerPromotionsFilter: {
    country: "HK",
    type: "special",
    platform: "M21",
  },
};

type bannerPromotionsFilter = {
  country: string;
  type: string;
  platform: string;
};

export const bannerPromotionsFilterSlice = createSlice({
  name: "thisBannerPromotionsFilter",
  initialState,
  reducers: {
    setBannerPromotionsFilter: (
      state,
      action: PayloadAction<bannerPromotionsFilter>
    ) => {
      state.bannerPromotionsFilter = {
        ...state.bannerPromotionsFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setBannerPromotionsFilter } =
  bannerPromotionsFilterSlice.actions;

export const getBannerPromotionsFilter = (state: RootState) =>
  state.thisBannerPromotionsFilter.bannerPromotionsFilter;

export default bannerPromotionsFilterSlice.reducer;
