import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack } from "@mui/material";
import {
    Create,
    useGetIdentity,
    useNotify,
    useRedirect,
    useRefresh
} from "react-admin";
import Swal from "sweetalert2";
import { dataProvider } from "../../../provider";
import { omit } from "lodash";
import Mbaas from "../../../provider/Mbaas";
import RewardForm from "./components/RewardForm";

interface CreateServiceRewardsProps {
    onClose: () => void;
}

export const CreateServiceRewards: React.FC<CreateServiceRewardsProps> = (props) => {
    const { onClose, ...restProps } = props;
    const { isLoading: isIdLoading, identity: user } = useGetIdentity();

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const [loading, setLoading] = useState<boolean>(false);

    const isLoading = loading || isIdLoading;

    const onSubmitHandler = async (values: any) => {
        setLoading(true)
        try {
            const {
                item_detail,
                category,
                item_key,
                item_group,
                availability_by_service,
                is_bonus,
                bonus_item_key,
                start,
                end,
                limit,
                amount,
                ...restValue
            } = values;

            let codes = {};

            if(item_detail.codes){
               codes = item_detail.codes.map((item:any) => ({
                code:item.code,
                "redeemed": false,
                "purchased": false,
                "redeemed_at": "",
                "purchased_at":""
            }))}

            const payloads: any = {
                ...omit(restValue, ['item_label', 'redemption_amount', 'redeem_limits', 'bonus_item_group']),
                item_label: {},
                redemption_amount: {},
                redeem_limits: {},
                availability_by_service: [availability_by_service],
                author_id: user?.id,
                item_detail: {
                    codes,
                    image:item_detail.image,
                    item: {
                        category,
                        item_key,
                        item_group,
                        limit,
                        amount,
                    }
                },
                update_history: []
            }

            if (values.item_label) {
                values.item_label.forEach((m: any) => {
                    payloads["item_label"][m.country] = m.label
                })
            }

            if (values.redemption_amount) {
                values.redemption_amount.forEach((m: any) => {
                    payloads["redemption_amount"][m.country] = {
                        coin: Number(m.coin),
                        point: Number(m.point),
                    }
                })
            }

            if (values.redeem_limits) {
                console.log('Masuk')
                payloads["redeem_limits"] = {
                    daily: Number(values.redeem_limits.daily),
                    monthly: Number(values.redeem_limits.monthly),
                    minimum_balance: {
                        coin: Number(values.redeem_limits.minimum_balance.coin),
                        point: Number(values.redeem_limits.minimum_balance.point)
                    }
                }
            }

            if (item_detail.image) {
                const uploadImage: any = await Mbaas.client.storage.upload({
                    file: item_detail.image?.rawFile,
                    params: {
                        filename: `reward-${Date.now()}`,
                        bucket: "banners",
                    }
                })

                const download = await Mbaas.client.storage.downloadUrl({
                    params: {
                        bucket: "banners",
                    },
                    fileId: uploadImage?.data?.id,
                });

                const urlImg = download.data.url.split("?");
                payloads['item_detail']["image"] = urlImg[0]
            }

            if(is_bonus){
                payloads.item_detail.bonus_for = {item_keys: bonus_item_key,
                    start,
                    end
                }
            }
            const resp = await dataProvider.create('rewardCatalogues', { data: payloads });
            if (resp.data) {
                notify("ra.notification.created", {
                    type: "info",
                    messageArgs: { smart_count: 1 },
                    undoable: true,
                });
                redirect(`/services/rewards`);
                refresh();
                Swal.fire("Success!", "", "success");
            }
        } catch (error) {
            Swal.fire(`Request failed: ${error}`, "", "error");
        } finally {
            setLoading(false)
        }
    }

    return (
        <Create resource="rewardCatalogues" title=" &rsaquo; Create" {...restProps}>
            <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
                <Stack
                    direction="row"
                    p={0}
                    sx={{ position: "absolute", right: 10, zIndex: 100 }}
                >
                    <IconButton color="error" onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <RewardForm isLoading={isLoading} onSubmit={onSubmitHandler} type="add" />
            </Box>
        </Create>
    )
}