import { Drawer } from "@mui/material"
import moment from "moment";
import { useCallback, useState } from "react";
import { AutocompleteInput, ChipField, Datagrid, DateField, FilterButton, FunctionField, List, NumberField, RaRecord, SearchInput, TextField, TopToolbar, useListContext } from "react-admin"
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { UsageRewardDetail } from "./Detail";
import { get, isEmpty } from "lodash";

const rowStyleByStatus = (record: any) => {
    let bgColor = "transaparent";
    switch (record.status.toLowerCase()) {
        case "purchased":
            bgColor = "#dcf8c6"
            break;
        case "redeemed":
            bgColor = "#ffe4e4"
            break;
        default:
            break;
    }
    return {
      backgroundColor: bgColor,
    };
};

const DateRange = (props: any) => {
    const { filterValues, displayedFilters, setFilters } = useListContext();
    const [dateValue, setDateValue] = useState<any>({
        dateGte: filterValues.dateGte ? moment(filterValues.dateGte).startOf("day").format() : null,
        dateLte: filterValues.dateLte ? moment(filterValues.dateLte).endOf("day").format() : null,
    });

    const dateBegin = filterValues.dateGte ? new Date(moment(filterValues.dateGte).startOf("day").format()) : null;
    const dateEnd = filterValues.dateLte ? new Date(moment(filterValues.dateLte).endOf("day").format()) : null;

    return (
        <DateRangePicker 
            defaultValue={dateBegin && dateEnd ? [dateBegin, dateEnd ] : null}
            value={ dateValue.dateGte && dateValue.dateLte? [new Date(dateValue.dateGte), new Date(dateValue.dateLte)] : null}
            onClean={() => {
                setDateValue({
                    dateGte: null,
                    dateLte: null,
                });
                const updateFilter = {
                    ...filterValues,
                    dateGte: null,
                    dateLte: null
                };
                setFilters(updateFilter, displayedFilters);
            }}
            onChange={(value) => {
                const start = value ? moment(value[0].toString()).startOf("day").format() : null
                const end = value ? moment(value[1].toString()).endOf("day").format() : null

                setDateValue({
                  dateGte: start,
                  dateLte: end,
                });
                const updateFilter = {
                    ...filterValues,
                    dateGte: start,
                    dateLte: end
                };
                setFilters(updateFilter, displayedFilters);
              }}
        />
    )
}

const ListActions = ({filters}: any) => {
    return (
        <TopToolbar>
            <FilterButton filters={filters} />
        </TopToolbar>
    )
}


const RewardFilters = [
    <DateRange alwaysOn />,
    <SearchInput source="code" />,
    <AutocompleteInput
      label="Status"
      source="status"
      choices={[
        { id: "PURCHASED", name: "PURCHASED" },
        { id: "REDEEMED", name: "REDEEMED" },
      ]}
      data-testid="search-status"
      sx={{
        minWidth: '250px'
      }}
    />
];

export const UsageRewardList: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const match = matchPath("/usages/reward/:id/show/*", location.pathname);

    const handleCancelDrawer = useCallback(() => {
        navigate(`/usages/reward`);
      }, [navigate]);

    return (
        <>
            <List
                resource='rewardLogs'
                sort={{ field: "created_at", order: "DESC" }}
                title="Usages &rsaquo; Rewards"
                empty={false}
                filters={RewardFilters}
                disableSyncWithLocation
                actions={<ListActions filters={RewardFilters} />}
            >
                <Datagrid
                    size="small"
                    rowStyle={rowStyleByStatus}
                    bulkActionButtons={false}
                    rowClick={(id) => `/usages/reward/${id}/show`}
                >    
                    <DateField
                        source="created_at"
                        label="Date"
                        showTime
                        locales="en-HK"
                        options={{
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                        }}
                    />
                    <TextField source="code" label="Code"/>
                    <ChipField sx={{ margin: 0 }} label="Status" size="small" source="status"/>
                    <FunctionField 
                        render={(record: RaRecord) => {
                            const rewardLabel = get(record, 'reward_detail.item_label', {});
                            const subsInfo = get(record, 'subscriber_info.subscriber', {});
                            return !isEmpty(subsInfo) ? rewardLabel[subsInfo.country] : null
                        }} 
                        label="Reward" 
                        sortable={false}
                    />
                    <TextField label="Subscriber" source="subscriber_info.subscriber.name" sortable={false}/>
                    <NumberField label="Deducted Point" source="subscriber_info.deducted_balance.point" sortable={false}/>
                </Datagrid>
            </List>
            <Drawer
                variant="persistent"
                open={!!match}
                anchor="right"
                onClose={handleCancelDrawer}
                sx={{ zIndex: 100 }}
            >
                {!!match && <UsageRewardDetail 
                     id={(match as any).params.id}
                     onCancel={handleCancelDrawer}
                />}
            </Drawer>
        </>
    )
}
