/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Grid,
  CircularProgress,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import { Google } from "@mui/icons-material";
import ColorVal from "../utils/color";
import { useLogin, useNotify, useRedirect } from "react-admin";
import mbaas from "../provider/Mbaas";

function getparam(parameterName: String) {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export default function LoginScreen() {
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify = async () => {
    const code = getparam("code");
    const socmed = getparam("prompt") ? "google" : "facebook";
    if (!code) {
      return;
    } else {
      setLoadingGoogle(true);

      try {
        const loginRes = await mbaas.client.auth.login(socmed, {
          callback: `${window.location.origin}/login`,
          code,
        });

        console.log(loginRes);

        if (!loginRes.data) {
          setLoadingGoogle(false);
          notify("invalid code", { type: "warning" });
          return;
        }

        if (loginRes.data.type === "LoginExisting") {
          console.log("loginexist");
          const check = await mbaas.client.user.get();
          const email = check.data?.email;
          console.log(check);
          localStorage.setItem("username", email || "");
          window.location.assign("/");
          setLoadingGoogle(false);
          return;
        }

        const loginAttr = loginRes.data;

        //   Create a new user based on the social account.
        const registerRes = await mbaas.client.auth.register(socmed, {
          oauthKey: loginAttr.oauthKey,
          email: loginAttr.email,
          extras: {
            name: loginAttr.name,
          },
        });

        console.log(registerRes);

        if (!registerRes.data) {
          notify("error register", { type: "warning" });
          return;
        }
        if (registerRes.data.type === "Profile") {
          mbaas.client.auth.redoOAuth(socmed);
        }
      } catch (e) {
        notify("Error", { type: "warning" });
      }
    }

    setLoadingGoogle(false);
  };

  const newLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await login({ username: email, password: pass });
      console.log(res);
    } catch (e: any) {
      console.log(e);
      notify(e?.title, { type: "warning" });
    }
    setLoading(false);
  };

  const googleLogin = async (e: any) => {
    e.preventDefault();
    setLoadingGoogle(true);

    try {
      const res = await mbaas.client.auth.oauthRedirect(
        "google",
        `${window.location.origin}/login`
      );
    } catch (e) {
      console.log(e);
      notify("error oauth", { type: "warning" });
    }
    setLoadingGoogle(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(/bg5.jpg)`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Paper
          style={{
            width: "350px",
            minHeight: "450px",
            padding: "40px",
            marginTop: "50px",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <img src={"logom21.png"} alt="logo" style={{ width: "80px" }} />
            </Grid>
            <Grid item>
              <p>Hey welcome back !</p>
            </Grid>
            <Grid item style={{ margin: "10px" }}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={loading && loadingGoogle}
                onClick={googleLogin}
                style={{
                  backgroundColor: ColorVal.secondary,
                  color: ColorVal.primary,
                }}
                startIcon={loadingGoogle ? "" : <Google />}
              >
                {loadingGoogle ? (
                  <CircularProgress size={20} />
                ) : (
                  "Continue with google"
                )}
              </Button>
            </Grid>
            <Grid item style={{ width: "100%", margin: "20px 0px" }}>
              <Divider>Or</Divider>
            </Grid>
            <Grid item style={{ width: "100%", marginBottom: "10px" }}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="medium"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                data-test-id="email"
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                size="medium"
                style={{ width: "100%" }}
                value={pass}
                type="password"
                onChange={(e) => setPass(e.target.value)}
                data-test-id="password"
              />
            </Grid>
            <Grid
              item
              style={{ margin: "10px", width: "100%", marginTop: "40px" }}
            >
              <Button
                color="primary"
                variant="contained"
                // onClick={idfs}
                onClick={newLogin}
                disabled={loading && loadingGoogle}
                style={{
                  backgroundColor: ColorVal.primary,
                  color: ColorVal.secondary,
                  width: "100%",
                }}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Sign in"
                )}
              </Button>
            </Grid>
            <Grid
              item
              style={{
                color: ColorVal.primary,
                fontSize: "13px",
                cursor: "pointer",
              }}
              onClick={() => redirect("/forgot-password")}
            >
              Forgot Password ?
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
