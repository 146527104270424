import React from "react";
import { Button, Divider, Grid } from "@mui/material";
import TextFieldMui from "@mui/material/TextField";
import { LexoRank } from "lexorank";
import { Save, Delete } from "@mui/icons-material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageIcon from "@mui/icons-material/Image";
import { useNotify } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Mbaas from "../../provider/Mbaas";
import Swal from "sweetalert2";
interface Props {
  items: any;
  refetch: any;
  parent_id: string;
  mode: string;
  selectedData: any;
  setOpenForm: any;
}


interface NewDataChild {
  icon: string;
  purpose: string;
  label_id: string;
  label_eng: string;
  url: string;
  token: string;
  image: string;
}



function makeid(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const AddNewParent = ({
  items,
  refetch,
  parent_id,
  mode,
  selectedData,
  setOpenForm,
}: Props) => {
  const [newData, setNewData] = React.useState<NewDataChild>({
    icon: "",
    label_id: "",
    label_eng: "",
    purpose: "",
    url: "",
    token: "",
    image: "",
  });

  const [newImageFile, setNewImageFile] = React.useState<any>(null);
  const [nameFile, setNameFile] = React.useState("");
  const [cropper, setCropper] = React.useState<any>(null);
  const [imageBlob, setImageBlob] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = React.useState(0);
  const notifier = useNotify();

  const fileInputRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if (mode === "edit") {
      setNewData({
        icon: "",
        label_id: selectedData.configuration?.label_id,
        label_eng: selectedData.configuration?.label_eng,
        purpose: selectedData.purpose,
        url: selectedData.configuration?.url,
        token: "",
        image: selectedData.image,
      });
    } else {
      setNewData({
        icon: "",
        label_id: "",
        label_eng: "",
        purpose: "",
        url: "",
        token: "",
        image: "",
      });
      setNewImageFile(null);
    }
  }, [selectedData, mode]);

  const handleFileChange = (event: any) => {
    const nametemp = event.target.files[0].name;
    setImageBlob(null);
    setNameFile(nametemp);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => setNewImageFile(reader.result),
      false
    );
    reader.readAsDataURL(event.target.files[0]);
  };

  const deleteData = async (id: string) => {
    await Swal.fire({
      title: "Do you want to delete this item?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      input: "text",
      inputValue: "",
      inputPlaceholder: "Input token",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (result.value === "") {
          Swal.fire("Error!", "token invalid", "error");
        } else {
          requestDelete(id, result.value);
        }
      }
    });
  };

  const requestDelete = async (id: string, token: string) => {
    const req = await Mbaas.client.invokeApi("console-delete-menu-layout", {
      token,
      id,
    });

    console.log(req);

    if (req.data) {
      Swal.fire("Success!", "config deleted", "success");
      refetch();
      setOpenForm(false);
    } else {
      Swal.fire("Error!", req.error.error, "error");
    }
  };

  const SubmitAndUploadImage = async (childItem: any) => {
    cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toBlob(async (blob: any) => {
        try {
          let urlImg;
          if (nameFile !== "") {
            const uploadResult: any = await Mbaas.client.storage.upload({
              file: blob,
              progressCallback: (progressVal: number) =>
                setProgress(progressVal),
              params: {
                filename: `ProductLayout/${makeid(7)}-${nameFile}`,
                bucket: "menu-images",
              },
            });
            console.log(uploadResult);
            const download = await Mbaas.client.storage.downloadUrl({
              params: {
                bucket: "menu-images",
              },
              fileId: uploadResult.data.id,
            });

            console.log(download);
            urlImg = download.data.url.split("?")[0];
            console.log(urlImg);
          } else {
            urlImg = selectedData.image;
          }

          let newKey;

          if (mode === "create") {
            if (childItem.length === 0) {
              newKey = LexoRank.middle().toString();
            } else {
              console.log(childItem);

              if (childItem[childItem.length - 1].priority !== null) {
                newKey = LexoRank.parse(
                  childItem[childItem.length - 1].priority
                )
                  .genNext()
                  .toString();
              } else {
                newKey = LexoRank.middle().toString();
              }
            }
            console.log(newKey);
          } else {
            newKey = selectedData.priority;
          }

          // gql
          const dataForm = {
            token: newData.token,
            data: {
              purpose: newData.purpose,
              configuration: {
                label_id: newData.label_id,
                label_eng: newData.label_eng,
                url: newData.url,
              },
              parent_id,
              country: {},
              priority: newKey,
              image: urlImg,
            },
          };

          let req;

          if (mode === "create") {
            req = await Mbaas.client.invokeApi("console-create-menu-layout", dataForm);
          } else {
            req = await Mbaas.client.invokeApi("console-update-menu-layout", {
              ...dataForm,
              id: selectedData.id,
            });
          }

          if (req.data) {
            Swal.fire(
              "Success!",
              mode === "create" ? "Item added" : "Item edited",
              "success"
            );

            //refresh
            await refetch();

            //cleanup
            setImageBlob(null);
            setOpenForm(false);
            setNewData({
              icon: "",
              label_eng: "",
              label_id: "",
              url: "",
              purpose: "",
              token: "",
              image: "",
            });
          } else {
            Swal.fire("Something wrong!", req.error.error, "error");
          }
          setLoading(false);
        } catch (e) {
          console.warn(e);
          notifier("Upload failed: exception");
        }
      }, "image/png");
  };

  const submitWithoutImage = async (childItem: any) => {
    try {
      let newKey;

      if (mode === "create") {
        if (childItem.length === 0) {
          newKey = LexoRank.middle().toString();
        } else {
          console.log(childItem);

          if (childItem[childItem.length - 1].priority !== null) {
            newKey = LexoRank.parse(childItem[childItem.length - 1].priority)
              .genNext()
              .toString();
          } else {
            newKey = LexoRank.middle().toString();
          }
        }
        console.log(newKey);
      } else {
        newKey = selectedData.priority;
      }

      // gql
      const dataForm = {
        token: newData.token,
        data: {
          purpose: newData.purpose,
          configuration: {
            label_id: newData.label_id,
            label_eng: newData.label_eng,
            url: newData.url,
          },
          parent_id,
          country: {},
          priority: newKey,
        },
      };

      let req;

      if (mode === "create") {
        req = await Mbaas.client.invokeApi("console-create-menu-layout", dataForm);
      } else {
        req = await Mbaas.client.invokeApi("console-update-menu-layout", {
          ...dataForm,
          id: selectedData.id,
        });
      }

      if (req.data) {
        Swal.fire(
          "Success!",
          mode === "create" ? "Item added" : "Item edited",
          "success"
        );

        //refresh
        await refetch();

        //cleanup
        setImageBlob(null);
        setOpenForm(false);
        setNewData({
          icon: "",
          label_eng: "",
          label_id: "",
          url: "",
          purpose: "",
          token: "",
          image: "",
        });
      } else {
        Swal.fire("Something wrong!", req.error.error, "error");
      }

      setLoading(false);
    } catch (e: any) {
      console.log(e);
      notifier("edit failed: exception");
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (nameFile === "") {
      await submitWithoutImage(items);
    } else {
      await SubmitAndUploadImage(items);
    }
  };

  const resetImageSelect = () => {
    setNewImageFile(null);
  };

  const submitCrop = () => {
    const test = cropper
      .getCroppedCanvas({ width: "100", height: "100" })
      .toDataURL("image/png");
    setImageBlob(test);
    resetImageSelect();
  };

  const onChangeDetail = (e: any, name: string) => {
    // e.preventDefault();
    setNewData({
      ...newData,
      [name]: e.target.value,
    });
  };

  return (
    <div>
      <form onSubmit={submit}>
        <div
          style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "bold" }}
        >
          <Grid
            container
            direction="row"
            style={{ marginBottom: "20px" }}
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {mode === "create" ? "Add New Item" : "Edit item"}
            </Typography>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    startIcon={loading ? "" : <Save />}
                    disabled={
                      loading ||
                      (mode === "create" ? imageBlob === null : false)
                    }
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : mode === "create" ? (
                      "Save New Item"
                    ) : (
                      "Save Edit Item"
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  {mode === "edit" && (
                    <Button
                      color="error"
                      size="small"
                      variant="contained"
                      startIcon={<Delete />}
                      onClick={() => deleteData(selectedData.id)}
                      disabled={loading}
                    >
                      Delete
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "10px" }} />
        </div>

        <div>
          <Grid container direction="row" style={{ marginBottom: "20px" }}>
            <Grid
              item
              xs
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {imageBlob ? (
                <img
                  src={imageBlob}
                  alt="current img"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : newData.image !== "" ? (
                !newImageFile && (
                  <img
                    src={newData.image}
                    alt="current img"
                    style={{ width: "100px", height: "100px" }}
                  />
                )
              ) : (
                !newImageFile && (
                  <img
                    src={
                      "https://placehold.jp/30/D10000/FFFFFF/200x200.jpg?text=Select Image"
                    }
                    alt="current img"
                    style={{ width: "100px", height: "100px" }}
                  />
                )
              )}
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e)}
                accept="image/*"
              />
              {!newImageFile && (
                <div style={{ margin: "10px 0px" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<ImageIcon />}
                    onClick={() => fileInputRef?.current?.click()}
                    size="small"
                  >
                    Select Image
                  </Button>
                </div>
              )}
              {newImageFile && (
                <div>
                  <Cropper
                    src={newImageFile}
                    style={{
                      width: "200px",
                      height: "200px",
                      alignSelf: "center",
                    }}
                    // Cropper.js options
                    // aspectRatio={200 / 200}
                    viewMode={1}
                    guides={true}
                    scalable={true}
                    onInitialized={(cropperInstance) =>
                      setCropper(cropperInstance)
                    }
                  />
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "15px",
                      textAlign: "center",
                      width: "200px",
                      overflowX: "auto",
                    }}
                  >
                    {nameFile}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<ImageIcon />}
                      onClick={() => fileInputRef?.current?.click()}
                      size="small"
                    >
                      Select Image
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => submitCrop()}
                      size="small"
                      startIcon={<Save />}
                      style={{ marginTop: "10px" }}
                    >
                      Save Image
                    </Button>
                  </div>
                </div>
              )}
            </Grid>

            <Grid item xs={9}>
              <b>Config Purpose</b>

              <TextFieldMui
                label="Purpose"
                value={newData.purpose}
                required
                fullWidth
                // helperText="Label name for parents"
                onChange={(e) => {
                  onChangeDetail(e, "purpose");
                }}
                style={{ marginBottom: "20px" }}
              />
              <b>Configuration</b>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xs={6}>
                  <TextFieldMui
                    label="Label Indonesia"
                    value={newData.label_id}
                    required
                    fullWidth
                    // helperText="Label name for parents"
                    onChange={(e) => {
                      onChangeDetail(e, "label_id");
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldMui
                    label="Label English"
                    value={newData.label_eng}
                    required
                    fullWidth
                    // helperText="Label name for parents"
                    onChange={(e) => {
                      onChangeDetail(e, "label_eng");
                    }}
                    style={{ marginBottom: "20px" }}
                  />
                </Grid>
              </Grid>

              <TextFieldMui
                label="URL Target"
                value={newData.url}
                fullWidth
                required
                onChange={(e) => {
                  onChangeDetail(e, "url");
                }}
                style={{ marginBottom: "20px" }}
              />

              <b>MFA</b>
              <TextFieldMui
                label="Token"
                value={newData.token}
                fullWidth
                required
                onChange={(e) => {
                  onChangeDetail(e, "token");
                }}
                style={{ marginBottom: "20px" }}
              />
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default AddNewParent;
