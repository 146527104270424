import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
} from "react-admin";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";

export class ExecutionLogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;
  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    if (params.filter.reference_id) {
      filterArray.push({
        reference_id: {
          _eq: params.filter.reference_id,
        },
      });
    }
    if (params.filter.type) {
      filterArray.push({
        type: {
          _eq: params.filter.type,
        },
      });
    }
    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });
    const queryResult = await this.client.gql.query({
      query: gql`
        query ExecutionLogsList($where: ExecutionLogsWhereInput) {
          allExecutionLogsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              type
              reference_id
              stage
              status
              reference_detail
              normal_detail
              exception_detail
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allExecutionLogsList?.data,
      total: queryResult?.allExecutionLogsList?.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log({ method: "getOne", resource, params });

    const queryResult = await this.client.gql.query({
      query: gql`
        query anExecutionLog($id: UUID) {
          getExecutionLogsById(id: $id) {
            id
            created_at
            updated_at
            type
            reference_id
            stage
            status
            reference_detail
            normal_detail
            exception_detail
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    return {
      data: queryResult?.getExecutionLogsById,
    };
  }
}
