import React from "react";
import { Grid } from "@mui/material";
import { Create, SimpleForm, required, TextInput } from "react-admin";

const CreatePage = () => {
  return (
    <Create>
      <SimpleForm>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextInput
              fullWidth
              label="Label"
              source="label"
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              fullWidth
              label="Contact"
              source="contact"
              validate={[required()]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreatePage;
