// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  Edit,
  required,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  AutocompleteInput,
  FormDataConsumer,
  useRecordContext,
  DateTimeInput,
  useGetOne,
  useNotify,
  useRedirect,
  useDataProvider,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  useRefresh,
} from "react-admin";
import DOMPurify from "dompurify";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import mbaas from "../../../provider/Mbaas";
import { dataProvider } from "../../../provider";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const transform = (data: any) => {
  console.log(999, data);

  let content = data.content;

  content = { ...content, content: DOMPurify.sanitize(content.content) };

  const res = {
    ...data,
    content,
    // question: DOMPurify.sanitize(data.question),
  };
  return res;
};

const countryList = [
  { id: "HK", name: "Hong Kong" },
  { id: "ID", name: "Indonesia" },
  { id: "MY", name: "Malaysia" },
  { id: "TW", name: "Taiwan" },
  { id: "WORLDWIDE", name: "WORLDWIDE" },
];

const category = [
  { id: "special", name: "special" },
  { id: "recommendation", name: "recommendation" },
];

const accountType = [
  { id: "M21", name: "M21" },
  { id: "G2", name: "G2" },
  { id: "GHK", name: "GHK" },
];

const PrevImage = () => {
  const record: any = useRecordContext();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "left",
        flexDirection: "row",
        marginBlockEnd: 50,
      }}
    >
      <p>Previous Image</p>
      <img src={record?.image} width={200} alt="img"></img>
    </div>
  );
};

const ToolbarEdit = () => {
  return (
    <Toolbar>
      <SaveButton sx={{ marginRight: "20px" }} />
      <DeleteWithConfirmButton redirect={`/promotions/flashsale`} />
    </Toolbar>
  );
};

const EditPage = (props: any) => {
  const classes = useStyles();
  const { id } = useParams();

  const { data, isLoading: loadingGetOne } = useGetOne("promotionsBanner", { id: id }, { enabled: !!id });

  const getLinkToList = async (country: string, platform: string[]) => {
    const resp = await mbaas.client.invokeApi("console-webview-link-list", {
      country: country,
      platform: platform,
    });

    if (resp.error) {
      return [{ url: "custom_url", label: "CUSTOM URL" }];
    }
    resp?.data.push({ url: "custom_url", label: "CUSTOM URL" });
    return resp?.data;
  };

  const [country, setCountry] = useState(data?.country);
  const [platform, setPlatform] = useState(data?.account_type);
  const [link, setLink] = useState([]);

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const data = await getLinkToList(country, [platform]);
        setLink(data);
      } catch (error) {
        console.error("Error fetching links:", error);
      }
    };
    fetchLink();
  }, [country, platform]);

  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmitHandler = async (data) => {
    console.log(data, "cek-data");
    await dataProvider
      .update("promotionsBanner", {
        data: {
          ...data,
        },
        id: props.id,
      })
      .then(() => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
          undoable: true,
        });
        redirect(`/promotionsBanner`);
        refresh();
        Swal.fire("Success!", "", "success");
      });
  };

  return (
    <Edit title=" " {...props} component="div">
      <Box ml={2} mr={2}>
        <SimpleForm toolbar={<ToolbarEdit />} onSubmit={onSubmitHandler}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectInput
                fullWidth
                className={classes.input}
                source="country"
                validate={[required()]}
                choices={countryList}
                label="Country"
                data-test-id="country"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteInput
                fullWidth
                className={classes.input}
                source="account_type"
                validate={[required()]}
                choices={accountType}
                optionText="name"
                optionValue="id"
                label="Account Type"
                data-test-id="account_type"
                onChange={(value) => {
                  setPlatform(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="title" validate={[required()]} multiline label="Title" fullWidth data-test-id="title" />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput source="url" label="Link to" optionText="label" optionValue="url" choices={link} fullWidth validate={required()} data-test-id="uri" />
            </Grid>
            <FormDataConsumer>
              {({ formData }: any) =>
                formData.url === "custom_url" && (
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      source="custom_url"
                      label="Custom Url"
                      validate={[required(), (value) => (value && value.startsWith("https://") ? undefined : "Must start with https://")]}
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>
            <Grid item xs={12}>
              <TextInput source="content" validate={[required()]} multiline label="Content" fullWidth data-test-id="contentInput" />
            </Grid>
            <Grid item xs={12}>
              <SelectInput fullWidth className={classes.input} source="category" validate={[required()]} choices={category} label="Category" data-test-id="category" />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput fullWidth className={classes.input} source="date_start" label="Valid From" defaultValue={new Date()} parse={(v) => new Date(v)} validate={required()} />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput fullWidth className={classes.input} source="date_end" label="Valid Until" defaultValue={new Date()} parse={(v) => new Date(v)} validate={required()} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <PrevImage />
              <ImageInput
                data-test-id="image"
                source="urlThumbnail"
                label="Main Image (.jpg)"
                validate={[required(), (value) => {
                  if (value && value.rawFile) {
                    const file = value.rawFile;
                    if (file.type !== "image/jpeg") {
                      return "Only JPG images are allowed";
                    }
                    if (file.size > 400 * 1024) {
                      return "Image size must be less than 400KB";
                    }
                  }
                  return undefined;
                }]}
                options={{ multiple: false }}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    boxShadow: "none",
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));

export default EditPage;
