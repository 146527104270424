// @ts-nocheck
import React, { useCallback } from 'react';
import { List, Datagrid, TopToolbar, sanitizeListRestProps, TextField, Pagination, DateField, BooleanField, AutocompleteInput, usePermissions, ReferenceInput, FunctionField, TextInput, Button } from 'react-admin';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Detail } from './Detail';
import { Drawer } from '@mui/material';
import { EditProduct } from './Edit';
import ReactJson from 'react-json-view';
import UpdateIcon from '@mui/icons-material/Update';
import UploadDialog from '../UploadDialog';
import mbaas from '../../../../src/provider/Mbaas';
import CustomExportButton from '../../CustomExportButton';
import { useDispatch } from 'react-redux';
import { setProductsListFilter } from 'src/slices/productsListPage/filterProductsListlice';
import { usePersistFilter } from 'src/hooks/usePersistFilter';

export interface UpdateProductCsvInterface {
  openDialog: {
    isOpen: boolean;
    dialogType: string;
  };
  setOpenDialog: (props: OpenStateDialogInterface) => any;
}

const UpdateProductCsv = (props: UpdateProductCsvInterface) => {
  const { openDialog, setOpenDialog } = props;

  const handleClickOpenDialog = (dialogType: string) => {
    setOpenDialog({ isOpen: true, dialogType: dialogType });
  };
  const handleCloseDialog = (dialogType: string) => {
    setOpenDialog({ isOpen: false, dialogType: dialogType });
  };

  return (
    <>
      <Button label="Update Pricing" onClick={() => handleClickOpenDialog('external-pricing')} data-testid="update-pricing">
        <UpdateIcon />
      </Button>

      <UploadDialog openDialog={openDialog.isOpen} dialogType={openDialog.dialogType} handleClickOpen={() => handleClickOpenDialog(openDialog.dialogType)} handleClose={() => handleCloseDialog(openDialog.dialogType)} />
    </>
  );
};

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, openDialog, userData, setOpenDialog, ...rest } = props;
  const { isLoading, permissions } = usePermissions();
  usePersistFilter();
  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Admin' || el === 'Super Admin');

  return (
    <>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {isAdmin && (
          <>
            <CustomExportButton exportBtnType="products" userData={userData} />
            <UpdateProductCsv openDialog={openDialog} setOpenDialog={setOpenDialog} />
          </>
        )}
      </TopToolbar>
    </>
  );
};

const ServicesProductPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

export interface OpenStateDialogInterface {
  isOpen: boolean;
  dialogType: string;
}

export const ServicesProductList = () => {
  const [openDialog, setOpenDialog] = React.useState<OpenStateDialogInterface>({
    isOpen: false,
    dialogType: '',
  });
  let { id: idDataRecord } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const dispatch = useDispatch();
  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const handleCancelDrawer = useCallback(() => {
    navigate(`/services/product`);
  }, [navigate]);

  const matchEdit = matchPath('/services/product/:id', location.pathname);
  const matchShow = matchPath('/services/product/:id/show/*', location.pathname);

  const rowStyleBySelectedRecord = (record: RaRecord) => {
    let bgColor = '';
    if (record.id === idDataRecord) {
      bgColor = '#f2f2f2';
    }

    return {
      backgroundColor: bgColor,
    };
  };

  const ServicesProductFilter = [
    <ReferenceInput filter={{ isVas: true }} source="id" reference="productCatalogsItemGroup" alwaysOn>
      <AutocompleteInput
        sx={{ width: '300px' }}
        fullWidth
        source="item_group"
        optionText={(choice?: any) => (choice?.id ? choice.item_group : '')}
        label="Item Group"
        data-testid="search-item-group"
        onChange={(e) => dispatch(setProductsListFilter({ id: e }))}
      />
    </ReferenceInput>,
    <TextInput alwaysOn data-test-id="search-by-label" source="label" onChange={(e) => dispatch(setProductsListFilter({ label: e.target.value }))} />,
  ];

  return (
    <>
      <List
        resource="servicesProduct"
        title="Services &rsaquo; Product"
        filter={{ isMvno:"false", isVas:"true" }}
        filters={ServicesProductFilter}
        actions={<ListActions openDialog={openDialog} setOpenDialog={setOpenDialog} userData={userData} />}
        perPage={25}
        pagination={<ServicesProductPagination />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!matchShow || !!matchEdit ? '600px' : 0,
        }}
      >
        <Datagrid
          rowClick={(id) => {
            navigate(`/services/product/${id}/show`);
          }}
          key={(id) => id}
          bulkActionButtons={false}
          rowStyle={rowStyleBySelectedRecord}
        >
          <DateField
            source="created_at"
            label="Date Added"
            showTime
            locales="en-HK"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }}
          />
          <TextField source="item_key" label="Item Key" />
          <TextField source="item_group" label="Item Group" />
          <TextField source="label" label="Label" />
          <TextField source="description" label="Description" />
          <TextField source="retail_price" label="Price" />
          <BooleanField source="is_available" label="Available?" />
          <FunctionField
            source="vas_sync_time"
            label="VAS Sync Time"
            render={(record) => {
              if (record.vas_sync_time) {
                return (
                  <DateField
                    source="vas_sync_time"
                    showTime
                    locales="en-HK"
                    options={{
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    }}
                  />
                );
              } else return '-';
            }}
          />
          <FunctionField
            source="vas_sync_detail"
            label="VAS Sync Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: any) => {
              return <ReactJson src={record.vas_sync_detail || {}} shouldCollapse={() => true} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} name={false} />;
            }}
          />
          <BooleanField source="auto_enabled" label="Auto Enabled?" />
        </Datagrid>
      </List>
      <Drawer variant="persistent" open={!!matchShow} anchor="right" onClose={handleCancelDrawer} sx={{ zIndex: 100 }}>
        {!!matchShow && <Detail id={(matchShow as any).params.id} onCancel={handleCancelDrawer} match={matchShow} />}
      </Drawer>
      <Drawer variant="persistent" open={!!matchEdit} anchor="right" onClose={handleCancelDrawer} sx={{ zIndex: 100 }}>
        {!!matchEdit && <EditProduct id={(matchEdit as any).params.id} onCancel={handleCancelDrawer} />}
      </Drawer>
    </>
  );
};
