import { Box, Chip, Divider, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DateField, FunctionField, Identifier, NumberField, Pagination, RaRecord, Show, Tab, TabbedShowLayout, TextField, WithRecord, useRefresh } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ReactJson from "react-json-view";
import moment from "moment";
import { Button } from "rsuite";
import Swal from "sweetalert2";
import Mbaas from "../../provider/Mbaas";
import { get, isEmpty } from "lodash";

interface UsageRewardDetailProps {
    onCancel: () => void;
    id: string
}

const renderColor = (status: string) => {
    let bgColor = "transaparent";
    switch (status.toLowerCase()) {
        case "purchased":
            bgColor = "#dcf8c6"
            break;
        case "redeemed":
            bgColor = "#ffe4e4"
            break;
        default:
            break;
    }
    return bgColor
}

const RewardLogHistories = ({ histories = [] }: { histories: any[]}) => {
    const [page, setPage] = useState(1);
    const perPage = 5;
    const total = histories?.length;
    const shownHistories = histories
    .sort((a: any, b: any ) =>  moment.utc(a.timestamp).diff(moment.utc(b.timestamp)))
    .filter(
        (x: any, index: number) =>
          x && index >= (page - 1) * perPage && index < page * perPage
      );
  
    return (
      <>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shownHistories.map((data: RaRecord) => {
              return (
                <TableRow key={data.purchase_id}>
                  <TableCell>
                    {moment(data.timestamp).format("DD/MM/YYYY, hh:mm:ss A")}
                  </TableCell>
                  <TableCell>{data.status.toUpperCase()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
          rowsPerPageOptions={[2]}
        />
      </>
    );
};

export const UsageRewardDetail: React.FC<UsageRewardDetailProps> = ({ onCancel, ...props}) => {
    const [isLoading,setIsLoading] = useState(false);
    const refresh = useRefresh();

    const handleRedeem = async (id: Identifier) => {
        setIsLoading(true)
        try {
            const resp = await Mbaas.client.invokeApi('console-override-reward', { reward_log_id: id });
            if(resp.data){
                Swal.fire("Success!", "", "success");
                refresh();
            }
            if(resp.error) throw resp.error;
        } catch (error) {
            console.error('error => ', error);
            Swal.fire(`Request failed: ${get(error, 'error', error)}`, "", "error");
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const close = (e: any) => {
          if (e.keyCode === 27) {
            onCancel();
          }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [onCancel]);

    return (
        <Show sx={{ minHeight: "100%" }} resource="rewardLogs" title=" &rsaquo; Detail" {...props}>
            <Box
                pt={5}
                width={{ xs: "100vw", sm: 600 }}
                minHeight="100vh"
                mt={{ xs: 2, sm: 1 }}
                ml={{ xs: 2, sm: 1 }}
                mr={{ xs: 2, sm: 1 }}
            >
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    p={0}
                    pl={2}
                    sx={{ position: "absolute", right: 0, zIndex: 100, width: '100%' }}
                >
                    <Stack direction="row">
                        <Typography variant="h6"> Code: #</Typography>
                        <TextField source="code" variant="h6" fontWeight={600} label="Code" />
                    </Stack>
                    <IconButton onClick={onCancel} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack> 
                <TabbedShowLayout sx={{ marginTop: '10px'}}>
                    <Tab label="Detail">
                        <FunctionField render={(record: RaRecord) => {
                            return record.status === "PURCHASED" ? <Button size="sm" appearance="primary" loading={isLoading} onClick={() => handleRedeem(record.id)}>
                                <Stack direction={"row"} spacing={0.5} alignItems={"center"}><ChangeCircleIcon fontSize={"small"} /> <div>REDEEM</div></Stack>
                            </Button> : null
                        }}/>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <strong>Date</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <DateField
                                    source="created_at"
                                    label="Date"
                                    showTime
                                    locales="en-HK"
                                    options={{
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Status</strong>
                            </Grid>
                            <Grid item xs={8}>
                               <FunctionField render={(record: RaRecord)=> {
                                return <Chip size="small" sx={{ backgroundColor: renderColor(record.status)}} label={record.status} />
                               }}/>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Delivery Mode</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField source="reward_detail.delivery_mode"/>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Daily limit</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <NumberField source="reward_detail.redeem_limits.daily"/>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Monthly limit</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <NumberField source="reward_detail.redeem_limits.monthly"/>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Minimum redeem point</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <NumberField source="reward_detail.redeem_limits.minimum_point_balance"/>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Reward</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <FunctionField 
                                    render={(record: RaRecord) => {
                                        const rewardLabel = get(record, 'reward_detail.item_label', {});
                                        const subsInfo = get(record, 'subscriber_info.subscriber', {});
                                        return !isEmpty(subsInfo) ? rewardLabel[subsInfo.country] : null
                                    }} 
                                    label="Reward" 
                                    sortable={false}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Label</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <FunctionField
                                    render={(record: RaRecord) => {
                                        return (
                                            <ReactJson
                                                src={record.reward_detail?.item_label || {}}
                                                enableClipboard={false}
                                                shouldCollapse={() => true}
                                                displayDataTypes={false}
                                                displayObjectSize={false}
                                                name={false}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Redemption amount</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <FunctionField
                                    render={(record: RaRecord) => {
                                        return (
                                            <ReactJson
                                                src={record.reward_detail?.redemption_amount|| {}}
                                                shouldCollapse={() => true}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                displayObjectSize={false}
                                                name={false}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: '20px'}}>SUBSCRIBER INFO</Divider>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <strong>Deducted Point</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <NumberField source="subscriber_info.deducted_balance.point" /> point
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Name</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField source="subscriber_info.subscriber.name" />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Email</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField source="subscriber_info.subscriber.email" />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Phone</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField source="subscriber_info.subscriber.phone" />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Country</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField source="subscriber_info.subscriber.country" />
                            </Grid>
                            <Grid item xs={4}>
                                <strong>Referral code</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField source="subscriber_info.subscriber.referral_code" />
                            </Grid>
                            
                        </Grid>
                    </Tab>
                    <Tab label="History">
                        <WithRecord
                            render={(record) => {                                
                                return <RewardLogHistories histories={record.delivery_history || []} />
                            }}
                        />
                    </Tab>
                </TabbedShowLayout>
            </Box>
        </Show>

    )
}
