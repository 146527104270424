import * as React from "react";
import {
  List,
  Datagrid,
  DateField,
  FilterButton,
  TextInput,
  TopToolbar,
  RowClickFunction,
  Identifier,
  TextField,
  FunctionField,
  RaRecord,
  SelectInput,
  Pagination,
  useListContext,
} from "react-admin";
import { Chip, Drawer, Button as MaterialButton, Stack } from "@mui/material";
import { matchPath, useLocation, useNavigate, Link } from "react-router-dom";
import { CreateFlashsale } from "./Create";
import { EditFlashsale } from "./Edit";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSaleProgramFilter } from "src/slices/saleProgramsPage/filterSaleProgramSlice";
import { DateRangePicker } from "rsuite";
import { filter, get } from "lodash";

const ListActions = ({ flashsaleFilter }: any) => {
  return (
    <TopToolbar>
      <FilterButton filters={flashsaleFilter} />
      <MaterialButton
        color="primary"
        size="small"
        data-testid="create_button"
        component={Link}
        to={{
          pathname: "/promotions/flashsale/create",
        }}
      >
        + Add New
      </MaterialButton>
    </TopToolbar>
  );
};

const PromotionsFlashsalePagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const FlashsaleList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TextArrayField = ({
    record,
    source,
    sub_source,
  }: {
    record: RaRecord;
    source: string;
    sub_source: string;
  }) => (
    <>
      {record[source][sub_source].map((item: any) => (
        <Chip
          sx={{ backgroundColor: "#284b63", color: "#FFFFFF", margin: 0.25 }}
          label={item}
          key={item}
        />
      ))}
    </>
  );
  TextArrayField.defaultProps = { addLabel: true };

  const handleCancelDrawer = React.useCallback(() => {
    navigate(`/promotions/flashsale`);
  }, [navigate]);

  const matchCreate = matchPath(
    "/promotions/flashsale/create",
    location.pathname
  );
  let matchEdit = null;
  if (!location.pathname.endsWith("create"))
    matchEdit = matchPath("/promotions/flashsale/:id/*", location.pathname);

  const DateRange = (props: any) => {
    const { filterValues, displayedFilters, setFilters } = useListContext();
    const [dateValue, setDateValue] = React.useState<any>({
      dateGte: filterValues.dateGte
        ? moment(filterValues.dateGte).startOf("day").format()
        : null,
      dateLte: filterValues.dateLte
        ? moment(filterValues.dateLte).endOf("day").format()
        : null,
    });

    const dateBegin = filterValues.dateGte
      ? new Date(moment(filterValues.dateGte).startOf("day").format())
      : null;
    const dateEnd = filterValues.dateLte
      ? new Date(moment(filterValues.dateLte).endOf("day").format())
      : null;

    return (
      <DateRangePicker
        defaultValue={dateBegin && dateEnd ? [dateBegin, dateEnd] : null}
        value={
          dateValue.dateGte && dateValue.dateLte
            ? [new Date(dateValue.dateGte), new Date(dateValue.dateLte)]
            : null
        }
        size="lg"
        onClean={() => {
          setDateValue({
            dateGte: null,
            dateLte: null,
          });
          const updateFilter = {
            ...filterValues,
            dateGte: null,
            dateLte: null,
          };
          setFilters(updateFilter, displayedFilters);
        }}
        onChange={(value) => {
          const start = value
            ? moment(value[0].toString()).startOf("day").format()
            : null;
          const end = value
            ? moment(value[1].toString()).endOf("day").format()
            : null;

          setDateValue({
            dateGte: start,
            dateLte: end,
          });
          const updateFilter = {
            ...filterValues,
            dateGte: start,
            dateLte: end,
          };
          setFilters(updateFilter, displayedFilters);
        }}
      />
    );
  };
  const FlashsaleFilter = [
    <DateRange alwaysOn />,
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { id: "ACTIVE", name: "ACTIVE" },
        { id: "EXPIRED", name: "EXPIRED" },
        { id: "INACTIVE", name: "INACTIVE" },
      ]}
      alwaysOn
      data-testid="search-status"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSaleProgramFilter({ status: e.target.value }))
      }
    />,
    <SelectInput
      source="platform"
      label="Platform"
      choices={[
        { id: "M21", name: "M21" },
        { id: "G2", name: "G2" },
        { id: "GHK", name: "GHK" },
      ]}
      alwaysOn
      data-testid="search-status"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSaleProgramFilter({ platform: e.target.value }))
      }
    />,
    <SelectInput
      label="Country"
      source="country"
      choices={[
        { id: "HK", name: "Hongkong" },
        { id: "ID", name: "Indonesia" },
        { id: "TW", name: "Taiwan" },
        { id: "MY", name: "Malaysia" },
      ]}
      data-testid="search-country"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSaleProgramFilter({ country: e.target.value }))
      }
    />,
    <TextInput
      source="item_keys"
      label="Item Keys"
      data-testid="search-item-keys"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSaleProgramFilter({ item_keys: e.target.value }))
      }
    />,
    <TextInput
      source="item_groups"
      label="Item Groups"
      data-testid="search-item-groups"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setSaleProgramFilter({ item_groups: e.target.value }))
      }
    />,
  ];

  return (
    <>
      <List
        empty={false}
        resource="promotionsFlashsale"
        perPage={25}
        title="Promotions &rsaquo; Sale Programs"
        filters={FlashsaleFilter}
        actions={<ListActions flashsaleFilter={FlashsaleFilter} />}
        sort={{ field: "created_at", order: "DESC" }}
        pagination={<PromotionsFlashsalePagination />}
      >
        <Datagrid
          rowClick={
            ((id: Identifier) =>
              navigate(
                `/promotions/flashsale/${id}`
              )) as unknown as RowClickFunction
          }
          key={((id: Identifier) => id) as unknown as React.Key}
          bulkActionButtons={false}
        >
          <DateField
            source="created_at"
            label="Created"
            showTime
            locales="en-HK"
          />
          <TextField
            source="author_id_data.name"
            label="Author"
            sortable={false}
            sx={{ maxWidth: "10%" }}
          />
          <FunctionField
            sortable={false}
            label="Platform"
            render={(record: RaRecord) => {
              if (record?.configs.platform) {
                console.log(record?.configs.platform, "tes-array");
                let platform: any;
                if (typeof record?.configs.platform === "string") {
                  platform = <Chip label={record?.configs.platform} />;
                } else {
                  const arrayPlatform: any[] = [];
                  record.configs.platform.forEach((element: string) => {
                    arrayPlatform.push(
                      <Chip sx={{ marginBottom: "2px" }} label={element} />
                    );
                  });
                  platform = arrayPlatform;
                }
                return platform;
              }
              return "";
            }}
          />
          <TextField source="country" />
          <TextField source="value" label="Value" />
          <FunctionField
            sortable={false}
            label="Items"
            render={(record: RaRecord) => (
              <TextArrayField
                record={record}
                source="items"
                sub_source="item_keys"
              />
            )}
          />
          <FunctionField
            label="Bonus Usage"
            render={(record: RaRecord) => {
              const usages = get(record, "items.bonus.usage", []);
              return (
                <>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      size="small"
                      color="info"
                      label={
                        <>
                          Given:{" "}
                          <b>{filter(usages, (rec) => !!rec.given_at).length}</b>
                        </>
                      }
                    />
                    <Chip
                      size="small"
                      color="secondary"
                      label={
                        <>
                          Claimed:{" "}
                          <b>
                            {filter(usages, (rec) => !!rec.claimed_at).length}
                          </b>
                        </>
                      }
                    />
                    <Chip
                      size="small"
                      label={
                        <>
                          Total: <b>{usages.length}</b>
                        </>
                      }
                    />
                  </Stack>
                </>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Configuration"
            render={(record: RaRecord) => {
              return (
                <div>
                  {record.configs.specific_subscriber.status && (
                    <label>
                      <b>Specific for:</b>&nbsp;
                      {record.configs.specific_subscriber.name}
                    </label>
                  )}
                  {record.configs.max_usage_per_subscriber && (
                    <>
                      <br />
                      <label>
                        <b>Usage:</b>&nbsp;
                        {record.configs.max_usage_per_subscriber}/subs
                      </label>
                    </>
                  )}
                  {record.configs.total_usage && (
                    <>
                      <br />
                      <label>
                        <b>Total Usage:</b>&nbsp;
                        {record.configs.total_usage}
                      </label>
                    </>
                  )}
                  {record.configs.once_per_target && (
                    <>
                      <br />
                      <label>
                        <b>Limit:</b>&nbsp;1/MSISDN
                      </label>
                    </>
                  )}
                  {record.configs.new_subscriber_only && (
                    <>
                      <br />
                      <label>
                        <b>New subs only</b>
                      </label>
                    </>
                  )}
                  {record.configs.ever_productive_subscriber && (
                    <>
                      <br />
                      <label>
                        <b>Ever prod. subs</b>
                      </label>
                    </>
                  )}
                  {record.configs.registered_subscriber && (
                    <>
                      <br />
                      <label>
                        <b>Regist. subs</b>
                      </label>
                    </>
                  )}
                  {record.configs.comeback_subscriber && (
                    <>
                      <br />
                      <label>
                        <b>Comeback subs</b>
                      </label>
                    </>
                  )}
                </div>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Validity Period"
            render={(record: RaRecord) => {
              const validityObject = {
                from: moment(record.start).format("DD/M/YYYY, h:mm:ss a"),
                until: moment(record.end).format("DD/M/YYYY, h:mm:ss a"),
              };
              return (
                <div>
                  <label>
                    <b>From:</b>
                    <br />
                    {validityObject.from}
                  </label>
                  <br />
                  <label>
                    <b>To:</b>
                    <br />
                    {validityObject.until}
                  </label>
                </div>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Status"
            render={(record: RaRecord) => {
              const start = moment(record.start).toISOString();
              const end = moment(record.end).toISOString();
              let status;
              if (moment(moment().toISOString()).isBetween(start, end)) {
                status = "ACTIVE";
              } else if (moment(moment().toISOString()).isBefore(end)) {
                status = "INACTIVE";
              } else status = "EXPIRED";
              return status;
            }}
          />
        </Datagrid>
      </List>
      <Drawer
        open={!!matchCreate}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchCreate && <CreateFlashsale onCancel={handleCancelDrawer} />}
      </Drawer>
      <Drawer
        open={!!matchEdit}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!matchEdit && (
          <EditFlashsale
            id={(matchEdit as any).params.id}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </>
  );
};

export default FlashsaleList;
