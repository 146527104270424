import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Grid,
  CircularProgress,
  Button,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ColorVal from "../utils/color";
import { useNotify, useRedirect } from "react-admin";
import mbaas from "../provider/Mbaas";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function getparam(parameterName: String) {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const [pass, setPass] = useState("");
  const [passNew, setPassNew] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const redirect = useRedirect();

  const notify = useNotify();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPassReq = async (e: any) => {
    e.preventDefault();

    if (pass !== passNew) {
      notify("New password doesnt same with confirm password", {
        type: "warning",
      });
      return;
    }

    if (pass === "") {
      notify("Password cant be empty", {
        type: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      const token = getparam("token");
      if (token) {
        const resetPassword = await mbaas.client.auth.resetPassword(
          token,
          pass
        );
        console.log(resetPassword);

        if (resetPassword?.error?.title) {
          notify(resetPassword?.error?.title, {
            type: "warning",
          });
          setLoading(false);
          return;
        } else {
          notify(`Success reset password`, {
            type: "success",
          });
          setLoading(false);
          setPass("");
          setPassNew("");
          return;
        }
      } else {
        notify("Token doesnt exist", {
          type: "warning",
        });
        return;
      }
      // RESET PASSWORD
    } catch (e: any) {
      console.log(e);
      notify(e?.title, { type: "warning" });
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(/bg5.jpg)`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Paper
          style={{
            width: "350px",
            minHeight: "450px",
            padding: "40px",
            marginTop: "50px",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <img src={"logom21.png"} alt="logo" style={{ width: "80px" }} />
            </Grid>
            <Grid item>
              <p>Reset Password</p>
            </Grid>
            <Grid item style={{ width: "100%", margin: "20px 0px" }}>
              <Divider />
            </Grid>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ m: 1, width: "25ch" }}
              style={{ marginBottom: "20px" }}
              size="medium"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              sx={{ m: 1, width: "25ch" }}
              size="medium"
            >
              <InputLabel htmlFor="outlined-adornment-password1">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password1"
                type={showPassword ? "text" : "password"}
                value={passNew}
                onChange={(e) => setPassNew(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Input New Password Again"
              />
            </FormControl>

            <Grid
              item
              style={{ margin: "10px", width: "100%", marginTop: "100px" }}
            >
              <Button
                color="primary"
                variant="contained"
                // onClick={idfs}
                onClick={resetPassReq}
                disabled={loading}
                style={{
                  backgroundColor: ColorVal.primary,
                  color: ColorVal.secondary,
                  width: "100%",
                }}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </Grid>
            <Grid
              item
              style={{
                color: ColorVal.primary,
                fontSize: "13px",
                cursor: "pointer",
              }}
              onClick={() => redirect("/login")}
            >
              Back to Login Page
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
