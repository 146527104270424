import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { ProviderBase } from "./ProviderBase";
import { GetListParams, GetListResult, GetManyParams, GetManyResult } from "react-admin";
import { buildArgs } from "./utils";
import gql from "graphql-tag";
export class ProductCatalogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult<any>> {
    const { filter } = params
    const { page, perPage } = params.pagination;

    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    const filterOrArray: any[] = []

    const args = buildArgs({
      // @ts-ignore
      order: 'ASC',
      size: perPage,
      pageNumber: page,
      sort: 'item_group',
    });

    if (filter.q) {
      filterOrArray.push({
        item_key: {
          _ilike: `%${filter.q}%`,
        }
      });
      filterOrArray.push({
        label: {
          _ilike: `%${filter.q}%`,
        }
      });
      filterOrArray.push({
        item_group: {
          _ilike: `%${filter.q}%`,
        }
      });
    }

    if (filter.itemKey) {
      where['item_key'] = {
        _ilike: `%${filter.itemKey}%`,
      };
    }

    if (filter.itemGroup) {
      where["item_group"] = {
        _eq: filter.itemGroup
      }
    }

    // filter for item group input in reward->bonus for
    if (filter.item_group) {
      where["item_group"] = {
        _in: filter.item_group
      }
    }

    // filter for item item key input in reward->bonus for
    if (filter.item_key) {
      where["item_key"] = {
        _in: filter.item_key
      }
    }

    if (filter.isAvailable) {
      where["is_available"] = {
        _eq: filter.isAvailable
      }
    }

    if (filter.mvnoCountry) {
      where["mvno_country"] = {
        _eq: filter.mvnoCountry
      }
    }

    if (filter.isVas || filter.isVas !== undefined) {
      where["is_vas"] = {
        _eq: filter.isVas
      }
    }

    if (filter.isMvno || filter.isMvno !== undefined) {
      where["is_mvno"] = {
        _eq: filter.isMvno
      }
    }

    if (filter.isMetadata || filter.isMetadata !== undefined) {
      where["metadata"] = {
        _is_null: filter.isMetadata,
        _contains: {
          "group": "tm_sms"
        }
      }
    }

    if (filter.extraFilter) {
      where["_or"] = filter.extraFilter
    }

    if (filterArray.length > 0) where._and = filterArray;
    if (filterOrArray.length > 0) where._or = filterOrArray;

    const queryResult = await this.client.gql.query({
      query: gql`query allProductCatalogsList($where: ProductCatalogsWhereInput) {
                allProductCatalogsList(where: $where, ${args}) {
                    count
                    data {
                        id
                        created_at
                        updated_at
                        item_key
                        item_group
                        label
                        description
                        retail_price
                        inquiry_rules
                        execution_rules
                        is_available
                        is_vas
                        vas_sync_time
                        vas_sync_detail
                        constraint_id
                        country
                        auto_enabled
                        is_mvno
                        mvno_country
                        is_bundled
                        is_postpaid
                        metadata
                        product_pricings_product_catalog_id_list {
                          data {
                            product_catalog_id_data {
                              country
                            }
                          }
                        }
                    }
                }
            }`,
      variables: {
        where
      }
    })

    if (queryResult.error) {
      console.error(queryResult.error);
      throw new Error("GraphQL query error");
    }

    return {
      data: queryResult.allProductCatalogsList.data,
      total: queryResult.allProductCatalogsList.count,
    };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    const where: Record<string, any> = {};

    if (params.ids) {
      where._or = [
        {
          id: {
            _in: params.ids,
          },
        },
      ];
    }

    const queryResult = await this.client.gql.query({
      query: gql`
            query allProductCatalogsList($where: ProductCatalogsWhereInput) {
              allProductCatalogsList(where: $where) {
                data {
                  id
                  item_group
                  label
                  item_key
                }
              }
            }
          `,
      variables: {
        where,
      },
    });

    return {
      data: queryResult?.allProductCatalogsList?.data || [],
    };
  }
}