import React, { useContext, useEffect, useState } from "react";

import MaterialTable, { Column } from "@material-table/core";
import Mbaas from "../../provider/Mbaas";
import gql from "graphql-tag";
import ReactJson from "react-json-view";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsIcon from "@mui/icons-material/Settings";
import AddMenu from "./AddMenu";
import { MenuListContext } from "./MenuContext";
import { CircularProgress, Grid } from "@mui/material";

const LayoutList = () => {
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("create");
  const [selectedData, setSelectedData] = useState({});

  const globalstate = useContext(MenuListContext);

  //pagination
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [offsetPage, setOffsetPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [order, setOrder] = React.useState("DESC");
  const [sort, setSort] = React.useState("created_at");

  const columnList = ["purpose", "configuration", "country", "created_At"];

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    fetchList(false);
  }, [rowsPerPage, offsetPage, order, sort]);

  const fetchList = async (loadingMode: boolean = true) => {
    // `console`.log(offsetPage);

    if (loadingMode) {
      setLoading(true);
    }
    try {
      const req = await Mbaas.client.gql.query({
        query: gql`
          query layouts {
            allMenuLayoutsList(
              sort: ${sort}
              order: ${order}
              limit : ${rowsPerPage}
              offset : ${offsetPage}
              where: { parent_id: { _is_null: true } }
            ) {
              data {
                id
                created_at
                updated_at
                country
                purpose
                configuration
                parent_id
              }
              count
            }
          }
        `,
      });

      console.log(req);

      setdata(req.allMenuLayoutsList.data);
      setCount(req.allMenuLayoutsList.count);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const columns: Array<Column<any>> = [
    { title: "Purpose", field: "purpose" },
    // {
    //   title: "Label Name",
    //   field: "configuration",
    //   sorting: false,
    //   render: (rowData: any) => (
    //     <div>{rowData.configuration.label_id || "-"}</div>
    //   ),
    // },
    {
      title: "Configuration",
      field: "configuration",
      sorting: false,
      render: (rowData: any) => (
        <div>
          <ReactJson
            src={rowData.configuration || {}}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
            collapsed={0}
          />
        </div>
      ),
    },
    {
      title: "Country",
      field: "country",
      sorting: false,
      render: (rowData: any) => (
        <div>
          <ReactJson
            src={rowData.country || {}}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
            collapsed={0}
          />
        </div>
      ),
    },
    {
      title: "Screen",
      field: "screen",
      sorting: false,
      render: (rowData: any) => (
        <div style={{ fontWeight: 700 }}>
          {rowData?.configuration?.screen?.toUpperCase() || "-"}
        </div>
      ),
    },
    {
      title: "Platform",
      field: "platform",
      sorting: false,
      render: (rowData: any) => (
        <div style={{ fontWeight: 700 }}>
          {rowData?.configuration?.platform?.toUpperCase() || "-"}
        </div>
      ),
    },
    {
      title: "Created",
      field: "created_at",
      type: "datetime",
    },
  ];

  const deleteData = async (id: string) => {
    await Swal.fire({
      title: "Do you want to delete this menu?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      input: "text",
      inputValue: "",
      inputPlaceholder: "Input token",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (result.value === "") {
          Swal.fire("Error!", "token invalid", "error");
        } else {
          requestDelete(id, result.value);
        }
      }
    });
  };

  const requestDelete = async (id: string, token: string) => {
    const req = await Mbaas.client.invokeApi("console-delete-menu-layout", {
      token,
      id,
    });

    console.log(req);

    if (req.data) {
      Swal.fire("Success!", "Menu deleted", "success");
      fetchList();
    } else {
      let errorMsg = req.error.error;
      if (errorMsg.includes("violates foreign key constraint"))
        errorMsg =
          "This menu has children. Please remove the child-menu first.";
      Swal.fire("Error!", errorMsg, "error");
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress size={120} />
        </Grid>
      ) : (
        <MaterialTable
          onPageChange={(page: number, pageSize: number) => {
            setCurrentPage(page);
            setRowsPerPage(pageSize);
            setOffsetPage(page * rowsPerPage);
          }}
          onOrderChange={(orderBy: number, orderDirection: "desc" | "asc") => {
            if (orderBy === -1) {
              setOrder("DESC");
              setSort("created_at");
            } else {
              setOrder(orderDirection?.toUpperCase());
              setSort(columnList[orderBy]);
            }
          }}
          // isLoading={true}
          totalCount={count}
          page={currentPage}
          title="Menu Group"
          columns={columns}
          options={{
            pageSize: rowsPerPage,
            pageSizeOptions: [1, 5, 10, 15],
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            columnsButton: false,
            search: false,
            thirdSortClick: false,
          }}
          data={data}
          actions={[
            {
              icon: () => <SettingsIcon color="success" />,
              tooltip: "Detail Menu Child",
              onClick: (event, rowData) => {
                globalstate.setMenuID(rowData.id);
                globalstate.setPurpose(rowData.purpose);
              },
            },
            {
              icon: () => <AddBoxIcon color="success" />,
              tooltip: "Add Menu Group",
              isFreeAction: true,
              onClick: (event) => {
                setMode("create");
                setOpen(true);
              },
            },
            {
              icon: () => <EditIcon color="info" />,
              tooltip: "Edit Menu Group",
              onClick: (event, rowData) => {
                setMode("edit");
                setSelectedData(rowData);
                setOpen(true);
              },
            },
            (rowData) => ({
              icon: () => <DeleteIcon color="error" />,
              tooltip: "Delete Menu Group",
              onClick: (event, rowData) => deleteData(rowData.id),
            }),
          ]}
        />
      )}
      <AddMenu
        open={open}
        setOpen={setOpen}
        refetch={fetchList}
        modeForm={mode}
        data={selectedData}
      />
    </React.Fragment>
  );
};

export default LayoutList;
