import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceField,
  AutocompleteInput,
  FunctionField,
} from "react-admin";
import ReactJson from "react-json-view";

const DocumentFilter = (props: any) => {
  const choices = [
    { id: "PRODUCT_CATALOG_BATCH", name: "package csv" },
    { id: "PRICE_LIST_BATCH", name: "pricing csv" },
  ];
  return (
    <Filter {...props}>
      <AutocompleteInput
        source="type"
        optionValue="id"
        optionText="name"
        choices={choices}
        alwaysOn
        fullWidth
      />
    </Filter>
  );
};

export const DocumentsList = (props: any) => {
  return (
    <List
      {...props}
      filters={<DocumentFilter />}
      resource="documentsBatch"
      sort={{ field: "created_at", order: "DESC" }}
      title="Documents &rsaquo; Batch"
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="created_at" label="Created" showTime />
        <TextField source="author_id_data.email" label="Author" />
        <TextField source="status" />
        <TextField source="filename" />
        <FunctionField
          label="Remark"
          render={(record: any) => {
            return (
              <ReactJson
                src={record.remark || []}
                collapsed={true}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                name={false}
              />
            );
          }}
        />
      </Datagrid>
    </List>
  );
};
