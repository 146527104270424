// @ts-nocheck
import * as React from "react";
import { useGetList, Confirm, useRefresh, usePermissions } from "react-admin";

import MaterialTable, { Column } from "@material-table/core";
import { Paper, TextField } from "@mui/material";
import mbaas from "../../../provider/Mbaas";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import { format } from "date-fns";
import CountrySelector from "../../CountrySelectorWithStore";
import countryList from "react-select-country-list";
import tariffsStore from "../../../store/tariffsStore";

const SmsTariffs = () => {
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const resetStore = () => {
    tariffsStore.originCountry = "";
    tariffsStore.destinationCountry = "";
    tariffsStore.submitConf = { method: "", data: {} };
  };

  const handleDialogClose = () => {
    setOpen(false);
    resetStore();
  };
  const handleConfirm = async () => {
    setIsLoading(true);
    const { error, data } = await mbaas.client.invokeApi(
      tariffsStore.submitConf.method,
      {
        ...tariffsStore.submitConf.data,
        token: otpCode,
      }
    );
    if (error) {
      Swal.fire(`Request failed: ${error.error}`, "", "error");
    }
    if (data) {
      Swal.fire("Success!", "", "success");
      initData();
      refresh();
    }
    setIsLoading(false);
    setOpen(false);
  };

  const { data } = useGetList("servicesTariffs", {
    filter: {
      service_type: "SMS",
    },
    sort: {
      field: "updated_at",
      order: "DESC",
    },
  });

  const mapData = (rawData: any) => {
    return rawData?.map((x: any) =>
      Object.assign({
        ...x,
        id: x.id,
        author: x.author,
        created_at: x.created_at,
        label: x.label,
        origin_country: x.origin_country,
        destination_country: x.destination_country,
        direction: x.direction,
        is_active: x.is_active,
        rate: x.rate,
        service_type: x.service_type,
        updated_at: x.updated_at,
        version: x.version,
      })
    );
  };

  const originData = mapData(data);
  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const [tableData, setTableData] = React.useState(originData);

  // initData here
  const initData = async () => {
    const { allServiceRatesList } = await mbaas.client.gql.query({
      query: gql`
        query ServicesTariffsList {
          allServiceRatesList {
            data {
              id
              created_at
              updated_at
              service_type
              destination_country
              origin_country
              label
              direction
              rate
              author
              version
              is_active
            }
            count
          }
        }
      `,
    });
    //
    const mappedData = mapData(allServiceRatesList.data);
    setTableData(mappedData);
  };

  const columns: Array<Column<IProductPricingItem>> = [
    {
      title: "Created at",
      field: "created_at",
      editable: "never",
      render: (rowData) =>
        format(new Date(rowData.created_at), "eeee, dd/MM/yyyy HH:mm:ss"),
    },
    {
      title: "Service Type",
      field: "service_type",
      render: (rowData) => rowData.service_type,
      editable: "never",
    },
    {
      title: "Origin Country",
      field: "origin_country",
      width: 300,
      render: (rowData) =>
        rowData.origin_country === null
          ? ""
          : rowData.origin_country === "GLOBAL"
          ? "WORLDWIDE"
          : `${countryList().getLabel(rowData.origin_country)} (${
              rowData.origin_country
            })`,
      editComponent(props) {
        if (tariffsStore.originCountry === "") {
          tariffsStore.originCountry = props?.rowData?.origin_country;
        }
        return <CountrySelector targetCountry="origin" />;
      },
    },
    {
      title: "Destination Country",
      field: "destination_country",
      width: 300,
      render: (rowData) =>
        rowData.destination_country === "GLOBAL"
          ? "WORLDWIDE"
          : `${countryList().getLabel(rowData.destination_country)} (${
              rowData.destination_country
            })`,
      editComponent(props) {
        if (tariffsStore.destinationCountry === "") {
          tariffsStore.destinationCountry = props?.rowData?.destination_country;
        }
        return <CountrySelector targetCountry="destination" />;
      },
    },
    {
      title: "Label",
      field: "label",
      render: (rowData) => rowData.label,
      editComponent(props) {
        return (
          <TextField
            value={props?.rowData?.label ? props?.rowData?.label : null}
            onChange={(data) => props.onChange(data.target.value)}
            id="label"
            label="Label"
            variant="standard"
          />
        );
      },
    },
    {
      title: "Direction",
      field: "direction",
      render: (rowData) => rowData.direction,
      editComponent(props) {
        return (
          <TextField
            value={props?.rowData?.direction ? props?.rowData?.direction : null}
            onChange={(data) => {
              props.onChange(data.target.value);
            }}
            onSubmit={(e) => e.preventDefault()}
            id="direction"
            label="Direction"
            variant="standard"
          />
        );
      },
    },
    {
      title: "Rate",
      field: "rate",
      render: (rowData) => rowData.rate,
      editComponent(props) {
        return (
          <TextField
            value={props?.rowData?.rate ? props?.rowData?.rate : null}
            onChange={(data) => props.onChange(+data.target.value)}
            id="rate"
            label="Rate"
            variant="standard"
            type="number"
          />
        );
      },
    },
    {
      title: "Ver.",
      field: "version",
      render: (rowData) => rowData.version,
      editComponent(props) {
        return (
          <TextField
            value={props?.rowData?.version ? props?.rowData?.version : null}
            onChange={(data) => props.onChange(data.target.value)}
            id="version"
            label="Version"
            variant="standard"
          />
        );
      },
    },
  ];

  //
  const { isLoading: isLoading2, permissions } = usePermissions();

  const isAdmin =
    !isLoading2 &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");
  //
  const editableObj = {
    onRowUpdateCancelled: async () => resetStore(),
    onRowAddCancelled: async () => resetStore(),
    onRowAdd: async (newData) => {
      if (Object.keys(newData).length === 0) {
        Swal.fire("There is no data to be added!", "", "error");
      } else {
        const { label, rate, version, direction } = newData;
        //
        setOtpCode("");
        tariffsStore.submitConf = {
          method: "console-add-tariffs",
          data: {
            data: {
              service_type: "SMS",
              destination_country: tariffsStore.destinationCountry,
              origin_country: tariffsStore.originCountry,
              direction,
              label,
              rate,
              version,
            },
          },
        };
        setOpen(true);
      }
    },
    onRowUpdate: async (newData, oldData) => {
      console.log(234, {
        oldData,
        newData,
      });
      const { label, rate, version, direction } = newData;

      setOtpCode("");
      tariffsStore.submitConf = {
        method: "console-update-tariffs",
        data: {
          data: {
            service_type: "SMS",
            destination_country: tariffsStore.destinationCountry,
            origin_country: tariffsStore.originCountry,
            direction,
            label,
            rate,
            version,
          },
          id: newData.id,
        },
      };
      setOpen(true);
    },
  };
  // show editabled when super admin or admin
  const showEditTable = isAdmin ? { ...editableObj } : {};

  return (
    <>
      <Confirm
        isOpen={open}
        loading={otpCode.length < 6 ? true : isLoading}
        title="OTP code"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Paper style={{ width: "100%" }}>
        <MaterialTable
          columns={columns}
          title={false}
          data={tableData}
          editable={{
            ...showEditTable,
          }}
        />
      </Paper>
    </>
  );
};

export default SmsTariffs;
