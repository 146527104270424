import List from "./ListPage";
import Show from "./ShowPage";
import Edit from "./EditPage"

const resource = {
  list: List,
  show: Show,
  edit: Edit
};

export default resource;
