import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { endOfToday, startOfToday, subMonths } from "date-fns";

export interface IreloadLog {
  reloadLogFilter: {
    status: string;
    name: string;
    phone: string;
    channel: string;
    reload_id: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: IreloadLog = {
  reloadLogFilter: {
    status: "",
    name: "",
    phone: "",
    channel: "",
    reload_id: "",
    dateGte: subMonths(startOfToday(), 1),
    dateLte: endOfToday(),
  },
};

type reloadLogFilter = {
  status: string;
  name: string;
  phone: string;
  channel: string;
  reload_id: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const reloadLogFilterSlice = createSlice({
  name: "thisReloadLogFilter",
  initialState,
  reducers: {
    setReloadLogFilter: (state, action: PayloadAction<reloadLogFilter>) => {
      state.reloadLogFilter = {
        ...state.reloadLogFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setReloadLogFilter } = reloadLogFilterSlice.actions;

export const getReloadLogFilter = (state: RootState) =>
  state.thisReloadLogFilter.reloadLogFilter;

export default reloadLogFilterSlice.reducer;
