import React from "react";
import { Toolbar, SaveButton, DeleteWithConfirmButton } from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const CustomToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton confirmContent="Are you sure want to delete this record?" />
  </Toolbar>
);
