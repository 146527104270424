import { Box, Grid, IconButton, Stack } from "@mui/material";
import { AutocompleteArrayInput, DateTimeInput, Edit, NumberInput, SimpleForm, required, useGetIdentity, useNotify, useRedirect, useRefresh } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { useState } from "react";
import { dataProvider } from "../../../provider";
import moment from "moment";
import { omit } from "lodash";

interface EditPointRulesProps {
    onClose : () => void;
    id: string
}

export const EditPointRules: React.FC<EditPointRulesProps> = (props) => {
    const { onClose, id, ...restProps } = props;
    const { isLoading: isIdLoading, identity: user } = useGetIdentity();

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const [loading, setLoading] = useState<boolean>(false);
    const [prevData, setPrevData] = useState<any>(null);

    const isLoading = loading || isIdLoading;

    const onSubmitHandler = async (values: any) => {
        setLoading(true)
        try {
            const payloads = {
                ...omit(values, ['created_at','updated_at', 'id']),
                start: moment(values.start).toISOString(),
                end: moment(values.end).toISOString(),
                update_history: {
                    "updated_by": {
                        "name": user?.name,
                        "user_id": user?.id
                    },
                    "changes": {
                        "from": { 
                            ...omit(prevData, ['created_at','updated_at', 'id']),
                            start: moment(prevData.start).toISOString(),
                            end: moment(prevData.end).toISOString(),
                        },
                        "to": {
                            ...omit(values, ['created_at','updated_at', 'id']),
                            start: moment(values.start).toISOString(),
                            end: moment(values.end).toISOString(),
                        },
                    }
                }
            }
            const resp = await dataProvider.update('pointRules', {
                id: id,
                data: payloads,
                previousData: undefined
            });
            if(resp.data){
                notify("ra.notification.created", {
                    type: "info",
                    messageArgs: { smart_count: 1 },
                    undoable: true,
                });
                redirect(`/internal/point-rules`);
                refresh();
                Swal.fire("Success!", "", "success");
            }
        } catch (error) {
            Swal.fire(`Request failed: ${error}`, "", "error");
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Edit id={id} resource="pointRules" title=" &rsaquo; Edit" {...restProps} queryOptions={{ onSuccess: (data: any) => {
                setPrevData(data);
            }}}>
                <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
                    <Stack
                        direction="row"
                        p={0}
                        sx={{ position: "absolute", right: 10, zIndex: 100 }}
                    >
                        <IconButton color="error" onClick={onClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <SimpleForm pt={1} onSubmit={onSubmitHandler}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                            <Grid item xs={12} sm={6}>
                                <AutocompleteArrayInput 
                                    source="affected_countries"
                                    optionValue="id"
                                    optionText="name"
                                    fullWidth
                                    validate={required()}
                                    readOnly={isLoading}
                                    choices={[
                                        { id: "GLOBAL", name: "GLOBAL" },
                                        { id: "HK", name: "Hongkong (HK)" },
                                        { id: "ID", name: "Indonesia (ID)" },
                                        { id: "TW", name: "Taiwan (TW)" },
                                        { id: "MY", name: "Malaysia (MY)" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutocompleteArrayInput 
                                    source="affected_service"
                                    optionValue="id"
                                    optionText="name"
                                    fullWidth
                                    validate={required()}
                                    readOnly={isLoading}
                                    choices={[
                                        { id: "G2", name: "G2" },
                                        { id: "M21", name: "M21" },
                                        { id: "GHK", name: "GHK" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateTimeInput 
                                    source="start"
                                    label="Valid From" 
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateTimeInput 
                                    source="end"
                                    label="Valid To" 
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.point.min"
                                    data-testid="limits.point.min"
                                    label="Min Limit Point"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.point.max"
                                    data-testid="limits.point.max"
                                    label="Max Limit Point"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.coin.min"
                                    data-testid="limits.coin.min"
                                    label="Min Limit Coin"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.coin.max"
                                    data-testid="limits.coin.max"
                                    label="Max Limit Coin"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.usage_limit"
                                    data-testid="limits.usage_limit"
                                    label="Usage Limit"
                                    validate={required()}
                                />
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Box>
            </Edit>
        </>
    )
}
