import { Drawer, Stack } from "@mui/material";
import React, { Fragment } from "react";
import {
  Datagrid,
  TextField,
  TextInput,
  Pagination,
  RaRecord,
  List,
  FunctionField,
  FilterForm,
  Identifier,
  RowClickFunction,
  DateField,
} from "react-admin";
//
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { ExecutionLogsShow } from "./Show";
import ReactJson from "react-json-view";
//
const CustomPagination = (props: any) => (
  <Pagination rowsPerPageOptions={["5", "10", "25", "50", "100"]} {...props} />
);
//
export const ExecutionLogsList = () => {
  const Filters = [
    <TextInput
      source="reference_id"
      label="Reference ID"
      alwaysOn
      data-test-id="search-by-reference-id"
    />,
    <TextInput
      source="type"
      label="Type"
      alwaysOn
      data-test-id="search-by-type"
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <FilterForm filters={Filters} />
    </Stack>
  );
  //
  const location = useLocation();
  const navigate = useNavigate();
  //
  const handleClose = React.useCallback(() => {
    navigate("/internal/execution-logs");
  }, [navigate]);
  //
  const match = matchPath(
    "/internal/execution-logs/:id/show",
    location.pathname
  );
  //
  return (
    <Fragment>
      <List
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        actions={<ListToolbar />}
        pagination={<CustomPagination />}
        resource="executionLogs"
        title="Execution Logs"
      >
        <Datagrid
          rowClick={
            ((id: Identifier) =>
              navigate(
                `/internal/execution-logs/${id}/show`
              )) as unknown as RowClickFunction
          }
          key={((id: Identifier) => id) as unknown as React.Key}
          bulkActionButtons={false}
        >
          <DateField
            source="created_at"
            label="Created"
            showTime
            locales="en-HK"
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }}
          />
          <TextField source="type" label="Type" />
          <TextField source="stage" label="Stage" />
          <TextField source="status" label="Status" />
          <TextField source="reference_id" label="Reference ID" />
          <FunctionField
            label="Reference Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.reference_detail || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Normal Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.normal_detail || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
          <FunctionField
            label="Exception Detail"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.exception_detail || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
        </Datagrid>
      </List>
      <Drawer
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && <ExecutionLogsShow id={(match as any).params.id} />}
      </Drawer>
    </Fragment>
  );
};
