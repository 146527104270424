import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
} from "react-admin";
import gqltag from "graphql-tag";
import gql from "graphql-tag";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class FaqsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log({ method: "getList", resource, params });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.language) {
      filterArray.push({
        language: {
          _eq: params.filter.language,
        },
      });
    }

    if (params.filter.question) {
      filterArray.push({
        question: {
          _ilike: `%${params.filter.question}%`,
        },
      });
    }

    if (filterArray.length > 0) where._and = filterArray;

    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;

    const args = buildArgs({
      // @ts-ignore
      order: order || "DESC",
      size: perPage,
      pageNumber: page,
      sort: field || "created_at",
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query getAllFaq($where: FaqsWhereInput) {
          allFaqsList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              question
              answer
              language
            }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allFaqsList?.data || [],
      total: queryResult?.allFaqsList?.count || 0,
    } as GetListResult;
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const queryResult = await this.client.gql.query({
      query: gqltag`
          query {
              allFaqsList(
                where: {
                  id: {
                  _eq: "${params.id}",
                  }
                }
              ){
              data {
                id
                created_at
                updated_at
                question
                answer
                language
              }
              count
            }
          }
      `,
    });

    return { data: queryResult?.allFaqsList?.data[0] };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    console.log("getMany");

    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
              mutation($input: [FaqsCreateInput!]!){
                  createFaqs(input: $input){
                      id
                      created_at
                      updated_at
                      question
                      answer
                      language
                  }
              }
          `,
      variables: {
        input: params.data,
      },
    });

    return { data: queryResult?.createFaqs[0] } as CreateResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const submittedData = {
      language: params?.data?.language,
      question: params?.data?.question,
      answer: params?.data?.answer,
    };
    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
              mutation($ids: [UUID!]!, $input: FaqsUpdateInput!){
                  updateFaqsById(ids:$ids, input:$input){
                      id
                      question
                      answer
                      language
                  }
              }
          `,
      variables: {
        ids: [`${params.id}`],
        input: submittedData,
      },
    });

    return { data: queryResult?.updateFaqsById[0] } as UpdateResult;
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
            mutation($ids: [UUID!]!){
              deleteFaqsById(ids:$ids)
            }
          `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return { data: queryResult?.deleteFaqsById[0] } as DeleteResult;
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams<any>
  ): Promise<DeleteManyResult<any>> {
    console.log({ method: "deleteMany", resource, params });

    let final_result;

    const queryResult = await this.client.gql.mutation({
      mutation: gqltag`
            mutation($ids: [UUID!]!){
              deleteFaqsById(ids:$ids)
            }
          `,
      variables: {
        ids: [...params.ids],
      },
    });

    if (queryResult?.deleteFaqsById) {
      final_result = queryResult?.deleteFaqsById;
    }

    if (queryResult?.error) {
      throw new Error("Deletion failed");
    }
    return {
      data: [final_result],
    } as DeleteManyResult;
  }
}
