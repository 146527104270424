import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import gqltag from "graphql-tag"
import { ProviderBase } from "./ProviderBase";
export class PrivacyPolicyProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    
    const queryResult = await this.client.gql.query({
        query: gqltag`
            query {
                allSystemConfigsList(
                    limit: ${perPage}, 
                    offset: ${(page - 1) * perPage},
                    sort: ${field},
                    order: ${order || "ASC"},
                    where: {
                      key: {
                        _ilike: "privacypolicy%"
                      }
                    }
                    ){
                    data {
                      id
                      created_at
                      updated_at
                      key
                      value
                    }
                    count
                }
            }
        `,
    });
    
    return {data: queryResult?.allSystemConfigsList?.data, total: queryResult?.allSystemConfigsList?.count};
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const queryResult = await this.client.gql.query({
      query: gqltag`
          query {
              allSystemConfigsList(
                where: {
                  id: {
                  _eq: "${params.id}",
                  }
                }
              ){
              data {
                id
                created_at
                updated_at
                key
                value
              }
              count
            }
          }
      `,
    });
  
    return {data: queryResult?.allSystemConfigsList?.data[0]};
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const { data } = await this.client.getTable(resource).insert(params.data);
    return { data: data } as CreateResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const submittedData = {
        key: params?.data?.key,
        value: params?.data?.value
      };
    
      const queryResult = await this.client.gql.mutation({
        mutation: gqltag`
                mutation($ids: [UUID!]!, $input: SystemConfigsUpdateInput!){
                    updateSystemConfigsById(ids:$ids, input:$input){
                        id
                        key
                        value
                    }
                }
            `,
        variables: {
            ids: [`${params.id}`],
            input: submittedData,
        },
      });
  
      return { data: queryResult?.updateSystemConfigsById[0] } as UpdateResult;
  }
}
