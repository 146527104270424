/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import mbaas from "../../provider/Mbaas";
import QRCodeView from "qrcode.react";
import { Key } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// @ts-ignore
import createHash from "crypto-browserify";
import axios from "axios";
import { secretbox } from "tweetnacl";
import { useNotify, useRefresh } from "react-admin";
import id from "date-fns/esm/locale/id/index.js";

export default function FormKycDialog({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
}) {
  const [token, setToken] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [imgzoom, setImgzoom] = useState<boolean>(false);
  const [numberData, setNumberData] = useState(
    data?.identity_cards_registration_id_list?.data[0]
  );
  const [imgblob, setImgblob] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setNumberData(data?.identity_cards_registration_id_list?.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const changeValue = (value: any, names: any) => {
    setNumberData({
      ...numberData,
      [names]: value,
    });
  };

  const submitForm = async (e: any, status: boolean) => {
    console.log(status);
    try {
      const data = {
        token: token,
        data: {
          ...numberData,
          remark: remark,
          status: status ? "APPROVED" : "REJECTED",
        },
      };
      const req = await mbaas.client.invokeApi(
        "console-update-registration-number",
        data
      );
      console.log(req);

      if (req.error) {
        if (req.error.error) {
          notify(req.error.error, { type: "warning" });
        } else {
          notify("Please fill all input form", { type: "warning" });
        }
        return;
      }
      notify("Success update registration", { type: "success" });
      setRemark("");
      setToken("");
      setOpen(false);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const decryptFile = async (fileid: string) => {
    setLoading(true);
    setImgzoom(true);
    try {
      console.log("====================================");
      console.log(data);
      console.log("====================================");
      const secretUser = data.user_id_data.secret;
      const idUser = data.user_id_data.id;

      const secret = secretUser + idUser;

      const key = createHash.createHash("sha256").update(secret).digest();
      const decUrl = await mbaas.client.storage.downloadUrl({
        params: {
          bucket: "registrations",
          expire: "20",
        },
        fileId: fileid,
      });

      console.log(decUrl);
      const { data: mergedtext } = await axios.request({
        method: "get",
        url: decUrl.data.url,
        responseType: "arraybuffer",
      });
      console.log(mergedtext);
      const nonce = mergedtext.slice(0, secretbox.nonceLength);
      const ciphertext = mergedtext.slice(
        secretbox.nonceLength,
        mergedtext.length
      );
      console.log("====================================");
      console.log("chiperText");
      console.log(ciphertext);
      const encodedString = Buffer.from(ciphertext).toString("base64");
      console.log(encodedString);

      console.log("nonce");
      console.log(nonce);

      console.log("key");
      console.log(key);
      const encodedStrings = Buffer.from(key).toString("base64");
      console.log(encodedStrings);

      console.log("====================================");
      var chpr = new Uint8Array(ciphertext);
      var iv = new Uint8Array(nonce);

      const plaintext = secretbox.open(chpr, iv, key);
      // const plaintext = secretbox.open(ciphertext, nonce, key);

      if (plaintext) {
        console.log("plaintext");
        console.log(plaintext);
        const base64Plain = Buffer.from(plaintext).toString("base64");
        console.log(base64Plain);

        // const content = new Uint8Array([
        //   137, 80, 78, 71, 13, 10, 26, 10, 0, 0, 0, 13, 73, 72, 68, 82, 0, 0, 0,
        //   5, 0, 0, 0, 5, 8, 6, 0, 0, 0, 141, 111, 38, 229, 0, 0, 0, 28, 73, 68,
        //   65, 84, 8, 215, 99, 248, 255, 255, 63, 195, 127, 6, 32, 5, 195, 32,
        //   18, 132, 208, 49, 241, 130, 88, 205, 4, 0, 14, 245, 53, 203, 209, 142,
        //   14, 31, 0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130,
        // ]);
        // const example = await URL.createObjectURL(
        //   new Blob([content.buffer], { type: "image/jpeg" })
        // );

        const blobUrl = await (window.URL ? URL : webkitURL).createObjectURL(
          new Blob([plaintext], { type: "image/jpeg" })
        );

        console.log(blobUrl);

        // as blob
        setImgblob(blobUrl);

        // as data
        // setImgblob("data:image/jpeg;base64," + base64Plain);
      } else {
        throw new Error("decryption failed");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={imgzoom}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">File Detail</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loading ? (
              "LOADING . . ."
            ) : (
              <img src={imgblob} alt="imgpreview" style={{ width: 900 }} />

              // <Zoom img={imgblob} zoomScale={2} width={900} height={500} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setImgzoom(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            <Key /> Form KYC Virtual Number
          </Grid>
        </DialogTitle>
        <form onSubmit={(e) => e.preventDefault()}>
          <DialogContent>
            <DialogContentText>
              Data checking for pending subscriber registration
            </DialogContentText>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <TextField
                label="NIK"
                value={numberData?.nik || ""}
                onChange={(e) => changeValue(e.target.value, "nik")}
                required
                fullWidth
              />
              <Box width={"32px"} />
              <TextField
                label="Full Name"
                value={numberData?.full_name || ""}
                onChange={(e) => changeValue(e.target.value, "full_name")}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <TextField
                id="date"
                label="Date birth"
                type="date"
                fullWidth
                value={numberData?.date_of_birth || ""}
                onChange={(e) => changeValue(e.target.value, "date_of_birth")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box width={"32px"} />
              <TextField
                label="Place Birth"
                value={numberData?.place_of_birth || ""}
                onChange={(e) => changeValue(e.target.value, "place_of_birth")}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormControl variant="filled" sx={{ minWidth: 120 }} fullWidth>
                <InputLabel id="demo-simple-select-filled-label">
                  Sex
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  fullWidth
                  value={numberData?.sex || "L"}
                  onChange={(e) => changeValue(e.target.value, "sex")}
                  // onChange={handleChange}
                >
                  <MenuItem value={"P"}>Perempuan</MenuItem>
                  <MenuItem value={"L"}>Laki Laki</MenuItem>
                </Select>
              </FormControl>
              <Box width={"32px"} />
              <TextField
                label="Nationality"
                value={numberData?.citizenship || ""}
                onChange={(e) => changeValue(e.target.value, "citizenship")}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <TextField
                id="date"
                label="Valid until"
                type="date"
                fullWidth
                value={numberData?.date_of_expiry || ""}
                onChange={(e) => changeValue(e.target.value, "date_of_expiry")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box width={"32px"} />
              <TextField
                label="remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                required
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <TextField
                label="Token"
                value={token}
                required
                fullWidth
                onChange={(e) => setToken(e.target.value)}
              />
            </Box>

            <div style={{ margin: "20px 0px 10px 0px" }}>Registration File</div>
            <Grid container direction="row" alignItems="center" gap={1}>
              <Button
                size="small"
                variant="contained"
                disabled={
                  numberData?.document_id === null ||
                  numberData?.document_id === undefined
                }
                onClick={() => decryptFile(numberData?.document_id)}
              >
                Document
              </Button>
              <Button
                size="small"
                variant="contained"
                disabled={
                  numberData?.signature_id === null ||
                  numberData?.signature_id === undefined
                }
                onClick={() => decryptFile(numberData?.signature_id)}
              >
                Signature
              </Button>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container direction="row" justifyContent="space-between">
              <Button
                onClick={() => setOpen(false)}
                // disabled={loading}
                color="primary"
                size="small"
              >
                Close
              </Button>
              <Grid item gap={2}>
                <Button
                  style={{ marginRight: "20px", backgroundColor: "#e02828" }}
                  color="secondary"
                  variant="contained"
                  size="small"
                  type="submit"
                  startIcon={<CancelIcon />}
                  onClick={(e) => submitForm(e, false)}
                >
                  REJECT
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                  startIcon={<CheckCircleIcon />}
                  onClick={(e) => submitForm(e, true)}
                >
                  ACCEPT
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
