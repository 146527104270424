// @ts-nocheck
import React from "react";
import {
  SimpleForm,
  required,
  useListContext,
  useNotify,
  Create,
  useRedirect,
  SelectInput,
  TextInput,
  useGetList,
  Loading,
  Error,
  ImageInput,
  ImageField,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { RichTextInput } from "ra-input-rich-text";

const countryList = [
  { id: "GLOBAL", name: "Worldwide" },
  { id: "HK", name: "Hong Kong" },
  { id: "ID", name: "Indonesia" },
  { id: "MY", name: "Malaysia" },
  { id: "TW", name: "Taiwan" },
];

const CreatePage = (props) => {
  const listData = useListContext();

  const classes = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/diasporaEmbassy");
  };
  const { data, isLoading, error } = useGetList("diasporaEmbassy");

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  // const postDefaultValue = () => ({ total: data?.total });
  return (
    <Create
      {...props}
      title="Diaspora Embassy Info &gt; Add"
      mutationOptions={{
        onSuccess: onSuccess,
      }}
      redirect="list"
    >
      <SimpleForm defaultValues={{ total: data }}>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  source="content.title"
                  validate={[required()]}
                  multiline
                  label="Title"
                  fullWidth
                  data-test-id="title"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  fullWidth
                  className={classes.input}
                  source="country_code"
                  validate={[required()]}
                  choices={countryList}
                  label="Country"
                  data-test-id="country"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="content.uri"
                  validate={[required()]}
                  multiline
                  label="Uri"
                  fullWidth
                  data-test-id="uri"
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextInput
                  source="content.content"
                  label="Content"
                  className={classes.input}
                  fullWidth
                  validate={[required()]}
                  data-test-id="contentInput"
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <ImageInput
                  data-test-id="image"
                  source="urlThumbnail"
                  label="Main Image"
                  validate={[required()]}
                  accept="image/*"
                  options={{ multiple: false }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreatePage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
}));
