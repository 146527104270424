/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Grid,
  CircularProgress,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import { Google } from "@mui/icons-material";
import ColorVal from "../utils/color";
import { useLogin, useNotify, useRedirect } from "react-admin";
import mbaas from "../provider/Mbaas";

function getparam(parameterName: String) {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const [email, setEmail] = useState("");
  const login = useLogin();
  const redirect = useRedirect();

  const notify = useNotify();

  const newLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await mbaas.client.auth.forgotPassword(email);
      console.log(res);
      notify("Check ur email", { type: "success" });
      setEmail("");
    } catch (e: any) {
      console.log(e);
      notify(e?.title, { type: "warning" });
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(/bg5.jpg)`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Paper
          style={{
            width: "350px",
            minHeight: "450px",
            padding: "40px",
            marginTop: "50px",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <img src={"logom21.png"} alt="logo" style={{ width: "80px" }} />
            </Grid>
            <Grid item>
              <p>Forgot Password</p>
            </Grid>
            <Grid item style={{ width: "100%", margin: "20px 0px" }}>
              <Divider />
            </Grid>
            <Grid item style={{ width: "100%", marginBottom: "10px" }}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="medium"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid
              item
              style={{ margin: "10px", width: "100%", marginTop: "100px" }}
            >
              <Button
                color="primary"
                variant="contained"
                // onClick={idfs}
                onClick={newLogin}
                disabled={loading && loadingGoogle}
                style={{
                  backgroundColor: ColorVal.primary,
                  color: ColorVal.secondary,
                  width: "100%",
                }}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Forgot Password"
                )}
              </Button>
            </Grid>
            <Grid
              item
              style={{
                color: ColorVal.primary,
                fontSize: "13px",
                cursor: "pointer",
              }}
              onClick={() => redirect("/login")}
            >
              Back to Login Page
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
