import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  DateTimeInput,
  SelectInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  usePermissions,
  FormDataConsumer,
} from "react-admin";
import { Box } from "@mui/material";

const UserEditToolbar = (props: any) => {
  const { isLoading, permissions } = usePermissions();
  console.log({ isLoading, permissions });

  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  return (
    <Toolbar {...props}>
      <SaveButton disabled={!isLoading && !isAdmin} />
    </Toolbar>
  );
};

const EditPage = () => {
  return (
    <Edit>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput
            source="id"
            label="Record ID"
            isRequired
            fullWidth
            disabled
          />
          <Box width={"32px"} />
          <DateTimeInput source="created_at" fullWidth disabled />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <TextInput source="email" isRequired fullWidth disabled />
          <Box width={"32px"} />{" "}
          <TextInput source="name" fullWidth defaultValue={""} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <SelectInput
            source="roles"
            fullWidth
            isRequired
            choices={[
              { id: "Admin", name: "Admin" },
              { id: "Super Admin", name: "Super Admin" },
              { id: "Staff", name: "Staff" },
            ]}
          />
          <Box width={"32px"} />{" "}
          <TextInput source="token" fullWidth defaultValue={""} required />
        </Box>
        {/* <BooleanInput source="is_blocked" label="Blocked" fullWidth /> */}
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.deactivated_at !== null) {
              return (
                <BooleanInput
                  source="edit_deactivated"
                  label="Blocked true "
                  defaultValue={true || ""}
                  fullWidth
                />
              );
            } else {
              return (
                <BooleanInput
                  source="edit_deactivated"
                  label="Blocked false"
                  fullWidth
                />
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default EditPage;
