import React from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import tariffsStore from "../store/tariffsStore";
import { view } from "@risingstack/react-easy-state";

interface CountryOption {
  readonly value: string;
  readonly label: string;
}

function CountrySelector(props: any) {
  const { targetCountry } = props;
  const options: readonly CountryOption[] = countryList().getData();
  const worldwide: readonly CountryOption[] = [
    { value: "GLOBAL", label: "Worldwide" },
  ];
  const res = worldwide.concat(options) as readonly CountryOption[];

  const changeHandler = (value: any) => {
    if (targetCountry === "destination") {
      tariffsStore.destinationCountry = value.value;
    } else {
      tariffsStore.originCountry = value.value;
    }
  };

  const defaultValue = res.find((country) =>
    targetCountry === "destination"
      ? country.value === tariffsStore.destinationCountry
      : country.value === tariffsStore.originCountry
  );

  return (
    <Select
      //@ts-ignore
      options={res}
      getOptionLabel={(halo: any) => {
        return `(${halo?.value}) ${halo?.label}`;
      }}
      onChange={changeHandler}
      placeholder={<div>Select Country</div>}
      //@ts-ignore
      defaultValue={defaultValue}
    />
  );
}

export default view(CountrySelector);
