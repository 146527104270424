import React, { useEffect } from "react";
import {
  Button,
  useListContext,
  Confirm,
  useCreate,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  required,
  Toolbar,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  requirePropFactory,
  TextField,
  Typography,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { endOfToday, format } from "date-fns";
import SyncIcon from "@mui/icons-material/Sync";
import { dataProvider } from "../../provider";
import ButtonMui from "@mui/material/Button";
import Mbaas from "../../provider/Mbaas";
import Swal from "sweetalert2";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function MigrateAs2in1UserButton(props: any) {
  const [open, setOpen] = React.useState(false);

  const [userId, setUserId] = React.useState("");
  const [otpCode, setOtpCode] = React.useState("");

  //
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleAdd = async () => {
    const { data, error } = await Mbaas.client.invokeApi(
      "console-migrate-from-as2in1",
      {
        token: otpCode,
        data: {
          user_id: userId,
        },
      }
    );
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: ".MuiDialog-paperFullWidth",
      });
    }
    // --
    if (data) {
      Swal.fire({
        icon: "success",
        title: "Success Migrate Data",
        text: data,
        timer: 5000,
        target: ".MuiDialog-paperFullWidth",
      });
      setTimeout(() => {
        // -- perform update
        setOtpCode("");
      }, 1400);
    }
  };

  const handleCancel = () => {
    setUserId("");
    setOtpCode("");
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        size="medium"
        startIcon={<SyncIcon />}
        label={`Migrate As2in1 User`}
        onClick={handleOnClick}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Migrate As2in1 User</DialogTitle>
        <DialogContent style={{ paddingBlockEnd: 0 }}>
          <Alert style={{ marginInline: 15 }} severity="warning">
            Be careful you are going to migrate subscriber's data from As2In1 to
            M21 Apps, this process cannot be undone after you confirm.
          </Alert>

          <SimpleForm toolbar={false} style={{ paddingBlockEnd: 0 }}>
            <ReferenceInput
              source="email"
              reference="subscribers"
              label="Subscriber"
              helperText={false}
            >
              <AutocompleteInput
                fullWidth
                isRequired
                size="small"
                source="email"
                optionText="email"
                label="E-mail"
                helperText={false}
                onChange={(x) => setUserId(x)}
              />
            </ReferenceInput>
            {!userId && (
              <span style={{ color: "#e42313", marginBlockEnd: 15 }}>
                Cannot be empty!
              </span>
            )}
            <TextField
              type="number"
              label="OTP 2FA"
              fullWidth
              data-test-id="input-otp"
              value={otpCode}
              required
              onChange={(e) => setOtpCode(e.target.value)}
            />
            {!otpCode && (
              <span style={{ color: "#e42313", marginBlockEnd: 15 }}>
                Cannot be empty!
              </span>
            )}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <ButtonMui
            sx={{ margin: 2 }}
            variant="contained"
            color="error"
            size="small"
            onClick={handleCancel}
            startIcon={<CancelIcon />}
          >
            Cancel
          </ButtonMui>
          <ButtonMui
            sx={{ margin: 2 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              if (userId && otpCode) {
                handleAdd();
              }
            }}
            startIcon={<CheckCircleIcon />}
          >
            Confirm
          </ButtonMui>
        </DialogActions>
      </Dialog>
    </>
  );
}
