import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export interface Imvno {
    mvnoFilter: {
        purchase_id: string;
        status: string;
        user_id: string;
        bundle_id: string;
        dateGte: string;
        dateLte: string;
    };
}

const initialState: Imvno = {
    mvnoFilter: {
        purchase_id: "",
        bundle_id: "",
        status: "",
        user_id: "",
        dateGte: "",
        dateLte: "",
    },
};

type mvnoFilter = {
    purchase_id: string;
    bundle_id: string;
    status: string;
    user_id: string;
    dateGte: string;
    dateLte: string;
};

export const mvnoFilterSlice = createSlice({
    name: "thisMvnoFilter",
    initialState,
    reducers: {
        setMvnoFilter: (state, action: PayloadAction<mvnoFilter>) => {
            state.mvnoFilter = {
                ...state.mvnoFilter,
                ...action.payload,
            };
        },
        reset: () => {
            return initialState;
        },
    },
});

export const { setMvnoFilter } = mvnoFilterSlice.actions;

export const getMvnoFilter = (state: RootState) =>
    state.thisMvnoFilter.mvnoFilter;

export default mvnoFilterSlice.reducer;
