import { AutocompleteInput, required, useInput } from "react-admin";

interface DeliveryModeInputProps {
  isLoading: boolean;
  formData: any;
}

const DeliveryModeInput: React.FC<DeliveryModeInputProps> = (props) => {
  const { isLoading, formData } = props;
  const { field: fieldCategory } = useInput({
    source: "category",
  });

  const CHOICES: any = {
    M21: [
      { id: "VOUCHER-CODE", name: "VOUCHER-CODE" },
      { id: "COUPON-CODE", name: "COUPON-CODE" },
      { id: "AUTO-ORDER", name: "AUTO-ORDER" },
    ],
    G2: [
      { id: "VOUCHER-CODE", name: "VOUCHER-CODE" },
      { id: "COUPON-CODE", name: "COUPON-CODE" },
    ],
    GHK: [
      { id: "VOUCHER-CODE", name: "VOUCHER-CODE" },
      { id: "COUPON-CODE", name: "COUPON-CODE" },
      { id: "AUTO-ORDER", name: "AUTO-ORDER" },
    ],
  };

  return (
    <AutocompleteInput
      source="delivery_mode"
      optionValue="id"
      optionText="name"
      label="Delivery mode"
      fullWidth
      readOnly={isLoading}
      validate={required()}
      onChange={() => {
        fieldCategory.onChange(null);
      }}
      choices={CHOICES[formData.availability_by_service] || []}
    />
  );
};

export default DeliveryModeInput;
