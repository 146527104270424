import React, { useEffect, useLayoutEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Card,
  Box,
  Fab,
  CardMedia,
  CardContent,
  Drawer,
  Button,
  Typography,
  CardActions,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  CreateButton,
  EditButton,
  FilterButton,
  List,
  RecordContextProvider,
  SelectInput,
  TopToolbar,
  useListContext,
  FunctionField,
  Loading,
  useGetOne,
  useGetList,
  required,
} from "react-admin";
import SendIcon from "@mui/icons-material/Send";
import EditPage from "./EditPage";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import Broadcast from "./Broadcast";
import { useDispatch } from "react-redux";
import {
  setBannerPromotionsFilter,
  getBannerPromotionsFilter,
} from "src/slices/bannerPromotionsPage/bannerPromotionsSlice";
import { usePersistFilter } from "src/hooks/usePersistFilter";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useSelector } from "react-redux";
import { LexoRank } from "lexorank";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import Mbaas from "../../../provider/Mbaas";

const ListActions = ({ bannerFilter }: any) => {
  usePersistFilter();
  return (
    <TopToolbar>
      <FilterButton filters={bannerFilter} />
      <CreateButton />
    </TopToolbar>
  );
};

const ListPage = () => {
  const dispatch = useDispatch();

  const BannerFilter = [
    // <DateRange alwaysOn />,
    <SelectInput
      source="country"
      label="Country"
      // defaultValue={"HK"}
      choices={[
        { id: "HK", name: "Hong Kong" },
        { id: "TW", name: "Taiwan" },
        { id: "MY", name: "Malaysia" },
        { id: "ID", name: "Indonesia" },
      ]}
      validate={required()}
      alwaysOn
      data-testid="search-country"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setBannerPromotionsFilter({ country: e.target.value }))
      }
    />,

    <SelectInput
      source="category"
      // defaultValue={"special"}
      label="Type"
      choices={[
        { id: "special", name: "Special" },
        { id: "recommendation", name: "Recommendation" },
      ]}
      validate={required()}
      alwaysOn
      data-testid="search-country"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setBannerPromotionsFilter({ type: e.target.value }))
      }
    />,
    <SelectInput
      source="account_type"
      label="Platform"
      // defaultValue={"M21"}
      choices={[
        { id: "M21", name: "M21" },
        { id: "G2", name: "G2" },
        { id: "GHK", name: "GHK" },
      ]}
      validate={required()}
      alwaysOn
      data-testid="search-country"
      onChange={(e) =>
        //@ts-ignore
        dispatch(setBannerPromotionsFilter({ platform: e.target.value }))
      }
    />,
  ];

  return (
    <List
      perPage={999999}
      pagination={false}
      filterDefaultValues={{
        // country: "HK",
        category: "special",
        account_type: "M21",
      }}
      component="div"
      resource="promotionsBanner"
      title="Promotions &rsaquo; Banner"
      filters={BannerFilter}
      actions={<ListActions bannerFilter={BannerFilter} />}
    >
      <LayoutDetail />
    </List>
  );
};

const LayoutDetail = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useListContext();
  const [items, setItems] = React.useState<Array<any>>([]);
  const [expand, setExpand] = React.useState<Array<string>>([]);
  const [openBroadCastModal, setOpenBroadCastModal] = React.useState(false);
  const [recordId, setRecordId] = React.useState<string>("");
  const bannerPromotionsFilter = useSelector(getBannerPromotionsFilter);
  const [value, setValue] = React.useState(0);
  const [rowsData, setRowsData] = React.useState<Array<any>>([]);

  console.log("COUNTREE", bannerPromotionsFilter);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenBroadCastModal = (record: string) => {
    setRecordId(record);
    setOpenBroadCastModal(true);
  };
  const handleCloseBroadCastModal = () => {
    setRecordId("");
    setOpenBroadCastModal(false);
  }

  const {
    data: bannerPromoData,
    isLoading: isLoadingData,
    error,
  } = useGetOne("promotionsBanner", { id: recordId });

  const { data: bannerList, isLoading: bannerLoading } = useGetList(
    "promotionsBanner",
    {
      filter: {
        type: value === 0 ? "ACTIVE" : value === 1 ? "INACTIVE" : "EXPIRED",
        country: bannerPromotionsFilter.country,
        category: bannerPromotionsFilter.type,
        account_type: bannerPromotionsFilter.platform,
      },
    }
  );

  useLayoutEffect(() => {
    setItems(bannerList || []);
  }, [bannerList]);

  const handleClose = React.useCallback(() => {
    console.log({ cancel: navigate });
    navigate("/promotionsBanner");
  }, [navigate]);

  const matchEdit = matchPath("/promotionsBanner/:id", location.pathname);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const StyledDialog = styled(Drawer)`
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.5);
    }
  `;

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    // console.log(startIndex);
    // console.log(endIndex);
    // console.log(list);

    let newKey;
    if (startIndex < endIndex) {
      console.log("AFTER NEXT");
      // console.log(list[endIndex]);
      const key = LexoRank.parse(list[endIndex].priority).genNext().toString();
      newKey = key;
      console.log(key);
    } else {
      console.log("BEFORE");
      // console.log(list[endIndex]);
      const key = LexoRank.parse(list[endIndex].priority).genPrev().toString();
      newKey = key;
      console.log(key);
    }
    // console.log(newKey);

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result[endIndex].priority = newKey;
    return { data: result[endIndex], newKey, result };
  };

  const updateRow = async (id: string, data: any) => {
    try {
      // console.log(id);
      // console.log(data);

      const req = await Mbaas.client.gql.mutation({
        mutation: gql`
          mutation updateBannersOrder(
            $ids: [UUID!]!
            $input: PromotionalBannersUpdateInput!
          ) {
            updatePromotionalBannersById(ids: $ids, input: $input) {
              id
              created_at
              updated_at
              priority
            }
          }
        `,
        variables: {
          ids: id,
          input: { priority: data.priority },
        },
      });

      console.log(req);

      // fetchData();
    } catch (e: any) {
      Swal.fire("Something wrong!", e.error.error, "error");
    }
  };

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    console.log(result);

    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    const itemorder = reorder(items, sourceIndex, destIndex);

    setItems(itemorder.result);
    console.log(result.draggableId);
    console.log(itemorder);

    // if (!result.destination) return;
    // let tempData = Array.from(rowsData);
    // let [source_data] = tempData.splice(result.source.index, 1);
    // tempData.splice(result.destination.index, 0, source_data);
    // setRowsData(tempData);

    await updateRow(result.draggableId, itemorder.data);
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: "1em", marginLeft: "0px" }}>
      <Grid container direction="column">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab
            sx={{
              bgcolor: value === 0 ? "#5e548e" : "#ffffff",
              color: value === 0 ? "#ffffff" : "#5e548e",
            }}
            label="Active"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              bgcolor: value === 1 ? "#5e548e" : "#ffffff",
              color: value === 1 ? "#ffffff" : "#5e548e",
            }}
            label="Inactive"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              bgcolor: value === 2 ? "#5e548e" : "#ffffff",
              color: value === 2 ? "#ffffff" : "#5e548e",
            }}
            label="Expired"
            {...a11yProps(2)}
          />
        </Tabs>
      </Grid>
      {bannerLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px",
          }}
        >
          <CircularProgress style={{ width: "75px", height: "75px" }} />
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              sx={{ bgcolor: "#e7e7eb", color: "#5e548e", fontWeight: 700 }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={50}
                >
                  Order
                </TableCell>
                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={200}
                  align="center"
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={300}
                  align="center"
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={300}
                  align="center"
                >
                  Content
                </TableCell>
                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={300}
                  align="center"
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={300}
                  align="center"
                >
                  End Date
                </TableCell>

                <TableCell
                  sx={{ color: "#5e548e", fontWeight: 700 }}
                  width={300}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Droppable
            droppableId="dropppable"
            direction="vertical"
            type="parents"
          >
            {(provided, snapshot) => (
              <div
                // style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {items?.map((data, index) => (
                    <RecordContextProvider key={data.id} value={data}>
                      <Draggable
                        key={data.id}
                        draggableId={data.id}
                        index={index}
                        isDragDisabled={expand.includes(data.id)}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            key={data.title}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <TableCell
                              width={50}
                              component="th"
                              scope="row"
                              {...provided.dragHandleProps}
                            >
                              <DragIndicatorIcon />
                            </TableCell>
                            <TableCell width={200} align="center">
                              <img
                                src={data.image}
                                alt="icon"
                                style={{
                                  marginRight: 1,
                                  width: "200px",
                                  height: "100px",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              width={300}
                              align="center"
                              style={{ fontWeight: 700 }}
                            >
                              {data.title}
                            </TableCell>
                            <TableCell width={300} align="center">
                              {data.content}
                            </TableCell>
                            <TableCell width={300} align="center">
                              {format(new Date(data?.date_start), "dd-MM-yyyy")}
                            </TableCell>
                            <TableCell width={300} align="center">
                              {format(new Date(data?.date_end), "dd-MM-yyyy")}
                            </TableCell>
                            <TableCell width={300} align="center">
                              <Grid
                                container
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                <Grid item xs={6}>
                                  <Button
                                    disabled={data.broadcast_message_id}
                                    size="medium"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    startIcon={<SendIcon />}
                                    sx={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      handleOpenBroadCastModal(data.id);
                                    }}
                                    data-testid={`broadcast-btn-${data.title}`}
                                  >
                                    Broadcast
                                  </Button>
                                </Grid>
                                <Grid item xs={6}>
                                  <EditButton
                                    label="Edit"
                                    fullWidth
                                    size="medium"
                                    data-testid={`edit-btn-${data.title}`}
                                    color="primary"
                                    variant="outlined"
                                    style={{
                                      marginRight: "-11px",
                                      // color: "white",
                                      textTransform: "none",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    </RecordContextProvider>
                  ))}
                </TableBody>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Broadcast
        open={openBroadCastModal}
        props={bannerPromoData}
        handleClose={handleCloseBroadCastModal}
      />
      <StyledDialog
        open={!!matchEdit}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && <EditPage id={(matchEdit as any).params?.id} />}
      </StyledDialog>
    </Grid>
  );
};

const grid = 8;

const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  overflow: "hidden",
  minHeight: "500px",
});

export default ListPage;
