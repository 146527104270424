import React, { useEffect } from "react";
import {
  Show,
  FunctionField,
  usePermissions,
  Datagrid,
  Pagination,
  ReferenceManyField,
  DateField,
  RaRecord,
} from "react-admin";
import ReactJson from "react-json-view";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const HistoriesPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const HistoryExpand = ({ record }: any) => {
  const expandObj = {
    allocation_date: format(
      new Date(record.allocation_date),
      "eeee, dd-MM-yyyy"
    ),
    termination_date: format(
      new Date(record.termination_date),
      "eeee, dd-MM-yyyy"
    ),
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={11}>
        <ReactJson
          src={expandObj || []}
          shouldCollapse={false}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          name={false}
        />
      </Grid>
    </Grid>
  );
};

export const Detail = ({ onCancel, match, ...props }: any) => {
  const { isLoading, permissions } = usePermissions();

  const isAdmin =
    !isLoading &&
    permissions.find((el: string) => el === "Admin" || el === "Super Admin");

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const navigate = useNavigate();

  return (
    <Show resource="virtualMsisdn" title=" &rsaquo; Detail" {...props}>
      <Box pt={5} width="50vW" mt={{ xs: 2, sm: 1 }} height="100vh">
        <Typography
          variant="h6"
          style={{ marginBottom: "17px", marginLeft: "7px" }}
        >
          Virtual MSISDN Histories
        </Typography>
        <ReferenceManyField
          reference="msisdnHistories"
          resource="msisdnHistories"
          target="virtual_msisdn_id"
          label=""
          pagination={<HistoriesPagination />}
          perPage={25}
          sort={{ field: "created_at", order: "DESC" }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              ".column-created_at": { width: "20% !important" },
              ".column-data": { width: "50% !important" },
            }}
            expand={HistoryExpand}
          >
            <DateField
              source="created_at"
              label="Date"
              showTime
              locales="en-HK"
            />
            <FunctionField
              source="virtual_msisdn_id"
              render={(record: RaRecord) =>
                record.virtual_msisdn_id_data.msisdn
              }
            />
            <FunctionField
              source="user_id"
              render={(record: RaRecord) => record.user_id_data.name}
            />
            <FunctionField
              source="termination_reason"
              render={(record: RaRecord) => record.termination_reason}
            />
            <FunctionField
              source="remark"
              render={(record: RaRecord) =>
                record.remark ? record.remark : "-"
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </Box>
    </Show>
  );
};
