import { Box, Grid, IconButton, Stack } from "@mui/material";
import { AutocompleteArrayInput, Create, DateTimeInput, NumberInput, SimpleForm, required, useGetIdentity, useNotify, useRedirect, useRefresh } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { useState } from "react";
import { dataProvider } from "../../../provider";
import moment from "moment";

interface CreatePointRulesProps {
    onClose : () => void;
}

export const CreatePointRules: React.FC<CreatePointRulesProps> = (props) => {
    const { onClose, ...restProps } = props;
    const { isLoading: isIdLoading, identity: user} = useGetIdentity();
    
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    
    const [loading, setLoading] = useState<boolean>(false);

    const isLoading = loading || isIdLoading;

    const onSubmitHandler = async (values: any) => {
        setLoading(true)
        try {
            const payloads = {
                ...values,
                author_id: user?.id,
                start: moment(values.start).toISOString(),
                end: moment(values.end).toISOString(),
            }
            const resp = await dataProvider.create('pointRules', {data: payloads});
            if(resp.data){
                notify("ra.notification.created", {
                    type: "info",
                    messageArgs: { smart_count: 1 },
                    undoable: true,
                });
                redirect(`/internal/point-rules`);
                refresh();
                Swal.fire("Success!", "", "success");
            }
        } catch (error) {
            Swal.fire(`Request failed: ${error}`, "", "error");
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Create resource="pointRules" title=" &rsaquo; Create" {...restProps}>
                <Box pt={5} width={{ xs: "100vW", sm: 600 }} mt={{ xs: 2, sm: 1 }}>
                    <Stack
                        direction="row"
                        p={0}
                        sx={{ position: "absolute", right: 10, zIndex: 100 }}
                    >
                        <IconButton color="error" onClick={onClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <SimpleForm pt={1} onSubmit={onSubmitHandler}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                            <Grid item xs={12} sm={6}>
                                <AutocompleteArrayInput 
                                    source="affected_countries"
                                    optionValue="id"
                                    optionText="name"
                                    fullWidth
                                    disabled={isLoading}
                                    validate={required()}
                                    choices={[
                                        { id: "GLOBAL", name: "GLOBAL" },
                                        { id: "HK", name: "Hongkong (HK)" },
                                        { id: "ID", name: "Indonesia (ID)" },
                                        { id: "TW", name: "Taiwan (TW)" },
                                        { id: "MY", name: "Malaysia (MY)" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutocompleteArrayInput 
                                    source="affected_service"
                                    optionValue="id"
                                    optionText="name"
                                    fullWidth
                                    readOnly={isLoading}
                                    validate={required()}
                                    choices={[
                                        { id: "G2", name: "G2" },
                                        { id: "M21", name: "M21" },
                                        { id: "GHK", name: "GHK" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateTimeInput 
                                    source="start"
                                    label="Valid From" 
                                    disabled={isLoading}
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateTimeInput 
                                    source="end"
                                    label="Valid To" 
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.point.min"
                                    data-testid="limits.point.min"
                                    label="Min Limit Point"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.point.max"
                                    data-testid="limits.point.max"
                                    label="Max Limit Point"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.coin.min"
                                    data-testid="limits.coin.min"
                                    label="Min Limit Coin"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.coin.max"
                                    data-testid="limits.coin.max"
                                    label="Max Limit Coin"
                                    validate={required()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    fullWidth
                                    source="limits.usage_limit"
                                    data-testid="limits.usage_limit"
                                    label="Usage Limit"
                                    validate={required()}
                                />
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Box>
            </Create>
        </>
    )
}
