import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export interface IproductList {
  productListFilter: {
    id: string;
    label: string;
  };
}

const initialState: IproductList = {
  productListFilter: {
    id: "",
    label: "",
  },
};

type productListFilter = {
  id: string;
  label: string;
};

export const productsListFilterSlice = createSlice({
  name: "thisProductsListFilter",
  initialState,
  reducers: {
    setProductsListFilter: (
      state,
      action: PayloadAction<productListFilter>
    ) => {
      state.productListFilter = {
        ...state.productListFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setProductsListFilter } = productsListFilterSlice.actions;

export const getProductsListFilter = (state: RootState) =>
  state.thisProductsListFilter.productListFilter;

export default productsListFilterSlice.reducer;
