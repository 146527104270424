// @ts-nocheck
import * as React from "react";
import {
  CreateButton,
  FilterButton,
  List,
  RaRecord,
  SelectInput,
  TopToolbar,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  ReferenceField,
} from "react-admin";
import { Grid, Drawer, Box, Backdrop } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "../../DateRange";
import EditPage from "./EditPage";
import ReactJson from "react-json-view";
import CreatePage from "./CreatePage";
import ProductCatalogTable from "./ProductCatalogTable";
import MuiButton from "@mui/material/Button";
import DifferenceIcon from "@mui/icons-material/Difference";

const ListActions = () => {
  return (
    <TopToolbar>
      <CreateButton label="add config" />
    </TopToolbar>
  );
};

const ListPage = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [recordId, setRecordId] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleOpenDrawer = (record: any) => {
    setRecordId(record);
    setDrawerOpen(true);
  };

  const closeDrawer = () => setDrawerOpen(false);

  const handleClose = React.useCallback(() => {
    // console.log({ cancel: navigate });
    navigate("/constraintsLimit");
  }, [navigate]);

  const matchEdit = matchPath("/constraintsLimit/:id", location.pathname);
  const matchCreate = matchPath("/constraintsLimit/create", location.pathname);

  return (
    <>
      <List actions={<ListActions />} title="Constraint Limits">
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <DateField source="created_at" label="Date" showTime />
          <TextField source="label" label="Label" />
          <TextField source="type" label="Type" />
          <FunctionField
            label="Values"
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <label>
                  <ReactJson
                    src={record.values || {}}
                    collapsed={true}
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </label>
              </div>
            )}
          />
          <TextField source="author_data.email" />
          <FunctionField
            label=" "
            onClick={(e: any) => e.stopPropagation()}
            render={(record: RaRecord) => (
              <div>
                <MuiButton
                  variant="contained"
                  startIcon={<DifferenceIcon />}
                  onClick={() => handleOpenDrawer(record?.id)}
                >
                  Applies On
                </MuiButton>
              </div>
            )}
          />
        </Datagrid>
      </List>
      <Drawer
        open={
          !!matchCreate || (!!matchEdit && matchEdit?.params?.id !== "create")
        }
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== "create" && (
          // @ts-ignore
          <EditPage id={(matchEdit as any).params.id} />
        )}

        {!!matchCreate && (
          // @ts-ignore
          <CreatePage />
        )}
      </Drawer>
      {/* DRAWER APPLIES ON */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={drawerOpen}
      >
        <Drawer
          open={drawerOpen}
          anchor="right"
          onClose={closeDrawer}
          variant="persistent"
        >
          <Box
            id="muiDrawer"
            display="block"
            ml={2}
            mr={2}
            justifyContent="center"
            alignItems="center"
            width="50vW"
          >
            <Grid container spacing={5} style={{ marginTop: "17px" }}>
              <Grid item xs={12}>
                <ProductCatalogTable
                  recordId={recordId}
                  onClose={closeDrawer}
                  drawerOpen={drawerOpen}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </Backdrop>
    </>
  );
};

export default ListPage;
