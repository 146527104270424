import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import gqltag, { gql } from "graphql-tag";
import {
    GetOneParams,
    GetOneResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";

export class WalletLedgersProviderClass extends ProviderBase<any> {
    client: MobileServiceClient;

    constructor(client: MobileServiceClient) {
        super();
        this.client = client;
    }

    async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
        // console.log({ method: "getOne", resource, params }, 99999999);

        const queryResult = await this.client.gql.query({
            query: gqltag`
            query getWalletLedgersById($id: UUID){
                getWalletLedgersById(id: $id) {
                    id
                    unit
                    balance
                    wallet_id_data {
                        user_id_data {
                            id
                            name
                            email
                        }
                    }
                    purchase_log_id_data {
                        id
                        order_id
                        status
                        selling_price
                        target_detail
                        product_snapshot
                        currency_snapshot
                        remark
                        product_catalog_id_data {
                            label
                        }
                    }
                    sms_cdr_id_data {
                        id
                        created_at
                        direction
                        status
                        hkd_cost
                        counterparty_msisdn
                        virtual_msisdn_id_data {
                            msisdn
                            user_id
                            user_id_data {
                                phone
                            }
                        }
                        current_user_id_data {
                            phone
                        }
                    }
                    voice_cdr_id_data {
                        id
                        created_at
                        direction
                        status
                        begin
                        end
                        duration
                        hkd_cost
                        counterparty_msisdn
                        subscriber_id_data {
                            phone
                            name
                            virtual_msisdn_user_id_list {
                                data {
                                    msisdn
                                }
                            }
                        }
                    }
                    wallet_reload_log_id_data {
                        id
                        status
                        channel
                        amount
                        rate_snapshot
                        histories
                        reload_id
                        user_id_data {
                            id
                            name
                            email
                            phone
                        }
                    }
                }
            }
        `,
            variables: {
                id: params.id,
            },
        });

        const rawData = queryResult?.getWalletLedgersById;
        let mappedData = rawData.sms_cdr_id_data ?
        ({
            ...queryResult?.getWalletLedgersById,
            a_number_sms:
                rawData.sms_cdr_id_data.direction === "MO"
                ? rawData.sms_cdr_id_data.current_user_id_data.phone
                : rawData.sms_cdr_id_data.counterparty_msisdn,
            b_number_sms:
                rawData.sms_cdr_id_data.direction === "MO"
                ? rawData.sms_cdr_id_data.counterparty_msisdn
                : rawData.sms_cdr_id_data.virtual_msisdn_id_data.msisdn,
        }) : (
            {
                ...queryResult?.getWalletLedgersById,
            }
        );
        
        if (rawData.voice_cdr_id_data){
            const counterpartyName = await this.fetchCounterPartyData([rawData]);
            let vn =
            rawData.voice_cdr_id_data.subscriber_id_data.virtual_msisdn_user_id_list.data[0]?.msisdn ||
            "";
            if (vn === "") {
                vn = await this.fetchMissingVn(
                    rawData.voice_cdr_id_data.subscriber_id_data.phone,
                    rawData.voice_cdr_id_data.end
                );
            }
            mappedData = {
                ...mappedData,
                a_number_voice:
                    rawData.voice_cdr_id_data.direction === "MO"
                    ? vn
                    : rawData.voice_cdr_id_data.counterparty_msisdn,
                b_number_voice:
                    rawData.voice_cdr_id_data.direction === "MO"
                    ? rawData.voice_cdr_id_data.counterparty_msisdn
                    : rawData.voice_cdr_id_data.subscriber_id_data.virtual_msisdn_user_id_list.data[0].msisdn || "-",
                a_name:
                    rawData.voice_cdr_id_data.direction === "MO"
                    ? rawData.voice_cdr_id_data.subscriber_id_data.name
                    : counterpartyName[rawData.voice_cdr_id_data.counterparty_msisdn],
                b_name:
                    rawData.voice_cdr_id_data.direction === "MO"
                    ? counterpartyName[rawData.voice_cdr_id_data.counterparty_msisdn]
                    : rawData.voice_cdr_id_data.subscriber_id_data.name
            }
        }

        // console.log(mappedData,90990);
        return { data: mappedData };
    }

    async fetchCounterPartyData(cdrData: object[]) {
        const listOfCounterpartyMSISDN = new Set();
        cdrData.forEach((x: any) =>
        listOfCounterpartyMSISDN.add(x.counterparty_msisdn)
        );
        let counterpartyName: { [key: string]: string } = {};
        if (listOfCounterpartyMSISDN) {
            const whereParty: Record<string, any> = {};
            whereParty._or = [
            {
                phone: {
                _in: Array.from(listOfCounterpartyMSISDN),
                },
            },
            {
                virtual_msisdn_user_id_list: {
                    msisdn: {
                        _in: Array.from(listOfCounterpartyMSISDN),
                    },
                },
            },
            ];
            const partyResults = await this.client.gql.query({
                query: gql`
                query partyList($where: UsersWhereInput) {
                    allUsersList(where: $where) {
                        data {
                            name
                            phone
                            virtual_msisdn_user_id_list {
                                data {
                                    msisdn
                                }
                            }
                        }
                    }
                }
            `,
                variables: {
                    where: whereParty,
                },
            });
        partyResults?.allUsersList?.data.forEach(
            (element: {
                phone: string;
                name: string;
                virtual_msisdn_user_id_list: { data: { msisdn: string }[] };
            }) => {
                counterpartyName[element?.phone] = element.name;
                counterpartyName[
                    element?.virtual_msisdn_user_id_list?.data[0]?.msisdn
                ] = element.name;
            }
            );
        }
        //
        return counterpartyName || [];
    }
    
    async fetchMissingVn(userPhone: string, endCallDate: string) {
        let missingVn = "";
        const where: Record<string, any> = {};
        where._and = [
            {
                created_at: {
                    _gt: moment(endCallDate).toISOString(),
                },
            },
            {
                register_msisdn: {
                    _eq: userPhone,
                },
            },
            {
                mode: {
                    _eq: "DEPROVISION",
                },
            },
        ];
        const args = buildArgs({
          // @ts-ignore
            order: "ASC",
            sort: "created_at",
        });
        const queryResult = await this.client.gql.query({
            query: gql`
            query missingVns($where: ProvisionResultsWhereInput) {
                allProvisionResultsList(where: $where, ${args}) {
                    data {
                    tsel_msisdn
                    }
                }
            }
            `,
            variables: {
                where,
            },
        });
        if (queryResult?.allProvisionResultsList?.data.length) {
            missingVn = queryResult?.allProvisionResultsList?.data[0].tsel_msisdn;
        }
        return missingVn;
    }
}
