import { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "rsuite";
import { useDispatch } from "react-redux";
import { setPurchaseLogFilter } from "src/slices/purchaseLogPage/filterPurchaseLogSlice";
import { setSmsLogFilter } from "src/slices/smsLogPage/filterSmsLogSlice";
import { setVoiceLogFilter } from "src/slices/voiceLogPage/filterVoiceLogSlice";
import { setReloadLogFilter } from "src/slices/reloadLogPage/filterReloadLogSlice";
import { setSubscribersFilter } from "src/slices/subscribersPage/filterSubscribersSlice";
import { setBannerPromotionsFilter } from "src/slices/bannerPromotionsPage/bannerPromotionsSlice";
import { setBroadcastFilter } from "src/slices/broadCastPage/broadcastSlice";
import { setSaleProgramFilter } from "src/slices/saleProgramsPage/filterSaleProgramSlice";
import { setReferralBenefitFilter } from "src/slices/referralBenefitsPage/filterReferralBenefitSlice";
import { setDeviceLogsFilter } from "src/slices/deviceLogsPage/filterDeviceLogsSlice";
import { setMvnoFilter } from "src/slices/mvnoPage/filterMvnoSlice";
import { useLocation } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";

const useStyles = makeStyles(() => ({
  date: {
    marginBottom: "12px",
  },
}));

export const DateRange = (props: any) => {
  const styleClass = useStyles();
  const { filterValues } = useListContext();
  const dispatch = useDispatch();
  const location = useLocation();

  const [dateValue, setDateValue] = useState({
    dateEnd:
      typeof filterValues?.dateLte === "string"
        ? new Date(filterValues.dateLte)
        : filterValues.dateLte,
    dateBegin:
      typeof filterValues?.dateGte === "string"
        ? new Date(filterValues.dateGte)
        : filterValues.dateGte,
  });

  function purchaseLogPageDateRange() {
    dispatch(
      //@ts-ignore
      setPurchaseLogFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function mvnoPageDateRange() {
    dispatch(
      //@ts-ignore
      setMvnoFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function smsLogPageDateRange() {
    dispatch(
      //@ts-ignore
      setSmsLogFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function voiceLogpageDateRange() {
    dispatch(
      //@ts-ignore
      setVoiceLogFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function reloadLogPageDateRange() {
    dispatch(
      //@ts-ignore
      setReloadLogFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function subscribersPageDateRange() {
    dispatch(
      //@ts-ignore
      setSubscribersFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  // function bannerPromotionsPageDateRange() {
  //   dispatch(
  //     //@ts-ignore
  //     setBannerPromotionsFilter({
  //       dateGte: dateValue.dateBegin,
  //       dateLte: dateValue.dateEnd,
  //     })
  //   );
  // }

  function broadcastPageDateRange() {
    dispatch(
      //@ts-ignore
      setBroadcastFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function saleProgramPageDateRange() {
    dispatch(
      //@ts-ignore
      setSaleProgramFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function referralBenefitPageDateRange() {
    dispatch(
      //@ts-ignore
      setReferralBenefitFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  function deviceLogsPageDateRange() {
    dispatch(
      //@ts-ignore
      setDeviceLogsFilter({
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      })
    );
  }

  useEffect(() => {
    switch (location.pathname) {
      case "/usages/purchase":
        purchaseLogPageDateRange();
        break;
      case "/usages/mvno":
        mvnoPageDateRange();
        break;
      case "/usages/sms":
        smsLogPageDateRange();
        break;
      case "/usages/voice":
        voiceLogpageDateRange();
        break;
      case "/usages/reload":
        reloadLogPageDateRange();
        break;
      case "/subscribers":
        subscribersPageDateRange();
        break;
      // case "/promotionsBanner":
      //   bannerPromotionsPageDateRange();
      //   break;
      case "/promotions/broadcast":
        broadcastPageDateRange();
        break;
      case "/promotions/flashsale":
        saleProgramPageDateRange();
        break;
      case "/promotions/referral_benefits":
        referralBenefitPageDateRange();
        break;
      case "/internal/device-logs":
        deviceLogsPageDateRange();
        break;
      default:
        console.log("default");
        break;
    }
  }, [location.pathname, dateValue]);

  console.log(371294124, "dateValue", dateValue);

  return (
    <DateRangePicker
      value={[dateValue.dateBegin, dateValue.dateEnd]}
      //if the value clean, the date will set to null
      onClean={() => {
        setDateValue({
          dateBegin: null,
          dateEnd: null,
        });
      }}
      onChange={(value) => {
        setDateValue({
          dateBegin: value ? new Date(value[0]) : null,
          dateEnd: value ? new Date(value[1]) : null,
        });
      }}
      className={styleClass.date}
      size="lg"
    />
  );
};
