// @ts-ignore
// @ts-nocheck

import React from "react";
import {
  Datagrid,
  TextField,
  ShowController,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  DateField,
  ReferenceManyField,
  ReferenceField,
  Pagination,
  BooleanField,
  useRecordContext,
  FunctionField,
  RaRecord,
  useCreate,
  Confirm,
  Button as RAButton,
} from "react-admin";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  TextField as TF,
  MenuItem,
  Paper,
  Box,
} from "@mui/material";
import moment from "moment";
import ReactJson from "react-json-view";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import DriveFileRenameOutline from "@mui/icons-material/DriveFileRenameOutline";
import FormKycDialog from "./FormKycDialog";
import FormKycDialogPassport from "./FormKycDialogPassport";
// @ts-ignore
import createHash from "crypto-browserify";
import axios from "axios";
import { randomBytes, secretbox } from "tweetnacl";
import mbaas from "../../provider/Mbaas";
import FilerobotImageEditor from "react-filerobot-image-editor";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import EditCoinModal from "./EditCoinModal";
import EditVnExpiryModal from "./EditVnExpiryModal";
import Tooltip from "@mui/material/Tooltip";
import { useRetrievePermission } from "../../hooks/useRetrievePermission";
import { endOfToday, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import DetailPage from "./DetailPage";
import AdjustPointModal from "./AdjustPointModal";

const ExpandShow = (props: any) => {
  // activities
  const [cdr, setCdr] = React.useState("sms");
  const [msisdn, setMsisdn] = React.useState("");
  // common
  const fromData = moment().subtract(30, "days").format("YYYY-MM-DD");
  const toData = moment().format("YYYY-MM-DD");
  const [datex, setDate] = React.useState(fromData);
  const [datey, setDatey] = React.useState(toData);
  const [openForm, setOpenForm] = React.useState(false);
  const [openFormPass, setOpenFormPass] = React.useState(false);
  const [imgblob, setImgblob] = React.useState("");
  const [imgzoom, setImgzoom] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [userData, setUserData] = React.useState<{
    secret: string;
    id: string;
  }>({ secret: "", id: "" });
  const [decryptedFileName, setDecryptedFileName] = React.useState<string>("");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [idValue, setIdValue] = React.useState("");
  const [data, setData] = React.useState({});
  const [currentRecord, setCurrentRecord] = React.useState({} as RaRecord);
  const [openEditCoinModal, setOpenEditCoinModal] = React.useState(false);
  const [openAdjustPointModal, setOpenAdjustPointModal] = React.useState(false);
  const [openEditVnExpiryModal, setOpenEditVnExpiryModal] =
    React.useState(false);

  const [isSuperAdmin, isAdmin, isStaff] = useRetrievePermission();

  const [checkedItems, setCheckedItems] = React.useState({
    debit: true,
    credit: true,
  }); 
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  const handleRowClick = (id, valueDrawer) => {
    setIdValue(id)
    setDrawerOpen(valueDrawer);
  };
  
  const CheckboxList = ({checkedItems, setCheckedItems}) => {

    const options = [
      { value: 'debit', label: 'DEBIT' },
      { value: 'credit', label: 'CREDIT' }
    ];
  
    const handleCheckboxChange = (event) => {
      const {name, checked} = event.target;
  
      if(checked===true) {
        setCheckedItems({ ...checkedItems, [name]: true });
      } else {
        setCheckedItems({ ...checkedItems, [name]: false });
      }
      
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center'}}> 
        {options.map(option => (
          <div key={option.value} style={{ marginInline: '10px'}}>
            <input
              type="checkbox"
              id={option.value}
              name={option.value}
              value={option.value}
              checked={checkedItems[option.value]}
              onChange={handleCheckboxChange}
              style={{ marginRight: '5px' }}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    );
  };

  const JsonField = (props: any) => {
    const record = useRecordContext(props);

    return (
      <ReactJson
        src={record[props.target] !== null ? record[props.target] : {}}
        displayDataTypes={false}
        name={null}
        collapsed={0}
        enableClipboard={false}
      />
    );
  };

  const ReviewKYC = (props: any) => {
    // const record = useRecordContext(props);
    const record = props.data;
    return (
      <Button
        variant="contained"
        size="small"
        startIcon={<DocumentScannerIcon />}
        disabled={record.verified_by !== null}
        onClick={() => {
          setData(record);
          setOpenForm(true);
        }}
      >
        Review Number
      </Button>
    );
  };

  const ReviewKYCPassport = (props: any) => {
    // const record = useRecordContext(props);
    const record = props.data;
    return (
      <Button
        variant="contained"
        size="small"
        startIcon={<DocumentScannerIcon />}
        // disabled={record.verified_by !== null}
        disabled={false}
        onClick={() => {
          setData(record);
          setOpenFormPass(true);
        }}
      >
        Review Account
      </Button>
    );
  };

  const decryptFile = async (user: any, fileid: string) => {
    setUserData(user);
    setDecryptedFileName(fileid);
    setLoading(true);
    setImgzoom(true);
    try {
      // console.log("====================================");
      // console.log(data);
      // console.log("====================================");
      const secretUser = user.secret;
      const idUser = user.id;

      const secret = secretUser + idUser;

      const key = createHash.createHash("sha256").update(secret).digest();
      const decUrl = await mbaas.client.storage.downloadUrl({
        params: {
          bucket: "registrations",
          expire: "20",
        },
        fileId: fileid,
      });

      console.log(decUrl);

      // console.log(decUrl);
      const { data: mergedtext } = await axios.request({
        method: "get",
        url: decUrl.data.url,
        responseType: "arraybuffer",
      });
      // console.log(mergedtext);
      const nonce = mergedtext.slice(0, secretbox.nonceLength);
      const ciphertext = mergedtext.slice(
        secretbox.nonceLength,
        mergedtext.length
      );
      // console.log("====================================");
      // console.log("chiperText");
      // console.log(ciphertext);
      // const encodedString = Buffer.from(ciphertext).toString("base64");
      // console.log(encodedString);

      // console.log("nonce");
      // console.log(nonce);

      // console.log("key");
      // console.log(key);
      // const encodedStrings = Buffer.from(key).toString("base64");
      // console.log(encodedStrings);

      // console.log("====================================");
      var chpr = new Uint8Array(ciphertext);
      var iv = new Uint8Array(nonce);

      const plaintext = secretbox.open(chpr, iv, key);
      // const plaintext = secretbox.open(ciphertext, nonce, key);

      if (plaintext) {
        // console.log("plaintext");
        // console.log(plaintext);
        // const base64Plain = Buffer.from(plaintext).toString("base64");
        // console.log(base64Plain);

        // const content = new Uint8Array([
        //   137, 80, 78, 71, 13, 10, 26, 10, 0, 0, 0, 13, 73, 72, 68, 82, 0, 0, 0,
        //   5, 0, 0, 0, 5, 8, 6, 0, 0, 0, 141, 111, 38, 229, 0, 0, 0, 28, 73, 68,
        //   65, 84, 8, 215, 99, 248, 255, 255, 63, 195, 127, 6, 32, 5, 195, 32,
        //   18, 132, 208, 49, 241, 130, 88, 205, 4, 0, 14, 245, 53, 203, 209, 142,
        //   14, 31, 0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130,
        // ]);
        // const example = await URL.createObjectURL(
        //   new Blob([content.buffer], { type: "image/jpeg" })
        // );

        const blobUrl = await (window.URL ? URL : webkitURL).createObjectURL(
          new Blob([plaintext], { type: "image/jpeg" })
        );

        // console.log(blobUrl);

        // as blob
        setImgblob(blobUrl);

        // as data
        // setImgblob("data:image/jpeg;base64," + base64Plain);
      } else {
        throw new Error("decryption failed");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleOpenEditCoinModal = () => {
    setOpenEditCoinModal(true);
  };
  const handleCloseEditCoinModal = () => setOpenEditCoinModal(false);


  const handleOpenAdjustPointModal = () => {
    setOpenAdjustPointModal(true);
  };
  const handleCloseAdjustPointModal = () => setOpenAdjustPointModal(false);

  const handleOpenEditVnExpiryModal = () => {
    setOpenEditVnExpiryModal(true);
  };
  const handleCloseEditVnExpiryModal = () => setOpenEditVnExpiryModal(false);

  const [open, setOpen] = React.useState(false);
  const [authorData, setAuthorData] = React.useState({});

  const navigate = useNavigate();

  const ExportReferredButton = (props) => {
    const getAuthorData = async () => {
      const res = await mbaas.client.user.get();
      if (res.data) {
        return res.data;
      }
      if (res.error) {
        // @ts-ignore
        notify(res.error);
      }
    };
    getAuthorData().then((data) => {
      setAuthorData(data.id);
    });
    //
    const type_name = "referred-by-promotor";
    const gen_file_name = `${type_name}_${format(
      new Date(endOfToday()),
      "yyyy-MM-dd"
    )}`;
    //
    console.log("filterValues", props);
    //
    const [exportData, { data, error }] = useCreate("reports", {
      data: {
        author_id: authorData,
        type: type_name,
        status: "queued",
        data: {
          filter: {
            dateGte: moment(new Date(datex)).startOf("day").toISOString(),
            dateLte: moment(new Date(datey)).endOf("day").toISOString(),
            promotorId: props?.subs_id,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, "haiii");
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports?filter={"type":"referred-by-promotor"}`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress
            please visit report page below
          </Typography>
        </Paper>
      );
    };

    return (
      <RAButton
        variant="contained"
        label="Export"
        size="medium"
        onClick={() => handleOnClick()}
      >
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </RAButton>
    );
  };

  return (
    <>
      <ShowController {...props}>
        {(controllerProps) => (
          <Show {...props} title="Subscriber Detail" {...controllerProps}>
            <FunctionField
              render={(record: RaRecord) => setCurrentRecord(record)}
            />
            <SimpleShowLayout>
              <FormKycDialog
                open={openForm}
                setOpen={setOpenForm}
                data={data}
              />
              <FormKycDialogPassport
                open={openFormPass}
                setOpen={setOpenFormPass}
                data={data}
              />
              <Dialog
                open={imgzoom}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}
              >
                <DialogTitle id="form-dialog-title">File Detail</DialogTitle>
                <DialogContent>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {loading ? (
                      "LOADING . . ."
                    ) : (
                      <FilerobotImageEditor
                        // @ts-ignore
                        show={imgzoom}
                        source={imgblob}
                        onClose={() => {}}
                        onBeforeSave={(_savedImageDataBefore) => false}
                        onSave={async (editedImageObject, _designState) => {
                          const secret = userData.secret + userData.id;
                          const key = createHash
                            .createHash("sha256")
                            .update(secret)
                            .digest();
                          const nonce = randomBytes(secretbox.nonceLength);
                          const binaryString = window.atob(
                            editedImageObject.imageBase64?.split(
                              ","
                            )[1] as string
                          );
                          const len = binaryString.length;
                          const bytesImage = new Uint8Array(len);
                          for (let i = 0; i < len; i++) {
                            bytesImage[i] = binaryString.charCodeAt(i);
                          }
                          const ciphertext = secretbox(bytesImage, nonce, key);
                          const mergedtext = new Uint8Array(
                            nonce.length + ciphertext.length
                          );
                          mergedtext.set(nonce);
                          mergedtext.set(ciphertext, nonce.length);
                          const uploadData = await mbaas.client.storage.upload({
                            params: {
                              filename: decryptedFileName,
                              bucket: "registrations",
                              expire: "20",
                            },
                            file: mergedtext,
                          });
                        }}
                      />
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Grid item gap={2} marginBottom={2}>
                    <Button
                      style={{ marginRight: "20px" }}
                      variant="outlined"
                      size="small"
                      type="submit"
                      onClick={() => setImgzoom(false)}
                    >
                      Close
                    </Button>
                  </Grid>
                </DialogActions>
              </Dialog>

              <TabbedShowLayout>
                <Tab label="profile" path="">
                  <TextField label="Name" source="name" />
                  <TextField label="Email" source="email" />
                  <TextField label="Phone" source="phone" />
                  <FunctionField
                    source="deactivated_at"
                    label="Blocked"
                    render={(
                      record: { deactivated_at: any },
                      source: string | undefined
                    ) => (
                      <BooleanField
                        record={{
                          ...record,
                          deactivated_at: !!record.deactivated_at,
                        }}
                        source={source}
                      />
                    )}
                  />
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Current Coin Balance
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "-12px",
                      }}
                    >
                      <TextField
                        label="Current Coin Balance"
                        source="current_balance"
                      />
                      {(isSuperAdmin || isAdmin) && (
                        <Tooltip title="Edit Subscriber Coin">
                          <Button
                            style={{ marginLeft: "61px" }}
                            onClick={() => handleOpenEditCoinModal()}
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                      )}
                      <EditCoinModal
                        open={openEditCoinModal}
                        currentRecord={currentRecord}
                        handleClose={handleCloseEditCoinModal}
                      />
                    </div>
                  </div>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Virtual MSISDN
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <TextField label="Virtual MSISDN" source="vn_msisdn" />
                    </div>
                  </div>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Virtual Number Expiry Date
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "-12px",
                      }}
                    >
                      <TextField
                        label="Virtual Number Expiry Date"
                        source="vn_expiry"
                      />
                      {(isSuperAdmin || isAdmin) && (
                        <Tooltip title="Edit Virtual Number Expiry Date">
                          <Button
                            style={{ marginLeft: "61px" }}
                            onClick={() => handleOpenEditVnExpiryModal()}
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                      )}

                      <EditVnExpiryModal
                        open={openEditVnExpiryModal}
                        currentRecord={currentRecord}
                        handleClose={handleCloseEditVnExpiryModal}
                      />
                    </div>
                  </div>
                  <TextField label="Referral code" source="referral_code" />
                  <JsonField target="referral_detail" label="Referral detail" />
                  <TextField
                    label="Referrer"
                    source="referral_referrer_id_data.email"
                  />
                  <JsonField
                    target="referral_referrer_id_data"
                    label="Referrer detail"
                  />

                  {/* <ReviewKYC /> */}
                </Tab>

                <Tab label="Limit" path="limit">
                  <TextField source="max_coins" />
                  <TextField source="max_daily_purchase" />
                  <TextField source="max_monthly_purchase" />
                  <TextField source="preferred_language" />
                </Tab>
                <Tab label="point" path="point">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                    <CheckboxList checkedItems={checkedItems} setCheckedItems={setCheckedItems}/>
                    <Box flex={"auto"} display={"flex"} justifyContent={"right"} py={2}>
                    {(isSuperAdmin || isAdmin) && (
                      <Button 
                        size="small" 
                        variant="contained" 
                        onClick={handleOpenAdjustPointModal}
                        startIcon={<DriveFileRenameOutline />}
                      >
                        Adjust
                      </Button>
                    )}
                    <AdjustPointModal
                        open={openAdjustPointModal}
                        currentRecord={currentRecord}
                        handleClose={handleCloseAdjustPointModal}
                      />
                    </Box>
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    pagination={<Pagination />}
                    perPage={25}
                    filter={{
                      table: "wallet",
                      type: "POINT",
                      start: datex,
                      end: datey,
                    }}
                    
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid
                    rowClick={(id) => {
                      handleRowClick(id, true)
                    }}
                    bulkActionButtons={false}>
                      <TextField source="remark" />
                      <TextField source="unit" />
                      <TextField source="balance" />
                      { checkedItems.debit && <TextField source="debit" /> }
                      { checkedItems.credit && <TextField source="credit" /> }
                      <DateField source="created_at" showTime />
                      <DateField source="updated_at" showTime />
                      <ReferenceField
                        source="wallet_id_data.user_id_data.id"
                        reference="users"
                        label="User"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    </Datagrid>
                  </ReferenceManyField>
                  <Drawer
                    variant="persistent"
                    open={drawerOpen}
                    anchor="right"
                    onClose={handleDrawer}
                    sx={{ zIndex: 100 }}
                  >
                    <DetailPage walletId={idValue} onClose={handleDrawer}/>
                  </Drawer>
                </Tab>

                <Tab label="coin" path="coin">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    pagination={<Pagination />}
                    perPage={25}
                    filter={{
                      table: "wallet",
                      type: "HKD",
                      start: datex,
                      end: datey,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="remark" sortable={false} />
                      <TextField source="unit" />
                      <FunctionField
                        label="Balance Before"
                        render={(record: RaRecord) => {
                          if (record.debit !== 0) {
                            return (
                              <span>
                                {Number((record.balance + record.debit).toFixed(2))}
                              </span>
                            );
                          }
                          if (record.credit !== 0) {
                            return (
                              <span>
                                {Number((record.balance - record.credit).toFixed(2))}
                              </span>
                            );
                          }
                          return <span>{record.balance}</span>;
                        }}
                      />
                      <TextField source="balance" label={"Balance After"} />
                      <TextField source="debit" />
                      <TextField source="credit" />
                      <DateField source="created_at" showTime />
                      <DateField source="updated_at" showTime />
                      <FunctionField
                        label="Updated By"
                        render={(record: RaRecord) => {
                          if (record.metadata) {
                            return <span>{record.metadata.by.name}</span>;
                          }
                          return <span>-</span>;
                        }}
                      />
                      <FunctionField
                        label="Reason"
                        render={(record: RaRecord) => {
                          if (record.metadata) {
                            return (
                              <span>{record.metadata.changes.reason}</span>
                            );
                          }
                          return <span>-</span>;
                        }}
                      />
                      <ReferenceField
                        source="wallet_id_data.user_id_data.id"
                        reference="users"
                        label="User"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
                <Tab label="package" path="package">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    pagination={<Pagination />}
                    perPage={25}
                    filter={{
                      table: "package",
                      type: "package",
                      start: datex,
                      end: datey,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="remark" />

                      <FunctionField
                        label="Package Detail"
                        render={(record: any) => {
                          if (record.package_detail) {
                            return (
                              <ReactJson
                                src={record.package_detail || []}
                                collapsed={0}
                                enableClipboard={false}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                name={false}
                              />
                            );
                          } else {
                            return "-";
                          }
                        }}
                      />

                      <DateField source="expiry_date" showTime />

                      <DateField source="created_at" showTime />
                      <DateField source="updated_at" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
                <Tab label="Sms Quota" path="sms">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    perPage={25}
                    pagination={<Pagination />}
                    filter={{
                      table: "quota",
                      type: "SMS",
                      start: datex,
                      end: datey,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="remark" />
                      <TextField source="unit" />
                      <TextField source="balance" />
                      <TextField source="debit" />
                      <TextField source="credit" />
                      <DateField source="created_at" showTime />
                      <DateField source="updated_at" showTime />
                      <ReferenceField source="user_id" reference="users">
                        <TextField source="name" />
                      </ReferenceField>
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>

                <Tab label="Voice Quota" path="voice">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    perPage={25}
                    pagination={<Pagination />}
                    filter={{
                      table: "quota",
                      type: "MINUTES",
                      start: datex,
                      end: datey,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="remark" />
                      <TextField source="unit" />
                      <TextField source="balance" />
                      <TextField source="debit" />
                      <TextField source="credit" />
                      <DateField source="created_at" showTime />
                      <DateField source="updated_at" showTime />
                      {/* <ReferenceField source="user_id" reference="users">
                      <TextField source="name" />
                    </ReferenceField> */}
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
                <Tab label="activites" path="activities">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      data-testid="filter-date-start"
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                      data-testid="filter-date-end"
                    />
                    <TF
                      select
                      value={cdr}
                      label="Data"
                      onChange={(e) => {
                        setCdr(e.target.value);
                      }}
                      data-testid="filter-data-cdr"
                    >
                      <MenuItem value="sms">SMS</MenuItem>
                      <MenuItem value="voice">VOICE</MenuItem>
                    </TF>
                    <TF
                      label="Number"
                      value={msisdn}
                      onChange={(e) => {
                        setMsisdn(e.target.value);
                      }}
                      data-testid="filter-number"
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    pagination={<Pagination />}
                    perPage={25}
                    filter={{
                      table: "activities",
                      type: "activities",
                      start: datex,
                      end: datey,
                      cdr,
                      msisdn,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <DateField
                        source="created_at"
                        label="Date"
                        showTime
                        locales="en-HK"
                        options={{
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        }}
                      />
                      <TextField source="direction" />
                      {cdr === "voice" ? (
                        <FunctionField
                          render={(record: any) => (
                            <>
                              <strong>{record.a_name}</strong>
                              <br />
                              <label>{record.a_number}</label>
                            </>
                          )}
                          source="a_number"
                          label="A Number"
                        />
                      ) : (
                        <TextField
                          source="virtual_msisdn_id_data.msisdn"
                          label="A Number"
                        />
                      )}
                      {cdr === "voice" ? (
                        <FunctionField
                          render={(record: any) => (
                            <>
                              <strong>{record.b_name}</strong>
                              <br />
                              <label>{record.b_number}</label>
                            </>
                          )}
                          source="b_number"
                          label="B Number"
                        />
                      ) : (
                        <TextField
                          source="counterparty_msisdn"
                          label="B Number"
                        />
                      )}
                      <TextField source="status" />
                      <TextField source="hkd_cost" label="Cost" />
                      <FunctionField
                        source="data"
                        render={(record: any) => {
                          return (
                            <ReactJson
                              src={record.data || []}
                              shouldCollapse={() => true}
                              enableClipboard={false}
                              displayDataTypes={false}
                              displayObjectSize={false}
                              name={false}
                            />
                          );
                        }}
                      />
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
                <Tab label="Device Log Info" path="device_info">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    perPage={25}
                    pagination={<Pagination />}
                    filter={{
                      table: "device_logs",
                      type: "minute",
                      start: datex,
                      end: datey,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <DateField source="created_at" showTime />
                      <TextField source="info" />
                      <TextField source="fingerprint" />
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
                <Tab label="KYC" path="kyc">
                  <Grid container gap={3}>
                    <TF
                      id="date"
                      label="From"
                      type="date"
                      value={datex}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TF
                      id="date"
                      label="To"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={datey}
                      onChange={(e) => {
                        setDatey(e.target.value);
                      }}
                    />
                  </Grid>
                  <ReferenceManyField
                    label=""
                    reference="subscribers"
                    target="id"
                    pagination={<Pagination />}
                    perPage={25}
                    filter={{
                      table: "kyc",
                      type: "kyc",
                      start: datex,
                      end: datey,
                    }}
                    sort={{ field: "created_at", order: "desc" }}
                  >
                    <Datagrid
                      bulkActionButtons={false}
                      rowStyle={(record: any) => ({
                        backgroundColor:
                          record.status === "REJECTED"
                            ? "#ffa5a5"
                            : record.status === "APPROVED"
                            ? "#c0ffa5"
                            : "",
                      })}
                    >
                      <TextField label="Type" source="type" />
                      <TextField label="Status" source="status" />
                      <TextField
                        label="Verified By"
                        source="verified_by_data.email"
                      />
                      <TextField label="Remark" source="remark" />

                      <DateField source="created_at" showTime />
                      <DateField source="updated_at" showTime />
                      <FunctionField
                        label="Document"
                        render={(record: any) => {
                          return (
                            <div>
                              {record.type === "ACCOUNT" ? (
                                <div>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                      decryptFile(
                                        record.user_id_data,
                                        record.passports_registration_id_list
                                          ?.data[0].document_id
                                      );
                                    }}
                                    disabled={
                                      record?.passports_registration_id_list
                                        ?.data[0]?.document_id === null ||
                                      record?.passports_registration_id_list
                                        ?.data[0]?.document_id === undefined
                                    }
                                  >
                                    Document
                                  </Button>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      decryptFile(
                                        record.user_id_data,
                                        record.passports_registration_id_list
                                          ?.data[0].selfie_id
                                      );
                                    }}
                                    disabled={
                                      record?.passports_registration_id_list
                                        ?.data[0]?.selfie_id === null ||
                                      record?.passports_registration_id_list
                                        ?.data[0]?.selfie_id === undefined
                                    }
                                  >
                                    Selfie
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                      decryptFile(
                                        record.user_id_data,
                                        record
                                          .identity_cards_registration_id_list
                                          ?.data[0].document_id
                                      );
                                    }}
                                    disabled={
                                      record
                                        ?.identity_cards_registration_id_list
                                        ?.data[0]?.document_id === null ||
                                      record
                                        ?.identity_cards_registration_id_list
                                        ?.data[0]?.document_id === undefined
                                    }
                                  >
                                    IDENTITY
                                  </Button>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      decryptFile(
                                        record.user_id_data,
                                        record
                                          .identity_cards_registration_id_list
                                          ?.data[0].signature_id
                                      );
                                    }}
                                    disabled={
                                      record
                                        ?.identity_cards_registration_id_list
                                        ?.data[0]?.signature_id === null ||
                                      record
                                        ?.identity_cards_registration_id_list
                                        ?.data[0]?.signature_id === undefined
                                    }
                                  >
                                    SIGNATURE
                                  </Button>
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                      <FunctionField
                        label="Action"
                        render={(record: any) => {
                          return record.type === "ACCOUNT" ? (
                            <ReviewKYCPassport data={record} />
                          ) : (
                            <ReviewKYC data={record} />
                          );
                        }}
                      />
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
                {currentRecord?.is_promotor && (
                  <Tab label="Referred by Promotor" path="referee">
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item gap={3}>
                        <TF
                          id="date"
                          label="From"
                          type="date"
                          value={datex}
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TF
                          id="date"
                          label="To"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={datey}
                          onChange={(e) => {
                            setDatey(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item gap={3}>
                        <ExportReferredButton subs_id={currentRecord.id} />
                      </Grid>
                    </Grid>
                    <ReferenceManyField
                      label=""
                      reference="subscribers"
                      target="id"
                      perPage={25}
                      pagination={<Pagination />}
                      filter={{
                        table: "referral",
                        start: datex,
                        end: datey,
                      }}
                      sort={{ field: "added_at", order: "desc" }}
                    >
                      <Datagrid bulkActionButtons={false}>
                        <TextField source="name" label="Name" />
                        <DateField source="added_at" showTime />
                      </Datagrid>
                    </ReferenceManyField>
                  </Tab>
                )}
              </TabbedShowLayout>
            </SimpleShowLayout>
          </Show>
        )}
      </ShowController>
    </>
  );
};

export default ExpandShow;
