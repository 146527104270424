// @ts-nocheck
import { SimpleForm, Edit } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import DOMPurify from "dompurify";

import { Toolbar, SaveButton} from "react-admin";

export const CustomToolbarNoDelete = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const transform = (data) => ({
  ...data,
  value: DOMPurify.sanitize(data.value),
  // question: DOMPurify.sanitize(data.question),
});

const EditTitle = () => {
  return <span>Terms & Condition &gt; Edit</span>;
};

export const EditPage = (props) => {
  const classes = useStyles();

  return (
    <Edit transform={transform} title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbarNoDelete />}>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RichTextInput
                  source="value"
                  className={classes.input}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditPage;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: "-20px ",
  },
  text: {
    color: "#0000008A",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));