import * as React from "react";
import {
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  NumberInput,
  RecordContextProvider,
  Confirm,
  useNotify,
  useUpdate,
  useRedirect,
  useGetOne,
  useRefresh,
} from "react-admin";
import { TextField as MuiTextField } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import mbaas from "../../../provider/Mbaas";
import Swal from "sweetalert2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const country = [
  { id: "ID", name: "ID" },
  { id: "HK", name: "HK" },
  { id: "TW", name: "TW" },
];

const useStyles = makeStyles({
  input: {
    marginBottom: "-20px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export interface UpdateDialogProps {
  openUpdateDialog: boolean;
  recordId: string;
  handleCloseUpdateDialog: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export interface UpdatedDataProps {
  country: string;
  hkd_price: number | null;
  msisdn: string;
}

export default function UpdateDialog(props: UpdateDialogProps) {
  const { recordId, handleCloseUpdateDialog, openUpdateDialog } = props;
  // --
  const classes = useStyles();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  // --
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [updatedData, setUpdatedData] = React.useState<UpdatedDataProps>({
    country: "",
    hkd_price: null,
    msisdn: "",
  });
  // --
  const handleConfirmDialogOpen = () => setOpenConfirmDialog(true);
  const handleConfirmDialogClose = () => setOpenConfirmDialog(false);
  // --
  const ToolbarEdit = () => {
    return <></>;
  };
  // --
  const { data } = useGetOne(
    "virtualMsisdn",
    { id: recordId },
    { onError: () => redirect("/virtualMsisdn") }
  );
  // --
  React.useEffect(() => {
    setUpdatedData({
      country: data?.country,
      hkd_price: data?.hkd_price,
      msisdn: data?.msisdn,
    });
  }, [data]);
  // --
  const [update, { isLoading: isLoadingUpdate, isSuccess: isUpdateSuccess }] =
    useUpdate("virtualMsisdn", {
      id: recordId,
      data: { ...updatedData },
      previousData: { id: recordId, ...updatedData },
    });
  //--
  const handleValidateOtp = async () => {
    const { data, error } = await mbaas.client.invokeApi("console-validate-totp", {
      token: otpCode,
    });
    // --
    if (error) {
      Swal.fire({
        icon: "error",
        title: `Request failed: ${error.error}`,
        target: "#muiDrawer",
      });
    }
    // --
    if (data) {
      Swal.fire({
        icon: "success",
        title: "Otp is valid",
        showConfirmButton: false,
        timer: 1400,
        target: "#muiDrawer",
      });
      setTimeout(() => {
        // -- perform update
        update();
      }, 1400);
    }
  };
  // --
  React.useEffect(() => {
    if (isUpdateSuccess) {
      handleConfirmDialogClose();
      refresh();
      handleCloseUpdateDialog();
      notify("Msisdn Successfully Updated", { type: "success" });
    }
  }, [isUpdateSuccess]);
  // --
  return (
    <RecordContextProvider value={data}>
      <div>
        <BootstrapDialog
          onClose={handleCloseUpdateDialog}
          aria-labelledby="customized-dialog-title"
          open={openUpdateDialog}
          fullWidth
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseUpdateDialog}
          >
            Update Virtual Msisdn
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <SimpleForm toolbar={<ToolbarEdit />} title=" ">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="msisdn"
                    data-testid="msisdnInput"
                    label="msisdn"
                    validate={required()}
                    onChange={(e) =>
                      setUpdatedData((prevState: any) => ({
                        ...prevState,
                        msisdn: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    fullWidth
                    className={classes.input}
                    source="country"
                    validate={[required()]}
                    choices={country}
                    label="country"
                    data-test-id="country"
                    onChange={(e) =>
                      setUpdatedData((prevState: any) => ({
                        ...prevState,
                        country: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    fullWidth
                    className={classes.input}
                    source="hkd_price"
                    data-testid="hkdPrice"
                    label="HKD Price"
                    validate={required()}
                    onChange={(e) =>
                      setUpdatedData((prevState: any) => ({
                        ...prevState,
                        hkd_price: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button data-testid="save-btn" onClick={handleConfirmDialogOpen}>
              Save
            </Button>
          </DialogActions>
        </BootstrapDialog>
        {/* Show input totp dialog */}
        <Confirm
          id="muiDrawer"
          isOpen={openConfirmDialog}
          loading={otpCode.length < 6 ? true : isLoadingUpdate}
          title="OTP code"
          content={
            <MuiTextField
              type="number"
              fullWidth
              data-test-id="input-otp"
              value={otpCode}
              onChange={(e: any) => setOtpCode(e.target.value)}
            />
          }
          onConfirm={handleValidateOtp}
          onClose={handleConfirmDialogClose}
          sx={{
            "& .Mui-disabled": {
              color: "#c5c5c5",
            },
          }}
        />
      </div>
    </RecordContextProvider>
  );
}
