import List from "./ListPage";
import Edit from "./EditPage";
import Create from "./CreatePage";

const resource = {
  list: List,
  edit: Edit,
  create: Create,
};

export default resource;
