import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export interface Isubscribers {
  subscribersFilter: {
    keyword: string;
    status: string;
    service: string;
    msisdn: string;
    passport_number: string;
    dateGte: Date | null;
    dateLte: Date | null;
  };
}

const initialState: Isubscribers = {
  subscribersFilter: {
    keyword: "",
    status: "",
    service: "",
    passport_number: "",
    msisdn: "",
    dateGte: null,
    dateLte: null,
  },
};

type subscribersFilter = {
  keyword: string;
  status: string;
  service: string;
  passport_number: string;
  msisdn: string;
  dateGte: Date | null;
  dateLte: Date | null;
};

export const subscribersFilterSlice = createSlice({
  name: "thisSubscribersFilter",
  initialState,
  reducers: {
    setSubscribersFilter: (state, action: PayloadAction<subscribersFilter>) => {
      state.subscribersFilter = {
        ...state.subscribersFilter,
        ...action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSubscribersFilter } = subscribersFilterSlice.actions;

export const getSubscribersFilter = (state: RootState) =>
  state.thisSubscribersFilter.subscribersFilter;

export default subscribersFilterSlice.reducer;
