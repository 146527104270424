// @ts-nocheck
import React from "react";
import {
  List,
  Datagrid,
  TopToolbar,
  useListContext,
  CreateButton,
  FunctionField,
  sanitizeListRestProps,
  SelectInput,
  FilterButton,
  TextInput,
} from "react-admin";
import HTMLRenderer from "react-html-renderer";

const langList = [
  { id: "en", name: "English" },
  { id: "id", name: "Indonesia" },
  { id: "ch", name: "Chinese" },
];

const FaqFilter = [
  <SelectInput
    source="language"
    label="Language"
    choices={langList}
    alwaysOn
  />,
  <TextInput source="question" label="Question" alwaysOn />,
];

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={FaqFilter} />
      <CreateButton data-test-id="create_btn" basePath={basePath} />
    </TopToolbar>
  );
};

const ListPage = (props) => {
  return (
    <List {...props} title="FAQs" actions={<ListActions />} filters={FaqFilter}>
      <Datagrid data-test-id="datagrid_faqs" rowClick="edit">
        <FunctionField
          render={(record) => {
            return langList.filter((val) => val.id === record.language)[0].name;
          }}
          source="language"
        />
        <FunctionField
          render={(record) => {
            return record.question.split(" ").slice(0, 10).join(" ");
          }}
          source="question"
        />
        <FunctionField
          render={(record) => <HTMLRenderer html={record.answer} />}
          source="answer"
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;
