// @ts-nocheck
import { List, Datagrid, FunctionField, TextField } from "react-admin";
import { format } from "date-fns";

const ListPage = (props) => {
  return (
    <List {...props} title="Diasporas Latest News">
      <Datagrid
        data-test-id="datagrid_news"
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField source="country_code" label="Country" />
        <FunctionField
          render={(record) => {
            return record?.content?.title;
          }}
          source="content"
          label="Title"
        />
        <FunctionField
          render={(record) => {
            return format(new Date(record?.updated_at), "dd-MM-yyyy");
          }}
          source="updated_at"
          label="Last Update"
        />
      </Datagrid>
    </List>
  );
};

export default ListPage;
