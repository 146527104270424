import { AutocompleteInput, required, useInput } from "react-admin";

interface ServiceInputProps {
  isLoading: boolean;
}

const ServiceInput: React.FC<ServiceInputProps> = ({ isLoading }) => {
  const { field: fieldDeliveryMode } = useInput({
    source: "delivery_mode",
  });

  return (
    <AutocompleteInput
      source="availability_by_service"
      optionValue="id"
      optionText="name"
      label="Availability by services"
      fullWidth
      readOnly={isLoading}
      validate={required()}
      onChange={() => {
        fieldDeliveryMode.onChange(null);
      }}
      choices={[
        { id: "G2", name: "G2" },
        { id: "M21", name: "M21" },
        { id: "GHK", name: "GHK" },
      ]}
    />
  );
};

export default ServiceInput;
