import React, { useState, useMemo } from "react";
import Select from "react-select";
// @ts-ignore
import countryList from "react-select-country-list";

function CountrySelector(props: any) {
  const { destinationCountry, setDestinationCountry } = props;
  const options = useMemo(() => countryList().getData(), []);
  const worldwide = [{ value: "GLOBAL", label: "Worldwide" }];
  const res = worldwide.concat(options);

  const changeHandler = (value: any) => {
    setDestinationCountry(value);
  };

  return (
    <Select
      // @ts-ignore
      options={res}
      value={destinationCountry}
      getOptionLabel={(halo: any) => {
        return `(${halo?.value}) ${halo?.label}`;
      }}
      onChange={changeHandler}
      placeholder={<div>Select Country</div>}
    />
  );
}

export default CountrySelector;
